import React from 'react';
import ReactDOM from "react-dom/client";
import {AppDesktop as App} from "./app/common/App/App@desktop";
import CustomSprite from "./app/helpers/CustomSprite/CustomSprite";
import 'normalize.css';
import "./app/themes/default/root.css"
import {Provider as ReduxProvider} from "react-redux";
import {configureStore} from '@reduxjs/toolkit';
import {appReducer} from "./app/redux/AppReducer";
import reportWebVitals from './reportWebVitals';
import './app/i18n';

const appStore = configureStore({reducer: appReducer});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <CustomSprite/>
        <ReduxProvider store={appStore}>
            <App/>
        </ReduxProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
