import React from 'react';
import { IClassNameProps } from '@bem-react/core'
import {cn} from "@bem-react/classname";
import {useRegistry} from "@bem-react/di";
import {appId} from "../../common/App/App";
import './ProfilePage.css';
import {Footer, PageWrapper} from "../../blocks";
import '../../i18n/';
import {useTranslation} from 'react-i18next';

export interface IProfilePageProps extends IClassNameProps {
}

export const cnProfilePage = cn('ProfilePage');

export const ProfilePage: React.FC<IProfilePageProps> = ({className}) => {
    const {t} = useTranslation();
    const {Header} = useRegistry(appId);

    return (
        <div className={cnProfilePage({}, [className, 'CommonPage'])}>
            <Header title={t('profile-page-title')}/>
            <Header/>
            <PageWrapper>
                {t('profile')}
            </PageWrapper>
            <Footer/>
        </div>
    )
};

