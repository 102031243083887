import {Box3, Mesh, Vector2, Vector3} from "three";
import {ThreeConstructor} from "../../../ThreeConstructor";
import {ThreeGeometry} from "../../../helpers";
import {IThreePanelIndentionPoints, IThreePanelPoints} from "../../../interfaces";
import {IPanel, IPoint} from "../../../../../../domain/intarfaces";
import {ThreeObject} from "../../ThreeObject";
import {ThreeSideWardrobe} from "../../ThreeSideWardrobe";
import {ThreeConnectionPoint, ThreePanelPoint, ThreePoint} from "../../points";
import {
    CONNECTION_TYPE_DEFAULT,
    CONNECTION_TYPE_MAIN_LEFT,
    CONNECTION_TYPE_MAIN_RIGHT,
    IMPORT_PANEL_DIRECTION_TYPE_VERTICAL,
    MATERIAL_TYPE_LDSP
} from "../../../../../../common-code/constants/";
import {TPanelShift, TPanelType} from "../../../../../../common-code/domain/types";
import {AXIS_X, AXIS_Y} from "../../../../../../domain/constants";
import {IPanelData} from '../../../../../../common-code/domain/interfaces/IPanelData/IPanelData';
import {TPanelDirectionType} from "../../../../../../common-code/domain/types/TPanelDirectionType";
import {TPanelContourPath} from "../../../../../../common-code/domain/types/TPanelContourPath";
import {TPanelButt} from "../../../../../../common-code/domain/types/TPanelButt";
import {ISquareEntityData} from '../../../../../../common-code/domain/interfaces/ISquareEntityData';
import {
    ISquareMaterialPriceData
} from '../../../../../../common-code/domain/interfaces/IPriceData/ISquareMaterialPriceData';
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {
    IMPORT_PANEL_DIRECTION_TYPE_FRONT,
    IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL
} from "../../../../../../common-code/constants";
import {TMaterialType} from "../../../../../../common-code/domain/types/TMaterialType";
import {IPanelPriceData} from '../../../../../../common-code/domain/interfaces/IPriceData/IPanelPriceData';
import {TPanelOutsole} from '../../../../../../common-code/domain/types/TPanelOutsole';
import {
    IPlankMaterialPriceData
} from '../../../../../../common-code/domain/interfaces/IPriceData/IPlankMaterialPriceData';
import {IPlankEntityData} from '../../../../../../common-code/domain/interfaces/IPlankEntityData';
import {CommonHelper} from '../../../../../helpers/CommonHelper';
import {TThreeLine3d} from '../../../../../types/TThreeLine3d';

interface IPoints {
    A: Vector3;
    B: Vector3;
    C: Vector3;
    D: Vector3;
}

export class ThreePanel extends ThreeObject implements IPanel {
    readonly isPanel: boolean;
    sideWardrobe: ThreeSideWardrobe;
    mainConstructor: ThreeConstructor;
    width: number;
    depth: number;
    pointA: ThreeConnectionPoint | ThreePanelPoint;
    pointB: ThreeConnectionPoint | ThreePanelPoint;
    sort: number;
    leftNeighbor?: ThreePanel;
    rightNeighbor?: ThreePanel;
    leftParent?: ThreePanel;
    rightParent?: ThreePanel;
    points?: IThreePanelPoints;
    indentionPoints?: IThreePanelIndentionPoints;
    visiblePoints?: IThreePanelPoints;
    shift: TPanelShift;
    isPseudo: boolean;
    type: TPanelType;
    directionType: TPanelDirectionType;
    contourPath?: TPanelContourPath;
    butts?: TPanelButt[];
    materialId: number;
    material?: ISquareEntityData;
    outsoles?: TPanelOutsole[];

    constructor(sideWardrobe: ThreeSideWardrobe, options: IPanelData) {
        super(sideWardrobe.mainConstructor, options);
        this.sideWardrobe = sideWardrobe;
        this.mainConstructor = sideWardrobe.mainConstructor;
        this.width = options.width;
        this.depth = options.depth;
        this.sort = options.sort;
        this.materialId = options.materialId;
        this.material = this.initMaterialData();
        this.pointA = ThreePanel._getPoint(this.mainConstructor.getPointById(options.pointA));
        this.pointB = ThreePanel._getPoint(this.mainConstructor.getPointById(options.pointB));
        this.isPanel = true;
        this.shift = options.shift;
        this.isPseudo = options.isPseudo !== undefined ? options.isPseudo : false;
        this.type = options.type;
        this.directionType = options.directionType;
        this.contourPath = options.contourPath;
        this.butts = options.butts ? CommonHelper.deepCopy(options.butts) : [];
        this.outsoles = options.outsoles;
    }

    /**
     * Get reference point (A or B) with correct type
     *
     * @param point
     * @private
     */
    private static _getPoint(point: IPoint): ThreePanelPoint | ThreeConnectionPoint {
        if (point.panelId) {
            return point as ThreePanelPoint;
        } else {
            return point as ThreeConnectionPoint;
        }
    }

    public getSaveData(): IPanelData {
        super.getSaveData();
        let globalPoints: IStartEndPoints | undefined;
        globalPoints = this.calculateGlobalPoints();

        return {
            id: this.getId(),
            pointA: this.pointA.getId(),
            pointB: this.pointB.getId(),
            shift: this.shift,
            depth: this.depth,
            width: this.width,
            sort: this.sort,
            type: this.type,
            directionType: this.calculateDirectionType(globalPoints),
            isPseudo: this.isPseudo,
            contourPath: this.contourPath,
            butts: this.getButtsSaveData(),
            outsoles: this.outsoles ? [...this.outsoles] : [],
            materialId: this.materialId,
            productCode: this.getProductCode(),
            startPoint: globalPoints?.start,
            endPoint: globalPoints?.end,
            materialType: this.getMaterialType(),
        };
    }

    public getLength(): number {
        if (!this.visiblePoints) {
            this.calculatePoints();
            this.calculateVisiblePoints();
        }

        if (!this.visiblePoints) {
            return 0;
        }

        return ThreeGeometry.getLength(this.visiblePoints.A, this.visiblePoints.B);
    }

    public getDepth(): number {
        return +this.depth;
    }

    public setLeftNeighbor(panel: ThreePanel): void {
        this.leftNeighbor = panel;
    }

    public setRightNeighbor(panel: ThreePanel): void {
        this.rightNeighbor = panel;
    }

    public setLeftParent(panel: ThreePanel | undefined): void {
        this.leftParent = panel;
    }

    public setRightParent(panel: ThreePanel | undefined): void {
        this.rightParent = panel;
    }

    public initState() {
        this.calculatePoints();
        this.calculateVisiblePoints();
        this.calculateIndentionPoints();
    }

    public createView(): void {
        if (!this.points) {
            return;
        }
        this.createBody();
        this.createOutsoles();
        this.createCarcass();
        this.sideWardrobe.view3d.add(this.view3d);
        if (this.isPseudo) {
            this.view3d.visible = false;
        }
        super.createView();
    }

    public calculateConnectionPoints(point: ThreePoint): IPoints {
        if (!this.points) {
            this.calculatePoints();
        }
        if (!this.points) {
            throw new Error('Error in calculateConnectionPoints')
        }
        let indentionValue = this.mainConstructor.getConstructorService().getIndentionValue(
            point.connection.indention,
            this
        );

        if (indentionValue !== 0) {
            let localPointA;
            let localPointB;
            let points: IPoints;

            localPointA = ThreeGeometry.toVector2D(
                this.pointA.value,
                AXIS_X,
                AXIS_Y
            );
            localPointB = ThreeGeometry.toVector2D(
                this.pointB.value,
                AXIS_X,
                AXIS_Y
            );
            points = {
                A: this.calculateShiftBottomPoint(
                    localPointA,
                    localPointA,
                    localPointB,
                    -this.shift.depth - indentionValue
                ),
                B: this.calculateShiftBottomPoint(
                    localPointB,
                    localPointA,
                    localPointB,
                    -this.shift.depth - indentionValue
                ),
                C: this.calculateShiftBottomPoint(
                    localPointA,
                    localPointA,
                    localPointB,
                    -this.shift.depth + this.depth - indentionValue
                ),
                D: this.calculateShiftBottomPoint(
                    localPointB,
                    localPointA,
                    localPointB,
                    -this.shift.depth + this.depth - indentionValue
                ),
            };

            return points;
        }

        return this.points;
    }

    protected getProductCode(): string | undefined {
        if (this.material) {
            return this.material.product.code;
        }

        return undefined;
    }

    protected getMaterialType(): TMaterialType {
        return MATERIAL_TYPE_LDSP;
    }

    protected calculateGlobalPoints(): IStartEndPoints | undefined {
        let points: Vector3[] = [];
        let box: Box3;
        if (!this.visiblePoints) {
            this.calculatePoints();
            this.calculateVisiblePoints();
        }
        if (!this.visiblePoints) {
            return undefined;
        }
        points.push(this.visiblePoints.A.clone().applyMatrix4(this.view3d.matrixWorld));
        points.push(this.visiblePoints.B.clone().applyMatrix4(this.view3d.matrixWorld));
        points.push(this.visiblePoints.C.clone().applyMatrix4(this.view3d.matrixWorld));
        points.push(this.visiblePoints.D.clone().applyMatrix4(this.view3d.matrixWorld));
        points.push(this.visiblePoints.A2.clone().applyMatrix4(this.view3d.matrixWorld));
        points.push(this.visiblePoints.B2.clone().applyMatrix4(this.view3d.matrixWorld));
        points.push(this.visiblePoints.C2.clone().applyMatrix4(this.view3d.matrixWorld));
        points.push(this.visiblePoints.D2.clone().applyMatrix4(this.view3d.matrixWorld));

        box = new Box3();
        box.setFromPoints(points);

        return {
          start: {x: box.min.x, y: box.min.y, z: box.min.z},
          end: {x: box.max.x, y: box.max.y, z: box.max.z}
        };
    }

    protected calculateDirectionType(globalPoints?: IStartEndPoints): TPanelDirectionType {
        if (globalPoints) {
            let x: number;
            let y: number;
            let z: number;

            x = globalPoints.end.x - globalPoints.start.x;
            y = globalPoints.end.y - globalPoints.start.y;
            z = globalPoints.end.z - globalPoints.start.z;
            if (this.getDepth() === x) {
                return IMPORT_PANEL_DIRECTION_TYPE_VERTICAL;
            }
            if (this.getDepth() === y) {
                return IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL;
            }
            if (this.getDepth() === z) {
                return IMPORT_PANEL_DIRECTION_TYPE_FRONT;
            }
        }

        return this.directionType;
    }

    protected initMaterialData(): ISquareEntityData | undefined {
        if (this.materialId > 0) {
            return this.mainConstructor.getLdspById(this.materialId);
        }
        return undefined;
    }

    protected createBodyMaterial() {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.mainConstructor.getConstructorService().getPanelBodyMaterial();
        }
        if (this.isPseudo) {
            this.bodyMaterial.transparent = true;
            this.bodyMaterial.opacity = 0.2;
        }

        return this.bodyMaterial;
    }

    public calculatePoints() {
        // Если уже рассчитали точки, то не нужно заново считать
        if (this.points) {
            return;
        }
        let localPointA;
        let localPointB;
        let bottomPoints: IPoints;
        let topPoints: IPoints;

        localPointA = ThreeGeometry.toVector2D(
            this.pointA.value,
            AXIS_X,
            AXIS_Y
        );
        localPointB = ThreeGeometry.toVector2D(
            this.pointB.value,
            AXIS_X,
            AXIS_Y
        );
        bottomPoints = {
            A: this.calculateShiftBottomPoint(localPointA, localPointA, localPointB, -this.shift.depth),
            B: this.calculateShiftBottomPoint(localPointB, localPointA, localPointB, -this.shift.depth),
            C: this.calculateShiftBottomPoint(localPointA, localPointA, localPointB, -this.shift.depth + this.depth),
            D: this.calculateShiftBottomPoint(localPointB, localPointA, localPointB, -this.shift.depth + this.depth),
        };
        topPoints = {
            A: this.calculateShiftTopPoint(bottomPoints.A, this.width + this.shift.width),
            B: this.calculateShiftTopPoint(bottomPoints.B, this.width + this.shift.width),
            C: this.calculateShiftTopPoint(bottomPoints.C, this.width + this.shift.width),
            D: this.calculateShiftTopPoint(bottomPoints.D, this.width + this.shift.width),
        };
        this.setThisPoints(bottomPoints, topPoints);
    }

    public getPriceData(): IPanelPriceData {
        let priceData: IPanelPriceData;
        let squarePriceData: ISquareMaterialPriceData;
        let globalPoints: IStartEndPoints | undefined;
        let square: number | undefined;
        let sizes: number[];
        let depth: number | undefined;


        squarePriceData = this.mainConstructor.initSquareMaterialPriceData();
        priceData = {
            material: squarePriceData,
            butts: this.getButtsPriceData(),
        };
        globalPoints = this.calculateGlobalPoints();

        if (this.material && this.visiblePoints && !this.isPseudo && globalPoints) {
            priceData.material.name = this.material.product.name;
            priceData.material.code = this.material.product.code;
            priceData.material.materialId = this.material.id;
            priceData.material.sheetPrice = this.material.price;
            priceData.material.sheetSquare = this.material.sheetSquare;
            sizes = [
                (globalPoints.end.x - globalPoints.start.x),
                (globalPoints.end.y - globalPoints.start.y),
                (globalPoints.end.z - globalPoints.start.z)
            ];
            switch (sizes.indexOf(Math.min(...sizes))) {
                case 0:
                    depth = sizes[0];
                    square = sizes[1] * sizes[2];
                    break;
                case 1:
                    depth = sizes[1];
                    square = sizes[0] * sizes[2];
                    break;
                case 2:
                    depth = sizes[2];
                    square = sizes[0] * sizes[1];
                    break;
            }
            if (square) {
                priceData.material.square = square * 0.000001;
                priceData.material.sheetCount = Math.ceil(priceData.material.square / this.material.sheetSquare);
                priceData.material.sum = priceData.material.sheetCount * priceData.material.sheetPrice
            } else {
                debugger;
                console.error('square error');
            }
        }

        return priceData;
    }

    public getMaterialId(): number {
        return this.material ? this.material.id : 0;
    }

    protected getButtsPriceData(): IPlankMaterialPriceData[] {
        let buttPlanks: { [key: string]: IPlankMaterialPriceData } = {};
        let butt: TPanelButt;
        let butts: TPanelButt[] = this.getButtsSaveData();
        let buttMaterial: IPlankEntityData;
        let materialId: number | undefined;
        let plankLength: number;
        let buttId: string;
        for (butt of butts) {
            materialId = butt.materialId;
            if (!materialId || !butt.length) {
                continue;
            }
            buttMaterial = this.mainConstructor.getButtMaterial(this.getMaterialId());
            buttId = materialId + '_' + buttMaterial.price;
            if (!buttPlanks[buttId]) {
                plankLength = Math.ceil(butt.length / buttMaterial.length);
                buttPlanks[buttId] = {
                    materialId: +materialId,
                    plankPrice: buttMaterial.price,
                    length: butt.length,
                    plankLength: plankLength,
                    errors: [],
                    sum: plankLength * buttMaterial.price,
                    name: buttMaterial.product.name,
                    code: buttMaterial.product.code,
                    store: '-',
                    price: buttMaterial.price,
                    amount: 0,
                    count: plankLength
                }
            } else {
                buttPlanks[buttId].length += butt.length;
                plankLength = Math.ceil(buttPlanks[buttId].length / buttMaterial.length);
                buttPlanks[buttId].sum = plankLength * buttMaterial.price;
            }
        }

        return Object.values(buttPlanks);
    }

    protected getButtsSaveData(): TPanelButt[] {
        if (!this.butts) {
            return [];
        }
        let index: string;
        let buttMaterial: IPlankEntityData;
        let points: TThreeLine3d | undefined;

        if (!this.getMaterialId()) {
            return this.butts;
        }
        buttMaterial = this.mainConstructor.getButtMaterial(this.getMaterialId());
        for (index in this.butts) {
            points = this.calculateSegmentPoints(index);
            if (points) {
                this.butts[index].pointA = ThreeGeometry.toPoint3D(points.pointA);
                this.butts[index].pointB = ThreeGeometry.toPoint3D(points.pointB);
                this.butts[index].length = points.pointA.clone().distanceTo(points.pointB);
                this.butts[index].width = buttMaterial.width;
                this.butts[index].materialId = buttMaterial.id;
            }
        }

        return this.butts;
    }

    protected calculateSegmentPoints(segmentIndex: string): TThreeLine3d | undefined {
        let globalPoints: IStartEndPoints | undefined;
        let directionType: TPanelDirectionType;
        let line: TThreeLine3d | undefined;
        let axe1: string;
        let axe2: string;
        let constAxe: string;

        globalPoints = this.calculateGlobalPoints();
        if (!globalPoints) {
            return undefined;
        }
        directionType = this.calculateDirectionType(globalPoints);
        switch (directionType) {
            case IMPORT_PANEL_DIRECTION_TYPE_VERTICAL:
                constAxe = 'x';
                axe1 = 'y';
                axe2 = 'z';
                break;
            case IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL:
                axe1 = 'x';
                constAxe = 'y';
                axe2 = 'z';
                break;
            case IMPORT_PANEL_DIRECTION_TYPE_FRONT:
                axe1 = 'x';
                axe2 = 'y';
                constAxe = 'z';
                break;
        }
        switch (segmentIndex) {
            case '0':
                // line = {
                //     pointA: new Vector3(globalPoints.start.x, globalPoints.start.y, globalPoints.start.z),
                //     pointB: new Vector3(globalPoints.end.x, globalPoints.start.y, globalPoints.start.z)
                // }
                break;
            case '1':
                break;
            case '2':
                break;
            case '3':
                break;
        }

        return line;
    }

    protected calculateShiftBottomPoint(point: Vector2, pointA: Vector2, pointB: Vector2, shift: number): Vector3 {
        let shiftPoint: Vector2;

        shiftPoint = ThreeGeometry.getShiftPoint2D(point, pointA, pointB, shift);

        return new Vector3(
            shiftPoint.x,
            shiftPoint.y,
            this.shift.width
        );
    }

    private setThisPoints(bottomPoints: IPoints, topPoints: IPoints): void {
        this.points = {
            A: bottomPoints.A,
            B: bottomPoints.B,
            C: bottomPoints.C,
            D: bottomPoints.D,
            A2: topPoints.A,
            B2: topPoints.B,
            C2: topPoints.C,
            D2: topPoints.D,
        };
    }

    private calculateParentIntersectPoints(parent: ThreePanel): void {
        if (!this.points || !this.visiblePoints || !parent) {
            return;
        }
        parent.calculatePoints();
        if (!parent.points) {
            return;
        }
        if (!this.leftParent || !this.rightParent) {
            return;
        }

        let intersectPoint;
        let intersectPoints = [];
        let intersectPointA;
        let intersectPointB;
        let parentPoints: IPoints;

        parentPoints = (parent === this.leftParent) ?
            this.leftParent.calculateConnectionPoints(this.pointA) :
            this.rightParent.calculateConnectionPoints(this.pointB);

        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
            },
            {
                pointA: ThreeGeometry.toVector2D(parentPoints.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(parentPoints.B, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
            },
            {
                pointA: ThreeGeometry.toVector2D(parentPoints.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(parentPoints.D, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointA = ThreeGeometry.getNearPoint2D(
            ThreeGeometry.toVector2D((parent === this.leftParent ? this.points.B : this.points.A), AXIS_X, AXIS_Y),
            intersectPoints
        );
        if (intersectPointA) {
            if (parent === this.leftParent) {
                this.visiblePoints.A = new Vector3(intersectPointA.x, intersectPointA.y, this.shift.width);
                this.visiblePoints.A2 = new Vector3(intersectPointA.x, intersectPointA.y, this.width + this.shift.width);
            }
            if (parent === this.rightParent) {
                this.visiblePoints.B = new Vector3(intersectPointA.x, intersectPointA.y, this.shift.width);
                this.visiblePoints.B2 = new Vector3(intersectPointA.x, intersectPointA.y, this.width + this.shift.width);
            }
        }

        intersectPoints = [];

        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
            },
            {
                pointA: ThreeGeometry.toVector2D(parentPoints.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(parentPoints.B, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
            },
            {
                pointA: ThreeGeometry.toVector2D(parentPoints.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(parentPoints.D, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointB = ThreeGeometry.getNearPoint2D(
            ThreeGeometry.toVector2D((parent === this.leftParent ? this.points.D : this.points.C), AXIS_X, AXIS_Y),
            intersectPoints
        );
        if (intersectPointB) {
            if (parent === this.leftParent) {
                this.visiblePoints.C = new Vector3(intersectPointB.x, intersectPointB.y, this.shift.width);
                this.visiblePoints.C2 = new Vector3(intersectPointB.x, intersectPointB.y, this.width + this.shift.width);
            }
            if (parent === this.rightParent) {
                this.visiblePoints.D = new Vector3(intersectPointB.x, intersectPointB.y, this.shift.width);
                this.visiblePoints.D2 = new Vector3(intersectPointB.x, intersectPointB.y, this.width + this.shift.width);
            }
        }
    }

    private calculateLeftNeighborIntersectPoints() {
        let intersectPoint;
        if (!this.points || !this.visiblePoints || !this.leftNeighbor) {
            return;
        }
        this.leftNeighbor.calculatePoints();
        if (this.leftNeighbor.points) {
            switch (this.pointA.connectionType) {
                case CONNECTION_TYPE_DEFAULT:
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.leftNeighbor.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.leftNeighbor.points.B, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.A = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.A2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.leftNeighbor.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.leftNeighbor.points.D, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.C = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.C2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    break;
                case CONNECTION_TYPE_MAIN_LEFT:
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.leftNeighbor.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.leftNeighbor.points.D, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.A = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.A2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.leftNeighbor.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.leftNeighbor.points.D, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.C = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.C2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    break;
            }
        }
    }

    private calculateRightNeighborIntersectPoints() {
        let intersectPoint;
        if (!this.points || !this.visiblePoints || !this.rightNeighbor) {
            return;
        }
        this.rightNeighbor.calculatePoints();
        if (this.rightNeighbor.points) {
            switch (this.pointB.connectionType) {
                case CONNECTION_TYPE_DEFAULT:
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.rightNeighbor.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.rightNeighbor.points.B, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.B = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.B2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.rightNeighbor.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.rightNeighbor.points.D, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.D = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.D2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    break;
                case CONNECTION_TYPE_MAIN_RIGHT:
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.rightNeighbor.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.rightNeighbor.points.D, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.B = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.B2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.rightNeighbor.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.rightNeighbor.points.D, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.D = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.width);
                        this.visiblePoints.D2 = new Vector3(intersectPoint.x, intersectPoint.y, this.width + this.shift.width);
                    }
                    break;
            }
        }
    }

    public calculateVisiblePoints() {
        if (!this.points) {
            return;
        }
        this.visiblePoints = {...this.points} as IThreePanelPoints;

        if (this.leftNeighbor) {
            this.calculateLeftNeighborIntersectPoints();
        }
        if (this.rightNeighbor) {
            this.calculateRightNeighborIntersectPoints();
        }
        if (this.leftParent) {
            this.calculateParentIntersectPoints(this.leftParent);
        }
        if (this.rightParent) {
            this.calculateParentIntersectPoints(this.rightParent);
            // this.calculateRightParentIntersectPoints();
        }
    }

    private calculateIndentionPoints() {
        if (!this.points) {
            return;
        }
        this.indentionPoints = {
            leftA: this.points.A,
            leftB: this.points.B,
            rightA: this.points.A,
            rightB: this.points.B
        };
    }


    private createBody() {
        if (!this.visiblePoints) {
            return;
        }
        this.body = new Mesh(ThreeGeometry.createBoxGeometry(this.visiblePoints), this.createBodyMaterial());
        this.view3d.add(this.body);
    }

    private createOutsoles() {
        if (!this.outsoles) {
            return;
        }
    }
}