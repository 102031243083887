import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {useNewDoorSections} from '../../../../hooks';
import {cnButtonInput, ButtonInput} from '../../../all/ButtonInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TSelectOption} from '../../../../../common-code/domain/types';
import {Dispatch} from 'redux';
import {CHANGE_CONSTRUCTOR_FORM_DATA} from '../../../../constants';
import {DoorSectionFragment} from '../DoorSectionFragment';
import {CommonHelper} from '../../../../helpers/CommonHelper';
import {ThreeConstructorService} from '../../../3d';
import {IEntityData} from '../../../../../common-code/domain/interfaces/IEntityData';
import {MATERIAL_TYPE_MIRROR} from '../../../../../common-code/constants';

export const cnDoorFragment = cn('DoorFragment');

export interface IDoorFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    constructorService: ThreeConstructorService,
    sideIndex: number,
    doorIndex: number,
    dispatch: Dispatch,
    minCountSections: number,
    maxCountSections: number,
    materialTypes: {
        placeholder: string,
        options: Array<TSelectOption>
    },
    ldsp: IEntityData[]
    mirrors: IEntityData[]
}

export const DoorFragment: React.FC<IDoorFragmentProps> =
    ({
         className,
         constructorFormData,
         constructorService,
         sideIndex,
         doorIndex,
         dispatch,
         minCountSections,
         maxCountSections,
         materialTypes,
         ldsp,
         mirrors
     }) => {

        const {createNewDoorSections} = useNewDoorSections();

        const addDoorSection = () => {
            let newCountDoorSections: number;
            let newConstructorFormData = CommonHelper.deepCopy(constructorFormData);

            newCountDoorSections = newConstructorFormData.sideWardrobes[sideIndex].doors[doorIndex].countDoorSections;
            newCountDoorSections += 1;
            newConstructorFormData = createNewDoorSections(newConstructorFormData, sideIndex, doorIndex, newCountDoorSections,
                constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0);
            dispatch({
                type: CHANGE_CONSTRUCTOR_FORM_DATA,
                payload: newConstructorFormData,
            });
        }


        return (
            <div className={cnConstructorForm(cnDoorFragment(), [className])}>
                {constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].doorSections.map((doorSection, doorSectionIndex) => {
                    return (
                        <DoorSectionFragment
                            key={doorSectionIndex}
                            constructorFormData={constructorFormData}
                            sideIndex={sideIndex}
                            doorIndex={doorIndex}
                            doorSectionIndex={doorSectionIndex}
                            isDeleted={constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].countDoorSections > minCountSections}
                            dispatch={dispatch}
                            materialTypes={materialTypes}
                            materials={doorSection.materialType === MATERIAL_TYPE_MIRROR ? mirrors : ldsp}
                        />
                    );
                })}
                {constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].countDoorSections < maxCountSections ?
                    <div className={cnConstructorForm('AddButton')}>
                        <ButtonInput
                            className={`${cnButtonInput({size: 'sm'})} ${cnConstructorForm(cnButtonInput())}`}
                            text={'Добавить секцию'}
                            leftIcon={'add'}
                            callBack={addDoorSection}
                        />
                    </div> : null
                }
            </div>
        );
    };