import {IRod} from "../../../../../../domain/intarfaces";
import {ThreePanelPoint} from "../../points";
import {ThreePlank} from "../";
import {ThreeSideWardrobe} from "../../ThreeSideWardrobe";
import {
    CylinderGeometry,
    EllipseCurve,
    ExtrudeGeometry,
    Mesh,
    MeshStandardMaterial,
    Shape,
    Vector2,
    Vector3
} from "three";
import {ThreeGeometry} from "../../../helpers";
import {AXIS_X, AXIS_Y} from "../../../../../../domain/constants";
import {ThreePanel} from "../../panels";
import {IRodData} from '../../../../../../common-code/domain/interfaces/IRodData/IRodData';
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {IRodPriceData} from '../../../../../../common-code/domain/interfaces/IPriceData/IRodPriceData';
import {IEntityData} from '../../../../../../common-code/domain/interfaces/IEntityData';
import {TRodMaterials} from '../../../../../types/TRodMaterials';
import {IFurniturePriceData} from '../../../../../../common-code/domain/interfaces/IPriceData/IFurniturePriceData';

export class ThreeRod extends ThreePlank implements IRod {
    pointA: ThreePanelPoint;
    pointB: ThreePanelPoint;
    radius: number;
    shift: number;
    sort: number;
    sideWardrobe: ThreeSideWardrobe;
    visiblePointA?: Vector3;
    visiblePointB?: Vector3;
    leftParent?: ThreePanel;
    rightParent?: ThreePanel;
    shape?: Shape;
    bodyMaterial?: MeshStandardMaterial;

    constructor(sideWardrobe: ThreeSideWardrobe, options: IRodData) {
        super(sideWardrobe.mainConstructor, options);
        this.sideWardrobe = sideWardrobe;
        this.pointA = this.mainConstructor.getPointById(options.pointA) as ThreePanelPoint;
        this.pointB = this.mainConstructor.getPointById(options.pointB) as ThreePanelPoint;
        this.radius = options.radius;
        this.shift = options.shift;
        this.sort = options.sort;
    }

    public createView() {
        this.calculatePointParents();
        this.calculatePoints();
        if (!this.visiblePointA || !this.visiblePointB) {
            return;
        }
        this.createShape();
        this.createBody();
        this.createCarcass();
        this.setPosition();
        this.sideWardrobe.view3d.add(this.view3d);
        super.createView();
    }

    public setPosition() {
        if (!this.visiblePointA || !this.visiblePointB) {
            return;
        }
        this.view3d.position.copy(ThreeGeometry.getPointByRatio(this.visiblePointA, this.visiblePointB, 0.5));
    }

    public getSaveData(): IRodData {
        super.getSaveData();
        let globalPoints: IStartEndPoints | undefined;
        globalPoints = this.calculateGlobalPoints();

        return {
            id: this.getId(),
            pointA: this.pointA.getId(),
            pointB: this.pointB.getId(),
            shift: this.shift,
            radius: this.radius,
            sort: this.sort,
            startPoint: globalPoints?.start,
            endPoint: globalPoints?.end,
            length: globalPoints?.length,
            productCode: this.getProductCode(),
            furniture: this.getFurnitureCode(),
            width: this.radius * 2,
            rotation: globalPoints?.rotation
        };
    }

    public getLength(): number {
        if (!this.visiblePointA || !this.visiblePointB) {
            return 0;
        }
        return Math.ceil(this.visiblePointA.clone().distanceTo(this.visiblePointB.clone()) - 2);
    }

    public getPriceData(): IRodPriceData {
        let rodMaterials: TRodMaterials | undefined;
        let priceData: IRodPriceData;
        let furnitureItem: IEntityData;
        let rodFixers: IFurniturePriceData | undefined;

        priceData = {
            planks: [],
            furniture: []
        };

        rodMaterials = this.mainConstructor.getRodMaterials();
        if (rodMaterials) {
            priceData.planks = [
                {
                    materialId: rodMaterials.plank.id,
                    length: this.getLength(),
                    plankPrice: rodMaterials.plank.price,
                    sum: Math.ceil(this.getLength() / rodMaterials.plank.length) * rodMaterials.plank.price,
                    code: rodMaterials.plank.product.code,
                    errors: [],
                    name: rodMaterials.plank.name,
                    plankLength: rodMaterials.plank.length,
                    store: '-',
                    price: rodMaterials.plank.price,
                    count: Math.ceil(this.getLength() / rodMaterials.plank.length),
                    amount: rodMaterials.plank.store || 0
                }
            ]
            for (furnitureItem of rodMaterials.furniture) {
                switch (furnitureItem.type) {
                    case "rodFixer":
                        if (!rodFixers) {
                            rodFixers = {
                                offerId: furnitureItem.id,
                                price: furnitureItem.price,
                                name: furnitureItem.name,
                                count: 2,
                                store: '-',
                                code: furnitureItem.product.code,
                                sum: 2 * furnitureItem.price,
                                errors: [],
                                amount: 0,
                            }
                        }
                }
            }
            if (rodFixers) {
                priceData.furniture.push(rodFixers);
            }
        }
        return priceData;
    }

    protected getProductCode(): string | undefined {
        // TODO
        return undefined;
    }

    protected getFurnitureCode(): string[] | undefined {
        // TODO
        return [];
    }

    private createShape() {
        let curve;

        curve = new EllipseCurve(0, 0, this.radius, this.radius, 0, 2 * Math.PI, false, 0);
        curve.getPoints();
        this.shape = new Shape(curve.getPoints(10));
    }

    private createBody() {
        let geometry;
        let length;
        let leftHolder;
        let holderGeometry;
        let leftLength = 5;
        let rightHolder;

        if (!this.shape || !this.visiblePointA || !this.visiblePointB) {
            return;
        }
        length = this.getLength();
        geometry = new ExtrudeGeometry(this.shape, {
            steps: 1,
            depth: length,
        });
        geometry.rotateY(Math.PI / 2);
        geometry.center();
        this.body = new Mesh(geometry, this.createBodyMaterial());
        this.view3d.add(this.body);
        holderGeometry = new CylinderGeometry(this.radius * 2.5, this.radius * 2, leftLength, 20);
        leftHolder = new Mesh(holderGeometry, this.createBodyMaterial());
        leftHolder.position.setX(-length / 2 + leftLength / 2);
        leftHolder.rotateZ(Math.PI / 2);
        leftHolder.updateMatrix();
        this.view3d.add(leftHolder);
        rightHolder = new Mesh(holderGeometry, this.createBodyMaterial());
        rightHolder.position.setX(length / 2 - leftLength / 2);
        rightHolder.rotateZ(-Math.PI / 2);
        rightHolder.updateMatrix();
        this.view3d.add(rightHolder);

    }

    protected createBodyMaterial() {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.mainConstructor.getConstructorService().getRodBodyMaterial();
        }

        return this.bodyMaterial;
    }

    private calculatePointParents() {
        this.leftParent = this.pointA.getParentPanel();
        this.rightParent = this.pointB.getParentPanel();
    }

    private calculatePoints() {
        let localPoints: { A: Vector2; B: Vector2 };
        let intersectPoints = [];
        let intersectPoint;
        let intersectPointA;
        let intersectPointB;

        if (!this.leftParent || !this.rightParent || !this.leftParent.points || !this.rightParent.points) {
            return;
        }
        if (!this.pointA.isInited()) {
            this.pointA.calculatePosition();
        }
        if (!this.pointB.isInited()) {
            this.pointB.calculatePosition();
        }
        if (!this.pointA.isInited() || !this.pointB.isInited()) {
            return;
        }

        localPoints = {
            A: ThreeGeometry.toVector2D(
                this.pointA.value,
                AXIS_X,
                AXIS_Y
            ),
            B: ThreeGeometry.toVector2D(
                this.pointB.value,
                AXIS_X,
                AXIS_Y
            )
        };
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointA = ThreeGeometry.getNearPoint2D(localPoints.B, intersectPoints);
        if (intersectPointA) {
            this.visiblePointA = new Vector3(intersectPointA.x, intersectPointA.y, this.shift);
        }
        intersectPoints = [];
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointB = ThreeGeometry.getNearPoint2D(localPoints.A, intersectPoints);
        if (intersectPointB) {
            this.visiblePointB = new Vector3(intersectPointB.x, intersectPointB.y, this.shift);
        }
    }

}