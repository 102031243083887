import {IEditorOptions} from "../interfaces";
import {
    DEFAULT_BOTTOM_PLINTH,
    DEFAULT_COUNT_DOORS,
    DEFAULT_COUNT_SECTIONS,
    DEFAULT_TOP_PLINTH,
    DIRECTION_TYPE_NORMAL,
    DRANK_PLINTH_TYPE_NONE,
    FITTINGS_TYPE_FIRST,
    INSTALLATION_TYPE_SEPARATE,
    SHELVES_TYPE_REMOVABLE,
    VIEW_MODE_3D,
    WARDROBE_DEFAULT_DEPTH,
    WARDROBE_DEFAULT_HEIGHT,
    WARDROBE_DEFAULT_WIDTH,
} from "../../common-code/constants";
import {IConstructorFormData} from "../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData";
import {ProjectGenerator} from "../../domain/services/ProjectGenerator/ProjectGenerator";

export const SCREEN_TYPE_HORIZONTAL = 'horizontal';
export const SCREEN_TYPE_VERTICAL = 'vertical';

export const SEND_SMS_TIMEOUT_SECONDS = 60;
export const CHANGE_EDITOR_OPTIONS = 'CHANGE_EDITOR_OPTIONS';
export const CHANGE_PROJECT_DATA = 'CHANGE_PROJECT_DATA';
export const CHANGE_PROJECT_PRICE_DATA = 'CHANGE_PROJECT_PRICE_DATA';
export const CHANGE_TECHNOLOG_MAP = 'CHANGE_TEHNOLOG_MAP';
export const CHANGE_CONSTRUCTOR_FORM_DATA = 'CHANGE_CONSTRUCTOR_FORM_DATA';
export const CHANGE_SELECT_DVP = 'CHANGE_SELECT_DVP';
export const CHANGE_SELECT_LDSP = 'CHANGE_SELECT_LDSP';
export const CHANGE_SELECT_DOOR_LDSP = 'CHANGE_SELECT_DOOR_LDSP';
export const CHANGE_SELECT_MIRROR = 'CHANGE_SELECT_MIRROR';
export const CHANGE_SIDE_CONTOUR_PANEL = 'CHANGE_SIDE_CONTOUR_PANEL';
export const CHANGE_SHELVES_TYPE = 'CHANGE_SHELVES_TYPE';
export const CHANGE_BOTTOM_PLINTH = 'CHANGE_BOTTOM_PLINTH';
export const CONSTRUCTOR_SERVICE_INIT = 'CONSTRUCTOR_SERVICE_INIT';
export const CHANGE_WARDROBE_DEPTH = 'CHANGE_WARDROBE_DEPTH';
export const CHANGE_WARDROBE_HEIGHT = 'CHANGE_WARDROBE_HEIGHT';
export const CHANGE_WARDROBE_WIDTH = 'CHANGE_WARDROBE_WIDTH';
export const CHANGE_DOOR_MATERIAL_TYPE = 'CHANGE_DOOR_MATERIAL_TYPE';
export const CHANGE_DOOR_MATERIAL_ID = 'CHANGE_DOOR_MATERIAL_ID';
export const CHANGE_DRANK_BOTTOM_PLINTH = 'CHANGE_DRANK_BOTTOM_PLINTH';
export const CHANGE_INSTALLATION_TYPE = 'CHANGE_INSTALLATION_TYPE';
export const CHANGE_SECTION_RODS_COUNT = 'CHANGE_SECTION_RODS_COUNT';
export const CHANGE_SECTION_DRAWERS_COUNT = 'CHANGE_SECTION_DRAWERS_COUNT';
export const CHANGE_SECTION_SHELVES_COUNT = 'CHANGE_SECTION_SHELVES_COUNT';
export const CHANGE_LDSP = 'CHANGE_LDSP';
export const CHANGE_DVP = 'CHANGE_DVP';
export const CHANGE_MIRRORS = 'CHANGE_MIRRORS';
export const START_SESSION = 'START_SESSION';
export const CLOSE_SESSION = 'CLOSE_SESSION';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_USER = 'SET_USER';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const EDITOR_INIT_OPTIONS: IEditorOptions = {
    canvasClass: 'Editor-Canvas Editor-Canvas_bordered',
    viewMode: VIEW_MODE_3D,
    showDoors: false,
    stats: false,
};

export const INIT_CONSTRUCTOR_FORM_DATA: IConstructorFormData = {
    directionType: DIRECTION_TYPE_NORMAL,
    installationType: INSTALLATION_TYPE_SEPARATE,
    bottomPlinth: DEFAULT_BOTTOM_PLINTH,
    topPlinth: DEFAULT_TOP_PLINTH,
    drankPlinth: DRANK_PLINTH_TYPE_NONE,
    materials: {
        ldsp: 0,
        dvp: 0
    },
    wardrobeFittings: {
        type: FITTINGS_TYPE_FIRST,
        color: 0
    },
    sideWardrobes: [
        {
            width: WARDROBE_DEFAULT_WIDTH,
            depth: WARDROBE_DEFAULT_DEPTH,
            height: WARDROBE_DEFAULT_HEIGHT,
            countSections: DEFAULT_COUNT_SECTIONS,
            countDoors: DEFAULT_COUNT_DOORS,
            sections: ProjectGenerator.getDefaultSections(DEFAULT_COUNT_SECTIONS),
            doors: ProjectGenerator.getDefaultDoors(DEFAULT_COUNT_DOORS, 0),
            shelvesType: SHELVES_TYPE_REMOVABLE,
            contourPanels: {
                left: true,
                right: true,
                bottom: true,
                top: true
            }
        }
    ]
};

export const SIZE_DEFAULT_LINE_INDENT = 80;
export const SIZE_DEFAULT_LINE_HEIGHT = 120;
export const SIZE_DEFAULT_TEXT_INDENT = 10;
export const SIZE_DEFAULT_TEXT_SIZE = 80;