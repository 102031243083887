import React, {useEffect, useRef, useState} from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./Constructor.css";
import {cnEditor, Editor} from "../";
import {ConstructorForm} from "../../forms/ConstructorForm";
import {cnPageWrapper} from "../../layout/PageWrapper/PageWrapper";
import {ThreeConstructor, ThreeConstructorService, ThreeEditor, UserControls} from '../../3d';
import {useDispatch, useSelector} from 'react-redux';
import {IEditorOptions} from '../../../interfaces';
import {AppState} from '../../../redux/AppStore';
import {
    IConstructorFormData
} from '../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import changeProject from '../../../helpers/changeProject';
import axios, {AxiosResponse} from 'axios';
import {useNavigate} from 'react-router-dom';
import {useEffectDidMount} from '../../../hooks/useEffectDidMount';
import {
    IWardrobeProjectData
} from '../../../../common-code/domain/interfaces/IWardrobeProjectData/IWardrobeProjectData';
import {TTechnologMap} from '../../../../common-code/domain/types';
import {CHANGE_CONSTRUCTOR_FORM_DATA, HIDE_LOADING, INIT_CONSTRUCTOR_FORM_DATA, SHOW_LOADING} from '../../../constants';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {DEFAULT_PROJECT_ID} from '../../../../common-code/constants';
import {CommonHelper} from '../../../helpers/CommonHelper';

export const cnConstructor = cn('Constructor');

export interface IConstructorProps extends IClassNameProps {
    projectId: string;
}

export const Constructor: React.FC<IConstructorProps> = ({className, projectId}) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const editorOptions: IEditorOptions = useSelector((state: AppState) => state.constructorData.editorOptions);
    const [constructorService, initConstructorService] = useState<ThreeConstructorService | undefined>();
    const [threeEditor, initThreeEditor] = useState<ThreeEditor | undefined>();
    const [userControls, initUserControls] = useState<UserControls | undefined>();
    const [threeConstructor, initThreeConstructor] = useState<ThreeConstructor | undefined>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectData: IWardrobeProjectData | undefined = useSelector((state: AppState) => state.constructorData.project);
    const constructorFormData: IConstructorFormData = useSelector((state: AppState) => state.constructorData.constructorFormData);
    const technologMap: TTechnologMap = useSelector((state: AppState) => state.constructorData.technologMap);
    const [hasErrorProject, setHasErrorProject] = useState(false);
    const {height, width} = useWindowDimensions();
    const [editorHeight, setEditorHeight] = useState<number>(600);
    const loading = useSelector((state: AppState) => state.common.loading);


    const loadProject = (projectId: string, projectData: IWardrobeProjectData | undefined) => {
        if (projectId === DEFAULT_PROJECT_ID) {
            if (constructorService) {
                constructorService.setFormData(INIT_CONSTRUCTOR_FORM_DATA)
            }
            dispatch({
                type: CHANGE_CONSTRUCTOR_FORM_DATA,
                payload: INIT_CONSTRUCTOR_FORM_DATA
            });
            dispatch({
                type: HIDE_LOADING
            });
        }
        if ((!projectData || (projectData && projectData.id !== projectId)) && projectId !== DEFAULT_PROJECT_ID) {
            dispatch({
                type: SHOW_LOADING
            });
            axios.get<Promise<IWardrobeProjectData>>('/api/project/get/' + projectId)
                .then((response: AxiosResponse) => {
                    dispatch({
                        type: HIDE_LOADING
                    });
                    if (response.data && response.data.project && response.data.formData) {
                        if (constructorService) {
                            constructorService.setFormData(response.data.formData)
                        }
                        dispatch({
                            type: CHANGE_CONSTRUCTOR_FORM_DATA,
                            payload: response.data.formData
                        });

                        if (projectId !== response.data.project.id) {
                            debugger;
                            navigate('/constructor/' + response.data.project.id + '/');
                        }
                    } else {
                        setHasErrorProject(true);
                    }
                })
                .catch(() => {
                    setHasErrorProject(true);
                    dispatch({
                        type: HIDE_LOADING
                    });
                });
        }
    };
    useEffectDidMount(() => {
        console.log('useEffectDidMount exec')
        loadProject(projectId, projectData);

        return () => {
            console.log('useEffectDidMount end')
        }
    });

    useEffect(() => {
        if (!projectData || loading) {
            return;
        }
        loadProject(projectId, projectData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    useEffect(() => {
        if (width > 768) {
            setEditorHeight(height);
        } else {
            setEditorHeight(600);
        }
    }, [width, height])


    useEffect(() => {
        if (!threeEditor) {
            initThreeEditor(new ThreeEditor(window, editorOptions, dispatch));
        }

    }, [threeEditor, editorOptions, dispatch]);

    useEffect(() => {
        if (!constructorService) {
            initConstructorService(new ThreeConstructorService(technologMap, constructorFormData, dispatch));
        } else {
            constructorService.setFormData(constructorFormData);
        }
    }, [constructorService, technologMap, constructorFormData, dispatch]);

    useEffect(() => {
        if (threeEditor) {
            initUserControls(new UserControls(threeEditor));
        }
    }, [threeEditor]);

    useEffect(() => {
        if (threeEditor && userControls && technologMap && constructorService) {
            constructorService.onInit().then(() => {
                initThreeConstructor(new ThreeConstructor(
                    constructorService,
                    threeEditor,
                    userControls,
                    technologMap,
                    dispatch
                ));
            });
        }
    }, [threeEditor, userControls, technologMap, constructorService, dispatch]);

    useEffect(() => {
        if (threeConstructor && constructorService && !constructorService.getConstructor()) {
            constructorService.setConstructor(threeConstructor);
        }
    }, [threeConstructor, constructorService]);

    useEffect(() => {
        try {
            if (threeConstructor && editorRef.current instanceof HTMLDivElement) {
                console.log('Editor useEffect threeConstructor initState');
                threeConstructor.initState(editorRef.current);
                threeConstructor.startRender();
            }
        } catch (e) {
            setHasErrorProject(true);
        }
        return () => {
            if (threeConstructor) {
                console.log('Editor useEffect threeConstructor stop');
                threeConstructor.remove();
            }
        };
    }, [threeConstructor]);

    useEffect(() => {
        if (threeEditor) {
            threeEditor.setOptions(editorOptions);
            threeEditor.rebuildState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorOptions]);

    useEffect(() => {
        if (constructorFormData && constructorService) {
            const newProjectData = constructorService.generateProject(constructorFormData);
            if (newProjectData && projectData &&
                CommonHelper.md5(newProjectData) !== CommonHelper.md5(projectData)) {
                changeProject(dispatch, editorOptions, newProjectData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constructorFormData, editorOptions]);

    useEffect(() => {
        if (threeConstructor && projectData) {
            threeConstructor.setProjectData(projectData);
            threeConstructor.rebuildProject();
        }
    }, [projectData, threeConstructor]);

    if (!projectData || hasErrorProject) {
        return (
            <div className={cnConstructor({}, [className])}>
                <div className={cnConstructor('LoadingContainer')}>
                    <div className={cnConstructor('Loading', {error: hasErrorProject})}>
                        {hasErrorProject ? 'Ошибка загрузки проекта!' : 'Загружаем проект...'}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cnConstructor({}, [cnPageWrapper(cnConstructor()), className])}>
            <div className={cnConstructor('Wrapper', {})}>
                <div className={cnConstructor('FormContainer')}>
                    {constructorService ?
                        <ConstructorForm className={cnConstructor('Form')}
                                         constructorService={constructorService}
                                         constructorFormData={constructorFormData}
                                         editorOptions={editorOptions}
                        />
                        : null
                    }
                </div>
                <div className={cnConstructor('EditorContainer', [cnConstructor('EditorContainer', {
                    fixed: true,
                    bkg: 'white'
                })])} style={{height: '' + (editorHeight - 120) + 'px'}}>
                    {threeConstructor ?
                        <Editor className={cnConstructor(cnEditor())} editorRef={editorRef}
                                threeConstructor={threeConstructor}/> : null}
                </div>
            </div>
            {loading ? <div className={cnConstructor('LoadingContainer')}>
                <div className={cnConstructor('Loading')}>
                    {'Загружаем проект...'}
                </div>
            </div> : null}
        </div>
    );
};