import {combineReducers} from "redux";
import {MenuReducer} from "./MenuReducer";
import {UserReducer} from "./UserReducer";
import {SessionReducer} from "./SessionReducer";
import {ConstructorReducer} from "./ConstructorReducer";
import {LocationReducer} from "./LocationReducer";
import {CommonReducer} from "./CommonReducer";

export const appReducer = combineReducers({
    menus: MenuReducer,
    user: UserReducer,
    session: SessionReducer,
    constructorData: ConstructorReducer,
    location: LocationReducer,
    common: CommonReducer,
});