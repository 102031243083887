import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {useNewDoors} from '../../../../hooks';
import {cnRangeInput, RangeInput} from '../../../all/RangeInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {Dispatch} from 'redux';
import {CHANGE_CONSTRUCTOR_FORM_DATA} from '../../../../constants';
import {CommonHelper} from '../../../../helpers/CommonHelper';

export const cnCountDoorsFragment = cn('CountDoorsFragment');

export interface ICountDoorsFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    materialId: number,
    sideIndex: number,
    dispatch: Dispatch,
    min: number,
    max: number,
    step?: number
}

export const CountDoorsFragment: React.FC<ICountDoorsFragmentProps> =
    ({
         className,
         constructorFormData,
         materialId,
         sideIndex,
         dispatch,
         min,
         max,
         step
     }) => {

        const {createNewDoors} = useNewDoors();

        const changeCountDoors = (newCountDoors: number) => {
            let newConstructorFormData = CommonHelper.deepCopy(constructorFormData);

            newConstructorFormData = createNewDoors(newConstructorFormData, sideIndex, newConstructorFormData.sideWardrobes[sideIndex].width, newCountDoors, materialId);
            dispatch({
                type: CHANGE_CONSTRUCTOR_FORM_DATA,
                payload: newConstructorFormData,
        });
    };

    return (
        <div className={cnConstructorForm(cnCountDoorsFragment(), [className])}>
            <RangeInput
                className={cnConstructorForm(cnRangeInput(), { wide: true })}
                min={min}
                max={max}
                step={step}
                value={constructorFormData.sideWardrobes[sideIndex].countDoors}
                callBack={changeCountDoors}
                viewControl={true}
            />
        </div>
    );
};