import ru from './ru/translation.json';
import en from './en/translation.json';

export const resources = {
    ru: {
        translation: ru
    },
    en: {
        translation: en
    },
} as const;