import React, {useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './ProjectPrice.css';
import '../../../i18n';

import {ThreeConstructor} from '../../3d';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {cnModal, Modal} from '@yandex/ui/Modal/desktop/bundle';
import SvgIcon from '../../../helpers/SvgIcon/SvgIcon';
import {ProjectSpec} from '../ProjectSpec/ProjectSpec';

export const cnProjectPrice = cn('ProjectPrice');

export interface IProjectPriceProps extends IClassNameProps {
    threeConstructor: ThreeConstructor;
}

export const ProjectPrice: React.FC<IProjectPriceProps> = ({className, threeConstructor}) => {
    const {t} = useTranslation();
    const projectPrice = useSelector((state: AppState) => state.constructorData.projectPrice);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const toggleSpec = () => {
        setModalOpen(!modalOpen);
    }
    if (!projectPrice) {
        return null;
    }
    return (
        <div className={cnProjectPrice({}, [className])}>
            <div className={cnProjectPrice('Content')} onClick={toggleSpec}>
                {t('Итого')}: {projectPrice.full} {t("руб.")}
            </div>
            <Modal
                theme="normal"
                visible={modalOpen}>
                <div className={cnProjectPrice('Modal')}>
                    <div className={cnModal('Close')} onClick={() => {
                        setModalOpen(false)
                    }}>
                        <SvgIcon icon='remove' className={cnModal('CloseIcon')}/>
                    </div>
                    <div className={cnProjectPrice('ModalContent')}>
                        <ProjectSpec threeConstructor={threeConstructor}/>
                    </div>
                </div>
            </Modal>
        </div>
    );
};