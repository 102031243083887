import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './TabInput.css';
import {TabsMenu} from '@yandex/ui/TabsMenu/desktop/bundle';

export const cnTabInput = cn('TabInput');

export interface ITabInputProps extends IClassNameProps {
    options: Array<number>,
    active: number,
    prefix: string,
    callBack: (index: number) => void
}

export const TabInput: React.FC<ITabInputProps> = ({ className, options, active, prefix, callBack }) => {
    return (
        <div className={cnTabInput({ size: 'lg', bordered: true, bkg: 'blue', color: 'white', active: true }, [className])}>
            <TabsMenu
                view={'default'}
                layout={'horiz'}
                activeTab={`${active}`}
                tabs={
                    options.map((option, index) => {
                        return (
                            {
                                id: `${index + 1}`,
                                content: `${prefix} ${index + 1}`,
                                onClick: () => { callBack(index + 1) }
                            }
                        );
                    })
                }
            />
        </div>
    );
};