import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './ColorInput.css';
import {IEntityData} from '../../../../common-code/domain/interfaces/IEntityData';

export const cnColorInput = cn('ColorInput');

export interface IColorInputProps extends IClassNameProps {
    active: boolean,
    backgroundColor: IEntityData,
    callBack: (color: IEntityData) => void
}

export const ColorInput: React.FC<IColorInputProps> = ({ className, active, backgroundColor, callBack }) => {

    const eventClick = () => {
        callBack(backgroundColor);
    };

    return (
        <div
            className={cnColorInput({bordered: true, hovered: true, active: active}, [className])}
            onClick={eventClick}
        >
            {backgroundColor.colorHex ?
                <div
                    className={cnColorInput('Color', {bordered: true})}
                    style={{backgroundColor: backgroundColor.colorHex}}>

                </div>
                :
                <div
                    className={cnColorInput('Color', {bordered: true})}>
                    <img className={cnColorInput('Image')} src={'https://azbykamebeli.ru' + backgroundColor.preview}
                         alt={backgroundColor.name}/>
                </div>
            }

        </div>
    );
};