import {ISideWardrobe} from "../../../../../domain/intarfaces";
import {ThreeConstructor} from "../../ThreeConstructor";
import {ThreeGeometry} from "../../helpers";
import {ThreeObject} from "../ThreeObject";
import {ThreePanel, ThreeShelf} from "../panels";
import {ThreeConnectionPoint, ThreePanelPoint, ThreeSidePoint} from "../points";
import {ThreeRod} from "../planks";
import {Vector2} from "three";
import {ThreeDrawer} from "../ThreeDrawer/ThreeDrawer";
import {ThreeDoorContainer} from "../ThreeDoorContainer/ThreeDoorContainer";
import {
    POINT_TYPE_CONNECTION,
    POINT_TYPE_PANEL,
    SIZE_TYPE_DEPTH,
    SIZE_TYPE_HEIGHT, SIZE_TYPE_WIDTH,
} from "../../../../../common-code/constants";
import {ThreeBackFiller} from "../fillers";
import {ThreeFrontFiller} from "../fillers";
import {TInstallationType} from '../../../../../common-code/domain/types';
import {ISideWardrobeData} from '../../../../../common-code/domain/interfaces/ISideWardrobeData/ISideWardrobeData';
import {
    IConnectionPointData
} from '../../../../../common-code/domain/interfaces/IConnectionPointData/IConnectionPointData';
import {IPanelPointData} from '../../../../../common-code/domain/interfaces/IPanelPointData/IPanelPointData';
import {IPanelData} from "../../../../../common-code/domain/interfaces/IPanelData/IPanelData";
import {IDoorContainerData} from "../../../../../common-code/domain/interfaces/IDoorContainerData/IDoorContainerData";
import {IDrawerData} from "../../../../../common-code/domain/interfaces/IDrawerData/IDrawerData";
import {IFillerData} from "../../../../../common-code/domain/interfaces/IFillerData/IFillerData";
import {IRodData} from "../../../../../common-code/domain/interfaces/IRodData/IRodData";
import {IShelfData} from "../../../../../common-code/domain/interfaces/IShelfData/IShelfData";
import {ThreeSize} from '../../details/ThreeSize/ThreeSize';
import {ISizeData} from '../../../../../common-code/domain/interfaces/ISizeData/ISizeData';
import {CommonHelper} from '../../../../helpers/CommonHelper';

export class ThreeSideWardrobe extends ThreeObject implements ISideWardrobe {
    installationType: TInstallationType;
    initData: ISideWardrobeData;
    pointCenter: ThreeSidePoint;
    pointX: ThreeSidePoint;
    pointY: ThreeSidePoint;
    pointZ: ThreeSidePoint;
    leftNeighbor?: ThreeSideWardrobe;
    rightNeighbor?: ThreeSideWardrobe;
    mainConstructor: ThreeConstructor;
    doorContainer?: ThreeDoorContainer;
    backFillers: { [p: number]: ThreeBackFiller };
    frontFillers: { [p: number]: ThreeFrontFiller };
    points: { [n: number]: ThreeConnectionPoint | ThreePanelPoint };
    panels: { [p: number]: ThreePanel };
    shelves: { [p: number]: ThreeShelf };
    rods: { [p: number]: ThreeRod };
    drawers: { [p: number]: ThreeDrawer };
    threeSizes: ThreeSize[];


    constructor(mainConstructor: ThreeConstructor, options: ISideWardrobeData) {
        super(mainConstructor, options);
        this.mainConstructor = mainConstructor;
        this.installationType = options.installationType;
        this.points = {};
        this.backFillers = {};
        this.frontFillers = {};
        this.panels = {};
        this.shelves = {};
        this.rods = {};
        this.drawers = {};
        this.threeSizes = [];
        this.initData = options;
        this.pointCenter = this.mainConstructor.getPointById(options.pointCenter) as ThreeSidePoint;
        this.pointX = this.mainConstructor.getPointById(options.pointX) as ThreeSidePoint;
        this.pointY = this.mainConstructor.getPointById(options.pointY) as ThreeSidePoint;
        this.pointZ = this.mainConstructor.getPointById(options.pointZ) as ThreeSidePoint;
        this.setPosition();
        this.setRotation();
        this.view3d.position.copy(this.pointCenter.value);
        this.view3d.rotation.set(
            0,
            -ThreeGeometry.getNormalAngle(
                new Vector2(this.pointX.value.x - this.pointCenter.value.x, this.pointX.value.z - this.pointCenter.value.z)
            ),
            0
        );
        this.view3d.updateMatrix();
        this.view3d.updateMatrixWorld();
    }

    public initState(): void {
        this.createPoints();
        this.createPanels();
        this.setPanelNeighbors();
        this.createShelves();
        this.setPointParents();
        this.setPointPositions();
        this.createPointViews();
        this.createBackFillers();
        this.createFrontFillers();
        this.createRods();
        this.createDrawers();
        this.createDoorContainer();
        this.createCover();
        this.mainConstructor.addToScene(this);
    }

    public createView(): void {
        this.setPanelParents();
        this.setShelvesParents();
        this.createPanelViews();
        this.createShelfViews();
        this.createRodViews();
        this.createDrawerViews();
        this.createDoorContainerView();
        this.createBackFillerViews();
        this.createFrontFillerViews();
        this.createSizes();
        super.createView();
    }


    public remove(): void {
        let index;

        for (index in this.points) {
            this.points[index].remove();
        }
        for (index in this.panels) {
            this.panels[index].remove();
        }
        for (index in this.shelves) {
            this.shelves[index].remove();
        }
        for (index in this.rods) {
            this.rods[index].remove();
        }
        for (index in this.drawers) {
            this.drawers[index].remove();
        }
        for (index in this.backFillers) {
            this.backFillers[index].remove();
        }
        for (index in this.frontFillers) {
            this.frontFillers[index].remove();
        }
        for (index in this.threeSizes) {
            this.view3d.remove(this.threeSizes[index].view3d);
            this.threeSizes[index].remove();
        }
        if (this.doorContainer) {
            this.doorContainer.remove();
        }
        this.mainConstructor.removeFromScene(this);
        super.remove();
    }

    public setPosition() {
        this.view3d.position.copy(this.pointCenter.value);
        this.view3d.updateMatrix();
        this.view3d.updateMatrixWorld();
    }

    public setRotation() {
        let normalAngle;

        normalAngle = ThreeGeometry.getNormalAngle(ThreeGeometry.toVector2D(this.pointX.value.clone().sub(this.pointCenter.value)));
        this.view3d.rotation.set(0, normalAngle, 0);

    }

    public createPoints() {
        let pointData;
        let point: ThreeConnectionPoint | ThreePanelPoint | undefined;

        for (pointData of this.initData.points) {
            switch (pointData.type) {
                case POINT_TYPE_CONNECTION:
                    pointData = pointData as IConnectionPointData;
                    point = new ThreeConnectionPoint(this, pointData);
                    break;
                case POINT_TYPE_PANEL:
                    pointData = pointData as IPanelPointData;
                    point = new ThreePanelPoint(this, pointData);
                    break;
            }
            if (point) {
                this.points[point.getId()] = point;
            }
        }
    }

    public getSaveData(): ISideWardrobeData {
        super.getSaveData();
        return {
            id: this.getId(),
            points: this.getPointsSaveData(),
            panels: this.getPanelsSaveData(),
            doorContainer: this.getDoorContainerSaveData(),
            drawers: this.getDrawersSaveData(),
            backFillers: this.getBackFillersSaveData(),
            frontFillers: this.getFrontFillersSaveData(),
            pointX: this.pointX.getId(),
            pointY: this.pointY.getId(),
            pointZ: this.pointZ.getId(),
            pointCenter: this.pointCenter.getId(),
            rods: this.getRodsSaveData(),
            shelves: this.getShelvesSaveData(),
            installationType: this.installationType
        };
    }

    protected getShelvesSaveData(): IShelfData[] {
        let shelves: IShelfData[] = [];
        let index;

        for (index in this.shelves) {
            shelves.push(this.shelves[index].getSaveData());
        }
        return shelves;
    }

    protected getRodsSaveData(): IRodData[] {
        let rods: IRodData[] = [];
        let index;

        for (index in this.rods) {
            rods.push(this.rods[index].getSaveData());
        }
        return rods;
    }

    protected getPointsSaveData(): Array<IConnectionPointData | IPanelPointData> {
        let points: Array<IConnectionPointData | IPanelPointData> = [];
        let index;

        for (index in this.points) {
            points.push(this.points[index].getSaveData());
        }

        return  points;
    }

    protected getPanelsSaveData(): IPanelData[] {
        let panels: IPanelData[] = [];
        let index;

        for (index in this.panels) {
            panels.push(this.panels[index].getSaveData());
        }

        return panels;
    }

    protected getDoorContainerSaveData(): IDoorContainerData | undefined {
        if (!this.doorContainer) {
            return undefined;
        }

        return this.doorContainer.getSaveData();
    }

    protected getDrawersSaveData(): IDrawerData[] {
        let drawers: IDrawerData[] = [];
        let index;

        for (index in this.drawers) {
            drawers.push(this.drawers[index].getSaveData());
        }
        return drawers;
    }

    protected getBackFillersSaveData(): IFillerData[] {
        let backFillers: IFillerData[] = [];
        let index;

        for (index in this.backFillers) {
            backFillers.push(this.backFillers[index].getSaveData());
        }

        return backFillers;
    }

    protected getFrontFillersSaveData(): IFillerData[] {
        let frontFillers: IFillerData[] = [];

        let index;

        for (index in this.frontFillers) {
            frontFillers.push(this.frontFillers[index].getSaveData());
        }

        return frontFillers;
    }

    private setPointParents() {
        let pointId;

        for (pointId in this.points) {
            if (this.points[pointId].getType() === POINT_TYPE_PANEL) {
                this.points[pointId].setParentPanel();
            }
        }
    }


    private setPointPositions() {
        let pointId;

        for (pointId in this.points) {
            this.points[pointId].calculatePosition();
            this.points[pointId].setPosition();
        }
    }

    private createPointViews() {
        let pointId;

        for (pointId in this.points) {
            this.points[pointId].createView();
        }
    }

    public setLeftNeighbor(sideWardrobe: ThreeSideWardrobe) {
        this.leftNeighbor = sideWardrobe;
    }

    public setRightNeighbor(sideWardrobe: ThreeSideWardrobe) {
        this.rightNeighbor = sideWardrobe;
    }

    private createCover() {
        // let mesh;
        //
        // mesh = new Mesh();
    }

    private setPanelNeighbors() {
        let index;
        let index2;

        for (index in this.panels) {
            for (index2 in this.panels) {
                if (index === index2) {
                    continue;
                }
                if (this.panels[index].pointA.getId() === this.panels[index2].pointB.getId()) {
                    this.panels[index].setLeftNeighbor(this.panels[index2]);
                    this.panels[index2].setRightNeighbor(this.panels[index]);
                }
            }
        }
    }

    private setPanelParents() {
        let index;
        for (index in this.panels) {
            if (this.panels[index].pointA.type === POINT_TYPE_PANEL) {
                if (!this.panels[index].pointA.parentPanel) {
                    this.panels[index].pointA.setParentPanel();
                }
                this.panels[index].setLeftParent(this.panels[index].pointA.parentPanel)
            }
            if (this.panels[index].pointB.type === POINT_TYPE_PANEL) {
                if (!this.panels[index].pointB.parentPanel) {
                    this.panels[index].pointB.setParentPanel();
                }
                this.panels[index].setRightParent(this.panels[index].pointB.parentPanel)
            }
        }
    }

    private setShelvesParents() {
        let index;
        for (index in this.shelves) {
            if (this.shelves[index].pointA.type === POINT_TYPE_PANEL) {
                if (!this.shelves[index].pointA.parentPanel) {
                    this.shelves[index].pointA.setParentPanel();
                }
                this.shelves[index].setLeftParent(this.shelves[index].pointA.parentPanel);
            }
            if (this.shelves[index].pointB.type === POINT_TYPE_PANEL) {
                if (!this.shelves[index].pointB.parentPanel) {
                    this.shelves[index].pointB.setParentPanel();
                }
                this.shelves[index].setRightParent(this.shelves[index].pointB.parentPanel);
            }
        }
    }

    private createPanels() {
        let index;
        let panelData;
        let panel;

        for (index in this.initData.panels) {
            panelData = this.initData.panels[index];
            panel = new ThreePanel(this, CommonHelper.deepCopy(panelData));
            this.panels[panel.getId()] = panel;
        }
    }

    private createPanelViews() {
        let panelId;

        for (panelId in this.panels) {
            this.panels[panelId].initState();
            this.panels[panelId].createView();
        }
    }

    private createShelfViews() {
        let shelfId;

        for (shelfId in this.shelves) {
            this.shelves[shelfId].initState();
            this.shelves[shelfId].createView();
        }
    }


    private createRodViews() {
        let rodId;

        for (rodId in this.rods) {
            this.rods[rodId].createView();
        }
    }

    private createDrawerViews() {
        let drawerId;

        for (drawerId in this.drawers) {
            this.drawers[drawerId].createView();
        }
    }

    private createDoorContainerView() {
        if (this.doorContainer) {
            this.doorContainer.initState();
            this.doorContainer.createView();
            this.view3d.add(this.doorContainer.view3d);
        }
    }

    private createBackFillerViews() {
        let fillerId;

        for (fillerId in this.backFillers) {
            this.backFillers[fillerId].initState();
            this.backFillers[fillerId].createView();
            this.view3d.add(this.backFillers[fillerId].view3d);
        }
    }

    private createFrontFillerViews() {
        let fillerId;

        for (fillerId in this.frontFillers) {
            this.frontFillers[fillerId].initState();
            this.frontFillers[fillerId].createView();
            this.view3d.add(this.frontFillers[fillerId].view3d);
        }
    }

    private createSizes() {
        let sizeOptions: ISizeData;
        let size: ThreeSize;

        // if (this.mainConstructor.getViewMode() === VIEW_MODE_3D) {
        //     return;
        // }
        sizeOptions = {
            id: 0,
            pointA: {x: this.pointX.value.x, y: this.pointX.value.y, z: this.pointX.value.z},
            pointB: {x: this.pointX.value.x, y: this.pointY.value.y, z: this.pointX.value.z},
            type: SIZE_TYPE_HEIGHT,
            rotation: {z: -Math.PI / 2}
        };
        size = new ThreeSize(sizeOptions, this);
        size.initState();
        size.createView();
        this.threeSizes.push(size);
        sizeOptions = {
            id: 0,
            pointA: {x: this.pointCenter.value.x, y: this.pointY.value.y, z: this.pointCenter.value.z},
            pointB: {x: this.pointX.value.x, y: this.pointY.value.y, z: this.pointX.value.z},
            type: SIZE_TYPE_WIDTH,
        };
        size = new ThreeSize(sizeOptions, this);
        size.initState();
        size.createView();
        this.threeSizes.push(size);
        sizeOptions = {
            id: 0,
            pointA: {x: this.pointZ.value.x, y: this.pointY.value.y, z: this.pointZ.value.z},
            pointB: {x: this.pointCenter.value.x, y: this.pointY.value.y, z: this.pointCenter.value.z},
            type: SIZE_TYPE_DEPTH,
            rotation: {y: Math.PI / 2}
        };
        size = new ThreeSize(sizeOptions, this);
        size.initState();
        size.createView();
        this.threeSizes.push(size);

    }

    private createShelves() {
        let index;
        let shelfData;
        let shelf;

        for (index in this.initData.shelves) {
            shelfData = this.initData.shelves[index];
            shelf = new ThreeShelf(this, shelfData);
            this.shelves[shelf.getId()] = shelf;
        }
    }

    private createRods() {
        let index;
        let rodData;
        let rod;

        for (index in this.initData.rods) {
            rodData = this.initData.rods[index];
            rod = new ThreeRod(this, rodData);
            this.rods[rod.getId()] = rod;
        }
    }

    private createDrawers() {
        let index;
        let drawerData;
        let drawer;

        for (index in this.initData.drawers) {
            drawerData = this.initData.drawers[index];
            drawer = new ThreeDrawer(this, drawerData);
            this.drawers[drawer.getId()] = drawer;
        }
    }

    private createDoorContainer() {
        if (this.initData.doorContainer) {
            this.doorContainer = new ThreeDoorContainer(this, this.initData.doorContainer);
        }
    }

    private createBackFillers() {
        let fillerData;
        let filler: ThreeBackFiller;

        for (fillerData of this.initData.backFillers) {
            filler = new ThreeBackFiller(this, fillerData);
            this.backFillers[filler.getId()] = filler;
        }
    }

    private createFrontFillers() {
        let fillerData;
        let filler: ThreeFrontFiller;

        for (fillerData of this.initData.frontFillers) {
            filler = new ThreeFrontFiller(this, fillerData);
            this.frontFillers[filler.getId()] = filler;
        }
    }

}