import {ThreeObject} from "../../ThreeObject";
import {IPlank} from "../../../../../../domain/intarfaces";
import {ThreeConstructor} from "../../../ThreeConstructor";
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {Box3, Vector3} from "three";
import { IPlankData } from "../../../../../../common-code/domain/interfaces/IPlankData/IPlankData";

export class ThreePlank extends ThreeObject implements IPlank {
    readonly isPlank: boolean;

    constructor(mainConstructor: ThreeConstructor, options: IPlankData) {
        super(mainConstructor, options);
        this.isPlank = true;
    }

    protected calculateGlobalPoints(): IStartEndPoints | undefined {
        let box: Box3;
        let startPoint: Vector3;
        let endPoint: Vector3;
        let length: number;
        let lengthArray: number[];

        box = new Box3();
        box.setFromObject(this.view3d);
        startPoint = new Vector3(box.min.x, box.min.y, box.min.z);
        endPoint = new Vector3(box.max.x, box.max.y, box.max.z);
        lengthArray = [
            (box.max.x - box.min.x),
            (box.max.y - box.min.y),
            (box.max.z - box.min.z)
        ];
        length = Math.max(...lengthArray);
        switch (lengthArray.indexOf(length)) {
            case 0:
                startPoint.y = endPoint.y = (box.max.y + box.min.y)/2;
                startPoint.z = endPoint.z = (box.max.z + box.min.z)/2;
                break;
            case 1:
                startPoint.x = endPoint.x = (box.max.x + box.min.x)/2;
                startPoint.z = endPoint.z = (box.max.z + box.min.z)/2;
                break;
            case 2:
                startPoint.x = endPoint.x = (box.max.x + box.min.x)/2;
                startPoint.y = endPoint.y = (box.max.y + box.min.y)/2;
                break;
        }
        
        return {
            start: {x: startPoint.x, y: startPoint.y, z: startPoint.z},
            end: {x: endPoint.x, y: endPoint.y, z: endPoint.z},
            length: length
        };
    }

}