import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnRangeInput, RangeInput} from '../../../all/RangeInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TTechnologMap} from '../../../../../common-code/domain/types';
import {Dispatch} from 'redux';
import {CHANGE_BOTTOM_PLINTH} from '../../../../constants';
import {
    MIN_BOTTOM_PLINTH,
    MAX_BOTTOM_PLINTH
} from '../../../../../common-code/constants';

export const cnBottomPlintFragment = cn('BottomPlintFragment');

export interface IBottomPlintFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    technologMap: TTechnologMap | undefined,
    dispatch: Dispatch,
    min: number,
    max: number,
    step?: number
}

export const BottomPlinthFragment: React.FC<IBottomPlintFragmentProps> = ({
                                                                              className,
                                                                              constructorFormData,
                                                                              technologMap,
                                                                              dispatch,
                                                                              min,
                                                                              max,
                                                                              step
                                                                          }) => {

    const changeBottomPlinth = (value: number) => {
        if (!technologMap) {
            return;
        }

        if (value < MIN_BOTTOM_PLINTH) {
            value = MIN_BOTTOM_PLINTH;
        }
        if (value > MAX_BOTTOM_PLINTH) {
            value = MAX_BOTTOM_PLINTH;
        }

        dispatch({
            type: CHANGE_BOTTOM_PLINTH,
            payload: value,
        });
    };

    return (
        <div
            className={cnConstructorForm(cnBottomPlintFragment(), [className, cnConstructorForm('RangeInputContainer')])}>
            <span className={
                cnConstructorForm(
                    'Label',
                    {size: 'sm', color: 'lightNavy'},
                    [cnConstructorForm('RangeInputLabel')])
            }>Высота,мм</span>
            <RangeInput
                className={cnConstructorForm(cnRangeInput())}
                min={min}
                max={max}
                step={step}
                value={constructorFormData.bottomPlinth}
                callBack={changeBottomPlinth}
                viewControl={true}
            />
        </div>
    );
};