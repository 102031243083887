import {TUser} from '../../common-code/domain/types/TUser';
import {IMenuItem} from '../interfaces';
import {useTranslation} from 'react-i18next';

export const useMainMenuItems = (userData: TUser): IMenuItem[] => {
    let items: IMenuItem[];
    const {t} = useTranslation();

    if (userData.roles.includes('GUEST')) {
        items = [
            {
                url: '/',
                mods: {type: 'innerLink'},
                title: t('to-main-page')
            },
            {
                url: '/constructor/new/',
                mods: {type: 'innerLink'},
                title: t('create-wardrobe')
            },
        ];
    } else {
        items = [
            {
                url: '/',
                mods: {type: 'innerLink'},
                title: t('to-main-page')
            },
            {
                url: '/constructor/new/',
                mods: {type: 'innerLink'},
                title: t('create-wardrobe')
            },
            {
                url: '/projects/',
                mods: {type: 'innerLink'},
                title: t('my-projects')
            },
        ];
    }

    return items;
}