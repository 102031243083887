import React, {useState} from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import "./Projects.css";
import {IProjectData} from '../../../../common-code/domain/interfaces/IProjectData';
import SvgIcon from '../../../helpers/SvgIcon/SvgIcon';
import {useEffectDidMount} from '../../../hooks/useEffectDidMount';
import axios from 'axios';
import {ButtonInput, cnButtonInput} from '../ButtonInput';
import {useNavigate} from 'react-router-dom';

export const cnProjects = cn('Projects');

export interface IProjectsProps extends IClassNameProps {

}

export const Projects: React.FC<IProjectsProps> = ({className}) => {
    const [projects, setProjects] = useState<IProjectData[]>([]);
    const navigate = useNavigate();

    useEffectDidMount(() => {
        axios.get('/api/project/all')
            .then((response: any) => {
                setProjects(response.data)
            })
            .catch(() => {
            });
    });
    return (
        <div className={cnProjects({}, [className])}>
            {/*<div className={cnProjects('Filter')}>*/}
            {/*    Фильтр:*/}
            {/*</div>*/}
            <div className={cnProjects('Content')}>
                {
                    projects.length > 0 ?
                        <div className={cnProjects('Items')}>
                            {projects.map((projectData) => {
                                return <div className={cnProjects('ProjectData')} key={projectData.id}>
                                    <div className={cnProjects('Images')}>
                                        {projectData.image ?
                                            <img className={cnProjects('Image')} src={projectData.image}
                                                 alt={projectData.name}/> :
                                            <SvgIcon className={cnProjects('Image', {noImage: true})}
                                                     icon={'no-image'}/>}
                                    </div>
                                    <div className={cnProjects('Name')}>
                                        {projectData.name}
                                    </div>
                                    <div className={cnProjects('Buttons')}>
                                        <ButtonInput
                                            className={cnButtonInput({
                                                size: 'md',
                                                hovered: true,
                                                bordered: true,
                                                background: 'fill'
                                            }, [cnProjects(cnButtonInput())])}
                                            text={'Открыть'}
                                            callBack={() => {
                                                navigate('/constructor/' + projectData.id + '/')
                                            }}
                                        />
                                    </div>
                                </div>;
                            })}
                        </div> :
                        <div className={cnProjects('EmptyContent')}>Не найдено ни одного проекта</div>
                }
            </div>
        </div>
    );
}