import {IConstructorFormData} from '../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {ProjectGenerator} from '../../domain/services/ProjectGenerator/ProjectGenerator';
import {MIN_COUNT_DOORS_SECTIONS, MAX_COUNT_DOORS_SECTIONS} from '../../common-code/constants';
import {TFormDoorSection} from '../../common-code/domain/types';

export default function useNewDoorSections() {

    const createNewDoorSections = (constructorFormData: IConstructorFormData, sideIndex: number, doorIndex: number, countDoorSections: number, materialId: number): IConstructorFormData => {
        let index: number;
        let newCountDoorSections: number;
        let oldDoorSections: TFormDoorSection[];
        let newDoorSections: TFormDoorSection[] = [];

        newCountDoorSections = calcCountNewDoorSections(countDoorSections);
        constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].countDoorSections = newCountDoorSections;
        oldDoorSections = [...constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].doorSections];
        for (index = 0; index < newCountDoorSections; index++) {
            if (oldDoorSections[index] !== undefined) {
                newDoorSections.push(oldDoorSections[index]);
            } else {
                newDoorSections.push(ProjectGenerator.getDefaultDoorSection(materialId));
            }
        }
        constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].doorSections = newDoorSections;

        return constructorFormData;
    };

    // TODO Сделать корректный расчет количества секций по высоте двери
    const calcCountNewDoorSections = (countDoorSections: number): number => {
        let newCountDoorSections: number;

        newCountDoorSections = countDoorSections;
        if (newCountDoorSections < MIN_COUNT_DOORS_SECTIONS) {
            newCountDoorSections = MIN_COUNT_DOORS_SECTIONS;
        }
        if (newCountDoorSections > MAX_COUNT_DOORS_SECTIONS) {
            newCountDoorSections = MAX_COUNT_DOORS_SECTIONS;
        }

        return newCountDoorSections;
    };

    const removeDoorSection = (constructorFormData: IConstructorFormData, sideIndex: number, doorIndex: number, doorSectionIndex: number, countDoorSections: number): IConstructorFormData => {
        let newCountDoorSections: number;

        newCountDoorSections = calcCountNewDoorSections(countDoorSections);
        if (newCountDoorSections !== constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].countDoorSections) {
            constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].countDoorSections = newCountDoorSections;
            constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].doorSections.splice(doorSectionIndex, 1);
        }

        return constructorFormData;
    };

    return { createNewDoorSections, removeDoorSection };
}