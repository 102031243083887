import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";

export const cnSvgIcon = cn('SvgIcon');

export interface ISvgIconProps extends IClassNameProps {
    icon: string,
    label?: string
}
const SvgIcon: React.FC<ISvgIconProps> = ({icon, label, className}) => {
    return (
        <svg
            className={cnSvgIcon({}, [className])}
            aria-label={label}
        >
            <use xlinkHref={`#${icon}`} />
        </svg>
    );
};

export default SvgIcon;