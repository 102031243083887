import {Vector3} from "three";
import {ThreePoint} from "..";
import {ThreeSideWardrobe} from "../../ThreeSideWardrobe";
import {
    IConnectionPointData
} from '../../../../../../common-code/domain/interfaces/IConnectionPointData/IConnectionPointData';

export class ThreeConnectionPoint extends ThreePoint {
    value: Vector3;
    sideWardrobe: ThreeSideWardrobe;

    constructor(sideWardrobe: ThreeSideWardrobe, pointData: IConnectionPointData) {
        super(sideWardrobe.mainConstructor, pointData);
        this.sideWardrobe = sideWardrobe;
        this.value = new Vector3(pointData.value.x, pointData.value.y, pointData.value.z);
        this.isInit = true;
    }

    public getSaveData(): IConnectionPointData {
        super.getSaveData();
        return {
            connectionId: this.connectionId,
            connectionType: this.connectionType,
            sort: this.sort,
            type: this.type,
            id: this.id,
            value: {x: this.value.x, y: this.value.y, z: this.value.z}
        }
    }
}