import React from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./Footer.css";
import '../../../i18n';
import {useTranslation} from 'react-i18next';
import {cnConstructorPage} from "../../../pages/ConstructorPage/ConstructorPage";

export const cnFooter = cn('Footer');

export interface IFooterProps extends IClassNameProps {
}

export const Footer: React.FC<IFooterProps> = ({className}) => {
    const {t} = useTranslation();
    return (
        <div className={cnFooter({}, [cnConstructorPage(cnFooter()), className])}>
            <div className={cnFooter('Container')}>
                <div className={cnFooter('Copyright')}>
                    {'© — ' + new Date().getFullYear() + ' ' + t('footer')}
                </div>
            </div>
        </div>
    );
};