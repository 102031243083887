import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './RadioButtonInput.css';
import {RadioButton} from '@yandex/ui/RadioButton/desktop/bundle';
import {TRadioButtonOption} from '../../../../common-code/domain/types/TRadioButtonOption';

export const cnRadioButtonInput = cn('RadioButtonInput');

export interface IRadioButtonInputProps extends IClassNameProps {
    options: Array<TRadioButtonOption>,
    value: string,
    callBack: (event: ChangeEvent<HTMLInputElement>) => void
}

export const RadioButtonInput: React.FC<IRadioButtonInputProps> = ({ className, options, value, callBack }) => {
    return (
        <div className={cnRadioButtonInput({ size: 'md' }, [className])}>
            <RadioButton
                options={options}
                value={value}
                onChange={callBack}
            />
        </div>
    );
};