import React, {useEffect, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './RangeInput.css';
import {Slider} from '@yandex/ui/Slider/desktop/bundle';
import {NumberInput} from '../NumberInput';

export const cnRangeInput = cn('RangeInput');

export interface IRangeInputProps extends IClassNameProps {
    min: number;
    max: number;
    step?: number;
    value: number;
    callBack: (value: number) => void;
    viewControl?: boolean;
}

export const RangeInput: React.FC<IRangeInputProps> = ({className, min, max, step, value, callBack, viewControl}) => {

    const [newValue, setNewValue] = useState<number>(value);

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const eventInput = (event: any, value: number[]) => {
        setNewValue(value[0]);
    };

    const eventChange = (event: any, value: number[]) => {
        callBack(value[0]);
    };

    return (
        <div className={cnRangeInput({}, [className])}>
            <div className={cnRangeInput('Range')}>
                <Slider
                    view={'default'}
                    filled={true}
                    min={min}
                    max={max}
                    step={step}
                    value={[newValue]}
                    onInput={eventInput}
                    onChange={eventChange}
                />
            </div>
            <NumberInput
                className={cnRangeInput('Input')}
                min={min}
                max={max}
                step={step}
                value={newValue}
                viewControl={viewControl}
                callBack={callBack}
            />
        </div>
    );
};