import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {useTranslation} from 'react-i18next';
import {cnProjectSpec} from '../ProjectSpec';
import {ISpecItem} from '../../../../../../../common/domain/interfaces/IWardrobeProjectSpec/ISpecItem';
import {
    IWardrobeProjectSpec
} from '../../../../../../../common/domain/interfaces/IWardrobeProjectSpec/IWardrobeProjectSpec';

export interface IPropSpecContentProps extends IClassNameProps {
    projectSpec: IWardrobeProjectSpec | undefined;
}

export const ProjectSpecContent: React.FC<IPropSpecContentProps> = ({projectSpec, className}) => {
    const {t} = useTranslation();


    const renderTableItem = (specItem: ISpecItem, index: number) => {
        return (
            <div
                className={cnProjectSpec('TableRow')}
                key={index + specItem.code}
            >
                <p className={cnProjectSpec('TableCell', {offset: 'small'})}>{index}</p>
                <p className={cnProjectSpec('TableCell', {justify: 'start'})}>{specItem.code}</p>
                <p className={cnProjectSpec('TableCell', {justify: 'start', size: 'big'})}>{specItem.name}</p>
                <p className={cnProjectSpec('TableCell')}>{specItem.count}</p>
                <p className={cnProjectSpec('TableCell')}>{specItem.price}</p>
                <p className={cnProjectSpec('TableCell')}>{specItem.sum}</p>
                {/*<p className={cnProjectSpec('TableCell')}>{specItem.store}</p>*/}
            </div>
        );
    }

    const getSpecItemTitle = (index: keyof IWardrobeProjectSpec): string => {
        switch (index) {
            case 'ldsp':
                return t('ЛДСП');
            case 'dvp':
                return t('ДВП');
            case 'mirror':
                return t('Зеркало');
            case 'profiles':
                return t('Профиль');
            case 'services':
                return t('Услуги');
            case 'rods':
                return t('Штанга');
            case 'furniture':
                return t('Фурнитура');
            case 'other':
                return t('Другое');
            default:
                return t('Другое') + ' ' + index;
        }
    }

    const renderTableContent = (projectSpec: IWardrobeProjectSpec) => {
        let index: keyof IWardrobeProjectSpec;
        let renderData: React.ReactElement[] = [];
        for (index in projectSpec) {
            if (index === 'full') {
                continue;
            }
            if (projectSpec[index].length <= 0) {
                continue;
            }
            renderData.push(<div
                className={cnProjectSpec('TableItem')} key={index}
            >
                <div className={cnProjectSpec('TableItemTitle')}>{getSpecItemTitle(index)}</div>
                {projectSpec[index].map((specItem, index1) => {
                    return renderTableItem(specItem, index1 + 1);
                })}
            </div>);
        }
        if (projectSpec.full > 0) {
            renderData.push(<div
                className={cnProjectSpec('TableTotal')} key={'full'}
            >
                <div className={cnProjectSpec('TableTotalTitle')}>{t('ИТОГО')}:</div>
                <div className={cnProjectSpec('TableTotalValue')}>{projectSpec.full} {t('руб.')}</div>
            </div>)
        }
        return renderData;
    }


    return (
        <div className={cnProjectSpec('TableContent', [className])}>
            {
                projectSpec ? renderTableContent(projectSpec) :
                    <div className={cnProjectSpec('Notes')}>{t('Нет товаров в спецификации')}</div>
            }
        </div>
    );
}