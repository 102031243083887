import {Reducer} from "redux";
import {SET_LOCATION, SET_LOCATIONS} from "../constants";
import {TLocationData} from '../../common-code/domain/types';

interface ILocationStore {
    current: undefined | TLocationData;
    all: TLocationData[];
}

const initLocation: ILocationStore = {
    current: undefined,
    all: []
};

export const LocationReducer: Reducer = (state = initLocation, action) => {
    switch (action.type) {
        case SET_LOCATION: {
            if (action.payload && action.payload.id) {
                state = {...state, current: action.payload};
            }

            return state;
        }
        case SET_LOCATIONS: {
            if (action.payload && action.payload.length > 0) {
                state = {...state, all: action.payload};
            }

            return state;
        }
        default: {
            return state;
        }
    }
};