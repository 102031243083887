import {Reducer} from "redux";
import {TOGGLE_MENU} from "../constants";

const initState = {
    MainMenu: {
        visible: false,
    },
    LanguageMenu: {
        visible: false,
    },
    UserMenu: {
        visible: false,
    },
    LocationMenu: {
        visible: false,
    },
};

export const MenuReducer:Reducer = (state = initState, action) => {
    switch (action.type) {
        case TOGGLE_MENU: {
            return {
                ...state,
                [action.payload.forMenu]: {
                    ...state[action.payload.forMenu],
                    visible: action.payload.value
                }
            };
        }
        default:
            return state;
    }
};