export const VIEW_MODE_2D = '2d';
export const VIEW_MODE_3D = '3d';
export const VIEW_QUALITY_LOW = 'low';
export const VIEW_QUALITY_MEDIUM = 'medium';
export const VIEW_QUALITY_HIGH = 'high';
export const CONNECTION_TYPE_DEFAULT = 'default';
export const CONNECTION_TYPE_MAIN_LEFT = 'main_left';
export const CONNECTION_TYPE_MAIN_RIGHT = 'main_right';
export const DIRECTION_TYPE_NORMAL = 'normal';
export const DIRECTION_TYPE_ANGLE = 'angle';
export const DIRECTION_TYPE_UTYPE = 'utype';
export const INSTALLATION_TYPE_SEPARATE = 'separate';
export const INSTALLATION_TYPE_BUILTIN = 'builtin';
export const INSTALLATION_TYPE_ONLY_DOORS = 'only_doors';
export const LEVEL_BOTTOM = 'bottom';
export const LEVEL_TOP = 'top';
export const POINT_TYPE_CONNECTION = 'ConnectionPoint';
export const POINT_TYPE_PANEL = 'PanelPoint';
export const POINT_TYPE_SIDE = 'SidePoint';
export const POINT_TYPE_DOOR = 'DoorPoint';
export const POINT_TYPE_PROFILE = 'ProfilePoint';
export const FACADE_TYPE_DEFAULT = 'default';
export const FACADE_TYPE_SMILE = 'smile';
export const PANEL_TYPE_DEFAULT = 'default';
export const PANEL_TYPE_SECTION = 'sectionPanel';
export const PANEL_TYPE_HORIZONTAL_SECTION = 'horizontalPanel';
export const PANEL_TYPE_CONTOUR = 'contourPanel';
export const PANEL_TYPE_SHELF = 'shelfPanel';
export const CONTOUR_POSITION_TYPE_BOTTOM = 'bottom';
export const CONTOUR_POSITION_TYPE_LEFT = 'left';
export const CONTOUR_POSITION_TYPE_TOP = 'top';
export const CONTOUR_POSITION_TYPE_RIGHT = 'right';
export const PROFILE_TYPE_VERTICAL = 'verticalProfile';
export const PROFILE_TYPE_BOTTOM = 'bottomProfile';
export const PROFILE_TYPE_TOP = 'topProfile';
export const PROFILE_TYPE_INNER = 'innerProfile';
export const SHELVES_TYPE_REMOVABLE = 'removable';
export const SHELVES_TYPE_NOTREMOVABLE = 'notRemovable';
export const YES = 'yes';
export const NO = 'no';
export const IMPORT_PANEL_DIRECTION_TYPE_FRONT = 'front';
export const IMPORT_PANEL_DIRECTION_TYPE_VERTICAL = 'vertical';
export const IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL = 'horizontal';
export const DIRECT_EQUATION_TYPE_NORMAL = 'normal';
export const DIRECT_EQUATION_TYPE_VERTICAL = 'vertical';
export const DIRECT_EQUATION_TYPE_HORIZONTAL = 'horizontal';
export const DRANK_PLINTH_TYPE_NONE = 'none';
export const DRANK_PLINTH_TYPE_HIGH = 'high';
export const DRANK_PLINTH_TYPE_LOW = 'low';
export const FITTINGS_TYPE_FIRST = 'first';
export const FITTINGS_TYPE_SECOND = 'second';
export const FITTINGS_TYPE_THIRD = 'third';
export const FITTINGS_COLOR_DARK_WHITE = '#F4F7FB';
export const FITTINGS_COLOR_NAVY = '#3A4D69';
export const FITTINGS_COLOR_BLUE = '#445FE8';
export const FITTINGS_COLOR_GREEN = 'green';
export const FITTINGS_COLOR_YELLOW = 'yellow';
export const FITTINGS_COLOR_BROWN = 'brown';
export const DOOR_COLOR_DARK_WHITE = '#F4F7FB';
export const DOOR_COLOR_NAVY = '#3A4D69';
export const DOOR_COLOR_BLUE = '#445FE8';
export const DOOR_COLOR_GREEN = 'green';
export const DOOR_COLOR_YELLOW = 'yellow';
export const DOOR_COLOR_BROWN = 'brown';
export const BODY_COLOR_DARK_WHITE = '#F4F7FB';
export const BODY_COLOR_NAVY = '#3A4D69';
export const BODY_COLOR_BLUE = '#445FE8';
export const BODY_COLOR_GREEN = 'green';
export const BODY_COLOR_YELLOW = 'yellow';
export const BODY_COLOR_BROWN = 'brown';
export const BACK_WALL_COLOR_DARK_WHITE = '#F4F7FB';
export const BACK_WALL_COLOR_NAVY = '#3A4D69';
export const DEFAULT_BOTTOM_PLINTH = 100;
export const MIN_BOTTOM_PLINTH = 100;
export const MAX_BOTTOM_PLINTH = 150;
export const STEP_BOTTOM_PLINTH = 1;
export const DEFAULT_TOP_PLINTH = 100;
export const MIN_TOP_PLINTH = 100;
export const MAX_TOP_PLINTH = 150;
export const STEP_TOP_PLINTH = 1;
export const WARDROBE_DEFAULT_SMALL_WIDTH = 3600;
export const WARDROBE_DEFAULT_WIDTH = 3600;
export const WARDROBE_MIN_WIDTH = 1000;
export const WARDROBE_MAX_WIDTH = 4200;
export const WARDROBE_STEP_WIDTH = 10;
export const WARDROBE_DEFAULT_DEPTH = 600;
export const WARDROBE_MIN_DEPTH = 400;
export const WARDROBE_MAX_DEPTH = 1200;
export const WARDROBE_STEP_DEPTH = 10;
export const WARDROBE_DEFAULT_HEIGHT = 2400;
export const WARDROBE_MIN_HEIGHT = 1600;
export const WARDROBE_MAX_HEIGHT = 2600;
export const WARDROBE_STEP_HEIGHT = 10;
export const DEFAULT_COUNT_SECTIONS = 4;
export const MIN_COUNT_SECTIONS = 2;
export const MAX_COUNT_SECTIONS = 10;
export const STEP_COUNT_SECTIONS = 1;
export const DEFAULT_COUNT_DOORS = 4;
export const MIN_COUNT_DOORS = 2;
export const MAX_COUNT_DOORS = 10;
export const STEP_COUNT_DOORS = 1;
export const DEFAULT_COUNT_DOORS_SECTIONS = 1;
export const MIN_COUNT_DOORS_SECTIONS = 1;
export const MAX_COUNT_DOORS_SECTIONS = 10;
export const DEFAULT_COUNT_RODS = 0;
export const DEFAULT_COUNT_DRAWERS = 0;
export const DEFAULT_COUNT_SHELVES = 1;
export const MAX_COUNT_SHELVES = 5;
export const SIZE_TYPE_WIDTH = 'width';
export const SIZE_TYPE_HEIGHT = 'height';
export const SIZE_TYPE_DEFAULT = 'default';
export const SIZE_TYPE_DEPTH = 'depth';
export const TEXTURE_TYPE_DIFFUSE = 'diffuse';
export const TEXTURE_TYPE_ROUGHNESS = 'roughness';
export const TEXTURE_TYPE_TEXTURE = 'texture';
export const TEXTURE_TYPE_NORMAL = 'normal';
export const DEFAULT_PROJECT_ID = 'new';
export const MATERIAL_TYPE_LDSP = 'ldsp';
export const MATERIAL_TYPE_DVP = 'dvp';
export const MATERIAL_TYPE_MIRROR = 'mirror';

export const BOTTOM_RAIL = 'bottomRail';
export const TOP_RAIL = 'topRail';