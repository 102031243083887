import {Reducer} from "redux";
import {
    CHANGE_SELECT_DVP,
    CHANGE_BOTTOM_PLINTH,
    CHANGE_CONSTRUCTOR_FORM_DATA, CHANGE_SELECT_DOOR_LDSP,
    CHANGE_DOOR_MATERIAL_ID,
    CHANGE_DOOR_MATERIAL_TYPE,
    CHANGE_DRANK_BOTTOM_PLINTH,
    CHANGE_DVP,
    CHANGE_EDITOR_OPTIONS,
    CHANGE_INSTALLATION_TYPE,
    CHANGE_LDSP,
    CHANGE_MIRRORS,
    CHANGE_SELECT_LDSP,
    CHANGE_PROJECT_DATA,
    CHANGE_PROJECT_PRICE_DATA,
    CHANGE_SECTION_DRAWERS_COUNT,
    CHANGE_SECTION_RODS_COUNT,
    CHANGE_SECTION_SHELVES_COUNT,
    CHANGE_SELECT_MIRROR,
    CHANGE_SHELVES_TYPE,
    CHANGE_SIDE_CONTOUR_PANEL,
    CHANGE_TECHNOLOG_MAP,
    CHANGE_WARDROBE_DEPTH,
    CHANGE_WARDROBE_HEIGHT,
    CHANGE_WARDROBE_WIDTH,
    CONSTRUCTOR_SERVICE_INIT,
    EDITOR_INIT_OPTIONS,
    INIT_CONSTRUCTOR_FORM_DATA
} from "../constants";
import {IConstructorOptions} from "../interfaces";
import {TFormSideWardrobe, TWardrobeSection} from '../../common-code/domain/types';
import {INSTALLATION_TYPE_SEPARATE, MATERIAL_TYPE_LDSP, MATERIAL_TYPE_MIRROR} from '../../common-code/constants';
import {TMaterialType} from '../../common-code/domain/types/TMaterialType';

const initOptions: IConstructorOptions = {
    editorOptions: EDITOR_INIT_OPTIONS,
    project: undefined,
    constructorFormData: INIT_CONSTRUCTOR_FORM_DATA,
    technologMap: undefined,
    ldsp: [],
    dvp: [],
    mirrors: [],
};

export const ConstructorReducer: Reducer = (state = initOptions, action) => {
    switch (action.type) {
        case CHANGE_EDITOR_OPTIONS: {
            return {...state, editorOptions: {...action.payload}};
        }
        case CHANGE_PROJECT_DATA: {
            console.log('CHANGE_PROJECT_DATA', action.payload);
            return {...state, project: action.payload};
        }
        case CHANGE_PROJECT_PRICE_DATA: {
            return {...state, projectPrice: action.payload};
        }
        case CHANGE_TECHNOLOG_MAP: {
            return {...state, technologMap: {...action.payload}};
        }
        case CHANGE_DVP: {
            return {...state, dvp: action.payload};
        }
        case CHANGE_LDSP: {
            return {...state, ldsp: action.payload};
        }
        case CHANGE_MIRRORS: {
            return {...state, mirrors: action.payload};
        }
        case CHANGE_CONSTRUCTOR_FORM_DATA: {
            console.log('CHANGE_CONSTRUCTOR_FORM_DATA', action.payload)
            if (!state.technologMap) {
                return state;
            }
            return {...state, constructorFormData: {...action.payload}};
        }
        case CHANGE_SHELVES_TYPE: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                shelvesType: action.payload
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_SELECT_LDSP: {
            return {
                ...state,
                selectLdsp: action.payload,
                constructorFormData: {
                    ...state.constructorFormData,
                    materials: {...state.constructorFormData.materials, ldsp: action.payload.id}
                }
            };
        }
        case CHANGE_SELECT_DOOR_LDSP: {
            return {
                ...state,
                selectDoorLdsp: action.payload,
                constructorFormData: {
                    ...state.constructorFormData,
                    materials: {...state.constructorFormData.materials, doorLdsp: action.payload.id},
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe) => {
                        return {
                            ...sideWardrobe,
                            doors: sideWardrobe.doors.map((door) => {
                                return {
                                    ...door,
                                    doorSections: door.doorSections.map((doorSection) => {
                                        if (doorSection.materialId === 0 && doorSection.materialType === MATERIAL_TYPE_LDSP) {
                                            return {
                                                ...doorSection,
                                                materialId: action.payload.id
                                            }
                                        }
                                        return doorSection;
                                    })
                                }
                            })
                        }
                    })
                }
            };
        }
        case CHANGE_SELECT_MIRROR: {
            return {
                ...state,
                selectMirror: action.payload
            }
        }
        case CHANGE_BOTTOM_PLINTH: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    bottomPlinth: action.payload
                }
            };
        }
        case CHANGE_DRANK_BOTTOM_PLINTH: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    drankPlinth: action.payload
                }
            };
        }
        case CHANGE_INSTALLATION_TYPE: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    installationType: action.payload,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe) => {
                        if (action.payload === INSTALLATION_TYPE_SEPARATE) {
                            return {
                                ...sideWardrobe,
                                contourPanels: {
                                    left: true,
                                    right: true,
                                    bottom: true,
                                    top: true
                                }
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_WARDROBE_DEPTH: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                depth: action.payload
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_WARDROBE_WIDTH: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                width: action.payload
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_WARDROBE_HEIGHT: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                height: action.payload
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_DOOR_MATERIAL_TYPE: {
            const getDefaultMaterial = (type: TMaterialType): number => {
                switch (type) {
                    case MATERIAL_TYPE_LDSP:
                        return state.selectDoorLdsp ? state.selectDoorLdsp.id : 0;
                    case MATERIAL_TYPE_MIRROR:
                        return state.selectMirror ? state.selectMirror.id : 0;
                    default:
                        return 0;

                }
            }
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                doors: sideWardrobe.doors.map((door, index1) => {
                                    if (index1 === action.doorIndex) {
                                        return {
                                            ...door,
                                            doorSections: door.doorSections.map((doorSection, index2) => {
                                                if (index2 === action.doorSectionIndex) {
                                                    return {
                                                        ...doorSection,
                                                        materialType: action.payload,
                                                        materialId: doorSection.materialType !== action.payload ? getDefaultMaterial(action.payload) : doorSection.materialId
                                                    }
                                                }
                                                return doorSection;
                                            })
                                        }
                                    }
                                    return door;
                                })
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_DOOR_MATERIAL_ID: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                doors: sideWardrobe.doors.map((door, index1) => {
                                    if (index1 === action.doorIndex) {
                                        return {
                                            ...door,
                                            doorSections: door.doorSections.map((doorSection, index2) => {
                                                if (index2 === action.doorSectionIndex) {
                                                    return {
                                                        ...doorSection,
                                                        materialId: +action.payload
                                                    }
                                                }
                                                return doorSection;
                                            })
                                        }
                                    }
                                    return door;
                                })
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_SELECT_DVP: {
            return {
                ...state,
                selectDvp: action.payload,
                constructorFormData: {
                    ...state.constructorFormData,
                    materials: {...state.constructorFormData.materials, dvp: action.payload.id}
                }
            };
        }
        case CONSTRUCTOR_SERVICE_INIT: {
            return {...state, project: {...state.project}};
        }
        case CHANGE_SIDE_CONTOUR_PANEL: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                contourPanels: {
                                    ...sideWardrobe.contourPanels,
                                    [action.name]: action.payload
                                }
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_SECTION_RODS_COUNT: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                sections: sideWardrobe.sections.map((section: TWardrobeSection, index2) => {
                                    if (index2 === action.sectionIndex) {
                                        return {
                                            ...section,
                                            rods: {
                                                ...section.rods,
                                                count: action.payload
                                            }
                                        }
                                    }
                                    return section;
                                })
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_SECTION_DRAWERS_COUNT: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                sections: sideWardrobe.sections.map((section: TWardrobeSection, index2) => {
                                    if (index2 === action.sectionIndex) {
                                        return {
                                            ...section,
                                            drawers: {
                                                ...section.drawers,
                                                count: action.payload
                                            }
                                        }
                                    }
                                    return section;
                                })
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        case CHANGE_SECTION_SHELVES_COUNT: {
            return {
                ...state,
                constructorFormData: {
                    ...state.constructorFormData,
                    sideWardrobes: state.constructorFormData.sideWardrobes.map((sideWardrobe: TFormSideWardrobe, index: number) => {
                        if (index === action.sideIndex) {
                            return {
                                ...sideWardrobe,
                                sections: sideWardrobe.sections.map((section: TWardrobeSection, index2) => {
                                    if (index2 === action.sectionIndex) {
                                        return {
                                            ...section,
                                            shelves: {
                                                ...section.shelves,
                                                count: action.payload
                                            }
                                        }
                                    }
                                    return section;
                                })
                            }
                        }
                        return sideWardrobe;
                    })
                }
            };
        }
        default: {
            return state;
        }
    }
};