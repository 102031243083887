import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import shkaf from '../../../../assets/images/shkaf.jpg';
import {ButtonInput, cnButtonInput} from '../ButtonInput';
import {useNavigate} from 'react-router-dom';
import "./CreateProject.css";
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';

export const cnCreateProject = cn('CreateProject');

export interface ICreateProjectProps extends IClassNameProps {

}

export const CreateProject: React.FC<ICreateProjectProps> = ({className}) => {
    const navigate = useNavigate();
    const userData = useSelector((state: AppState) => state.user);

    return (
        <div className={cnCreateProject({}, [className])}>
            <div className={cnCreateProject('Images')}>
                <img className={cnCreateProject('Image')} src={shkaf} alt={'Создать проект'}/>
            </div>
            <div className={cnCreateProject('Content')}>
                <div className={cnCreateProject('Description')}>
                    Вы можете создать проект шкафа-купе любой конфигурации на заказ
                </div>
                <ButtonInput
                    className={cnButtonInput({
                        size: 'md',
                        hovered: true,
                        bordered: true,
                        background: 'fill'
                    }, [cnCreateProject(cnButtonInput())])}
                    text={'Создать проект'}
                    callBack={() => {
                        navigate('/constructor/new/')
                    }}
                />
                {
                    userData.roles.includes('GUEST') ||
                    <div className={cnCreateProject('Projects')}>
                        <div className={cnCreateProject('Description')}>
                            Вы можете создать проект шкафа-купе любой конфигурации на заказ

                        </div>
                        <ButtonInput
                            className={cnButtonInput({
                                size: 'md',
                                hovered: true,
                                bordered: true,
                                background: 'fill'
                            }, [cnCreateProject(cnButtonInput())])}
                            text={'Мои проекты'}
                            callBack={() => {
                                navigate('/projects/')
                            }}
                        />
                    </div>
                }
            </div>

        </div>
    );
}