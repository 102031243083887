import React, {useEffect, useState} from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./LoginForm.css"
import {useTranslation} from 'react-i18next';
import {cnTextinput, Textinput} from '@yandex/ui/Textinput/desktop/bundle'
import axios, {AxiosResponse} from "axios";
import {useDispatch} from "react-redux";
import ReactInputMask from "react-input-mask";
import {Link} from "@yandex/ui/Link/desktop/bundle";
import {Spin} from '@yandex/ui/Spin/desktop/bundle'
import {ISendResponse} from "../../../interfaces";
import * as AppConst from '../../../constants';
import {SET_USER, START_SESSION} from "../../../constants";
import {ButtonInput, cnButtonInput} from '../../all/ButtonInput';
import {CheckboxInput, cnCheckboxInput} from '../../all/CheckboxInput';
// import * as Form from './view/'

export const cnLoginForm = cn('LoginForm');

export interface ILoginFormProps extends IClassNameProps {
}

export const LoginForm: React.FC<ILoginFormProps> = ({className}) => {

    // const AppConst.SEND_SMS_TIMEOUT_SECONDS = 60; // таймаут между отправками смс 1 минута

    const [userPhone, setUserPhone] = useState('');
    const [userData, setUserData] = useState({});
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [checkPersonal, setCheckPersonal] = useState(true);
    const [checkOffer, setCheckOffer] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const [sendingSMS, setSendingSMS] = useState(false);
    const [timerSeconds, setTimerSeconds] = useState<number>(0);
    const {t} = useTranslation();

    axios.interceptors.request.use((config) => {
        setLoadingForm(true);
        return config;
    }, (error) => {
        setLoadingForm(false);
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        setLoadingForm(false);
        return response;
    }, (error) => {
        setLoadingForm(false);
        return Promise.reject(error);
    });
    const dispatch = useDispatch();
    const processLogin = () => {
        if (!validateLoginForm()) {
            return;
        }
        axios.post<any>('/auth/login', {
            phone: userPhone,
            password: password
        })
            .then((response) => {
                if (!response.data.user) {
                    setFormErrors({password: 'Вы ввели неверный пароль.'})
                }
                dispatch({
                    type: SET_USER,
                    // @ts-ignore тут я не знаю как сделать типы чтобы не ругалось, с сервака прилетает {user: UserData, sid: string}
                    payload: response.data.user,
                });
                dispatch({
                    type: START_SESSION,
                    // @ts-ignore тут я не знаю как сделать типы чтобы не ругалось, с сервака прилетает {user: UserData, sid: string}
                    payload: {sid: response.data.sid, success: true},
                });
            });
    };
    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formErrors && formErrors[event.target.name]) {
            delete formErrors[event.target.name];
            setFormErrors(formErrors);
        }
        switch (event.target.name) {
            case "checkPersonal":
                setCheckPersonal(event.target.checked);
                break;
            case "checkOffer":
                setCheckOffer(event.target.checked);
                break;
        }
    };
    const processRegister = () => {
        if (!validateRegisterForm()) {
            return;
        }
        axios.post('/auth/register', {
            phone: userPhone,
            firstName: userName,
            secondName: userLastName,
            email: userEmail,
            check_offer: checkOffer,
            check_personal: checkPersonal,
        })
            .then((response) => {
                dispatch({
                    type: SET_USER,
                    // @ts-ignore тут я не знаю как сделать типы чтобы не ругалось, с сервака прилетает {user: UserData, sid: string}
                    payload: response.data.user,
                });
                dispatch({
                    type: START_SESSION,
                    // @ts-ignore тут я не знаю как сделать типы чтобы не ругалось, с сервака прилетает {user: UserData, sid: string}
                    payload: {sid: response.data.sid, success: true},
                });
            });
    };

    const validateRegisterForm = (): boolean => {
        let newFormErrors: any = {};

        if (userName.length <= 0) {
            newFormErrors['userName'] = t('user-name-is-required');
        }
        if (userPhone.length <= 0) {
            newFormErrors['userPhone'] = t('user-phone-is-required');
        }
        if (userEmail.length > 0 &&
            !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(userEmail))) {
            newFormErrors['userEmail'] = t('email-is-invalid');
        }
        if (!checkPersonal) {
            newFormErrors['checkPersonal'] = t('check-personal-is-required');
        }
        if (!checkOffer) {
            newFormErrors['checkOffer'] = t('check-offer-is-required');
        }
        setFormErrors(newFormErrors);
        return Object.keys(newFormErrors).length <= 0;
    };

    const validateLoginForm = (): boolean => {
        let newFormErrors: any = {};

        if (userPhone.length <= 0 || !validatePhone(userPhone)) {
            newFormErrors['userPhone'] = t('user-phone-is-required');
        }
        if (password.length <= 0) {
            newFormErrors['password'] = t('password-is-required');
        }
        setFormErrors(newFormErrors);

        return Object.keys(newFormErrors).length <= 0;
    };

    const clearPhone = (phone: string): string => {
        return phone.replace(/(\+7)/, '8')
            // TODO: Убрал экранирующие символы, как того требовал EsLint.
            // TODO: Надо протестировать работоспособность.
            // .replaceAll(/(\+7)|(\()|(\))|(\-)|(\_)|(\s)/g, '');
            .replaceAll(/(\+7)|(\()|(\))|(-)|(_)|(\s)/g, '');
    };

    const validatePhone = (phoneValue: string) => {
        phoneValue = clearPhone(phoneValue);

        return (phoneValue.length === 11) && phoneValue[0] === '8' && phoneValue[1] === '9';
    };

    const onChangeUserPhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newPhone = event.target.value;
        setUserPhone(newPhone);
        if (formErrors && formErrors['userPhone']) {
            delete formErrors['userPhone'];
            setFormErrors(formErrors);
        }
        if (validatePhone(newPhone)) {
            axios.get('/api/user/get?userPhone=' + clearPhone(newPhone))
                .then((response: AxiosResponse) => {
                    if (response.data) {
                        setUserData(response.data);
                        console.log(response)
                    } else {
                        setUserData({isNew: true});
                    }
                })
                .catch(reason => {
                    console.log(reason);
                });
        }
    };

    const onChangePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formErrors && formErrors[event.target.name]) {
            delete formErrors[event.target.name];
            setFormErrors(formErrors);
        }
        switch (event.target.name) {
            case "userName":
                setUserName(event.target.value);
                break;
            case "userLastName":
                setUserLastName(event.target.value);
                break;
            case "userEmail":
                setUserEmail(event.target.value);
                break;
        }
    };
    useEffect(() => {
        if (timerSeconds > 0) {
            setTimeout(() => setTimerSeconds(timerSeconds - 1), 1000);
        } else {
            setSendingSMS(false);
        }
    }, [timerSeconds]);

    const sendSMSTimerPassword = () => {
        if (sendingSMS) {
            return;
        }
        setSendingSMS(true);
        axios.post<any>('/auth/send-sms', {
            phone: userPhone
        })
            .then((response) => {
                const responseData: ISendResponse = response.data;
                if (responseData.success) {
                    setTimerSeconds(AppConst.SEND_SMS_TIMEOUT_SECONDS);
                } else {
                    setFormErrors({sendSMSTimer: 'Не удалось отправить СМС, попробуйте снова.'});
                }
            })
            .catch(() => {
                setSendingSMS(false);
            });
    };

    return (
        <div className={cnLoginForm({}, [className])}>
            <div className={cnLoginForm('Title')}>{t('login-form-title')}</div>

            <div className={cnLoginForm('Field')}>
                <div className={cnLoginForm('FieldTitle')}>{t('user-phone')}:</div>
                <div className={cnLoginForm('FieldNotes')}>{t('user-phone-notes')}</div>
                <div className={cnLoginForm('Input', {error: !!(formErrors && formErrors['userPhone'])})}>
                    <ReactInputMask
                        mask="+7 (\999) 999-99-99"
                        value={userPhone}
                        onChange={onChangeUserPhoneInput}
                        placeholder="+7 (___) ___-__-__"
                    >
                        <span className={cnTextinput({size: "m", view: "material"})}>
                            <input type="text" className={cnTextinput('Control')}/>
                        </span>
                    </ReactInputMask>
                </div>
            </div>
            {userData.hasOwnProperty('id') ? (<>
                <div className={cnLoginForm('Field')}>
                    <div className={cnLoginForm('FieldTitle')}>{t('user-password')}:</div>
                    <div className={cnLoginForm('Input', {error: !!(formErrors && formErrors['password'])})}>
                        <Textinput
                            value={password}
                            type="password"
                            name="password"
                            onChange={onChangePasswordInput}>
                        </Textinput>
                    </div>
                </div>
            </>) : ''}
            {userData.hasOwnProperty('isNew') ? (<>
                <div className={cnLoginForm('Field')}>
                    <div className={cnLoginForm('FieldTitle')}>{t('user-name')}:</div>
                    <div className={cnLoginForm('Input', {error: !!(formErrors && formErrors['userName'])})}>
                        <Textinput
                            size="m"
                            view="default"
                            value={userName}
                            name="userName"
                            onChange={onChangeInput}>
                        </Textinput>
                    </div>
                </div>
                <div className={cnLoginForm('Field')}>
                    <div className={cnLoginForm('FieldTitle')}>{t('user-last-name')}:</div>
                    <div className={cnLoginForm('Input', {error: !!(formErrors && formErrors['userLastName'])})}>
                        <Textinput
                            size="m"
                            view="default"
                            value={userLastName}
                            name="userLastName"
                            onChange={onChangeInput}>
                        </Textinput>
                    </div>
                </div>
                <div className={cnLoginForm('Field')}>
                    <div className={cnLoginForm('FieldTitle')}>{t('user-email')}:</div>
                    <div className={cnLoginForm('FieldNotes')}>{t('user-email-notes')}:</div>
                    <div className={cnLoginForm('Input', {error: !!(formErrors && formErrors['userEmail'])})}>
                        <Textinput
                            size="m"
                            view="default"
                            value={userEmail}
                            name="userEmail"
                            onChange={onChangeInput}>
                        </Textinput>
                    </div>
                </div>
                <div className={cnLoginForm('Field')}>
                    <div className={cnLoginForm('Checkbox', {error: !!(formErrors && formErrors['checkPersonal'])})}>
                        <CheckboxInput
                            className={cnLoginForm(cnCheckboxInput(), {})}
                            checked={checkPersonal}
                            callBack={onChangeCheckbox}
                            name={'checkPersonal'}
                            label={<><span>{t('check-personal-label')}</span> <Link
                                href="https://azbykamebeli.ru/fz125.php" target="_blank">{t('more')}</Link></>}
                        />
                    </div>
                </div>
                <div className={cnLoginForm('Field')}>
                    <div className={cnLoginForm('Checkbox', {error: !!(formErrors && formErrors['checkOffer'])})}>
                        <CheckboxInput
                            className={cnLoginForm(cnCheckboxInput(), {})}
                            checked={checkOffer}
                            callBack={onChangeCheckbox}
                            name={'checkOffer'}
                            label={<><span>{t('acquainted-with-offer')}</span> <Link
                                href="https://azbykamebeli.ru/offer/" target="_blank">{t('more')}</Link></>}
                        />
                    </div>
                </div>
            </>) : ''}
            {(Object.keys(formErrors).length > 0) ? (
                <div className={cnLoginForm('Errors')}>
                    {Object.keys(formErrors).map(key =>
                        <div id={key}>{formErrors[key]}</div>
                    )}
                </div>
            ) : ''}
            {
                Object.keys(userData).length === 0 ||
                <ButtonInput
                    className={cnButtonInput({
                        size: 'md',
                        hovered: true,
                        bordered: true,
                        background: 'fill'
                    }, [cnLoginForm(cnButtonInput())])}
                    text={userData.hasOwnProperty('isNew') ? t('register') : t('login')}
                    callBack={() => {
                        userData.hasOwnProperty('isNew') ? processRegister() : processLogin()
                    }}
                />
                // <Button view="default" theme="action" size="m" type="submit"
                //         onClick={() => {
                //             userData.hasOwnProperty('isNew') ? processRegister() : processLogin()
                //         }}>
                //     {userData.hasOwnProperty('isNew') ? t('register') : t('login')}
                // </Button>
            }
            {userData.hasOwnProperty('id') ? (<>
                <div className={cnLoginForm('Notes')}>{t('forget-password')}?</div>
                {sendingSMS || <Link className={cnLoginForm('SendSMS')}
                                     onClick={sendSMSTimerPassword}>{t('send-new-password')}</Link>}
                {timerSeconds <= 0 ||
                    <div className={cnLoginForm('Asd')}>{t('sending-new-password')} {timerSeconds} {t('sec')}</div>}
            </>) : ''}
            <div className={cnLoginForm('Spin', {visible: loadingForm})}>
                <Spin progress={loadingForm} view="default" size="m"/>
            </div>
        </div>
    );
};