import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnRangeInput, RangeInput} from '../../../all/RangeInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {Dispatch} from 'redux';
import {CHANGE_WARDROBE_HEIGHT} from '../../../../constants';
import {
    WARDROBE_MIN_HEIGHT,
    WARDROBE_MAX_HEIGHT
} from '../../../../../common-code/constants';

export const cnHeightFragment = cn('HeightFragment');

export interface IHeightFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    sideIndex: number,
    dispatch: Dispatch,
    min: number,
    max: number,
    step?: number
}

export const HeightFragment: React.FC<IHeightFragmentProps> = ({ className, constructorFormData, sideIndex, dispatch, min, max, step }) => {

    const changeHeight = (height: number) => {
        if (height < WARDROBE_MIN_HEIGHT) {
            height = WARDROBE_MIN_HEIGHT;
        }
        if (height > WARDROBE_MAX_HEIGHT) {
            height = WARDROBE_MAX_HEIGHT;
        }

        dispatch({
            type: CHANGE_WARDROBE_HEIGHT,
            sideIndex: sideIndex,
            payload: height,
        });
    };

    return (
        <div className={cnConstructorForm(cnHeightFragment(), [className, cnConstructorForm('RangeInputContainer')])}>
            <span className={cnConstructorForm(
                'Label',
                {size: 'sm', color: 'lightNavy'},
                [cnConstructorForm('RangeInputLabel')]
            )}>Высота,мм</span>
            <RangeInput
                className={cnConstructorForm(cnRangeInput())}
                min={min}
                max={max}
                step={step}
                value={constructorFormData.sideWardrobes[sideIndex].height}
                callBack={changeHeight}
                viewControl={true}
            />
        </div>
    );
};