import {
    ThreePanel
} from "../";
import {IShelf} from "../../../../../../domain/intarfaces";
import {ThreeSideWardrobe} from "../../ThreeSideWardrobe";
import {IShelfData} from '../../../../../../common-code/domain/interfaces/IShelfData/IShelfData';

export class ThreeShelf extends ThreePanel implements IShelf {
    readonly isShelf: boolean;
    isPortable: boolean;

    constructor(sideWardrobe: ThreeSideWardrobe, options: IShelfData) {
        super(sideWardrobe, options);
        this.isPortable = options.isPortable;
        this.isShelf = true;
    }

    public getSaveData(): IShelfData {
        let saveData: IShelfData;

        saveData = super.getSaveData() as IShelfData;

        saveData.isPortable = this.isPortable;

        return saveData;
    }

    public calculatePoints() {
        super.calculatePoints();
    }

    public calculateVisiblePoints() {
        super.calculateVisiblePoints();
    }

    protected createBodyMaterial() {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.mainConstructor.getConstructorService().getPanelBodyMaterial();
        }

        return this.bodyMaterial;
    }
}