import {cn} from "@bem-react/classname";
import React, {useEffect} from "react";
import {IClassNameProps} from "@bem-react/core";
import {Modal} from '@yandex/ui/Modal/desktop/bundle'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {HIDE_LOADING, SET_LOCATION, SET_LOCATIONS, SHOW_LOADING} from "../../../constants";
import {TLocationData} from "../../../../common-code/domain/types";
import axios from "axios";
import "./LocationModal.css";
import {cnSpinInput, SpinInput} from '../../all/SpinInput';
import SvgIcon from '../../../helpers/SvgIcon/SvgIcon';
import {IsComponentVisible} from '../../../interfaces/IsComponentVisible';

export const cnLocationModal = cn('LocationModal');

export interface ILocationModalProps extends IClassNameProps {
    setIsComponentVisible: IsComponentVisible
}

export const LocationModal: React.FC<ILocationModalProps> = ({className, setIsComponentVisible}) => {

    const dispatch = useDispatch();
    const isModalOpen = useSelector((state: AppState) => state.menus.LocationMenu.visible);
    const locations = useSelector((state: AppState) => state.location.all);
    const currentLocation: TLocationData | undefined = useSelector((state: AppState) => state.location.current);
    const loading = useSelector((state: AppState) => state.common.loading);

    useEffect(() => {
        if (locations.length > 0) {
            return;
        }
        axios.get('/api/location/all')
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    dispatch({
                        type: SET_LOCATIONS,
                        payload: response.data,
                    });
                }
            });
    }, [locations, dispatch]);

    const onSelectLocation = (id: number) => {
        let location: TLocationData | undefined;

        dispatch({type: SHOW_LOADING});
        location = locations.find((item: TLocationData) => {
            return id === item.id
        });
        if (location) {
            axios.post('/api/location/set', location)
                .then((response) => {
                    if (response.data && response.data.id) {
                        dispatch({
                            type: SET_LOCATION,
                            payload: location,
                        });
                    }
                    dispatch({type: HIDE_LOADING});
                    setIsComponentVisible(false);
                }).catch(() => {
                dispatch({type: HIDE_LOADING});
            });
        }
    };

    return (
        <Modal
            theme="normal"
            visible={isModalOpen}>
            <div className={cnLocationModal({}, [className])}>
                <div className={cnLocationModal('Close')} onClick={() => {
                    setIsComponentVisible(false)
                }}>
                    <SvgIcon icon='remove' className={cnLocationModal('CloseIcon')}/>
                </div>
                {locations.map((item: TLocationData) => {
                    return (<div key={item.id}
                                 onClick={() => {
                                     onSelectLocation(item.id)
                                 }}
                                 className={cnLocationModal(
                                     'Item',
                                     {current: currentLocation && currentLocation.id === item.id}
                                 )}>
                        {item.title}
                    </div>);
                })}
                {!loading || <SpinInput
                    className={cnLocationModal(cnSpinInput())}
                    loading={loading}
                />}
            </div>
        </Modal>
    );
};