import {IRail} from "../../../../../../domain/intarfaces";
import {ThreeDoorContainer} from "../../ThreeDoorContainer/ThreeDoorContainer";
import {ExtrudeGeometry, Mesh, Shape, Vector2} from "three";
import {ThreePlank} from "..";
import {ThreeGeometry} from "../../../helpers";
import {IRailData} from "../../../../../../common-code/domain/interfaces/IRailData/IRailData";
import {TLevel} from "../../../../../../common-code/domain/types";
import {LEVEL_BOTTOM, LEVEL_TOP} from "../../../../../../common-code/constants";
import {TPoint2D} from '../../../../../../common-code/domain/types';
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {
    IPlankMaterialPriceData
} from '../../../../../../common-code/domain/interfaces/IPriceData/IPlankMaterialPriceData';
import {IPlankEntityData} from '../../../../../../common-code/domain/interfaces/IPlankEntityData';

export class ThreeRail extends ThreePlank implements IRail {
    width: number;
    height: number;
    level: TLevel;
    shape: Shape;
    shapePath: TPoint2D[];
    doorContainer: ThreeDoorContainer;

    constructor(doorContainer: ThreeDoorContainer, options: IRailData) {
        super(doorContainer.mainConstructor, options);
        this.doorContainer = doorContainer;
        this.width = options.width;
        this.height = options.height;
        this.level = options.level;
        this.shapePath = this.initShapePoints(this.level, options.shapePath);
        this.shape = this.createShape();
    }

    public createView() {
        let rail;
        let railGeometry;
        let position;

        if (!this.doorContainer.points) {
            return;
        }
        railGeometry = this.createBodyGeometry();
        rail = new Mesh(railGeometry, this.createBodyMaterial());
        rail.add(this.createCarcassMesh(rail.geometry));
        switch (this.level) {
            case LEVEL_BOTTOM:
                rail.rotateY(Math.PI / 2);
                position = ThreeGeometry.getPointByRatio(this.doorContainer.pointA, this.doorContainer.pointB, 0.5);
                position.y = this.height / 2;
                position.z = this.doorContainer.getDepth() / 2;
                rail.position.copy(position);
                break;
            case LEVEL_TOP:
                rail.rotateY(Math.PI / 2);
                rail.rotateZ(Math.PI);
                position = ThreeGeometry.getPointByRatio(this.doorContainer.pointA, this.doorContainer.pointB, 0.5);
                position.y = this.doorContainer.getHeight() - this.getHeight() / 2;
                position.z = this.doorContainer.getDepth() - this.getWidth() / 2;
                rail.position.copy(position);
        }

        this.view3d.add(rail);
        super.createView();
    }

    public getPriceData(): IPlankMaterialPriceData {
        let railData: IPlankEntityData | undefined;
        let plankCount: number;

        railData = this.mainConstructor.getRailByLevel(this.level);
        plankCount = Math.ceil(this.getLength() / (railData ? railData.length : this.getLength()))

        return {
            length: this.getLength(),
            plankPrice: railData ? railData.price : 0,
            code: railData ? railData.product.code : '',
            errors: [],
            name: railData ? railData.name : '',
            materialId: railData ? railData.id : 0,
            sum: railData ? plankCount * railData.price : 0,
            plankLength: railData ? railData.length : this.getLength(),
            store: railData && railData.store ? '' + railData.store : '-',
            price: railData ? railData.price : 0,
            amount: railData && railData.store ? railData.store : 0,
            count: plankCount,
        };
    }

    public getSaveData(): IRailData {
        super.getSaveData();
        let globalPoints: IStartEndPoints | undefined;
        globalPoints = this.calculateGlobalPoints();

        return {
            id: this.getId(),
            level: this.level,
            height: this.height,
            width: this.width,
            shapePath: this.shapePath,
            startPoint: globalPoints?.start,
            endPoint: globalPoints?.end,
            length: globalPoints?.length,
            rotation: globalPoints?.rotation
        };
    }

    public getWidth() {
        return +this.width;
    }


    public getHeight() {
        return +this.height;
    }

    public getLength(): number {
        if (!this.doorContainer.points) {
            throw new Error("Not calculate Length")
        }
        return this.doorContainer.getLength();
    }

    private createBodyGeometry() {
        let geometry;

        geometry = new ExtrudeGeometry(this.shape, {depth: this.getLength(), bevelEnabled: false});
        geometry.center();

        return geometry;
    }

    protected initShapePoints(level: TLevel, initShape?: TPoint2D[]): TPoint2D[] {
        let points: TPoint2D[] = [];

        if (initShape) {
            points = initShape;
        } else {
            switch (level) {
                case LEVEL_BOTTOM:
                    points = [
                        {x: -this.width / 2, y: 0},
                        {x: -this.width / 2 + 5, y: this.height},
                        {x: -this.width / 2 + 15, y: this.height},
                        {x: -this.width / 2 + 15, y: this.height - 5},
                        {x: -this.width / 2 + 18, y: this.height - 5},
                        {x: -this.width / 2 + 18, y: this.height},
                        {x: this.width / 2 - 18, y: this.height},
                        {x: this.width / 2 - 18, y: this.height - 5},
                        {x: this.width / 2 - 15, y: this.height - 5},
                        {x: this.width / 2 - 15, y: this.height},
                        {x: this.width / 2 - 5, y: this.height},
                        {x: this.width / 2, y: 0},
                        {x: -this.width / 2, y: 0},
                    ];
                    break;
                case LEVEL_TOP:
                    points = [
                        {x: -this.width / 2, y: this.height},
                        {x: -this.width / 2 + 1, y: this.height},
                        {x: -this.width / 2 + 1, y: 1},
                        {x: -0.5, y: 1},
                        {x: -0.5, y: this.height},
                        {x: 0.5, y: this.height},
                        {x: 0.5, y: 1},
                        {x: this.width / 2 - 1, y: 1},
                        {x: this.width / 2 - 1, y: this.height},
                        {x: this.width / 2, y: this.height},
                        {x: this.width / 2, y: 0},
                        {x: -this.width / 2, y: 0},

                    ];
                    break;
            }
        }

        return points;
    }


    private createShape(): Shape {
        let shapePoints: Vector2[];
        let shape: Shape;
        let point;

        shapePoints = [];
        for (point of this.shapePath) {
            shapePoints.push(new Vector2(point.x, point.y));
        }
        if (shapePoints.length <= 0) {
            throw new Error("Shape of ThreeRail cant be empty");
        }
        shape = new Shape();
        shape.setFromPoints(shapePoints);

        return shape;
    }
}