import React from 'react';
import { ReactComponent as IconSprite } from '../../../assets/svg-sprites/icon-sprite.svg';

const CustomSprite = () => {
    return (
        <div style={{ display: 'none' }}>
            <IconSprite />
        </div>
    );
};

export default CustomSprite;