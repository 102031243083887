import React, {RefObject} from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./Editor.css";
import '../../../i18n';
import {useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {EditorButtons} from "../";
import {cnEditorButtons} from "../EditorButtons";
import {cnSpinInput, SpinInput} from "../SpinInput";
import {ProjectPrice} from '../ProjectPrice/ProjectPrice';
import {ThreeConstructor} from '../../3d';

export const cnEditor = cn('Editor');

export interface IEditorProps extends IClassNameProps {
    editorRef: RefObject<HTMLDivElement>;
    threeConstructor: ThreeConstructor;
}

export const Editor: React.FC<IEditorProps> = ({className, editorRef, threeConstructor}) => {
    const loading = useSelector((state: AppState) => state.common.loading);

    return (
        <div className={cnEditor({}, [cnEditor({bordered: true}), className])} ref={editorRef}>
            <EditorButtons className={cnEditor(cnEditorButtons())} threeConstructor={threeConstructor}/>
            <SpinInput
                className={cnEditor(cnSpinInput())}
                loading={loading}
            />
            <ProjectPrice threeConstructor={threeConstructor}/>
        </div>
    );
};