import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './SelectInput.css';
import {Select} from '@yandex/ui/Select/desktop/bundle';
import {TSelectOption} from '../../../../common-code/domain/types/TSelectOption';

export const cnSelectInput = cn('SelectInput');

export interface ISelectInputProps extends IClassNameProps {
    placeholder: string,
    showPlaceholder?: boolean,
    options: Array<TSelectOption>,
    value: string,
    callBack: (event: ChangeEvent<HTMLSelectElement>) => void
}

export const SelectInput: React.FC<ISelectInputProps> = ({ className, placeholder, showPlaceholder, options, value, callBack  }) => {
    return (
        <div className={cnSelectInput({ placeholder: showPlaceholder }, [className])}>
            <Select
                theme={'normal'}
                placeholder={placeholder}
                showAlwaysPlaceholder={showPlaceholder}
                options={options}
                value={value}
                onChange={callBack}
            />
        </div>
    );
};