import {IConstructorService} from "../../intarfaces";
import {VIEW_MODE_2D, VIEW_QUALITY_MEDIUM} from "../../../common-code/constants";
import {ThreePanel} from "../../../app/blocks/3d/models/panels";
import {ThreeProfile} from "../../../app/blocks/3d/models/planks/ThreeProfile/ThreeProfile";
import {TViewMode} from '../../../common-code/domain/types/TViewMode';
import {TViewQuality} from '../../../common-code/domain/types/TViewQuality';
import {TTechnologMap} from '../../../common-code/domain/types/TTechnologMap';

export class ConstructorService implements IConstructorService {

    public id: number;
    public lastId: number;
    public viewMode: TViewMode;
    public viewQuality: TViewQuality;
    public currentIds: { [n: number]: number };
    public technologMap: TTechnologMap;

    constructor(technologMap: TTechnologMap, viewMode?: TViewMode, viewQuality?: TViewQuality) {
        this.id = +new Date();
        this.lastId = 1;
        this.viewMode = viewMode || this.getDefaultViewMode();
        this.viewQuality = viewQuality || this.getDefaultViewQuality();
        this.currentIds = {};
        this.technologMap = technologMap;
    }

    public createId(id?: number): number
    {
        let newId;

        if (id && !isNaN(+id) && !this.currentIds[id]) {
            newId = +id;
        } else {
            while (!this.currentIds[this.lastId]) {
                this.lastId++;
            }
            newId = +this.lastId;
        }
        this.currentIds[newId] = newId;

        return newId;
    };

    public removeId(id: number): void {
        delete this.currentIds[id];
    }

    public getDefaultViewMode(): TViewMode
    {
        return VIEW_MODE_2D;
    }
    public getViewQuality(): TViewQuality
    {
        return VIEW_QUALITY_MEDIUM;
    }

    public getDefaultViewQuality(): TViewQuality
    {
        return VIEW_QUALITY_MEDIUM;
    }

    public getViewMode(): TViewMode
    {
        return this.viewMode;
    }

    public getBodyMaterial(): object {
        return {};
    }

    public getPanelBodyMaterial(): object {
        return {};
    }

    public getShelfBodyMaterial(): object {
        return {};
    }

    public getRodBodyMaterial(): object {
        return {};
    }

    public getCurrentVersion(): string {
        return '1.0.0';
    }

    /**
     * Метод переводит строку в число
     * если задан процент первым символом, то берем прцент от толщины родителя
     * потом вохможно модификации появятся
     *
     * @param indention
     * @param parent
     */
    public getIndentionValue(indention: string, parent: ThreePanel | ThreeProfile): number {
        let resultValue: number;

        switch (indention[0]) {
            case "%":
                resultValue = +parent.depth;
                break;
            default:
                resultValue = +indention;
                if (isNaN(+indention)) {
                    resultValue = 0;
                }
                break;

        }
        return resultValue;
    }

}