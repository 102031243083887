import {ThreeObject} from "../ThreeObject";
import {IDrawer} from "../../../../../domain/intarfaces";
import {ThreeSideWardrobe} from "../ThreeSideWardrobe";
import {ThreePanelPoint} from "../points";
import {ThreeGeometry} from "../../helpers";
import {IThreePanelPoints} from "../../interfaces";
import {
    Box3,
    BoxGeometry, BufferGeometry,
    ExtrudeGeometry,
    Mesh, MeshStandardMaterial,
    Shape,
    Vector2,
    Vector3
} from "three";
import {ThreePanel} from "../panels";
import {AXIS_X, AXIS_Y} from "../../../../../domain/constants";
import {TBoxData} from "../../../../../common-code/domain/types";
import {TFacadeData} from "../../../../../common-code/domain/types";
import {TTechnologMap} from "../../../../../common-code/domain/types";
import {IDrawerData} from '../../../../../common-code/domain/interfaces/IDrawerData/IDrawerData';
import {IDrawerPanelData} from "../../../../../common-code/domain/interfaces/IDrawerData/IDrawerPanelData";
import {
    IMPORT_PANEL_DIRECTION_TYPE_FRONT,
    IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL,
    IMPORT_PANEL_DIRECTION_TYPE_VERTICAL
} from "../../../../../common-code/constants";
import {TPanelDirectionType} from "../../../../../common-code/domain/types/TPanelDirectionType";
import {IDrawerPriceData} from '../../../../../common-code/domain/interfaces/IPriceData/IDrawerPriceData';
import {
    ISquareMaterialPriceData
} from '../../../../../common-code/domain/interfaces/IPriceData/ISquareMaterialPriceData';

export class ThreeDrawer extends ThreeObject implements IDrawer {
    sideWardrobe: ThreeSideWardrobe;
    depth: number;
    height: number;
    pointA: ThreePanelPoint;
    pointB: ThreePanelPoint;
    shift: { depth: number; height: number };
    points?: IThreePanelPoints;
    leftParent?: ThreePanel;
    rightParent?: ThreePanel;
    visiblePointA?: Vector3;
    visiblePointB?: Vector3;
    box: TBoxData;
    facade: TFacadeData;
    width?: number;
    panelMaterial: number;
    bottomMaterial: number;
    panelMeshMaterial?: MeshStandardMaterial;
    shapePanelMeshMaterial?: MeshStandardMaterial;
    bottomMeshMaterial?: MeshStandardMaterial;
    bottomMeshPanels: Mesh[];
    meshPanels: Mesh[];
    facadeMeshPanels: Mesh[];

    constructor(sideWardrobe: ThreeSideWardrobe, options: IDrawerData) {
        super(sideWardrobe.mainConstructor, options);
        this.sideWardrobe = sideWardrobe;
        this.depth = options.depth;
        this.height = options.height;
        this.pointA = this.mainConstructor.getPointById(options.pointA) as ThreePanelPoint;
        this.pointB = this.mainConstructor.getPointById(options.pointB) as ThreePanelPoint;
        this.shift = options.shift;
        this.box = options.box;
        this.facade = options.facade;
        this.panelMaterial = options.panelMaterial;
        this.bottomMaterial = options.bottomMaterial;
        this.meshPanels = [];
        this.bottomMeshPanels = [];
        this.facadeMeshPanels = [];
    }


    public createView() {
        this.setParentPanels();
        this.calculatePoints();
        if (!this.points) {
            return;
        }
        this.createBox();
        this.createRails();
        this.createFacade();
        this.setPosition();
        this.sideWardrobe.view3d.add(this.view3d);
        super.createView();
    }

    public getSaveData(): IDrawerData {
        super.getSaveData();

        return {
            id: this.getId(),
            pointA: this.pointA.getId(),
            pointB: this.pointB.getId(),
            box: this.box,
            facade: this.facade,
            shift: this.shift,
            depth: this.depth,
            height: this.height,
            bottomMaterial: this.bottomMaterial,
            panelMaterial: this.panelMaterial,
            panels: this.calculatePanels(this.meshPanels),
            bottomPanels: this.calculatePanels(this.bottomMeshPanels),
            facadePanels: this.calculatePanels(this.facadeMeshPanels),
        };
    }

    public setPosition() {
        let position: Vector3;

        if (!this.visiblePointA) {
            return;
        }
        position = this.visiblePointA.clone();
        position.z = this.mainConstructor.getConstructorService().technologMap.backFiller.depth +
            this.mainConstructor.getConstructorService().technologMap.drawer.gap.back;
        this.view3d.position.copy(position);
    }

    public getPriceData(): IDrawerPriceData {
        let priceData: IDrawerPriceData;
        let squarePriceData: ISquareMaterialPriceData[] = [];

        priceData = this.initPriceData();

        // debugger;
        // squarePriceData = this.calculatePanelsPriceData(this.meshPanels);
        //
        //     bottomPanels: this.calculatePanels(this.bottomMeshPanels),
        //     facadePanels: this.calculatePanels(this.facadeMeshPanels),

        return priceData;
    }

    protected calculatePanelsPriceData(meshPanels: Mesh[], squarePriceData: ISquareMaterialPriceData[]) {
        let panels: IDrawerPanelData[];
        let panel: IDrawerPanelData;
        panels = this.calculatePanels(meshPanels);
        for (panel of panels) {
            // squarePriceData.push({
            //     code: '',
            //     name: '',
            //     store: '',
            //
            // })
        }

        return squarePriceData;
    }

    protected initPriceData(): IDrawerPriceData {
        return {
            ldsp: [],
            dvp: [],
            furniture: []
        };
    }

    protected calculatePanels(meshPanels: Mesh[]): IDrawerPanelData[] {
        let panels: IDrawerPanelData[] = [];
        let meshPanel: Mesh;
        let box: Box3;
        let sizes: number[];
        let directionType: TPanelDirectionType;
        let startPoint: Vector3;
        let endPoint: Vector3;
        let depth: number;

        box = new Box3();
        for (meshPanel of meshPanels) {
            box.setFromObject(meshPanel);
            if (box.isEmpty()) {
                continue;
            }
            startPoint = box.min.clone().applyMatrix4(this.sideWardrobe.view3d.matrixWorld);
            endPoint = box.max.clone().applyMatrix4(this.sideWardrobe.view3d.matrixWorld);
            sizes = [
                (endPoint.x - startPoint.x),
                (endPoint.y - startPoint.y),
                (endPoint.z - startPoint.z)
            ];
            directionType = IMPORT_PANEL_DIRECTION_TYPE_VERTICAL;
            depth = this.mainConstructor.getConstructorService().technologMap.drawer.sides.depth;
            switch (sizes.indexOf(Math.min(...sizes))) {
                case 0:
                    directionType = IMPORT_PANEL_DIRECTION_TYPE_VERTICAL;
                    depth = sizes[0];
                    break;
                case 1:
                    directionType = IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL;
                    depth = sizes[1];
                    break;
                case 2:
                    directionType = IMPORT_PANEL_DIRECTION_TYPE_FRONT;
                    depth = sizes[2];
                    break;
            }
            panels.push({
                startPoint: {x: startPoint.x, y: startPoint.y, z: startPoint.z},
                endPoint: {x: endPoint.x, y: endPoint.y, z: endPoint.z},
                directionType: directionType,
                depth: depth
            })
        }

        return panels;
    }

    private setParentPanels() {
        this.leftParent = this.pointA.getParentPanel();
        this.rightParent = this.pointB.getParentPanel();
    }

    private calculatePoints() {
        let width;

        this.calculateVisiblePoints();
        if (!this.visiblePointA || !this.visiblePointB) {
            return;
        }
        width = this.getWidth();

        this.points = {
            A: new Vector3(0, 0, 0),
            B: new Vector3(0, this.height, 0),
            C: new Vector3(width, 0, 0),
            D: new Vector3(width, this.height, 0),
            A2: new Vector3(0, 0, this.depth),
            B2: new Vector3(0, this.height, this.depth),
            C2: new Vector3(width, 0, this.depth),
            D2: new Vector3(width, this.height, this.depth),
        };
    }

    private getWidth(): number {
        if (!this.visiblePointA || !this.visiblePointB) {
            return 0;
        }
        return this.visiblePointA.clone().distanceTo(this.visiblePointB.clone());
    }

    private calculateVisiblePoints() {
        let localPoints: { A: Vector2; B: Vector2 };
        let intersectPoints = [];
        let intersectPoint;
        let intersectPointA;
        let intersectPointB;

        if (!this.leftParent || !this.rightParent || !this.leftParent.points || !this.rightParent.points) {
            return;
        }
        if (!this.pointA.isInited()) {
            this.pointA.calculatePosition();
        }
        if (!this.pointB.isInited()) {
            this.pointB.calculatePosition();
        }
        if (!this.pointA.isInited() || !this.pointB.isInited()) {
            return;
        }

        localPoints = {
            A: ThreeGeometry.toVector2D(
                this.pointA.value,
                AXIS_X,
                AXIS_Y
            ),
            B: ThreeGeometry.toVector2D(
                this.pointB.value,
                AXIS_X,
                AXIS_Y
            )
        };
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointA = ThreeGeometry.getNearPoint2D(localPoints.B, intersectPoints);
        if (intersectPointA) {
            this.visiblePointA = new Vector3(intersectPointA.x, intersectPointA.y + this.shift.height, this.shift.depth);
        }
        intersectPoints = [];
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: localPoints.A,
                pointB: localPoints.B
            },
            {
                pointA: ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y)
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointB = ThreeGeometry.getNearPoint2D(localPoints.A, intersectPoints);
        if (intersectPointB) {
            this.visiblePointB = new Vector3(intersectPointB.x, intersectPointB.y + this.shift.height, this.shift.depth);
        }
    }

    private createFacade() {
        let facadeGeometry: ExtrudeGeometry;
        let shape: Shape;
        let facade: Mesh;
        let width: number;

        shape = new Shape();
        width = this.getWidth();
        shape.moveTo(-width / 2 + this.facade.width, -this.height / 2 + this.facade.width);
        shape.lineTo(-width / 2 + this.facade.width, this.height / 2 - this.facade.width);
        shape.lineTo(width / 2 - this.facade.width, this.height / 2 - this.facade.width);
        shape.lineTo(width / 2 - this.facade.width, -this.height / 2 + this.facade.width);
        shape.lineTo(-width / 2 + this.facade.width, -this.height / 2 + this.facade.width);

        facadeGeometry = new ExtrudeGeometry(shape, {
            steps: 1,
            depth: this.facade.depth - 1,
            bevelEnabled: true,
            bevelThickness: 0.5,
            bevelSize: 0.3,
            bevelOffset: 0,
            bevelSegments: 3
        });
        facadeGeometry.center();

        facade = new Mesh(facadeGeometry, this.createShapePanelMaterial());
        facade.position.set(width / 2, this.height / 2, this.depth - this.facade.depth / 2);
        facade.add(this.createCarcassMesh(facadeGeometry));
        facade.name = 'facade';
        this.facadeMeshPanels.push(facade);
        this.view3d.add(facade);
    }

    private createBox() {
        let bottomDepth: number;
        let bottom: Mesh;
        let leftSide: Mesh;
        let rightSide: Mesh;
        let backSide: Mesh;
        let width;
        let technologMap: TTechnologMap;
        let boxWidth: number;
        let boxDepth: number;
        let sideHeight: number;

        if (!this.points) {
            return;
        }
        technologMap = this.mainConstructor.getConstructorService().technologMap;
        width = this.getWidth();
        boxDepth = this.depth - this.facade.depth;
        bottomDepth = boxDepth + technologMap.drawer.facade.boxCut.depth;
        boxWidth = width - technologMap.drawer.boxGap.left - technologMap.drawer.boxGap.right;
        sideHeight = this.height - technologMap.drawer.boxGap.bottom - technologMap.drawer.boxGap.top - this.box.bottom.depth;
        bottom = new Mesh(
            new BoxGeometry(
                boxWidth,
                this.box.bottom.depth,
                bottomDepth
            ),
            this.createBottomMaterial()
        );
        bottom.position.set(
            width / 2,
            technologMap.drawer.boxGap.bottom + this.box.bottom.depth / 2,
            bottomDepth / 2
        );
        bottom.add(this.createCarcassMesh(bottom.geometry));
        bottom.name = 'bottom';
        this.bottomMeshPanels.push(bottom);
        this.view3d.add(bottom);
        leftSide = new Mesh(
            new BoxGeometry(
                this.box.sides.depth,
                sideHeight,
                boxDepth - this.box.sides.depth
            ),
            this.createPanelMaterial()
        );
        leftSide.position.set(
            technologMap.drawer.boxGap.left + this.box.sides.depth / 2,
            technologMap.drawer.boxGap.bottom + this.box.bottom.depth + sideHeight / 2,
            (boxDepth - this.box.sides.depth) / 2 + this.box.sides.depth
        );
        leftSide.add(this.createCarcassMesh(leftSide.geometry));
        leftSide.name = 'leftSide';
        this.meshPanels.push(leftSide);
        this.view3d.add(leftSide);
        rightSide = new Mesh(
            new BoxGeometry(
                this.box.sides.depth,
                sideHeight,
                boxDepth - this.box.sides.depth
            ),
            this.createPanelMaterial()
        );
        rightSide.position.set(
            width - technologMap.drawer.boxGap.right - this.box.sides.depth / 2,
            technologMap.drawer.boxGap.bottom + this.box.bottom.depth + sideHeight / 2,
            (boxDepth - this.box.sides.depth) / 2 + this.box.sides.depth
        );
        rightSide.add(this.createCarcassMesh(rightSide.geometry));
        rightSide.name = 'rightSide';
        this.meshPanels.push(rightSide);
        this.view3d.add(rightSide);
        backSide = new Mesh(
            new BoxGeometry(boxWidth, sideHeight, this.box.sides.depth),
            this.createPanelMaterial()
        );
        backSide.position.set(
            width / 2,
            technologMap.drawer.boxGap.bottom + this.box.bottom.depth + sideHeight / 2,
            this.box.sides.depth / 2
        );
        backSide.add(this.createCarcassMesh(backSide.geometry));
        backSide.name = 'backSide';
        this.meshPanels.push(backSide);
        this.view3d.add(backSide);
    }

    protected createPanelMaterial() {
        if (!this.panelMeshMaterial) {
            this.panelMeshMaterial = this.mainConstructor.getConstructorService().getPanelMeshMaterial(this.panelMaterial);
        }

        return this.panelMeshMaterial;
    }

    protected createShapePanelMaterial() {
        if (!this.shapePanelMeshMaterial) {
            this.shapePanelMeshMaterial = this.mainConstructor.getConstructorService().getPanelMeshMaterial(this.panelMaterial, true);
        }

        return this.shapePanelMeshMaterial;
    }

    protected createBottomMaterial() {
        if (!this.bottomMeshMaterial) {
            this.bottomMeshMaterial = this.mainConstructor.getConstructorService().getDvpMeshMaterial(this.bottomMaterial);
        }

        return this.bottomMeshMaterial;
    }

    private createRails() {
        let leftRail: Mesh;
        let rightRail: Mesh;
        let railGeometry: BufferGeometry;
        let width;

        if (!this.points) {
            return;
        }

        width = this.getWidth();
        railGeometry = new BoxGeometry(this.box.rails.width, this.box.rails.height, this.box.rails.length);
        leftRail = new Mesh(railGeometry, this.createBodyMaterial());
        leftRail.position.set(
            this.box.rails.width / 2,
            10 + this.box.rails.height / 2,
            this.depth - this.box.rails.length / 2 - this.facade.depth
        );
        leftRail.add(this.createCarcassMesh(railGeometry));
        this.view3d.add(leftRail);
        rightRail = new Mesh(railGeometry, this.createBodyMaterial());
        rightRail.position.set(
            width - this.box.rails.width / 2,
            10 + this.box.rails.height / 2,
            this.depth - this.box.rails.length / 2 - this.facade.depth
        );
        rightRail.add(this.createCarcassMesh(railGeometry));
        this.view3d.add(rightRail);


    }

}