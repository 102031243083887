import {ThreeObject} from "../ThreeObject";
import {IDoor} from "../../../../../domain/intarfaces";
import {ThreeDoorContainer} from "../ThreeDoorContainer/ThreeDoorContainer";
import {ThreeFrame} from "../planks/ThreeFrame/ThreeFrame";
import {ThreeProfile} from "../planks/ThreeProfile/ThreeProfile";
import {ThreeDoorPoint} from "../points/ThreeDoorPoint/ThreeDoorPoint";
import {ThreeProfilePoint} from "../points/ThreeProfilePoint/ThreeProfilePoint";
import {Vector3} from "three";
import {ThreeDoorFiller} from "../fillers";
import {IDoorData} from "../../../../../common-code/domain/interfaces/IDoorData/IDoorData";
import {POINT_TYPE_DOOR, POINT_TYPE_PROFILE} from "../../../../../common-code/constants";
import {IDoorPointData} from "../../../../../common-code/domain/interfaces/IDoorPointData/IDoorPointData";
import {IProfilePointData} from "../../../../../common-code/domain/interfaces/IProfilePointData/IProfilePointData";
import {IFrameData} from "../../../../../common-code/domain/interfaces/IFrameData/IFrameData";
import {IProfileData} from "../../../../../common-code/domain/interfaces/IProfileData/IProfileData";
import {IDoorFillerData} from '../../../../../common-code/domain/interfaces/IDoorFillerData/IDoorFillerData';

export class ThreeDoor extends ThreeObject implements IDoor {
    railIndex: number;
    doorContainer: ThreeDoorContainer;
    initData: IDoorData;
    position: Vector3;

    points: { [p: number]: ThreeDoorPoint | ThreeProfilePoint };
    profiles: { [p: number]: ThreeProfile };
    frames: { [p: number]: ThreeFrame };
    fillers: { [p: number]: ThreeDoorFiller };


    constructor(doorContainer: ThreeDoorContainer, options: IDoorData) {
        super(doorContainer.mainConstructor, options);
        this.doorContainer = doorContainer;
        this.initData = options;
        this.railIndex = options.railIndex;
        this.points = {};
        this.profiles = {};
        this.frames = {};
        this.fillers = {};
        this.position = new Vector3();

    }

    public initState() {
        this.setPosition();
        this.createDoorPoints();
        this.createProfiles();
        this.setProfileNeighbors();
        this.createProfilePoints();
        this.setPointPositions();
        this.calculateProfileVisiblePoints();
        this.createFrames();
        this.calculateFrameVisiblePoints();
        this.createFillers();
    }

    public createView(): void {
        this.createPointViews();
        this.createProfileViews();
        this.createFrameViews();
        this.createFillerViews();
        this.doorContainer.view3d.add(this.view3d);
        super.createView();
    }

    public remove(): void {
        let index;

        for (index in this.fillers) {
            this.fillers[index].remove();
        }
        for (index in this.frames) {
            this.frames[index].remove();
        }
        for (index in this.profiles) {
            this.profiles[index].remove();
        }
        for (index in this.points) {
            this.points[index].remove();
        }
        super.remove();
    }

    public getSaveData(): IDoorData {
        super.getSaveData();
        return {
            id: this.getId(),
            fillers: this.getFillersSaveData(),
            frames: this.getFramesSaveData(),
            points: this.getPointsSaveData(),
            profiles: this.getProfilesSaveData(),
            railIndex: this.railIndex,
        };
    }

    public getProfileById(profileId: number): ThreeProfile | ThreeFrame {
        if (this.profiles[profileId]) {
            return this.profiles[profileId];
        }
        if (this.frames[profileId]) {
            return this.frames[profileId];
        }

        throw new Error('Not found profile in door ' + profileId);
    }

    public setPosition() {
        switch (this.railIndex) {
            case 0:
                this.position.z = this.doorContainer.getDepth() -
                    this.mainConstructor.getConstructorService().technologMap.door.rails.top.width + 3;
                break;
            case 1:
                this.position.z = this.doorContainer.getDepth() -
                    this.mainConstructor.getConstructorService().technologMap.door.rails.top.width / 2 + 3;
                break;
        }
        this.view3d.position.copy(this.position);
    }

    public getPointById(pointId: number): ThreeDoorPoint | ThreeProfilePoint {
        return this.points[pointId];
    }

    protected getFillersSaveData(): IDoorFillerData[] {
        let fillers: IDoorFillerData[] = [];
        let index;

        for (index in this.fillers) {
            fillers.push(this.fillers[index].getSaveData());
        }

        return fillers;
    }

    protected getFramesSaveData(): IFrameData[] {
        let frames: IFrameData[] = [];
        let index;

        for (index in this.frames) {
            frames.push(this.frames[index].getSaveData());
        }

        return frames;
    }

    protected getPointsSaveData(): Array<IDoorPointData | IProfilePointData> {
        let points: Array<IDoorPointData | IProfilePointData> = [];
        let index;

        for (index in this.points) {
            points.push(this.points[index].getSaveData());
        }

        return points;
    }

    protected getProfilesSaveData(): IProfileData[] {
        let profiles: IProfileData[] = [];
        let index;

        for (index in this.profiles) {
            profiles.push(this.profiles[index].getSaveData());
        }
        return profiles;
    }

    private setProfileNeighbors() {
        let index;
        let index2;

        for (index in this.profiles) {
            for (index2 in this.profiles) {
                if (index === index2) {
                    continue;
                }
                if (this.profiles[index].pointA.getId() === this.profiles[index2].pointB.getId()) {
                    this.profiles[index].setLeftNeighbor(this.profiles[index2]);
                    this.profiles[index2].setRightNeighbor(this.profiles[index]);
                }
            }
        }
    }

    private createDoorPoints() {
        let pointData;
        let point: ThreeDoorPoint;

        for (pointData of this.initData.points) {
            if (pointData.type === POINT_TYPE_DOOR) {
                pointData = pointData as IDoorPointData;
                point = new ThreeDoorPoint(this, pointData);
                point.calculatePosition();
                point.setPosition();
                this.points[point.getId()] = point;
            }
        }
    }

    private createProfiles() {
        let profileData;
        let profile: ThreeProfile;

        for (profileData of this.initData.profiles) {
            profile = new ThreeProfile(this, profileData);
            profile.initState();
            this.profiles[profile.getId()] = profile;
        }
    }

    private createProfilePoints() {
        let pointData;
        let point: ThreeProfilePoint;

        for (pointData of this.initData.points) {
            if (pointData.type === POINT_TYPE_PROFILE) {
                pointData = pointData as IProfilePointData;
                point = new ThreeProfilePoint(this, pointData);
                point.calculatePosition();
                point.setPosition();
                this.points[point.getId()] = point;
            }
        }
    }

    private setPointPositions() {
        // let pointId;
        //
        // for (pointId in this.points) {
        //     this.points[pointId].calculatePosition();
        //     this.points[pointId].setPosition();
        // }
    }

    private calculateProfileVisiblePoints() {
        let profileId;

        for (profileId in this.profiles) {
            this.profiles[profileId].calculateIndentionPoints();
            this.profiles[profileId].calculateVisiblePoints();
        }
    }

    private createFrames() {
        let frameData;
        let frame: ThreeFrame;

        for (frameData of this.initData.frames) {
            frame = new ThreeFrame(this, frameData);
            frame.initState();
            this.frames[frame.getId()] = frame;
        }
    }

    private calculateFrameVisiblePoints() {
        let frameId;

        for (frameId in this.frames) {
            this.frames[frameId].calculateVisiblePoints();
        }
    }

    private createFillers() {
        let fillerData: IDoorFillerData;
        let filler: ThreeDoorFiller;

        for (fillerData of this.initData.fillers) {
            filler = new ThreeDoorFiller(this, fillerData);
            filler.initState();
            this.fillers[filler.getId()] = filler;
        }
    }

    private createPointViews() {
        let pointId;

        for (pointId in this.points) {
            this.points[pointId].createView();
        }
    }

    private createProfileViews() {
        let profileId;

        for (profileId in this.profiles) {
            this.profiles[profileId].createView();
            this.view3d.add(this.profiles[profileId].view3d);
        }
    }

    private createFrameViews() {
        let frameId;

        for (frameId in this.frames) {
            this.frames[frameId].createView();
            this.view3d.add(this.frames[frameId].view3d);
        }
    }

    private createFillerViews() {
        let fillerId;

        for (fillerId in this.fillers) {
            this.fillers[fillerId].createView();
            this.view3d.add(this.fillers[fillerId].view3d);
        }
    }

}