import {ThreePoint} from "../";
import {Vector3} from "three";
import {ThreeConstructor} from "../../../ThreeConstructor";
import {ISidePointData} from '../../../../../../common-code/domain/interfaces/ISidePointData/ISidePointData';

export class ThreeSidePoint extends ThreePoint {
    value: Vector3;
    mainConstructor: ThreeConstructor;

    constructor(mainConstructor: ThreeConstructor, pointData: ISidePointData) {
        super(mainConstructor, pointData);
        this.mainConstructor = mainConstructor;
        this.value = new Vector3(pointData.value.x, pointData.value.y, pointData.value.z);
        this.isInit = true;
    }

    public getSaveData(): ISidePointData {
        super.getSaveData();
        return {
            connectionId: this.connectionId,
            connectionType: this.connectionType,
            sort: this.sort,
            type: this.type,
            id: this.id,
            value: {x: this.value.x, y: this.value.y, z: this.value.z}
        }
    }

    public remove() {
        this.mainConstructor.removeFromScene(this);
        super.remove();
    }
}