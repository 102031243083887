import React from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./PageWrapper.css"
import {cnConstructorPage} from "../../../pages/ConstructorPage/ConstructorPage";

export const cnPageWrapper = cn('PageWrapper');
export interface IPageWrapperProps extends IClassNameProps {
    children: React.ReactNode,
}

export const PageWrapper: React.FC<IPageWrapperProps> = ({className, children}) => {
    return (
        <div className={cnPageWrapper({}, [cnConstructorPage(cnPageWrapper()), className])}>
            <div className={cnPageWrapper('Wrapper')}>
                {children}
            </div>
        </div>
    );
}