import React, {useEffect} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnTabInput, TabInput} from '../../../all/TabInput';
import {
    IConstructorFormData
} from "../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData";

export const cnTabsFragment = cn('TabsFragment');

export interface ITabsFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    activeTab: number,
    prefix: string,
    callBack: (index: number) => void,
    children: React.ReactNode,
}

export const TabsFragment: React.FC<ITabsFragmentProps> = ({ className, children, constructorFormData, activeTab, prefix, callBack }) => {

    const sideWardrobes = constructorFormData.sideWardrobes;

    useEffect(() => {
        callBack(1);
    }, [sideWardrobes, callBack]);

    return (
        <div className={cnConstructorForm(cnTabsFragment(), [className])}>
            <TabInput
                className={cnConstructorForm(cnTabInput())}
                options={sideWardrobes.map((sideWardrobe, sideIndex) => {
                    return sideIndex;
                })}
                active={activeTab}
                prefix={prefix}
                callBack={callBack}
            />
            <div className={cnConstructorForm('TabsWrapper', { bordered: true })}>
                {children}
            </div>
        </div>
    );
};