import {ThreeObject} from "../../ThreeObject";
import {ThreeSideWardrobe} from "../../ThreeSideWardrobe";
import {IBackFillerSegmentInfo} from "../../../interfaces/IBackFillerSegmentInfo/IBackFillerSegmentInfo";
import {Box2, ExtrudeGeometry, Mesh, Shape, Vector2, Vector3} from "three";
import {IDoorFillerSegmentInfo} from "../../../interfaces/IDoorFillerSegmentInfo/IDoorFillerSegmentInfo";
import {TPanelShift} from '../../../../../../common-code/domain/types';
import {IFillerData} from '../../../../../../common-code/domain/interfaces/IFillerData/IFillerData';
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {TPanelDirectionType} from "../../../../../../common-code/domain/types/TPanelDirectionType";
import {
    IMPORT_PANEL_DIRECTION_TYPE_FRONT,
    IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL,
    IMPORT_PANEL_DIRECTION_TYPE_VERTICAL
} from "../../../../../../common-code/constants";
import {ISquareEntityData} from "../../../../../../common-code/domain/interfaces/ISquareEntityData";
import {
    ISquareMaterialPriceData
} from '../../../../../../common-code/domain/interfaces/IPriceData/ISquareMaterialPriceData';
import {IPanelPriceData} from '../../../../../../common-code/domain/interfaces/IPriceData/IPanelPriceData';
import {
    IPlankMaterialPriceData
} from '../../../../../../common-code/domain/interfaces/IPriceData/IPlankMaterialPriceData';
import {TPanelButt} from '../../../../../../common-code/domain/types/TPanelButt';
import {IPlankEntityData} from '../../../../../../common-code/domain/interfaces/IPlankEntityData';
import {CommonHelper} from '../../../../../helpers/CommonHelper';

export class ThreeFiller extends ThreeObject {
    sideWardrobe: ThreeSideWardrobe;
    segments: IBackFillerSegmentInfo[] | IDoorFillerSegmentInfo[];
    depth: number;
    shift: TPanelShift;
    points: Vector2[];
    initData: IFillerData;
    sort: number;
    materialId: number;
    shape?: Shape;
    material?: ISquareEntityData;
    butts?: TPanelButt[];

    constructor(sideWardrobe: ThreeSideWardrobe, options: IFillerData) {
        super(sideWardrobe.mainConstructor, options);
        this.sideWardrobe = sideWardrobe;
        this.shift = options.shift;
        this.initData = options;
        this.segments = [];
        this.points = [];
        this.sort = options.sort;
        this.depth = options.depth;
        this.shift = options.shift;
        this.materialId = options.materialId;
        this.material = this.initMaterialData();
        this.butts = options.butts ? CommonHelper.deepCopy(options.butts) : undefined;
    }

    public createView(): void {
        this.createShape();
        this.createBody();
        super.createView();
    }

    public getPriceData(): IPanelPriceData {
        let priceData: ISquareMaterialPriceData;
        let box: Box2 = new Box2();

        priceData = this.mainConstructor.initSquareMaterialPriceData();

        if (this.material) {
            priceData.materialId = this.material.id;
            priceData.name = this.material.product.name;
            priceData.code = this.material.product.code;
            priceData.sheetPrice = this.material.price;
            priceData.sheetSquare = this.material.sheetSquare;
            box.setFromPoints(Object.values(this.points));
            priceData.square = (box.max.x - box.min.x) * (box.max.y - box.min.y) * 0.000001;
            priceData.sheetCount = Math.ceil(priceData.square / this.material.sheetSquare);
            priceData.sum = priceData.sheetCount * priceData.sheetPrice
        }

        return {
            material: priceData,
            butts: this.getButtsPriceData(),
        };
    }

    public getMaterialId(): number {
        return this.material ? this.material.id : 0;
    }

    protected getButtsPriceData(): IPlankMaterialPriceData[] {
        let buttPlanks: { [key: string]: IPlankMaterialPriceData } = {};
        let butt: TPanelButt;
        let butts: TPanelButt[] = this.getButtsSaveData();
        let buttMaterial: IPlankEntityData;
        let materialId: number | undefined;
        let plankLength: number;
        let buttId: string;
        for (butt of butts) {
            materialId = butt.materialId;
            if (!materialId || !butt.length) {
                continue;
            }
            buttMaterial = this.mainConstructor.getButtMaterial(this.getMaterialId());
            buttId = materialId + '_' + buttMaterial.price;
            if (!buttPlanks[buttId]) {
                plankLength = Math.ceil(butt.length / buttMaterial.length);
                buttPlanks[buttId] = {
                    materialId: +materialId,
                    plankPrice: buttMaterial.price,
                    length: butt.length,
                    plankLength: plankLength,
                    errors: [],
                    sum: plankLength * buttMaterial.price,
                    name: buttMaterial.product.name,
                    code: buttMaterial.product.code,
                    store: '-',
                    price: buttMaterial.price,
                    count: plankLength,
                    amount: 0,
                }
            } else {
                buttPlanks[buttId].length += butt.length;
                plankLength = Math.ceil(buttPlanks[buttId].length / buttMaterial.length);
                buttPlanks[buttId].sum = plankLength * buttMaterial.price;
            }
        }

        return Object.values(buttPlanks);
    }

    protected getButtsSaveData(): TPanelButt[] {
        if (!this.butts) {
            return [];
        }
        let butt: TPanelButt;
        for (butt of this.butts) {
            if (!this.getMaterialId()) {
                continue;
            }
            butt.materialId = this.mainConstructor.getButtMaterial(this.getMaterialId()).id;

        }

        return this.butts;
    }

    protected calculateGlobalPoints(): IStartEndPoints | undefined {
        let box: Box2;
        let startPoint: Vector3;
        let endPoint: Vector3;

        if (this.points.length <= 0) {
            return undefined;
        }

        box = new Box2();
        box.setFromPoints(this.points);
        startPoint = new Vector3(box.min.x, box.min.y, this.shift.depth);
        endPoint = new Vector3(box.max.x, box.max.y, (this.shift.depth + this.depth));
        startPoint.applyMatrix4(this.view3d.matrixWorld);
        endPoint.applyMatrix4(this.view3d.matrixWorld);

        return {
            start: {x: startPoint.x, y: startPoint.y, z: startPoint.z},
            end: {x: endPoint.x, y: endPoint.y, z: endPoint.z}
        };
    }

    protected initMaterialData(): ISquareEntityData | undefined {
        if (this.materialId > 0) {
            return this.mainConstructor.getDvpById(this.materialId);
        }
        return undefined;
    }

    protected getProductCode(): string | undefined {
        if (this.material) {
            return this.material.product.code;
        }

        return undefined;
    }

    protected calculateDirectionType(globalPoints?: IStartEndPoints): TPanelDirectionType {
        if (globalPoints) {
            let sizes: number[] = [
                (globalPoints.end.x - globalPoints.start.x),
                (globalPoints.end.y - globalPoints.start.y),
                (globalPoints.end.z - globalPoints.start.z)
            ];
            switch (sizes.indexOf(Math.min(...sizes))) {
                case 0:
                    return IMPORT_PANEL_DIRECTION_TYPE_VERTICAL;
                case 1:
                    return IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL;
                case 2:
                    return IMPORT_PANEL_DIRECTION_TYPE_FRONT;
            }
        }

        return IMPORT_PANEL_DIRECTION_TYPE_FRONT;
    }

    protected createBodyMaterial() {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.mainConstructor.getConstructorService().getPanelBodyMaterial();
        }

        return this.bodyMaterial;
    }


    protected createShape() {
        if (this.points.length <= 0) {
            return;
        }
        this.shape = new Shape();
        this.shape.setFromPoints(this.points);
    }

    protected createBody() {
        if (!this.shape) {
            return;
        }
        let body;
        let box;
        let bodyPosition;

        body = new Mesh(this.createBodyGeometry(), this.createBodyMaterial());
        box = new Box2().setFromPoints(this.points);
        bodyPosition = new Vector2();
        bodyPosition = box.getCenter(bodyPosition);
        body.position.set(bodyPosition.x, bodyPosition.y, this.depth / 2 + this.shift.depth);
        body.add(this.createCarcassMesh(body.geometry));
        this.view3d.add(body);
    }

    protected createBodyGeometry() {
        let geometry;

        geometry = new ExtrudeGeometry(this.shape, {depth: this.depth, bevelEnabled: false});
        geometry.center();

        return geometry;
    }
}