import React from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import './MenuBurger.css'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {useComponentVisible} from "../../../hooks";
import {TOGGLE_MENU} from "../../../constants";

export const cnMenuBurger = cn('MenuBurger');

export interface IMenuBurgerProps extends IClassNameProps {
    size?: string,
    forMenu: "MainMenu" | "UserMenu",
    children: React.ReactNode,
}

export const MenuBurger:
    React.FC<IMenuBurgerProps> = ({
                                      className,
                                      forMenu,
                                      size,
                                      children
                                  }) => {
    const isMenuOpen = useSelector((state:AppState) => state.menus[forMenu].visible);
    const {ref} = useComponentVisible("MainMenu");

    const dispatch = useDispatch();
    const toggleMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: forMenu,
                value: !isMenuOpen
            },
        });
    };

    return (
        <div ref={ref} className={cnMenuBurger({
            size: size || 'default',
            active: isMenuOpen,
            type: 'elastic'
        }, [className])}>
            <div onClick={toggleMenu} className={cnMenuBurger('Box')}>
                <div className={cnMenuBurger('Inner')}>

                </div>
            </div>
            {children}
        </div>
    );
};