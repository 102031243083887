import React from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./UserMenu.css"
import SvgIcon from "../../../helpers/SvgIcon/SvgIcon";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {NavLink} from "react-router-dom";
import {useComponentVisible} from "../../../hooks";
import '../../../i18n';
import {useTranslation} from 'react-i18next';
import {UserMenuGuestMenu} from "./UserMenu-GuestMenu";
import axios from "axios";
import {SET_USER, START_SESSION, TOGGLE_MENU} from "../../../constants";
import {IMenuItem} from '../../../interfaces';

export const cnUserMenu = cn('UserMenu');
export interface IUserMenuProps extends IClassNameProps {
}
export const UserMenu: React.FC<IUserMenuProps> = ({className}) => {

    const {t} = useTranslation();

    const session = useSelector((state: AppState) => state.session);
    const userData = useSelector((state: AppState) => state.user);
    const isMenuOpen = useSelector((state: AppState) => state.menus.UserMenu.visible);
    const {ref} = useComponentVisible("UserMenu");
    const menuItems: IMenuItem[] = [
        // {
        //     url: '/profile/',
        //     mods: {type: 'innerLink'},
        //     title: t('profile')
        // },
    ];

    const dispatch = useDispatch();
    const toggleMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: "UserMenu",
                value: !isMenuOpen
            },
        });
    };
    const hideMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: "UserMenu",
                value: false
            },
        });
    };

    const processLogout = () => {
        if (!session.sid) {
            return;
        }
        axios.post('/auth/logout', {sid: session.sid})
            .then((response: any) => {
                if (response.data) {
                    dispatch({
                        type: SET_USER,
                        payload: undefined,
                    });
                    dispatch({
                        type: START_SESSION,
                        payload: {success: true},
                    });
                } else {
                    debugger;
                }
            })
            .catch(() => {
                debugger;
            });
    };

    if (userData.roles.includes('GUEST')) {
        return (<UserMenuGuestMenu/>);
    } else {
        return (
            <div ref={ref} className={cnUserMenu({}, [className])}>
                <div className={cnUserMenu('Burger')} onClick={toggleMenu}>
                    <SvgIcon className={cnUserMenu('Icon')} icon="login" label={t('login')+'/'+t('register')} />
                    <div className={cnUserMenu('Text')}>
                        {userData.name}
                    </div>
                </div>
                <div className={cnUserMenu('Menu', {visible: isMenuOpen})}>
                    <>
                        {menuItems.map((item, index) => {
                        return (<NavLink key={index} onClick={hideMenu} className={cnUserMenu('Item', item.mods)} to={item.url}>
                            {item.title}
                        </NavLink>);
                    })}
                    </>
                    <div className={cnUserMenu('Item', {type: 'innerLink'}, [cnUserMenu('Logout')])} onClick={processLogout}>
                        {t('logout')}
                    </div>
                </div>
            </div>
        );
    }
};