import React from 'react';
import {IClassNameProps} from '@bem-react/core'
import {cn} from "@bem-react/classname";
import {useRegistry} from "@bem-react/di";
import {appId} from "../../common/App/App";
import './ConstructorPage.css';
import {Footer, PageWrapper} from "../../blocks/";
import {Constructor} from "../../blocks/";
import {useTranslation} from "react-i18next";
import {TTechnologMap} from '../../../common-code/domain/types';
import {useSelector} from 'react-redux';
import {AppState} from '../../redux/AppStore';
import {useParams} from 'react-router-dom';

export interface IConstructorPageProps extends IClassNameProps {
}

export const cnConstructorPage = cn('ConstructorPage');

export const ConstructorPage: React.FC<IConstructorPageProps> = ({className}) => {
    const {Header} = useRegistry(appId);
    const {t} = useTranslation();
    const technologMap: TTechnologMap | undefined = useSelector((state: AppState) => state.constructorData.technologMap);
    const {project} = useParams();

    return (
        <div className={cnConstructorPage({}, [className])}>
            <div className={cnConstructorPage('Wrapper')}>
                <Header title={t('constructor-page-title')}/>
                <PageWrapper>
                    {technologMap && project ? <Constructor projectId={project}/> : null}
                </PageWrapper>
                <Footer/>
            </div>
        </div>
    )
};

