import {ThreePoint} from "..";
import {ThreeProfile} from "../../planks/ThreeProfile/ThreeProfile";
import {ThreeDoor} from "../../ThreeDoor/ThreeDoor";
import {Vector3} from "three";
import {ThreeGeometry} from "../../../helpers/ThreeGeometry";
import {IProfilePointData} from "../../../../../../common-code/domain/interfaces/IProfilePointData/IProfilePointData";
import {TPointConnection} from '../../../../../../common-code/domain/types/TPointConnection';
import {TLevel} from '../../../../../../common-code/domain/types/TLevel';

export class ThreeProfilePoint extends ThreePoint {
    profile: ThreeProfile;
    ratio: number;
    isInit: boolean;
    door: ThreeDoor;
    connection: TPointConnection;
    level?: TLevel;

    constructor(door: ThreeDoor, options: IProfilePointData) {
        super(door.mainConstructor, options);
        this.door = door;
        this.profile = this.door.getProfileById(options.profileId) as ThreeProfile;
        this.ratio = options.ratio;
        this.isInit = false;
        this.connection = this.mainConstructor.getPointConnectionById(options.connectionId);
    }

    public getSaveData(): IProfilePointData {
        super.getSaveData();
        return {
            connectionId: this.connectionId,
            connectionType: this.connectionType,
            sort: this.sort,
            type: this.type,
            id: this.id,
            profileId: this.profile.getId(),
            ratio: this.ratio
        }
    }

    public calculatePosition(): Vector3
    {
        if (!this.profile) {
            throw new Error('ThreeProfilePoint required profile!');
        }
        this.value = this.getPositionByRatio();
        if (!this.isInit) {
            this.isInit = true;
        }

        return this.value;
    }

    public getPositionByRatio(): Vector3
    {
        if (this.profile) {
            if (!this.profile.pointA.isInited()) {
                this.profile.pointA.calculatePosition();
            }
            if (!this.profile.pointB.isInited()) {
                this.profile.pointB.calculatePosition();
            }
            if (this.profile.pointA.isInited() &&
                this.profile.pointB.isInited()) {
                return ThreeGeometry.getPointByRatio(
                    this.profile.pointA.getPosition(),
                    this.profile.pointB.getPosition(),
                    this.ratio
                );
            }
        }

        return this.value;
    }
}