import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './ButtonInput.css';
import {Button} from '@yandex/ui/Button/desktop/bundle';
import SvgIcon from '../../../helpers/SvgIcon/SvgIcon';

export const cnButtonInput = cn('ButtonInput');

export interface IButtonInputProps extends IClassNameProps {
    text?: string,
    leftIcon?: string,
    rightIcon?: string
    modsIcon?: { size: string },
    callBack: () => void
}

export const ButtonInput: React.FC<IButtonInputProps> = ({ className, text, leftIcon, rightIcon, modsIcon, callBack }) => {
    return (
        <div className={cnButtonInput({}, [className])}>
            <Button
                view={'pseudo'}
                type={'submit'}
                onClick={callBack}>
                {leftIcon ? <SvgIcon icon={leftIcon} className={cnButtonInput('LeftIcon', {...modsIcon})} label={text}/> : null}
                {text}
                {rightIcon ? <SvgIcon icon={rightIcon} className={cnButtonInput('RightIcon', {...modsIcon})} label={text}/> : null}
            </Button>
        </div>
    );
};