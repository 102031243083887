import React from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./LanguageMenu.css"
import SvgIcon from "../../../helpers/SvgIcon/SvgIcon";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {NavLink} from "react-router-dom";
import {useComponentVisible} from "../../../hooks";
import '../../../i18n';
import {useTranslation} from 'react-i18next';
import i18next from "i18next";
import {TOGGLE_MENU} from "../../../constants";

export const cnLanguageMenu = cn('LanguageMenu');
export interface ILanguageMenuProps extends IClassNameProps {
}
export const LanguageMenu: React.FC<ILanguageMenuProps> = ({className}) => {

    const {t} = useTranslation();

    const isMenuOpen = useSelector((state:AppState) => state.menus.LanguageMenu.visible);
    const {ref} = useComponentVisible("LanguageMenu");
    const menuItems = [
        {
            url: '#',
            mods: {type: 'innerLink'},
            title: 'Русский',
            language: 'ru'
        },
        {
            url: '#',
            mods: {type: 'innerLink'},
            title: 'English',
            language: 'en'
        }
    ];

    const dispatch = useDispatch();
    const toggleMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: "LanguageMenu",
                value: !isMenuOpen
            },
        });
    };
    const hideMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: "LanguageMenu",
                value: false
            },
        });
    };

    const changeLanguage = (language: string): void => {
        i18next.changeLanguage(language);

    };

    // const getLanguage = (): string => {
    //     return t('lang');
    //
    // };

    const onClickMenuItem = (language: string) => {
        hideMenu();
        changeLanguage(language);
    };

    return (
        <div ref={ref} className={cnLanguageMenu({}, [className])}>
            <div className={cnLanguageMenu('Burger')} onClick={toggleMenu}>
                <SvgIcon className={cnLanguageMenu('Icon')} icon={t('lang')} label="Language" />
                <div className={cnLanguageMenu('Text')}>
                    {t('language')}
                </div>
            </div>
            <div className={cnLanguageMenu('Menu', {visible: isMenuOpen})}>
                {menuItems.map((item, index) => {
                    return (<NavLink key={index} onClick={() => {onClickMenuItem(item.language)}} className={cnLanguageMenu('Item', item.mods)} to={item.url}>
                        {item.title}
                    </NavLink>);
                })}
            </div>
        </div>
    );
}