import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './NumberInput.css';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';

export const cnNumberInput = cn('NumberInput');

export interface INumberInputProps extends IClassNameProps {
    min: number,
    max: number,
    step?: number,
    viewControl?: boolean,
    activeControl?: boolean,
    disabled?: boolean,
    value: number,
    callBack: (value: number) => void
}

export const NumberInput: React.FC<INumberInputProps> = ({ className, min, max, step, viewControl, activeControl, disabled, value, callBack }) => {

    const [newValue, setNewValue] = useState<number>(value);
    const [focused, setFocused] = useState<boolean>(false);
    const [activeDecrement, setActiveDecrement] = useState<boolean>(true);
    const [activeIncrement, setActiveIncrement] = useState<boolean>(true);

    useEffect(() => {
        if (!focused) {
            setNewValue(value);
        }
    }, [focused, value]);

    useEffect(() => {
        if (activeControl === false || value === min) {
            setActiveDecrement(false);
        } else {
            setActiveDecrement(true);
        }
    }, [activeControl, value, min]);

    useEffect(() => {
        if (activeControl === false || value === max) {
            setActiveIncrement(false);
        } else {
            setActiveIncrement(true);
        }
    }, [activeControl, value, max]);

    const eventFocus = () => {
        setFocused(true);
    };

    const eventKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        const regExp = new RegExp('\\d', 'g');

        if (!regExp.test(event.key)) {
            event.preventDefault();
        }
    };

    const eventInput = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 1 && event.target.value[0] === '0') {
            event.target.value = event.target.value.substring(1, event.target.value.length);
        }
        if (+event.target.value > max) {
            event.target.value = '' + max;
        }
        setNewValue(+event.target.value);
    };

    const eventBlur = (event: ChangeEvent<HTMLInputElement>) => {
        if (+event.target.value > max) {
            event.target.value = '' + max;
            callBack(max);
        }
        if (+event.target.value < min) {
            event.target.value = '' + min;
            callBack(min);
        }
        if (+event.target.value <= max && +event.target.value >= min) {
            callBack(+event.target.value);
        }
        if (step && step > 0) {
            event.target.value = '' + (Math.trunc((+event.target.value - min) / step) * step + min);
            callBack(+event.target.value);
        }
        setFocused(false);
        setNewValue(+event.target.value);
    };

    const decrement = () => {
        let tempValue;

        if (step && step > 0) {
            tempValue = Math.trunc((value - min) / step) * step + min;
            value = value !== tempValue ? tempValue : tempValue - step;
        } else {
            value -= 1;
        }
        if (value <= min) {
            value = min;
        }
        callBack(value);
    };

    const increment = () => {
        if (step && step > 0) {
            value = (Math.trunc((value - min) / step) * step + min) + step;
        } else {
            value += 1;
        }
        if (value >= max) {
            value = max;
        }
        callBack(value);
    };

    return (
        <div className={cnNumberInput({ bordered: true, bkg: 'white', color: 'black', size: 'lg', disabled: disabled }, [className])}>
            {viewControl ?
                activeDecrement ?
                    <span
                        className={cnNumberInput('Decrement', { size: 'lg', color: 'blackAlpha', active: activeDecrement })}
                        onClick={decrement}
                    >-</span>
                    :
                    <span
                        className={cnNumberInput('Decrement', { size: 'lg', color: 'blackAlpha' })}
                    >-</span>
                : null
            }
            <Textinput
                value={newValue}
                type={'text'}
                focused={focused}
                disabled={disabled}
                onFocus={eventFocus}
                onKeyPress={eventKeyPress}
                onInput={eventInput}
                onBlur={eventBlur}
            />
            {viewControl ?
                activeIncrement ?
                    <span
                        className={cnNumberInput('Increment', { size: 'lg', color: 'blackAlpha', active: activeIncrement })}
                        onClick={increment}
                    >+</span>
                    :
                    <span
                        className={cnNumberInput('Increment', { size: 'lg', color: 'blackAlpha' })}
                    >+</span>
                : null
            }
        </div>
    );
};