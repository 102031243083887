import {IConstructorFormData} from '../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TFormDoor} from '../../common-code/domain/types';
import {ProjectGenerator} from '../../domain/services/ProjectGenerator/ProjectGenerator';
import {MIN_COUNT_DOORS, MAX_COUNT_DOORS} from '../../common-code/constants';

export default function useNewDoors() {

    const createNewDoors = (constructorFormData: IConstructorFormData, sideIndex: number, width: number, countDoors: number, materialId: number): IConstructorFormData => {
        let index: number;
        let newCountDoors: number;
        let oldDoors: TFormDoor[];
        let newDoors: TFormDoor[] = [];

        newCountDoors = calcCountNewDoors(width, countDoors);
        constructorFormData.sideWardrobes[sideIndex].countDoors = newCountDoors;
        oldDoors = [...constructorFormData.sideWardrobes[sideIndex].doors];
        for (index = 0; index < newCountDoors; index++) {
            if (oldDoors[index] !== undefined) {
                newDoors.push(oldDoors[index]);
            } else {
                newDoors.push(ProjectGenerator.getDefaultDoor(materialId));
            }
        }
        constructorFormData.sideWardrobes[sideIndex].doors = newDoors;

        return constructorFormData;
    };

    // TODO Сделать корректный расчет количества дверей по ширине проема
    // Минимальный размер двери купе по ширине может составлять 40 см, столько же по высоте.
    // Максимальный размер двери по ширине – 1,5 м, по высоте – 3,5 м.
    // Оптимальными размерами являются 0,8-1 м по ширине и 2,1-2,75 по высоте.
    const calcCountNewDoors = (width: number, countDoors: number): number => {
        let newCountDoors: number;

        newCountDoors = countDoors;
        if (newCountDoors < MIN_COUNT_DOORS) {
            newCountDoors = MIN_COUNT_DOORS;
        }
        if (newCountDoors > MAX_COUNT_DOORS) {
            newCountDoors = MAX_COUNT_DOORS;
        }

        return newCountDoors;
    };

    return { createNewDoors };
}