import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnSelectInput, SelectInput} from '../../../all/SelectInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TSelectOption} from '../../../../../common-code/domain/types';
import {TShelvesType} from '../../../../../common-code/domain/types/TShelvesType';
import {Dispatch} from 'redux';
import {CHANGE_SHELVES_TYPE} from '../../../../constants';

export const cnShelvesTypeFragment = cn('ShelvesTypeFragment');

export interface IShelvesTypeFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    sideIndex: number,
    dispatch: Dispatch,
    placeholder: string,
    options: Array<TSelectOption>
}

export const ShelvesTypeFragment: React.FC<IShelvesTypeFragmentProps> = ({ className, constructorFormData, sideIndex, dispatch, placeholder, options }) => {

    const changeShelvesType = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: CHANGE_SHELVES_TYPE,
            sideIndex: sideIndex,
            payload: event.target.value as TShelvesType,
        });
    };

    return (
        <div className={cnConstructorForm(cnShelvesTypeFragment(), [className])}>
            <SelectInput
                className={cnConstructorForm(cnSelectInput())}
                placeholder={placeholder}
                showPlaceholder={false}
                options={options}
                value={constructorFormData.sideWardrobes[sideIndex].shelvesType}
                callBack={changeShelvesType}
            />
        </div>
    );
};