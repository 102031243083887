import React, {useState} from 'react';
import SvgIcon from "../../../helpers/SvgIcon/SvgIcon";
import '../../../i18n/';
import {useTranslation} from 'react-i18next';
import {cnUserMenu, IUserMenuProps} from "./UserMenu";
import {cnModal, Modal} from '@yandex/ui/Modal/desktop/bundle'
import {LoginForm} from "../../";

export const UserMenuGuestMenu: React.FC<IUserMenuProps> = ({className}) => {
    const {t} = useTranslation();

    const [showModal, onShowModal] = useState(false);
    const showModalLoginForm = () => {
        onShowModal(!showModal);
    };

    return (
        <div className={cnUserMenu({}, [className])}>
            <div className={cnUserMenu('GuestMenu')} onClick={showModalLoginForm}>
                <SvgIcon className={cnUserMenu('Icon')} icon="login" label={t('login')+'/'+t('register')} />
                <div className={cnUserMenu('Text')}>
                    {t('login')}
                </div>
            </div>
            {!showModal || <Modal
                theme="normal"
                onClose={() => {
                    onShowModal(false)
                }}
                visible={showModal}>
                <div className={cnModal('Close')} onClick={() => {
                    onShowModal(false)
                }}>
                    <SvgIcon icon='remove' className={cnModal('CloseIcon')}/>
                </div>
                <LoginForm/>
            </Modal>}

        </div>
    );
};