import {TPoint3D} from '../../../common-code/domain/types/TPoint3D';
import {TPoint2D} from '../../../common-code/domain/types/TPoint2D';
import {TLine} from '../../../common-code/domain/types/TLine';
import {
    DIRECT_EQUATION_TYPE_HORIZONTAL,
    DIRECT_EQUATION_TYPE_NORMAL,
    DIRECT_EQUATION_TYPE_VERTICAL
} from '../../../common-code/constants';
import {TDirectEquation} from '../../../common-code/domain/types/TDirectEquation';
import {TDirectEquationType} from '../../../common-code/domain/types/TDirectEquationType';

export class Geometry {
    /**
     * Метод возвращает длину векторов (расстояние между точками).
     *
     * @param pointA
     * @param pointB
     */
    public static getLength(pointA: TPoint3D, pointB: TPoint3D): number {
        let result = 0;

        if (pointA && pointB) {
            if (pointA.x !== undefined && pointB.x !== undefined) {
                result += Math.pow((pointB.x - pointA.x), 2);
            }
            if (pointA.y !== undefined && pointB.y !== undefined) {
                result += Math.pow((pointB.y - pointA.y), 2);
            }
            if (pointA.z !== undefined && pointB.z !== undefined) {
                result += Math.pow((pointB.z - pointA.z), 2);
            }
            result = Math.sqrt(result);
        }

        return result;
    }

    /**
     * Получение координат точки на отрезке между startPoint и endPoint
     * на расстоянии равном коэфициенту длины ratio
     * относительно длины отрезка startPoint-endPoint
     *
     * @param startPoint
     * @param endPoint
     * @param ratio
     * @param isInclude
     */
    public static getPointByRatio(
        startPoint: TPoint3D, endPoint: TPoint3D, ratio: number, isInclude: boolean = true): TPoint3D {
        let lambda: number;
        let point: TPoint3D;

        if (ratio >= 1 && isInclude) {
            return endPoint;
        }
        if (ratio === 1) {
            return endPoint;
        }
        lambda = ratio / (1 - ratio);
        point = {
            x: +((startPoint.x + lambda * endPoint.x) / (1 + lambda)).toFixed(3),
            y: +((startPoint.y + lambda * endPoint.y) / (1 + lambda)).toFixed(3),
            z: +((startPoint.z + lambda * endPoint.z) / (1 + lambda)).toFixed(3)
        };

        return point;
    }

    public static turnVector2D(vector: TPoint2D, angle: number, pointCenter?: TPoint2D): TPoint2D {
        if (pointCenter === undefined) {
            pointCenter = {x: 0, y: 0};
        }

        return {
            x: +(pointCenter.x + (vector.x) * Math.cos(angle) - (vector.y) * Math.sin(angle)).toFixed(3),
            y: +(pointCenter.y + (vector.x) * Math.sin(angle) + (vector.y) * Math.cos(angle)).toFixed(3)
        };
    }

    /**
     * Метод возвращает угол между двумя векторами
     *
     * @param vector1
     * @param vector2
     * @returns number
     */
    public static getAngle2D(vector1: TPoint2D, vector2: TPoint2D): number {
        let scalarSum = 0,
            scalarPow1 = 0,
            scalarPow2 = 0,
            scalarPow,
            result,
            cos;

        if (vector1.x !== undefined && vector2.x !== undefined) {
            scalarSum += vector1.x * vector2.x;
            scalarPow1 += Math.pow(vector1.x, 2);
            scalarPow2 += Math.pow(vector2.x, 2);
        }
        if (vector1.y !== undefined && vector2.y !== undefined) {
            scalarSum += vector1.y * vector2.y;
            scalarPow1 += Math.pow(vector1.y, 2);
            scalarPow2 += Math.pow(vector2.y, 2);
        }

        scalarPow1 = Math.sqrt(scalarPow1);
        scalarPow2 = Math.sqrt(scalarPow2);

        scalarPow = scalarPow1 * scalarPow2;

        if (scalarPow === 0) {
            cos = 0;
        } else {
            cos = scalarSum / scalarPow;
        }
        if (cos > 1) {
            cos = 1;
        }
        if (cos < -1) {
            cos = -1;
        }
        result = +Math.acos(cos).toFixed(15);

        return result;
    }

    /**
     * Возвращает true, если векторы сонаправлены.
     * @param vector1
     * @param vector2
     */
    public static isCoDirectionVectors(vector1: TPoint2D, vector2: TPoint2D): boolean {
        return (+vector1.x * +vector2.x + +vector1.y * +vector2.y > 0);
    }

    /**
     * Метод возвращает угол между переданным вектором и нормалью в радианах
     *
     * @param vector
     * @returns number
     */
    public static getNormalAngle(vector: TPoint2D): number {
        let angle;

        if (vector.x === 0 && vector.y === 0) {
            throw new Error('getNormalAngle');
        }

        angle = Math.acos(vector.x / Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2)));
        if (vector.y < 0) {
            angle = 360 * Math.PI / 180 - angle;
        }
        return +angle.toFixed(16);
    }

    /**
     * Метод возвращает координаты точки пересечения прямых line1 и line2, заданных формулой y = kx + b,
     * или undefined для параллельных прямых.
     *
     * @param line1
     * @param line2
     */
    public static getIntersectionPoint(line1: TLine, line2: TLine): TPoint2D | undefined {
        let d, d1, d2, x, y;
        let directEquation1;
        let directEquation2;

        directEquation1 = this.directEquation(line1.pointA, line1.pointB);
        directEquation2 = this.directEquation(line2.pointA, line2.pointB);


        switch (directEquation1.type) {
            case DIRECT_EQUATION_TYPE_HORIZONTAL:
                y = directEquation1.y;
                if (directEquation2.type === DIRECT_EQUATION_TYPE_VERTICAL) {
                    x = directEquation2.x;
                } else if (directEquation2.type === DIRECT_EQUATION_TYPE_HORIZONTAL) {
                    return undefined;
                } else {
                    x = (y - directEquation2.b) / directEquation2.k;
                }
                break;
            case DIRECT_EQUATION_TYPE_VERTICAL:
                x = directEquation1.x;
                if (directEquation2.type === DIRECT_EQUATION_TYPE_HORIZONTAL) {
                    y = directEquation2.y;
                } else if (directEquation2.type === DIRECT_EQUATION_TYPE_VERTICAL) {
                    return undefined;
                } else {
                    y = directEquation2.k * x + directEquation2.b;
                }
                break;
            default:
                // Два отрезка, лежащие на одной прямой, пересекаются везде, вызывает ошибку.
                if (this.isEqualLines(directEquation1, directEquation2)) {
                    return undefined;
                }
                switch (directEquation2.type) {
                    case DIRECT_EQUATION_TYPE_HORIZONTAL:
                        y = directEquation2.y;
                        x = (y - directEquation1.b) / directEquation1.k;
                        break;
                    case DIRECT_EQUATION_TYPE_VERTICAL:
                        x = directEquation2.x;
                        y = directEquation1.k * x + directEquation1.b;
                        break;
                    default:
                        d = (directEquation1.k * -1) - (directEquation2.k * -1);
                        d1 = (-directEquation1.b * -1) - (-directEquation2.b * -1);
                        d2 = directEquation1.k * (-directEquation2.b) - directEquation2.k * (-directEquation1.b);
                        x = d1 / d;
                        y = d2 / d;
                }
        }
        if (x === undefined || y === undefined ||
            isNaN(x) || isNaN(y) ||
            !isFinite(x) || !isFinite(y)) {
            return undefined;
        }

        return {x: x, y: y};
    }

    /**
     * Получение формулы прямой y = k*x + b по двум точкам
     *
     * @param pointA
     * @param pointB
     * @returns TDirectEquation
     */
    public static directEquation(pointA: TPoint2D, pointB: TPoint2D): TDirectEquation {
        let type: TDirectEquationType;
        let k;
        let b = 0;

        if (this.isEqualPoints2D(pointA, pointB)) {
            throw new Error('directEquation can not create for same points!')
        }
        if (pointB.x - pointA.x < 0.01 && pointA.x - pointB.x < 0.01) {
            type = DIRECT_EQUATION_TYPE_VERTICAL;
            k = 1;
        } else if (pointB.y - pointA.y < 0.01 && pointA.y - pointB.y < 0.01) {
            type = DIRECT_EQUATION_TYPE_HORIZONTAL;
            k = 0;
            b = pointB.y;
        } else {
            type = DIRECT_EQUATION_TYPE_NORMAL;
            k = (pointB.y - pointA.y) / (pointB.x - pointA.x);
            b = pointB.y - k * pointB.x;
        }

        return {
            type: type,
            k: k,
            b: b,
            normalAngle: this.getNormalAngle({x: pointB.x - pointA.x, y: pointB.y - pointA.y}),
            x: +pointA.x,
            y: +pointA.y
        }
    }

    /**
     * Возвращает true, если разница между координатами точек не превышает epsilon
     *
     * @param point1
     * @param point2
     * @param epsilon
     * @returns boolean
     */
    public static isEqualPoints2D(point1: TPoint2D, point2: TPoint2D, epsilon?: number): boolean {
        if (!point1 || !point2 ||
            point1.x === undefined || point2.x === undefined ||
            point1.y === undefined || point2.y === undefined) {
            return false;
        }
        return (this.isEqual(point1.x, point2.x, epsilon) && this.isEqual(point1.y, point2.y, epsilon));
    }

    /**
     * Возвращает true, если разница между value1 и value2 не превышает epsilon
     *
     * @param value1
     * @param value2
     * @param epsilon
     * @returns boolean
     */
    public static isEqual(value1: number, value2: number, epsilon?: number): boolean {
        value1 = +value1;
        value2 = +value2;
        if (!epsilon) {
            epsilon = 0;
        } else {
            epsilon = +epsilon;
        }

        return (value1 === value2 ||
            (value1 > value2 && value1 <= epsilon + value2) ||
            (value2 > value1 && value2 <= epsilon + value1)
        );
    }

    /**
     * Метод возвращает true, если прямые равны друг другу (являются одной прямой).
     *
     * @param line1
     * @param line2
     * @returns boolean
     */
    public static isEqualLines(line1: TDirectEquation, line2: TDirectEquation): boolean {
        if (line1.type !== line2.type) {
            return false;
        }
        if (line1.k.toFixed(4) !== line2.k.toFixed(4)) {
            return false;
        }

        if (line1.b.toFixed(4) !== line2.b.toFixed(4)) {
            return false;
        }
        return (
            line1.normalAngle.toFixed(8) === line2.normalAngle.toFixed(8) ||
            (line1.normalAngle - Math.PI * 2).toFixed(8) === line2.normalAngle.toFixed(8) ||
            (line1.normalAngle + Math.PI * 2).toFixed(8) === line2.normalAngle.toFixed(8)
        );
    }

    public static point3Dto2D(vector: TPoint3D): TPoint2D {
        return {x: +vector.x, y: +vector.y};
    }

    public static getNearPoint2D(point: TPoint2D, points: TPoint2D[]): TPoint2D | undefined {
        let selectedPoint,
            currentPoint,
            index;

        if (point && points && points.length > 0) {
            for (index in points) {
                currentPoint = points[index];
                if (selectedPoint === undefined) {
                    selectedPoint = currentPoint;
                } else if (selectedPoint &&
                    this.getLength2D(point, currentPoint) < this.getLength2D(point, selectedPoint)) {
                    selectedPoint = currentPoint;
                }
            }
            return selectedPoint;
        }
    }


    public static getLength2D(vector1: TPoint2D, vector2: TPoint2D): number {
        let result = 0;

        if (vector1 && vector2) {
            if (vector1.x !== undefined && vector2.x !== undefined) {
                result += Math.pow((vector2.x - vector1.x), 2);
            }
            if (vector1.y !== undefined && vector2.y !== undefined) {
                result += Math.pow((vector2.y - vector1.y), 2);
            }
            result = Math.sqrt(result);
        }

        return result;
    }

    public static getParallelPoints(line: TLine, shift: number): TLine {
        let pointA;
        let pointB;

        pointA = this.getShiftPoint2D(line.pointA, line.pointA, line.pointB, shift);
        pointB = this.getShiftPoint2D(line.pointB, line.pointA, line.pointB, shift);

        return {pointA: pointA, pointB: pointB};
    }

    public static getShiftPoint2D(point: TPoint2D, pointA: TPoint2D, pointB: TPoint2D, shift: number): TPoint2D {
        let normalAngle;

        normalAngle = this.getNormalAngle({x: (pointB.x - pointA.x), y: (pointB.y - pointA.y)});
        if (isNaN(normalAngle)) {
            throw new Error('getOffsetPoint  normalAngle isNaN!!!');
        }
        // Отнимаем от нормали 90 градусов в радианах
        normalAngle = normalAngle - 90 * Math.PI / 180;

        return {
            x: +(point.x + Math.cos(normalAngle) * shift).toFixed(10),
            y: +(point.y + Math.sin(normalAngle) * shift).toFixed(10)
        };
    }
}