import React, {Fragment, useState} from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import './ConstructorForm.css';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {
    IConstructorFormData
} from '../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {
    DIRECTION_TYPE_NORMAL,
    INSTALLATION_TYPE_BUILTIN,
    INSTALLATION_TYPE_ONLY_DOORS,
    INSTALLATION_TYPE_SEPARATE,
    SHELVES_TYPE_NOTREMOVABLE,
    SHELVES_TYPE_REMOVABLE,
    STEP_COUNT_SECTIONS,
    STEP_BOTTOM_PLINTH,
    STEP_TOP_PLINTH,
    MAX_COUNT_SECTIONS,
    WARDROBE_MAX_DEPTH,
    MIN_TOP_PLINTH,
    MIN_COUNT_DOORS_SECTIONS,
    WARDROBE_MIN_WIDTH,
    WARDROBE_STEP_DEPTH,
    MIN_BOTTOM_PLINTH,
    MAX_COUNT_DOORS_SECTIONS,
    MAX_COUNT_DOORS,
    WARDROBE_MAX_HEIGHT,
    STEP_COUNT_DOORS,
    MIN_COUNT_SECTIONS,
    MIN_COUNT_DOORS,
    WARDROBE_STEP_HEIGHT,
    MAX_BOTTOM_PLINTH,
    WARDROBE_MAX_WIDTH,
    MAX_TOP_PLINTH,
    WARDROBE_MIN_HEIGHT,
    WARDROBE_STEP_WIDTH,
    WARDROBE_MIN_DEPTH,
    DRANK_PLINTH_TYPE_HIGH,
    DRANK_PLINTH_TYPE_LOW,
    DRANK_PLINTH_TYPE_NONE, MATERIAL_TYPE_LDSP, MATERIAL_TYPE_MIRROR,
} from '../../../../common-code/constants';
import {TFormDoor, TTechnologMap, TWardrobeSection} from '../../../../common-code/domain/types';
import {InstallationTypeFragment} from './InstallationTypeFragment';
import {DirectionTypeFragment} from './DirectionTypeFragment';
import {ShelvesTypeFragment} from './ShelvesTypeFragment';
import {BottomPlinthFragment} from './BottomPlinthFragment';
import {TopPlintFragment} from './TopPlintFragment';
import {DrankPlinthFragment} from './DrankPlinthFragment';
import {WardrobeColorsFragment} from './WardrobeColorsFragment';
import {TabsFragment} from './TabsFragment';
import {WidthFragment} from './WidthFragment';
import {DepthFragment} from './DepthFragment';
import {HeightFragment} from './HeightFragment';
import {CountSectionsFragment} from './CountSectionsFragment';
import {CountDoorsFragment} from './CountDoorsFragment';
import {DoorFragment} from './DoorFragment';
import {SectionFragment} from './SectionFragment';
import {ThreeConstructorService} from '../../3d';
import {SideContourPanelsFragment} from './SideContourPanelsFragment/SideContourPanelsFragment';
import {ISquareEntityData} from '../../../../common-code/domain/interfaces/ISquareEntityData';
import {IEditorOptions} from '../../../interfaces';
import {CheckboxInput, cnCheckboxInput} from '../../all/CheckboxInput';
import {cnEditorButtons} from '../../all/EditorButtons';
import {CHANGE_EDITOR_OPTIONS} from '../../../constants';
import {useTranslation} from 'react-i18next';

export const cnConstructorForm = cn('ConstructorForm');

export interface IConstructorFormProps extends IClassNameProps {
    constructorFormData: IConstructorFormData;
    constructorService: ThreeConstructorService;
    editorOptions: IEditorOptions
}

export const ConstructorForm: React.FC<IConstructorFormProps> =
    ({
         className,
         constructorFormData,
         constructorService,
         editorOptions
     }) => {

        const {t} = useTranslation();
        const dispatch = useDispatch();
        const technologMap: TTechnologMap = useSelector((state: AppState) => state.constructorData.technologMap);
        const ldsp: ISquareEntityData[] = useSelector((state: AppState) => state.constructorData.ldsp);
        const mirrors: ISquareEntityData[] = useSelector((state: AppState) => state.constructorData.mirrors);
        const dvp: ISquareEntityData[] = useSelector((state: AppState) => state.constructorData.dvp);
        const [activeSideWardrobe, setActiveSideWardrobe] = useState<number>(1);

        // TODO Добавить поддержку английского языка
        const data = {
            installationType: {
                options: [
                    {value: INSTALLATION_TYPE_SEPARATE, text: 'Отдельностоящий'},
                    {value: INSTALLATION_TYPE_BUILTIN, text: 'Встроенный'},
                    {value: INSTALLATION_TYPE_ONLY_DOORS, text: 'Только двери'}
                ]
            },
            directionType: {
                options: [
                    {value: DIRECTION_TYPE_NORMAL, text: 'Прямой'},
                    // { value: DIRECTION_TYPE_ANGLE, text: 'Угловой' },
                    // { value: DIRECTION_TYPE_UTYPE, text: 'П-образный' }
                ]
            },
            bottomPlinth: {
                min: MIN_BOTTOM_PLINTH,
                max: MAX_BOTTOM_PLINTH,
                step: STEP_BOTTOM_PLINTH
            },
            topPlinth: {
                min: MIN_TOP_PLINTH,
                max: MAX_TOP_PLINTH,
                step: STEP_TOP_PLINTH,
                options: [
                    {value: 'enabled', text: 'Да'},
                    {value: 'disabled', text: 'Нет'}
                ]
            },
            drankPlinth: {
                options: [
                    {value: DRANK_PLINTH_TYPE_NONE, content: 'Нет'},
                    {value: DRANK_PLINTH_TYPE_HIGH, content: 'Высокий'},
                    {value: DRANK_PLINTH_TYPE_LOW, content: 'Низкий'}
                ]
            },
            tabs: {
                prefix: 'Сторона'
            },
            shelvesType: {
                placeholder: 'Тип полок',
                options: [
                    {value: SHELVES_TYPE_REMOVABLE, content: 'Съемные'},
                    {value: SHELVES_TYPE_NOTREMOVABLE, content: 'Не съемные'}
                ]
            },
            width: {
                min: WARDROBE_MIN_WIDTH,
                max: WARDROBE_MAX_WIDTH,
                step: WARDROBE_STEP_WIDTH
            },
            depth: {
                min: WARDROBE_MIN_DEPTH,
                max: WARDROBE_MAX_DEPTH,
                step: WARDROBE_STEP_DEPTH
            },
            height: {
                min: WARDROBE_MIN_HEIGHT,
                max: WARDROBE_MAX_HEIGHT,
                step: WARDROBE_STEP_HEIGHT
            },
            countSections: {
                min: MIN_COUNT_SECTIONS,
                max: MAX_COUNT_SECTIONS,
                step: STEP_COUNT_SECTIONS
            },
            countDoors: {
                min: MIN_COUNT_DOORS,
                max: MAX_COUNT_DOORS,
                step: STEP_COUNT_DOORS
            },
            door: {
                countSections: {
                    min: MIN_COUNT_DOORS_SECTIONS,
                    max: MAX_COUNT_DOORS_SECTIONS
                },
                materials: {
                    placeholder: 'Тип материала',
                    options: [
                        {value: MATERIAL_TYPE_LDSP, content: 'ЛДСП'},
                        {value: MATERIAL_TYPE_MIRROR, content: 'Зеркало'},
                    ]
                },
            },
        };

        const toggleShowDoors = () => {
            let newEditorOptions: IEditorOptions = {
                ...editorOptions
            };

            newEditorOptions.showDoors = !editorOptions.showDoors;
            dispatch({type: CHANGE_EDITOR_OPTIONS, payload: newEditorOptions});
        };

        return (
            <div className={cnConstructorForm({overflow: 'hidden'}, [className])}>
                <div className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                    <div className={cnConstructorForm('Title', {size: 'xl'})}>Тип шкафа-купе</div>
                    <InstallationTypeFragment
                        constructorFormData={constructorFormData}
                        technologMap={technologMap}
                        dispatch={dispatch}
                        options={data.installationType.options}
                    />
                    <div className={cnConstructorForm('Title', {size: 'xl'})}>Геометрия шкафа</div>
                    <DirectionTypeFragment
                        constructorFormData={constructorFormData}
                        constructorService={constructorService}
                        technologMap={technologMap}
                        dispatch={dispatch}
                        options={data.directionType.options}
                    />
                    {constructorFormData.installationType !== INSTALLATION_TYPE_SEPARATE ? null :
                        <Fragment>
                            <div className={cnConstructorForm('Title', {size: 'xl'})}>Верхний цоколь</div>
                            <TopPlintFragment
                                constructorFormData={constructorFormData}
                                technologMap={technologMap}
                                dispatch={dispatch}
                                min={data.topPlinth.min}
                                max={data.topPlinth.max}
                                step={data.topPlinth.step}
                                options={data.topPlinth.options}
                            />
                        </Fragment>
                    }

                    {constructorFormData.installationType === INSTALLATION_TYPE_ONLY_DOORS ? null :
                        <Fragment>
                            <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Цвет корпуса</h3>
                            <WardrobeColorsFragment
                                constructorFormData={constructorFormData}
                                constructorService={constructorService}
                                technologMap={technologMap}
                                ldspMaterials={ldsp.filter(item => {
                                    return item.depth === 16
                                })}
                                dvpMaterials={dvp}
                            />
                        </Fragment>
                    }

                </div>
                <TabsFragment
                    constructorFormData={constructorFormData}
                    activeTab={activeSideWardrobe}
                    prefix={data.tabs.prefix}
                    callBack={setActiveSideWardrobe}
                >
                    {constructorFormData.installationType === INSTALLATION_TYPE_ONLY_DOORS ? null :
                        constructorFormData.sideWardrobes.map(((sideWardrobe, sideIndex) => {
                            if (activeSideWardrobe !== sideIndex + 1) {
                                return null;
                            }

                            return (
                                <div key={sideIndex}
                                     className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                                    <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Тип полок</h3>
                                    <ShelvesTypeFragment
                                        constructorFormData={constructorFormData}
                                        sideIndex={sideIndex}
                                        dispatch={dispatch}
                                        placeholder={data.shelvesType.placeholder}
                                        options={data.shelvesType.options}
                                    />
                                </div>
                            );
                        }))
                    }
                    {constructorFormData.installationType !== INSTALLATION_TYPE_BUILTIN ? null :
                        constructorFormData.sideWardrobes.map(((sideWardrobe, sideIndex) => {
                            if (activeSideWardrobe !== sideIndex + 1) {
                                return null;
                            }
                            return (
                                <div key={sideIndex}
                                     className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                                    <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Наличие стенок</h3>
                                    {
                                        constructorFormData.installationType === INSTALLATION_TYPE_BUILTIN ?
                                            <SideContourPanelsFragment
                                                className={cnConstructorForm('Sides')}
                                                sideWardrobe={sideWardrobe}
                                                sideIndex={sideIndex}/> : null
                                    }

                                </div>
                            );
                        }))
                    }
                    {constructorFormData.sideWardrobes.map(((sideWardrobe, sideIndex) => {
                        if (activeSideWardrobe !== sideIndex + 1) {
                            return null;
                        }

                        return (
                            <div key={sideIndex}
                                 className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                                <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Габаритные размеры</h3>
                                <div className={cnConstructorForm('Sizes')}>
                                    <WidthFragment
                                        constructorFormData={constructorFormData}
                                        sideIndex={sideIndex}
                                        dispatch={dispatch}
                                        min={data.width.min}
                                        max={data.width.max}
                                        step={data.width.step}
                                    />
                                    <DepthFragment
                                        constructorFormData={constructorFormData}
                                        sideIndex={sideIndex}
                                        dispatch={dispatch}
                                        min={data.depth.min}
                                        max={data.depth.max}
                                        step={data.depth.step}
                                    />
                                    <HeightFragment
                                        constructorFormData={constructorFormData}
                                        sideIndex={sideIndex}
                                        dispatch={dispatch}
                                        min={data.height.min}
                                        max={data.height.max}
                                        step={data.height.step}
                                    />
                                </div>
                            </div>
                        );
                    }))}
                    {constructorFormData.installationType === INSTALLATION_TYPE_ONLY_DOORS ? null :
                        constructorFormData.sideWardrobes.map(((sideWardrobe, sideIndex) => {
                            if (activeSideWardrobe !== sideIndex + 1) {
                                return null;
                            }

                            return (
                                <div key={sideIndex}
                                     className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                                    <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Количество секций</h3>
                                    <CountSectionsFragment
                                        constructorFormData={constructorFormData}
                                        sideIndex={sideIndex}
                                        dispatch={dispatch}
                                        min={data.countSections.min}
                                        max={data.countSections.max}
                                        step={data.countSections.step}
                                    />
                                </div>
                            );
                        }))
                    }
                    {constructorFormData.sideWardrobes.map(((sideWardrobe, sideIndex) => {
                        if (activeSideWardrobe !== sideIndex + 1) {
                            return null;
                        }
                        return (
                            <Fragment key={sideIndex}>
                                {constructorFormData.installationType === INSTALLATION_TYPE_ONLY_DOORS ? null :
                                    <div className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                                        <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Наполнение секций
                                            шкафа</h3>
                                        <div className={cnConstructorForm('Labels')}>
                                        <span className={cnConstructorForm('Label', {
                                            size: 'sm',
                                            color: 'lightNavy',
                                            grid: 'columnStart'
                                        })}>Штанга</span>
                                            <span className={cnConstructorForm('Label', {
                                                size: 'sm',
                                                color: 'lightNavy'
                                            })}>Ящик</span>
                                            <span className={cnConstructorForm('Label', {
                                                size: 'sm',
                                                color: 'lightNavy'
                                            })}>Полка</span>
                                        </div>
                                        {sideWardrobe.sections.map((section: TWardrobeSection, sectionIndex) => {
                                            return (
                                                <SectionFragment
                                                    constructorFormData={constructorFormData}
                                                    key={sectionIndex}
                                                    sectionData={section}
                                                    sideIndex={sideIndex}
                                                    sectionIndex={sectionIndex}
                                                    technologMap={technologMap}
                                                />
                                            );
                                        })}
                                    </div>
                                }
                            </Fragment>
                        );
                    }))}
                    {<div className={cnConstructorForm('Group')}>
                        <CheckboxInput
                            className={cnConstructorForm(cnCheckboxInput(), {})}
                            checked={editorOptions.showDoors}
                            callBack={toggleShowDoors}
                            label={<span className={cnEditorButtons('Label', {size: 'lg'})}>
                                    {t('Показать/скрыть двери')}
                                </span>}
                        />
                    </div>}
                    {constructorFormData.sideWardrobes.map(((sideWardrobe, sideIndex) => {
                        if (activeSideWardrobe !== sideIndex + 1) {
                            return null;
                        }
                        if (!editorOptions.showDoors) {
                            return null;
                        }

                        return (
                            <div key={sideIndex}
                                 className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                                <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Количество дверей</h3>
                                <CountDoorsFragment
                                    constructorFormData={constructorFormData}
                                    materialId={constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0}
                                    sideIndex={sideIndex}
                                    dispatch={dispatch}
                                    min={data.countDoors.min}
                                    max={data.countDoors.max}
                                    step={data.countDoors.step}
                                />
                            </div>
                        );
                    }))}
                    {constructorFormData.sideWardrobes.map(((sideWardrobe, sideIndex) => {
                        if (activeSideWardrobe !== sideIndex + 1) {
                            return null;
                        }
                        if (!editorOptions.showDoors) {
                            return null;
                        }
                        return (
                            <Fragment key={sideIndex}>
                                {sideWardrobe.doors.map((door: TFormDoor, doorIndex) => {
                                    return (
                                        <div key={doorIndex}
                                             className={cnConstructorForm('Group', {bkg: 'white', animation: true})}>
                                            <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Дверь {doorIndex + 1}</h3>
                                            <DoorFragment
                                                constructorFormData={constructorFormData}
                                                constructorService={constructorService}
                                                sideIndex={sideIndex}
                                                doorIndex={doorIndex}
                                                dispatch={dispatch}
                                                minCountSections={data.door.countSections.min}
                                                maxCountSections={data.door.countSections.max}
                                                materialTypes={data.door.materials}
                                                ldsp={ldsp.filter(item => {
                                                    return item.depth === 10
                                                })}
                                                mirrors={mirrors}
                                            />
                                        </div>
                                    );
                                })}
                            </Fragment>
                        );
                    }))}
                </TabsFragment>
                <div className={cnConstructorForm('Group', {bkg: 'white', animation: true, margin: 'top'})}>
                    <Fragment>
                        <div className={cnConstructorForm('Title', {size: 'xl'})}>Нижний цоколь</div>
                        <BottomPlinthFragment
                            constructorFormData={constructorFormData}
                            technologMap={technologMap}
                            dispatch={dispatch}
                            min={data.bottomPlinth.min}
                            max={data.bottomPlinth.max}
                            step={data.bottomPlinth.step}
                        />
                    </Fragment>
                    {constructorFormData.installationType === INSTALLATION_TYPE_ONLY_DOORS ? null :
                        <Fragment>
                            <h3 className={cnConstructorForm('Title', {size: 'xl'})}>Запил под плинтус</h3>
                            <DrankPlinthFragment
                                constructorFormData={constructorFormData}
                                dispatch={dispatch}
                                options={data.drankPlinth.options}
                            />
                        </Fragment>
                    }
                </div>

            </div>
        );
    };