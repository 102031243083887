import React from 'react';
import { IClassNameProps } from '@bem-react/core'
import {cn} from "@bem-react/classname";
import {useRegistry} from "@bem-react/di";
import {appId} from "../../common/App/App";
import './MainPage.css';
import {Footer, PageWrapper} from "../../blocks";
import '../../i18n/';
import {useTranslation} from 'react-i18next';
import {CreateProject} from '../../blocks/all/CreateProject/CreateProject';

export interface IMainPageProps extends IClassNameProps {
}

export const cnMainPage = cn('MainPage');

export const MainPage: React.FC<IMainPageProps> = ({className}) => {
    const {t} = useTranslation();
    const {Header} = useRegistry(appId);


    return (
        <div className={cnMainPage({}, [className, 'CommonPage'])}>
            <Header title={t('main-page-title')}/>
            <PageWrapper>
                <h1 className={cnMainPage('Title')}>КОНСТРУКТОР шкафов-купе</h1>
                <CreateProject/>
            </PageWrapper>
            <Footer/>
        </div>
    )
};

