import {ConstructorService} from "../../../../domain/services/ConstructorService";
import {
    DoubleSide,
    LineBasicMaterial, LoadingManager,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial, RepeatWrapping,
    Texture,
    TextureLoader
} from "three";
import {
    IConstructorFormData
} from "../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData";
import {
    IWardrobeProjectData
} from "../../../../common-code/domain/interfaces/IWardrobeProjectData/IWardrobeProjectData";
import {ProjectGenerator} from "../../../../domain/services/ProjectGenerator/ProjectGenerator";
import {TextGeometry, TextGeometryParameters} from 'three/examples/jsm/geometries/TextGeometry';
import {
    CHANGE_SELECT_DVP,
    CHANGE_SELECT_LDSP,
    CHANGE_DVP,
    CHANGE_LDSP, CHANGE_MIRRORS,
    CONSTRUCTOR_SERVICE_INIT,
    HIDE_LOADING,
    SHOW_LOADING,
    SIZE_DEFAULT_TEXT_SIZE, CHANGE_SELECT_MIRROR, CHANGE_SELECT_DOOR_LDSP
} from '../../../constants';
import {Font, FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import * as threeFont from '../../../../assets/fonts/helvetiker_bold.typeface.json';
import {
    TFillerConnection, TLevel,
    TPointConnection, TProfileType,
    TShapeData,
    TTechnologMap, TViewMode, TViewQuality
} from '../../../../common-code/domain/types';
import {Dispatch} from 'redux';
import axios from 'axios';
import {ThreeConstructor} from '../ThreeConstructor';
import {CommonHelper} from '../../../helpers/CommonHelper';
import {ITextureData} from '../../../../common-code/domain/interfaces/ITextureData/ITextureData';
import {IMaterialTextures} from '../interfaces/IMaterialTextures/IMaterialTextures';
import {IEntityData} from '../../../../common-code/domain/interfaces/IEntityData';
import {ISquareEntityData} from '../../../../common-code/domain/interfaces/ISquareEntityData';
import {TMaterialType} from '../../../../common-code/domain/types/TMaterialType';
import {
    BOTTOM_RAIL,
    LEVEL_BOTTOM,
    LEVEL_TOP,
    MATERIAL_TYPE_LDSP,
    MATERIAL_TYPE_MIRROR, TOP_RAIL
} from '../../../../common-code/constants';
import {IPlankEntityData} from '../../../../common-code/domain/interfaces/IPlankEntityData';
import {IServiceData} from '../../../../../../common/domain/interfaces/IServiceData';
import {TRodMaterials} from '../../../types/TRodMaterials';


export class ThreeConstructorService extends ConstructorService {
    bodyMaterials: { [key: string]: MeshStandardMaterial };
    panelBodyMaterials: { [key: string]: MeshStandardMaterial };
    shelfBodyMaterial?: MeshStandardMaterial;
    rodBodyMaterial?: MeshStandardMaterial;
    backFillerBodyMaterials: { [key: string]: MeshStandardMaterial };
    sizeLineMaterial?: LineBasicMaterial;
    sizeTextMaterial?: MeshBasicMaterial;
    threeFont?: Font;
    textureLoader: TextureLoader;
    cacheTextures: { [id: string]: { isLoad: boolean, texture: Texture } };
    cacheMaterialTextures: { [id: string]: IMaterialTextures };
    loading: boolean;

    private readonly reduxDispatch: Dispatch;
    private formData: IConstructorFormData;

    public fillerConnections: TFillerConnection[];
    public pointConnections: TPointConnection[];
    public shapesList: TShapeData[];
    public dvp: ISquareEntityData[];
    public butts: IPlankEntityData[];
    public mirrors: ISquareEntityData[];
    public ldsp: ISquareEntityData[];
    public profiles: IPlankEntityData[];
    public wheels: IEntityData[];
    public seals: IEntityData[];
    public rails: IPlankEntityData[];
    public fasteners: IEntityData[];
    public rods: IEntityData[];
    public services: IServiceData[];

    public selectLdsp?: ISquareEntityData;
    public selectDoorLdsp?: ISquareEntityData;
    public selectMirror?: ISquareEntityData;
    public selectDvp?: ISquareEntityData;

    private ready: boolean;

    private threeConstructor?: ThreeConstructor;

    constructor(technologMap: TTechnologMap,
                constructorFormData: IConstructorFormData,
                reduxDispatch: Dispatch,
                viewMode?: TViewMode,
                viewQuality?: TViewQuality,
                manager?: LoadingManager) {
        super(technologMap, viewMode, viewQuality);
        this.textureLoader = new TextureLoader(manager);
        this.reduxDispatch = reduxDispatch;
        this.formData = constructorFormData;
        this.shapesList = [];
        this.fillerConnections = [];
        this.pointConnections = [];
        this.dvp = [];
        this.butts = [];
        this.mirrors = [];
        this.ldsp = [];
        this.profiles = [];
        this.wheels = [];
        this.seals = [];
        this.rails = [];
        this.fasteners = [];
        this.rods = [];
        this.services = [];
        this.ready = false;
        this.bodyMaterials = {};
        this.panelBodyMaterials = {};
        this.backFillerBodyMaterials = {};
        this.cacheTextures = {};
        this.cacheMaterialTextures = {};
        this.loading = false;
    }

    public setFormData(formData: IConstructorFormData) {
        this.formData = formData;
    }

    public getFormData(): IConstructorFormData {
        return this.formData;
    }

    public getConstructor(): ThreeConstructor | undefined {
        return this.threeConstructor;
    }

    public setConstructor(constructor: ThreeConstructor) {
        this.threeConstructor = constructor;
    }

    public async onInit() {
        await this.loadEntities();
        this.setDefaultSelectLdsp();
        this.setDefaultDoorLdsp();
        this.setDefaultMirror();
        this.setDefaultDvp();
        this.ready = true;
        this.reduxDispatch({type: CONSTRUCTOR_SERVICE_INIT});
    }

    public isReady() {
        return this.ready;
    }

    public getRailByLevel(level: TLevel): IPlankEntityData | undefined {
        let rail: IPlankEntityData;

        for (rail of this.rails) {
            switch (level) {
                case LEVEL_BOTTOM:
                    if (rail.type === BOTTOM_RAIL) {
                        return rail;
                    }
                    break;
                case LEVEL_TOP:
                    if (rail.type === TOP_RAIL) {
                        return rail;
                    }
                    break;
            }
        }

        return undefined;
    }

    public getPanelMaterialId(initMaterialId?: number): number {
        if (this.selectLdsp && !initMaterialId) {
            return this.selectLdsp.id;
        }
        let ldsp: IEntityData;

        for (ldsp of this.ldsp) {
            if (!initMaterialId) {
                return ldsp.id;
            }
            if (initMaterialId === ldsp.id) {
                return ldsp.id;
            }
        }

        throw new Error('error-ThreeConstructorService-getPanelMaterialId');
    }

    public getPanelMaterial(initMaterialId: number): ISquareEntityData {
        let ldsp: ISquareEntityData;

        for (ldsp of this.ldsp) {
            if (initMaterialId === ldsp.id) {
                return ldsp;
            }
        }
        if (this.selectLdsp) {
            return this.selectLdsp;
        }
        throw new Error('error-ThreeConstructorService-getPanelMaterial');
    }

    public getService(): IServiceData | undefined {
        return this.services[0] ? this.services[0] : undefined;
    }

    public getRodMaterials(): TRodMaterials | undefined {
        let plank: IPlankEntityData | undefined;
        let rod: IEntityData;
        let furniture: IEntityData[] = [];

        for (rod of this.rods) {
            if (rod.type === "rod") {
                plank = rod as IPlankEntityData;
            } else {
                furniture.push(rod);
            }
        }
        return plank ? {
            plank: plank,
            furniture: furniture
        } : undefined;
    }

    public getProfileMaterial(type: TProfileType): IPlankEntityData | undefined {
        let profile: IPlankEntityData;

        for (profile of this.profiles) {
            if (profile.type === type) {
                return profile;
            }
        }

        return undefined;
    }

    public getButtMaterial(panelMaterialId: number): IPlankEntityData {
        let butt: IPlankEntityData;
        let defaultButt: IPlankEntityData | undefined;
        let ldspMaterial: IEntityData;

        ldspMaterial = this.getPanelMaterial(panelMaterialId);

        for (butt of this.butts) {
            if (ldspMaterial.properties.color.value === butt.properties.color.value) {
                return butt;
            }
            if (!defaultButt) {
                defaultButt = butt;
            }
        }
        if (defaultButt) {
            return defaultButt;
        }
        throw new Error('error-ThreeConstructorService-getPanelMaterial');
    }

    public getDvpMaterial(id: number): ISquareEntityData {

        let ldsp: ISquareEntityData;

        for (ldsp of this.ldsp) {
            if (id === ldsp.id) {
                return ldsp;
            }
        }
        if (this.selectDvp) {
            return this.selectDvp;
        }
        throw new Error('error-ThreeConstructorService-getDvpMaterial');
    }

    public getMirrorMaterial(initMaterialId: number): ISquareEntityData {

        let mirror: ISquareEntityData;

        for (mirror of this.mirrors) {
            if (initMaterialId === mirror.id) {
                return mirror;
            }
        }
        if (this.selectMirror) {
            return this.selectMirror;
        }
        throw new Error('error-ThreeConstructorService-getMirrorMaterial');
    }

    public loadMaterialTextures(materialData: IEntityData, isShape?: boolean): IMaterialTextures {
        const cacheKey: string = '' + materialData.id + isShape;
        if (this.cacheMaterialTextures[cacheKey]) {
            return this.cacheMaterialTextures[cacheKey];
        }
        let textureData: ITextureData;
        this.cacheMaterialTextures[cacheKey] = {};

        if (materialData.textures) {
            for (textureData of materialData.textures) {
                this.cacheMaterialTextures[cacheKey][textureData.type] = this.loadTexture(materialData.id, textureData, isShape);
            }
            this.startTextureLoading();
        }

        return this.cacheMaterialTextures[cacheKey];
    }

    public loadTexture(materialId: number, textureData: ITextureData, isShape?: boolean): Texture {
        let textureId: string;

        textureId = CommonHelper.md5({
            id: materialId,
            type: textureData.type,
            repeat: textureData.repeat,
            offset: textureData.offset,
            isShape: isShape
        });
        if (this.cacheTextures[textureId]) {
            return this.cacheTextures[textureId].texture;
        }
        this.cacheTextures[textureId] = {
            isLoad: false,
            texture: this.textureLoader.load('/server-static' + textureData.path, () => {
                this.cacheTextures[textureId].texture.wrapT = RepeatWrapping;
                this.cacheTextures[textureId].texture.wrapS = RepeatWrapping;
                if (isShape) {
                    this.cacheTextures[textureId].texture.repeat.set(textureData.repeat.x * 0.001, textureData.repeat.y * 0.001);
                } else {
                    this.cacheTextures[textureId].texture.repeat.set(textureData.repeat.x, textureData.repeat.y);
                }
                if (textureData.offset) {
                    this.cacheTextures[textureId].texture.offset.set(textureData.offset.x, textureData.offset.y);
                }
                this.cacheTextures[textureId].isLoad = true;
            })
        };

        return this.cacheTextures[textureId].texture;
    }

    public startTextureLoading() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.showLoadingSpin();
        this.processTextureLoading();
    }

    public stopTextureLoading() {
        this.loading = false;
        this.hideLoadingSpin();
    }

    protected showLoadingSpin() {
        // TODO
    }

    protected hideLoadingSpin() {
        // TODO
    }

    protected processTextureLoading() {
        if (this.checkLoadTextures()) {
            setTimeout(() => {
                this.stopTextureLoading();
            }, 100);
        } else {
            setTimeout(() => {
                this.processTextureLoading();
            }, 100);
        }
    }

    protected checkLoadTextures() {
        let index;

        for (index in this.cacheTextures) {
            if (this.cacheTextures.hasOwnProperty(index)) {
                if (!this.cacheTextures[index].isLoad) {
                    return false;
                }
            }
        }

        return true;
    }

    public getFillerConnectionById(id: number): TFillerConnection {
        let fillerConnection: TFillerConnection;

        for (fillerConnection of this.fillerConnections) {
            if (id === fillerConnection.id) {
                return fillerConnection;
            }
        }
        throw new Error('Not found filler connection id: ' + id);
    }

    public getPointConnectionById(id: number): TPointConnection {
        let pointConnection: TPointConnection;

        for (pointConnection of this.pointConnections) {
            if (id === pointConnection.id) {
                return pointConnection;
            }
        }
        throw new Error('Not found point connection id: ' + id);
    }

    public getShapeById(shapeId: number | undefined): TShapeData | undefined {
        let shape: TShapeData;

        if (!shapeId) {
            return undefined;
        }
        for (shape of this.shapesList) {
            if (shape.id === shapeId) {
                return shape;
            }
        }

        return undefined;
    }

    public getDvpById(materialId: number): ISquareEntityData | undefined {
        let dvp: ISquareEntityData;
        for (dvp of this.dvp) {
            if (dvp.id === materialId) {
                return dvp;
            }
        }

        return undefined;
    }

    public getLdspById(materialId: number): ISquareEntityData | undefined {
        let ldsp: ISquareEntityData;
        for (ldsp of this.ldsp) {
            if (ldsp.id === materialId) {
                return ldsp;
            }
        }

        return undefined;
    }

    public getMirrorById(materialId: number): ISquareEntityData | undefined {
        let mirror: ISquareEntityData;
        for (mirror of this.mirrors) {
            if (mirror.id === materialId) {
                return mirror;
            }
        }

        return undefined;
    }

    public getSizeLineMaterial() {
        if (!this.sizeLineMaterial) {
            this.sizeLineMaterial = new LineBasicMaterial({
                color: '#000000',
                linewidth: 2
            });
        }

        return this.sizeLineMaterial;
    }

    public createTextSizeMesh(text: string, parameters?: TextGeometryParameters): Mesh {
        let textGeometry, textMesh, textSettings, textHeight;

        parameters = parameters || this.getDefaultTextGeometryParameters();
        textSettings = {
            ...this.getDefaultTextGeometryParameters(),
            ...parameters
        };
        textGeometry = new TextGeometry(
            text,
            textSettings
        );
        textGeometry.center();
        textGeometry.computeBoundingBox();
        textHeight = 0;
        if (textGeometry.boundingBox) {
            textHeight = textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y;
        }
        textMesh = new Mesh(textGeometry, this.getSizeTextMaterial());
        textMesh.position.y = textHeight / 2;
        textMesh.applyMatrix4(textMesh.matrix);

        return textMesh;
    }

    public getSizeTextMaterial(): MeshBasicMaterial {
        if (!this.sizeTextMaterial) {
            this.sizeTextMaterial = new MeshBasicMaterial({
                color: '#000000',
                side: DoubleSide
            });
        }

        return this.sizeTextMaterial;
    }

    public getDefaultTextGeometryParameters(): TextGeometryParameters {
        return {
            font: this.getFont(),
            size: SIZE_DEFAULT_TEXT_SIZE,
            height: 0,
            curveSegments: 2,
            bevelEnabled: true,
            bevelThickness: 0,
            bevelSize: 1
        }
    }

    public getFont(): Font {
        if (!this.threeFont) {
            this.threeFont = new FontLoader().parse(threeFont)
        }

        return this.threeFont;
    }

    public getBodyMaterial(): MeshStandardMaterial {
        const key: string = this.selectLdsp ? '' + this.selectLdsp.id : 'none';

        if (!this.bodyMaterials[key]) {
            this.bodyMaterials[key] = new MeshStandardMaterial({color: "#b3b3b3", side: DoubleSide});
        }
        return this.bodyMaterials[key];
    }

    public getPanelMeshMaterial(materialId?: number, isShape?: boolean): MeshStandardMaterial {
        let panelMaterial: IEntityData | undefined;

        if (!materialId && this.selectLdsp) {
            materialId = this.selectLdsp.id;
        }
        if (materialId) {
            panelMaterial = this.getPanelMaterial(materialId);
        }
        const key: string = CommonHelper.md5({
            id: panelMaterial ? '' + panelMaterial.id : 'none',
            type: 'ldsp',
            isShape: isShape
        });
        if (!this.panelBodyMaterials[key]) {
            if (panelMaterial) {
                const materialTextures = this.loadMaterialTextures(panelMaterial, isShape);
                this.panelBodyMaterials[key] = new MeshStandardMaterial({
                    color: panelMaterial.colorHex || '#ffffff',
                    map: materialTextures.texture || null,
                    normalMap: materialTextures.normal || null,
                    roughnessMap: materialTextures.roughness || null,
                    side: DoubleSide
                });
            } else {
                this.panelBodyMaterials[key] = new MeshStandardMaterial({color: "#b3b3b3", side: DoubleSide});
            }
        }
        return this.panelBodyMaterials[key].clone();
    }

    public getDvpMeshMaterial(materialId?: number, isShape?: boolean): MeshStandardMaterial {
        let dvpMaterial: IEntityData | undefined;

        if (!materialId && this.selectDvp) {
            materialId = this.selectDvp.id;
        }
        if (materialId) {
            dvpMaterial = this.getDvpMaterial(materialId);
        }
        const key: string = CommonHelper.md5({
            id: dvpMaterial ? '' + dvpMaterial.id : 'none',
            type: 'dvp',
            isShape: isShape
        });
        if (!this.panelBodyMaterials[key]) {
            if (dvpMaterial) {
                const materialTextures = this.loadMaterialTextures(dvpMaterial, isShape);
                this.panelBodyMaterials[key] = new MeshStandardMaterial({
                    color: dvpMaterial.colorHex || '#ffffff',
                    map: materialTextures.texture || null,
                    normalMap: materialTextures.normal || null,
                    roughnessMap: materialTextures.roughness || null,
                    side: DoubleSide
                });
            } else {
                this.panelBodyMaterials[key] = new MeshStandardMaterial({color: "#b3b3b3", side: DoubleSide});
            }
        }
        return this.panelBodyMaterials[key].clone();
    }

    public getMirrorMeshMaterial(materialId?: number, isShape?: boolean): MeshStandardMaterial {
        let mirrorMaterial: IEntityData | undefined;

        if (!materialId && this.selectMirror) {
            materialId = this.selectMirror.id;
        }
        if (materialId) {
            mirrorMaterial = this.getMirrorMaterial(materialId);
        }
        const key: string = CommonHelper.md5({
            id: mirrorMaterial ? '' + mirrorMaterial.id : 'none',
            type: 'mirror',
            isShape: isShape
        });
        if (!this.panelBodyMaterials[key]) {
            if (mirrorMaterial) {
                const materialTextures = this.loadMaterialTextures(mirrorMaterial, isShape);
                this.panelBodyMaterials[key] = new MeshStandardMaterial({
                    color: mirrorMaterial.colorHex || '#cdfcff',
                    roughness: 0,
                    map: materialTextures.texture || null,
                    normalMap: materialTextures.normal || null,
                    roughnessMap: materialTextures.roughness || null,
                    side: DoubleSide
                });
            } else {
                this.panelBodyMaterials[key] = new MeshStandardMaterial({color: "#b3b3b3", side: DoubleSide});
            }
        }
        return this.panelBodyMaterials[key].clone();
    }

    public getPanelBodyMaterial(isShape?: boolean): MeshStandardMaterial {
        const key: string = CommonHelper.md5({
            id: this.selectLdsp ? '' + this.selectLdsp.id : 'none',
            isShape: isShape
        });
        if (!this.panelBodyMaterials[key]) {
            if (this.selectLdsp) {
                const materialTextures = this.loadMaterialTextures(this.selectLdsp, isShape);
                this.panelBodyMaterials[key] = new MeshStandardMaterial({
                    color: this.selectLdsp.colorHex || '#ffffff',
                    map: materialTextures.texture || null,
                    normalMap: materialTextures.normal || null,
                    roughnessMap: materialTextures.roughness || null,
                    side: DoubleSide
                });
            } else {
                this.panelBodyMaterials[key] = new MeshStandardMaterial({color: "#b3b3b3", side: DoubleSide});
            }
        }
        return this.panelBodyMaterials[key].clone();
    }

    public getShelfBodyMaterial(): MeshStandardMaterial {
        if (!this.shelfBodyMaterial) {
            this.shelfBodyMaterial = new MeshStandardMaterial({color: "#5eea76", side: DoubleSide});
        }
        return this.shelfBodyMaterial;
    }

    public getRodBodyMaterial(): MeshStandardMaterial {
        if (!this.rodBodyMaterial) {
            this.rodBodyMaterial = new MeshStandardMaterial({color: "#6086ea", side: DoubleSide});
        }
        return this.rodBodyMaterial;
    }

    public getDoorFillerBodyMaterial(materialId: number, materialType: TMaterialType): MeshStandardMaterial {
        switch (materialType) {
            case MATERIAL_TYPE_MIRROR:
                return this.getMirrorMeshMaterial(materialId, true);
            case MATERIAL_TYPE_LDSP:
            default:
                return this.getPanelMeshMaterial(materialId, true);
        }
    }

    public getBackFillerBodyMaterial(isShape?: boolean): MeshStandardMaterial {
        const key: string = CommonHelper.md5({
            id: this.selectDvp ? '' + this.selectDvp.id : 'none',
            isShape: isShape
        });
        if (!this.backFillerBodyMaterials[key]) {
            if (this.selectDvp) {
                const materialTextures = this.loadMaterialTextures(this.selectDvp, isShape);
                this.backFillerBodyMaterials[key] = new MeshStandardMaterial({
                    color: this.selectDvp.colorHex || '#ffffff',
                    map: materialTextures.texture || null,
                    normalMap: materialTextures.normal || null,
                    roughnessMap: materialTextures.roughness || null,
                    side: DoubleSide
                });
            } else {
                this.backFillerBodyMaterials[key] = new MeshStandardMaterial({color: "#b3b3b3", side: DoubleSide});
            }
        }

        return this.backFillerBodyMaterials[key].clone();
    }

    public generateProject(formData: IConstructorFormData): IWardrobeProjectData | undefined {
        if (!formData) {
            return undefined;
        }

        const projectGenerator = new ProjectGenerator(this, formData, this.technologMap);

        return projectGenerator.generate();
    }

    public setSelectLdsp(color: ISquareEntityData) {
        this.selectLdsp = color;
        this.reduxDispatch({
            type: CHANGE_SELECT_LDSP,
            payload: this.selectLdsp,
        });
    }

    public setSelectDoorLdsp(material: ISquareEntityData) {
        this.selectDoorLdsp = material;
        this.reduxDispatch({
            type: CHANGE_SELECT_DOOR_LDSP,
            payload: this.selectDoorLdsp,
        });
    }

    public setSelectMirror(color: ISquareEntityData) {
        this.selectMirror = color;
        this.reduxDispatch({
            type: CHANGE_SELECT_MIRROR,
            payload: this.selectMirror,
        });
    }

    public setSelectDvp(color: ISquareEntityData) {
        this.selectDvp = color;
        this.reduxDispatch({
            type: CHANGE_SELECT_DVP,
            payload: this.selectDvp
        });
    }

    private setDefaultSelectLdsp() {
        if (this.selectLdsp) {
            return;
        }
        let selectLdsp: ISquareEntityData | undefined;
        let ldsp: ISquareEntityData;
        let defaultLpsd: ISquareEntityData | undefined;
        let defaultId: number;
        defaultId = this.formData.materials.ldsp;

        for (ldsp of this.ldsp) {
            if (!defaultId && ldsp.depth === 16) {
                selectLdsp = ldsp;
                break;
            }
            if (!defaultLpsd && ldsp.depth === 16) {
                defaultLpsd = ldsp;
            }
            if (defaultId === ldsp.id && ldsp.depth === 16) {
                selectLdsp = ldsp;
                break;
            }
        }
        if (!selectLdsp && defaultLpsd) {
            selectLdsp = defaultLpsd;
        }
        if (!selectLdsp) {
            throw new Error('error-ThreeConstructorService-setDefaultSelectLdsp');
        }
        this.setSelectLdsp(selectLdsp);
    }

    private setDefaultDoorLdsp() {
        if (this.selectDoorLdsp) {
            return;
        }
        let selectDoorLdsp: ISquareEntityData | undefined;
        let ldsp: ISquareEntityData;
        let defaultLpsd: ISquareEntityData | undefined;
        let defaultLpsd2: ISquareEntityData | undefined;

        for (ldsp of this.ldsp) {
            if (ldsp.depth === this.technologMap.door.filler.depth &&
                (!this.selectLdsp || this.selectLdsp.properties.color.value === ldsp.properties.color.value)) {
                selectDoorLdsp = ldsp;
                break;
            }
            if (!defaultLpsd && ldsp.depth === this.technologMap.door.filler.depth &&
                (!this.selectLdsp || this.selectLdsp.properties.color.value === ldsp.properties.color.value)) {
                defaultLpsd = ldsp;
            }
            if (!defaultLpsd && ldsp.depth === this.technologMap.door.filler.depth) {
                defaultLpsd2 = ldsp;
            }
        }
        if (!selectDoorLdsp && defaultLpsd) {
            selectDoorLdsp = defaultLpsd;
        }
        if (!selectDoorLdsp && defaultLpsd2) {
            selectDoorLdsp = defaultLpsd2;
        }
        if (!selectDoorLdsp) {
            throw new Error('error-ThreeConstructorService-setDefaultSelectLdsp');
        }
        this.setSelectDoorLdsp(selectDoorLdsp);
    }

    private setDefaultMirror() {
        if (this.selectMirror) {
            return;
        }
        let selectMirror: ISquareEntityData | undefined;

        selectMirror = this.mirrors[0];

        if (!selectMirror) {
            throw new Error('error-ThreeConstructorService-setDefaultMirror');
        }
        this.setSelectMirror(selectMirror);
    }

    private setDefaultDvp() {
        if (this.selectDvp) {
            return;
        }
        let dvp: ISquareEntityData;
        let selectDvp: ISquareEntityData | undefined;
        let defaultDvp: ISquareEntityData | undefined;
        let defaultId: number;
        defaultId = this.formData.materials.dvp;

        for (dvp of this.dvp) {
            if (!defaultId) {
                selectDvp = dvp;
                break;
            }
            if (!defaultDvp) {
                defaultDvp = dvp;
            }
            if (defaultId === dvp.id) {
                selectDvp = dvp;
                break;
            }
        }
        if (!selectDvp && defaultDvp) {
            selectDvp = defaultDvp;
        }
        if (!selectDvp) {
            throw new Error('error-ThreeConstructorService-setDefaultDvp');
        }
        this.setSelectDvp(selectDvp);
    }


    private async loadEntities() {
        this.reduxDispatch({type: SHOW_LOADING});
        await Promise.all([
            this.getEntityData('/api/entity/filler-connections'),
            this.getEntityData('/api/entity/point-connections'),
            this.getEntityData('/api/entity/shape-list'),
            this.getEntityData('/api/entity/dvp'),
            this.getEntityData('/api/entity/butts'),
            this.getEntityData('/api/entity/mirrors'),
            this.getEntityData('/api/entity/ldsp'),
            this.getEntityData('/api/entity/profiles'),
            this.getEntityData('/api/entity/wheels'),
            this.getEntityData('/api/entity/seals'),
            this.getEntityData('/api/entity/rails'),
            this.getEntityData('/api/entity/fasteners'),
            this.getEntityData('/api/entity/rods'),
            this.getEntityData('/api/entity/services'),
        ])
            .then(results => {
                this.fillerConnections = results[0];
                this.pointConnections = results[1];
                this.shapesList = results[2];
                this.dvp = results[3];
                this.butts = results[4];
                this.mirrors = results[5];
                this.ldsp = results[6];
                this.profiles = results[7];
                this.wheels = results[8];
                this.seals = results[9];
                this.rails = results[10];
                this.fasteners = results[11];
                this.rods = results[12];
                this.services = results[13];

                this.reduxDispatch({
                    type: CHANGE_LDSP,
                    payload: this.ldsp
                });
                this.reduxDispatch({
                    type: CHANGE_DVP,
                    payload: this.dvp
                });
                this.reduxDispatch({
                    type: CHANGE_MIRRORS,
                    payload: this.mirrors
                });
                this.reduxDispatch({type: HIDE_LOADING});
            })
            .catch(error => {
                console.log(error);
                this.reduxDispatch({type: HIDE_LOADING});
            });
    }

    private async getEntityData(url: string) {
        return axios.get(url).then(
            response => {
                return response.data;
            }
        ).catch(error => {
            return Promise.reject(error);
        });
    }
}