import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './SpinInput.css';
import {Spin} from '@yandex/ui/Spin/desktop/bundle';

export const cnSpinInput = cn('SpinInput');

export interface ISpinInputProps extends IClassNameProps {
    loading: boolean
}

export const SpinInput: React.FC<ISpinInputProps> = ({ className, loading }) => {
    return (
        <div className={cnSpinInput({}, [className])}>
            <Spin
                view={'default'}
                size={'m'}
                progress={loading}
            />
        </div>
    );
};