import {
    IPoint, IWardrobeProject,
} from "../../../../domain/intarfaces";
import {ThreeConstructor} from "../ThreeConstructor";
import {ThreeSidePoint} from "../models/points";
import {ThreeSideWardrobe} from "../models/ThreeSideWardrobe";
import {TDirectionType} from '../../../../common-code/domain/types';
import {TInstallationType} from '../../../../common-code/domain/types';
import {
    IWardrobeProjectData
} from '../../../../common-code/domain/interfaces/IWardrobeProjectData/IWardrobeProjectData';
import {TViewMode} from '../../../../common-code/domain/types';
import {TViewQuality} from '../../../../common-code/domain/types';
import {ISideWardrobeData} from "../../../../common-code/domain/interfaces/ISideWardrobeData/ISideWardrobeData";
import {ISidePointData} from "../../../../common-code/domain/interfaces/ISidePointData/ISidePointData";
import {IProjectPriceData} from '../../../../common-code/domain/interfaces/IProjectPriceData/IProjectPriceData';
import {ThreeBackFiller} from '../models/fillers';
import {ISquareMaterialPriceData} from '../../../../common-code/domain/interfaces/IPriceData/ISquareMaterialPriceData';
import {CHANGE_PROJECT_PRICE_DATA} from '../../../constants';
import {ThreeDoor} from '../models/ThreeDoor/ThreeDoor';
import {
    MATERIAL_TYPE_LDSP,
    MATERIAL_TYPE_MIRROR,
} from '../../../../common-code/constants';
import {
    IWardrobeProjectSpec
} from '../../../../common-code/domain/interfaces/IWardrobeProjectSpec/IWardrobeProjectSpec';
import {ISpecItem} from '../../../../common-code/domain/interfaces/IWardrobeProjectSpec/ISpecItem';
import {IRodPriceData} from '../../../../common-code/domain/interfaces/IPriceData/IRodPriceData';
import {IDrawerPriceData} from '../../../../common-code/domain/interfaces/IPriceData/IDrawerPriceData';
import {IFurniturePriceData} from '../../../../common-code/domain/interfaces/IPriceData/IFurniturePriceData';
import {IPlankMaterialPriceData} from '../../../../common-code/domain/interfaces/IPriceData/IPlankMaterialPriceData';
import {IPanelPriceData} from '../../../../common-code/domain/interfaces/IPriceData/IPanelPriceData';
import {ThreeRail} from '../models/planks/ThreeRail/ThreeRail';
import {IEntityData} from '../../../../common-code/domain/interfaces/IEntityData';
import {IServiceData} from '../../../../../../common/domain/interfaces/IServiceData';
import {IServicePriceData} from '../../../../common-code/domain/interfaces/IPriceData/IServicePriceData';
import {IOfferPriceData} from '../../../../common-code/domain/interfaces/IPriceData/IOfferPriceData';

export class WardrobeProject implements IWardrobeProject {
    directionType: TDirectionType;
    id: string;
    version: string;
    installationType: TInstallationType;
    name: string;
    points: { [n: number]: ThreeSidePoint };
    sideWardrobes: { [n: number]: ThreeSideWardrobe };
    projectData: IWardrobeProjectData;
    mainConstructor: ThreeConstructor;
    viewMode: TViewMode;
    viewQuality: TViewQuality;
    saveData: IWardrobeProjectData;
    priceData: IProjectPriceData;

    constructor(mainConstructor: ThreeConstructor, projectData: IWardrobeProjectData) {
        this.mainConstructor = mainConstructor;
        this.id = projectData.id;
        this.version = projectData.version;
        this.name = projectData.name;
        this.directionType = projectData.directionType;
        this.installationType = projectData.installationType;
        this.points = {};
        this.sideWardrobes = {};
        this.projectData = projectData;
        this.saveData = {...projectData};
        this.priceData = this.mainConstructor.initProjectPriceData();
        this.viewMode = this.mainConstructor.getConstructorService().getViewMode();
        this.viewQuality = this.mainConstructor.getConstructorService().getViewQuality();
    }

    public createView() {
        this.createPoints();
        this.createSideWardrobes();
        this.setSideWardrobesNeighbors();
        this.setPointPositions();
        this.createPointViews();
        this.createSideWardrobeViews();
        this.fitAll();
        this.setSaveData();
    }

    public rebuild(newProjectData: IWardrobeProjectData) {
        this.remove();
        this.setProjectData(newProjectData);
        this.createView();
    }

    public calculatePrice() {
        let priceData: IProjectPriceData;
        let index: string;
        let sideWardrobe: ThreeSideWardrobe;


        priceData = this.mainConstructor.initProjectPriceData();
        for (index in this.sideWardrobes) {
            sideWardrobe = this.sideWardrobes[index];
            this.calculateBackFillersPriceData(sideWardrobe, priceData);
            this.calculatePanelsPriceData(sideWardrobe, priceData);
            this.calculateShelvesPriceData(sideWardrobe, priceData);
            this.calculateFrontFillersPriceData(sideWardrobe, priceData);
            this.calculateDrawersPriceData(sideWardrobe, priceData);
            this.calculateDoorsPriceData(sideWardrobe, priceData);
            this.calculateRodsPriceData(sideWardrobe, priceData);
        }
        this.calculateServicesPriceData(priceData);
        this.calculateFullPriceData(priceData);
        this.priceData = priceData;
        this.mainConstructor.reduxDispatch({
            type: CHANGE_PROJECT_PRICE_DATA,
            payload: priceData
        })
    }

    public getSpec(): IWardrobeProjectSpec | undefined {
        let projectSpec: IWardrobeProjectSpec | undefined;
        if (this.priceData) {
            projectSpec = {
                ldsp: this.getSquareSpecItems(this.priceData.ldsp),
                dvp: this.getSquareSpecItems(this.priceData.dvp),
                mirror: this.getSquareSpecItems(this.priceData.mirror),
                butts: this.getPlankSpecItems(this.priceData.butts),
                rods: this.getPlankSpecItems(this.priceData.rods),
                profiles: this.getPlankSpecItems(this.priceData.profiles),
                furniture: this.getFurnitureSpecItems(this.priceData.furniture),
                other: this.getOfferSpecItems(this.priceData.other),
                services: this.getServiceSpecItems(this.priceData.services),
                full: this.priceData.full
            }
        }

        return projectSpec;
    }

    protected getSquareSpecItems(materials: ISquareMaterialPriceData[]): ISpecItem[] {
        let material: ISquareMaterialPriceData;
        let specItems: ISpecItem[] = [];
        for (material of materials) {
            specItems.push({
                code: material.code,
                name: material.name,
                price: material.sheetPrice,
                count: '' + material.sheetCount,
                sum: material.sum,
                store: material.store
            });
        }

        return specItems;
    }

    protected getPlankSpecItems(materials: IPlankMaterialPriceData[]): ISpecItem[] {
        let material: IPlankMaterialPriceData;
        let specItems: ISpecItem[] = [];
        for (material of materials) {
            specItems.push({
                code: material.code,
                name: material.name,
                price: material.plankPrice,
                count: '' + material.count,
                sum: material.sum,
                store: material.store
            });
        }

        return specItems;
    }

    protected getServiceSpecItems(services: IServicePriceData[]): ISpecItem[] {
        let service: IServicePriceData;
        let specItems: ISpecItem[] = [];
        for (service of services) {
            specItems.push({
                code: service.code,
                name: service.name,
                price: service.price,
                count: '' + service.count,
                sum: service.sum,
                store: service.store
            });
        }

        return specItems;
    }

    protected getFurnitureSpecItems(furnitureItems: IFurniturePriceData[]): ISpecItem[] {
        let furniture: IFurniturePriceData;
        let specItems: ISpecItem[] = [];
        for (furniture of furnitureItems) {
            specItems.push({
                code: furniture.code,
                name: furniture.name,
                price: furniture.price,
                count: '' + furniture.count,
                sum: furniture.sum,
                store: furniture.store
            });
        }

        return specItems;
    }

    protected getOfferSpecItems(items: IOfferPriceData[]): ISpecItem[] {
        let item: IOfferPriceData;
        let specItems: ISpecItem[] = [];

        for (item of items) {
            specItems.push({
                code: item.code,
                name: item.name,
                price: item.price,
                count: '' + item.count,
                sum: item.sum,
                store: item.store
            });
        }

        return specItems;
    }

    protected calculateFullPriceData(priceData: IProjectPriceData) {
        let priceGroups: (keyof IProjectPriceData)[];
        let fullPrice: number;

        priceGroups = [
            'dvp',
            'ldsp',
            'mirror',
            'butts',
            'furniture',
            'rods',
            'profiles',
            'services',
            'other'
        ];
        fullPrice = this.calculateGroupPriceData(priceData, priceGroups);
        priceData.full = fullPrice;
    }

    protected calculateGroupPriceData(priceData: IProjectPriceData, priceGroups: (keyof IProjectPriceData)[]): number {
        let index: string;
        let priceGroup: keyof IProjectPriceData;
        let fullPrice: number = 0;

        for (priceGroup of priceGroups) {
            let groupData: any = priceData[priceGroup];
            if (Array.isArray(groupData)) {
                for (index in groupData) {
                    if (groupData[index].sum > 0) {
                        fullPrice += groupData[index].sum;
                    }
                }
            }
        }

        return fullPrice;
    }

    protected calculateMirrorPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {

    }

    protected calculateServicesPriceData(priceData: IProjectPriceData) {
        let priceGroups: (keyof IProjectPriceData)[];
        let fullPrice: number;
        let servicePrice: number;
        let service: IServiceData | undefined;

        service = this.mainConstructor.getService();
        if (!service) {
            return;
        }
        priceGroups = [
            'dvp',
            'ldsp',
            'mirror',
            'butts',
            'furniture',
            'rods',
            'profiles',
            'other'
        ];
        fullPrice = this.calculateGroupPriceData(priceData, priceGroups);
        servicePrice = Math.ceil(fullPrice * 0.9);

        priceData.services = [
            {
                price: servicePrice,
                amount: 1,
                count: 1,
                code: service.product.code,
                name: service.name,
                errors: [],
                sum: servicePrice,
                store: '-'
            }
        ];
    }

    protected calculateRodsPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {
        let index: string;
        let rodPriceData: IRodPriceData;
        let plankPriceData: IPlankMaterialPriceData;
        let furniturePriceData: IFurniturePriceData;

        for (index in sideWardrobe.rods) {
            rodPriceData = sideWardrobe.rods[index].getPriceData();
            for (plankPriceData of rodPriceData.planks) {
                this.calculatePlankMaterialPriceData(plankPriceData, priceData.rods);
            }
            for (furniturePriceData of rodPriceData.furniture) {
                this.calculateFurniturePriceData(furniturePriceData, priceData.furniture);
            }
        }
    }

    protected calculateDoorsPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {
        if (!sideWardrobe.doorContainer) {
            return;
        }
        let index: string;
        let index2: string;
        let door: ThreeDoor;
        let rail: ThreeRail;
        let panelPriceData: IPanelPriceData;
        let railData: IEntityData | undefined;
        let plankPriceData: IPlankMaterialPriceData | undefined;

        if (sideWardrobe.doorContainer.rails) {
            for (index in sideWardrobe.doorContainer.rails) {
                rail = sideWardrobe.doorContainer.rails[index];
                railData = this.mainConstructor.getRailByLevel(rail.level);
                if (railData) {
                    this.calculatePlankMaterialPriceData(rail.getPriceData(), priceData.profiles);
                }
            }
        }
        for (index in sideWardrobe.doorContainer.doors) {
            door = sideWardrobe.doorContainer.doors[index];
            for (index2 in door.fillers) {
                panelPriceData = door.fillers[index2].getPriceData();
                if (panelPriceData.material.materialId > 0) {
                    switch (door.fillers[index2].materialType) {
                        case MATERIAL_TYPE_MIRROR:
                            this.calculateSquareMaterialPriceData(panelPriceData.material, priceData.mirror);
                            break;
                        case MATERIAL_TYPE_LDSP:
                        default:
                            this.calculateSquareMaterialPriceData(panelPriceData.material, priceData.ldsp);
                            break;
                    }
                }
            }
            for (index2 in door.profiles) {
                plankPriceData = door.profiles[index2].getPriceData();
                if (plankPriceData) {
                    this.calculatePlankMaterialPriceData(plankPriceData, priceData.profiles);
                }
            }
            for (index2 in door.frames) {
                plankPriceData = door.frames[index2].getPriceData();
                if (plankPriceData) {
                    this.calculatePlankMaterialPriceData(plankPriceData, priceData.profiles);
                }
            }
        }
    }

    protected calculatePanelsPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {
        let index: string;
        let panelPriceData: IPanelPriceData;
        let plankPriceData: IPlankMaterialPriceData;

        for (index in sideWardrobe.panels) {
            panelPriceData = sideWardrobe.panels[index].getPriceData();
            if (panelPriceData.material.materialId > 0) {
                this.calculateSquareMaterialPriceData(panelPriceData.material, priceData.ldsp)
                for (plankPriceData of panelPriceData.butts) {
                    if (plankPriceData.materialId > 0) {
                        this.calculatePlankMaterialPriceData(plankPriceData, priceData.butts);
                    }
                }
            }
        }
    }

    protected calculateShelvesPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {
        let index: string;
        let panelPriceData: IPanelPriceData;
        let plankPriceData: IPlankMaterialPriceData;

        for (index in sideWardrobe.shelves) {
            panelPriceData = sideWardrobe.shelves[index].getPriceData();
            if (panelPriceData.material.materialId > 0) {
                this.calculateSquareMaterialPriceData(panelPriceData.material, priceData.ldsp);
                for (plankPriceData of panelPriceData.butts) {
                    if (plankPriceData.materialId > 0) {
                        this.calculatePlankMaterialPriceData(plankPriceData, priceData.butts);
                    }
                }
            }
        }
    }

    protected calculateFrontFillersPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {
        let index: string;
        let panelPriceData: IPanelPriceData;
        let plankPriceData: IPlankMaterialPriceData;

        for (index in sideWardrobe.frontFillers) {
            panelPriceData = sideWardrobe.frontFillers[index].getPriceData();
            if (panelPriceData.material.materialId > 0) {
                this.calculateSquareMaterialPriceData(panelPriceData.material, priceData.ldsp);
                for (plankPriceData of panelPriceData.butts) {
                    if (plankPriceData.materialId > 0) {
                        this.calculatePlankMaterialPriceData(plankPriceData, priceData.butts);
                    }
                }
            }
        }
    }

    protected calculateDrawersPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {
        let index: string;
        let drawerPriceData: IDrawerPriceData;
        let squarePriceData: ISquareMaterialPriceData;
        let furniturePriceData: IFurniturePriceData;

        for (index in sideWardrobe.drawers) {
            drawerPriceData = sideWardrobe.drawers[index].getPriceData();
            for (squarePriceData of drawerPriceData.ldsp) {
                this.calculateSquareMaterialPriceData(squarePriceData, priceData.ldsp);
            }
            for (squarePriceData of drawerPriceData.dvp) {
                this.calculateSquareMaterialPriceData(squarePriceData, priceData.dvp);
            }
            for (furniturePriceData of drawerPriceData.furniture) {
                this.calculateFurniturePriceData(furniturePriceData, priceData.furniture);
            }
        }
    }

    protected calculateFurniturePriceData(priceData: IFurniturePriceData, furniture: IFurniturePriceData[]) {
        let index: string;
        let isSet: boolean;

        isSet = false;
        for (index in furniture) {
            if (furniture[index].offerId === priceData.offerId &&
                furniture[index].price === priceData.price) {
                furniture[index].count += priceData.count;
                furniture[index].sum += priceData.sum;
                isSet = true;
                break;
            }
        }
        if (!isSet) {
            furniture.push(priceData);
        }
    }

    protected calculatePlankMaterialPriceData(priceData: IPlankMaterialPriceData, planks: IPlankMaterialPriceData[]) {
        let index: string;
        let isSet: boolean;

        isSet = false;
        for (index in planks) {
            if (planks[index].materialId === priceData.materialId &&
                planks[index].plankPrice === priceData.plankPrice) {
                planks[index].length += priceData.length;
                planks[index].plankLength = Math.ceil(planks[index].length / planks[index].plankLength);
                planks[index].sum = planks[index].plankLength * planks[index].plankPrice;
                isSet = true;
                break;
            }
        }
        if (!isSet) {
            planks.push(priceData);
        }
    }

    protected calculateSquareMaterialPriceData(priceData: ISquareMaterialPriceData, material: ISquareMaterialPriceData[]) {
        let index: string;
        let isSet: boolean;

        isSet = false;
        for (index in material) {
            if (material[index].materialId === priceData.materialId) {
                material[index].square += priceData.square;
                material[index].sheetCount = Math.ceil(material[index].square / material[index].sheetSquare);
                material[index].sum = material[index].sheetCount * material[index].sheetPrice;
                isSet = true;
                break;
            }
        }
        if (!isSet) {
            material.push(priceData);
        }
    }

    protected calculateBackFillersPriceData(sideWardrobe: ThreeSideWardrobe, priceData: IProjectPriceData) {
        let index: string;
        let backFiller: ThreeBackFiller;
        let panelPriceData: IPanelPriceData;

        for (index in sideWardrobe.backFillers) {
            backFiller = sideWardrobe.backFillers[index];
            panelPriceData = backFiller.getPriceData();
            if (panelPriceData.material.materialId > 0) {
                this.calculateSquareMaterialPriceData(panelPriceData.material, priceData.dvp);
            }
        }
    }

    public fitAll() {
        this.mainConstructor.fitAll();
    }

    public setSaveData() {
        let index;
        let points: ISidePointData[] = [];
        let sideWardrobes: ISideWardrobeData[] = [];

        for (index in this.points) {
            points.push(this.points[index].getSaveData())
        }
        for (index in this.sideWardrobes) {
            sideWardrobes.push(this.sideWardrobes[index].getSaveData())
        }

        this.saveData = {
            id: this.projectData.id,
            points: points,
            sideWardrobes: sideWardrobes,
            directionType: this.projectData.directionType,
            name: this.projectData.name,
            version: this.projectData.version,
            installationType: this.projectData.installationType
        };
    }

    public getSaveData(): IWardrobeProjectData {
        return this.saveData;
    }

    public setProjectData(projectData: IWardrobeProjectData) {
        this.projectData = projectData;
    }

    public  getPointById(id: number): IPoint
    {
        let sideId;

        for (sideId in this.sideWardrobes) {
            if (this.sideWardrobes[sideId].points[id]) {
                return this.sideWardrobes[sideId].points[id];
            }
        }
        if (!this.points.hasOwnProperty(id)) {
            throw new Error("Error! not found point! by id: " + id);
        }

        return this.points[id];
    }

    public remove() {
        let index;

        for (index in this.points) {
            this.points[index].remove();
        }

        for (index in this.sideWardrobes) {
            this.sideWardrobes[index].remove();
        }
        this.points = {};
        this.sideWardrobes = {};
    }

    private createPoints()
    {
        let pointData;
        let point: ThreeSidePoint;

        for (pointData of this.projectData.points) {
            point = new ThreeSidePoint(this.mainConstructor, pointData);
            this.points[point.getId()] = point;
        }
    }

    private createSideWardrobes()
    {
        let sideWardrobeData;
        let sideWardrobe;

        for (sideWardrobeData of this.projectData.sideWardrobes) {
            sideWardrobe = new ThreeSideWardrobe(this.mainConstructor, sideWardrobeData);
            this.sideWardrobes[sideWardrobe.getId()] = sideWardrobe;
            this.sideWardrobes[sideWardrobe.getId()].initState();
        }
    }

    private setSideWardrobesNeighbors() {
        let sideId1;
        let sideId2;

        for (sideId1 in this.sideWardrobes) {
            for (sideId2 in this.sideWardrobes) {
                if (sideId1 === sideId2) {
                    continue;
                }
                if (this.sideWardrobes[sideId1].pointCenter.getId() === this.sideWardrobes[sideId2].pointX.getId()) {
                    this.sideWardrobes[sideId1].setLeftNeighbor(this.sideWardrobes[sideId2]);
                    this.sideWardrobes[sideId2].setRightNeighbor(this.sideWardrobes[sideId1]);
                }
                if (this.sideWardrobes[sideId1].pointX.getId() === this.sideWardrobes[sideId2].pointCenter.getId()) {
                    this.sideWardrobes[sideId1].setRightNeighbor(this.sideWardrobes[sideId2]);
                    this.sideWardrobes[sideId2].setLeftNeighbor(this.sideWardrobes[sideId1]);
                }
            }
        }
    }

    private setPointPositions()
    {
        let pointId;

        for (pointId in this.points) {
            this.points[pointId].calculatePosition();
            this.points[pointId].setPosition();
        }
    }

    private createPointViews()
    {
        let pointId;

        for (pointId in this.points) {
            this.points[pointId].createView();
        }
    }

    private createSideWardrobeViews()
    {
        let sideId;

        for (sideId in this.sideWardrobes) {
            this.sideWardrobes[sideId].createView();
        }

    }
}