import {ThreeFiller} from "../ThreeFiller";
import {IBackFiller} from "../../../../../../domain/intarfaces/IBackFiller/IBackFiller";
import {ThreeSideWardrobe} from "../../ThreeSideWardrobe";
import {IBackFillerSegmentInfo} from "../../../interfaces/IBackFillerSegmentInfo/IBackFillerSegmentInfo";
import {ThreeGeometry} from "../../../helpers";
import {AXIS_X, AXIS_Y} from "../../../../../../domain/constants";
import {TThreeLine} from "../../../../../types/TThreeLine";
import {IFillerData} from '../../../../../../common-code/domain/interfaces/IFillerData/IFillerData';
import {TFillerSegmentData} from "../../../../../../common-code/domain/types";
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {MATERIAL_TYPE_DVP} from '../../../../../../common-code/constants';
import { TMaterialType } from "../../../../../../common-code/domain/types/TMaterialType";

export class ThreeBackFiller extends ThreeFiller implements IBackFiller {
    segments: IBackFillerSegmentInfo[];

    constructor(sideWardrobe: ThreeSideWardrobe, options: IFillerData) {
        super(sideWardrobe, options);
        this.segments = [];

    }

    public initState() {
        this.setSegments();
        this.calculatePoints();
    }

    public getSaveData(): IFillerData {
        super.getSaveData();
        let globalPoints: IStartEndPoints | undefined;
        globalPoints = this.calculateGlobalPoints();
        return {
            id: this.getId(),
            shift: this.shift,
            depth: this.depth,
            sort: this.sort,
            segments: this.getSegmentsSaveData(),
            materialId: this.materialId,
            startPoint: globalPoints?.start,
            endPoint: globalPoints?.end,
            directionType: this.calculateDirectionType(globalPoints),
            productCode: this.getProductCode(),
            materialType: this.getMaterialType(),
        };
    }

    public getWidth(): number {
        debugger;
        return 0;
    }

    public getLength(): number {
        debugger;
        return 0;
    }

    protected getMaterialType(): TMaterialType {
        return MATERIAL_TYPE_DVP;
    }

    protected getSegmentsSaveData(): TFillerSegmentData[] {
        let segments: TFillerSegmentData[] = [];
        let segmentInfo: IBackFillerSegmentInfo;

        for (segmentInfo of this.segments) {
            segments.push(segmentInfo.info)
        }
        return segments;
    }

    protected setSegments() {
        let segmentData;

        for (segmentData of this.initData.segments) {
            this.segments.push({
                segment: this.mainConstructor.getPanelById(segmentData.segmentId),
                info: segmentData,
                connection: this.mainConstructor.getFillerConnectionById(segmentData.connectionId)
            });
        }
        this.segments.sort((a, b) => a.info.sort - b.info.sort);
    }

    protected createBodyMaterial() {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.mainConstructor.getConstructorService().getBackFillerBodyMaterial(true);
        }

        return this.bodyMaterial;
    }

    protected calculatePoints() {
        let index;
        let nextSegmentInfo: IBackFillerSegmentInfo;
        let currentSegmentInfo: IBackFillerSegmentInfo;
        let leftSegmentPoints: TThreeLine;
        let rightSegmentPoints: TThreeLine;
        let intersectPoint;

        this.points = [];
        for (index in this.segments) {
            currentSegmentInfo = this.segments[index];
            if (this.segments[+index + 1]) {
                nextSegmentInfo = this.segments[+index + 1];
            } else {
                nextSegmentInfo = this.segments[0];
            }
            currentSegmentInfo.segment.calculatePoints();
            nextSegmentInfo.segment.calculatePoints();
            if (currentSegmentInfo.segment.points && nextSegmentInfo.segment.points) {
                leftSegmentPoints = currentSegmentInfo.info.direction ?
                    {
                        pointA: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.C, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.D, AXIS_X, AXIS_Y)
                    } :
                    {
                        pointA: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.B, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.A, AXIS_X, AXIS_Y)
                    };
                rightSegmentPoints = nextSegmentInfo.info.direction ?
                    {
                        pointA: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.C, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.D, AXIS_X, AXIS_Y)
                    } :
                    {
                        pointA: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.B, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.A, AXIS_X, AXIS_Y)
                    };

                leftSegmentPoints = ThreeGeometry.getParallelLine(
                    leftSegmentPoints,
                    -this.mainConstructor.getConstructorService().getIndentionValue(
                        currentSegmentInfo.connection.indention,
                        currentSegmentInfo.segment
                    )
                );
                rightSegmentPoints = ThreeGeometry.getParallelLine(
                    rightSegmentPoints, -this.mainConstructor.getConstructorService().getIndentionValue(
                        nextSegmentInfo.connection.indention,
                        nextSegmentInfo.segment
                    )
                );
                intersectPoint = ThreeGeometry.getIntersectionPoint(leftSegmentPoints, rightSegmentPoints);
                if (!intersectPoint && ThreeGeometry.isEqualThreeLines(leftSegmentPoints, rightSegmentPoints)) {
                    intersectPoint = ThreeGeometry.getNearPoint2D(
                        leftSegmentPoints.pointA,
                        [rightSegmentPoints.pointA, rightSegmentPoints.pointB]
                    );
                }
                if (!intersectPoint) {
                    throw new Error('Not connected segments in filler');
                } else {
                    this.points.push(intersectPoint.clone());
                }
            } else {
                throw new Error('Not calculated points in segments in filler');
            }
        }
    }
}