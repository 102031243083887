import {ConstructorService} from "../ConstructorService";
import {
    TConnectionType,
    TContourPositionType,
    TDoorContainerSegmentData,
    TDoorContainerSizes,
    TFillerSegmentData,
    TFormDoor,
    TFormDoorSection,
    TFormSideWardrobe,
    TLevel,
    TLine,
    TPanelPointsPosition,
    TPanelShift,
    TPanelType,
    TPoint2D,
    TPoint3D,
    TProfileType,
    TSideContour,
    TTechnologMap,
    TWardrobeSection
} from "../../../common-code/domain/types";
import {IWardrobeProjectData} from "../../../common-code/domain/interfaces/IWardrobeProjectData/IWardrobeProjectData";
import {ISidePointData} from "../../../common-code/domain/interfaces/ISidePointData/ISidePointData";
import {ISideWardrobeData} from "../../../common-code/domain/interfaces/ISideWardrobeData/ISideWardrobeData";
import {TSidePoints} from "../../types/TSidePoints";
import {IConnectionPointData} from "../../../common-code/domain/interfaces/IConnectionPointData/IConnectionPointData";
import {IPanelPointData} from "../../../common-code/domain/interfaces/IPanelPointData/IPanelPointData";
import {IPanelData} from "../../../common-code/domain/interfaces/IPanelData/IPanelData";
import {IDoorContainerData} from "../../../common-code/domain/interfaces/IDoorContainerData/IDoorContainerData";
import {IRailData} from "../../../common-code/domain/interfaces/IRailData/IRailData";
import {IFillerData} from "../../../common-code/domain/interfaces/IFillerData/IFillerData";
import {IDoorData} from "../../../common-code/domain/interfaces/IDoorData/IDoorData";
import {IDoorPointData} from "../../../common-code/domain/interfaces/IDoorPointData/IDoorPointData";
import {
    CONNECTION_TYPE_DEFAULT,
    CONNECTION_TYPE_MAIN_LEFT,
    CONNECTION_TYPE_MAIN_RIGHT,
    CONTOUR_POSITION_TYPE_BOTTOM,
    CONTOUR_POSITION_TYPE_LEFT,
    CONTOUR_POSITION_TYPE_RIGHT,
    CONTOUR_POSITION_TYPE_TOP,
    DEFAULT_COUNT_DOORS_SECTIONS,
    DEFAULT_COUNT_DRAWERS,
    DEFAULT_COUNT_RODS,
    DEFAULT_COUNT_SHELVES,
    DEFAULT_PROJECT_ID,
    DIRECTION_TYPE_ANGLE,
    DIRECTION_TYPE_NORMAL,
    DIRECTION_TYPE_UTYPE,
    IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL,
    IMPORT_PANEL_DIRECTION_TYPE_VERTICAL,
    INSTALLATION_TYPE_BUILTIN,
    INSTALLATION_TYPE_ONLY_DOORS,
    INSTALLATION_TYPE_SEPARATE,
    LEVEL_BOTTOM,
    LEVEL_TOP,
    MATERIAL_TYPE_LDSP,
    PANEL_TYPE_CONTOUR,
    PANEL_TYPE_DEFAULT,
    PANEL_TYPE_HORIZONTAL_SECTION,
    PANEL_TYPE_SECTION,
    PANEL_TYPE_SHELF,
    POINT_TYPE_CONNECTION,
    POINT_TYPE_DOOR,
    POINT_TYPE_PANEL,
    POINT_TYPE_PROFILE,
    POINT_TYPE_SIDE,
    PROFILE_TYPE_BOTTOM,
    PROFILE_TYPE_INNER,
    PROFILE_TYPE_TOP,
    PROFILE_TYPE_VERTICAL,
    SHELVES_TYPE_REMOVABLE
} from "../../../common-code/constants";
import {TBoxSizes} from "../../types/TBoxSizes";
import {IShelfData} from "../../../common-code/domain/interfaces/IShelfData/IShelfData";
import {IRodData} from "../../../common-code/domain/interfaces/IRodData/IRodData";
import {IDrawerData} from "../../../common-code/domain/interfaces/IDrawerData/IDrawerData";
import {IProfileData} from "../../../common-code/domain/interfaces/IProfileData/IProfileData";
import {IProfilePointData} from "../../../common-code/domain/interfaces/IProfilePointData/IProfilePointData";
import {IFrameData} from "../../../common-code/domain/interfaces/IFrameData/IFrameData";
import {TPanelDirectionType} from "../../../common-code/domain/types/TPanelDirectionType";
import {TShelvesType} from "../../../common-code/domain/types/TShelvesType";
import {IDoorFillerData} from '../../../common-code/domain/interfaces/IDoorFillerData/IDoorFillerData';
import {TFormContourPanels} from '../../../common-code/domain/types/TFormContourPanels';
import {Geometry} from "../../helpers/Geometry";
import {IConstructorFormData} from '../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TMaterialType} from '../../../common-code/domain/types/TMaterialType';
import {TPanelButt} from '../../../../../common/domain/types/TPanelButt';

export class ProjectGenerator {
    constructorService: ConstructorService;
    formData: IConstructorFormData;
    technologMap: TTechnologMap;
    entityId: number;
    sort: number;
    sidePoints: ISidePointData[];
    contourPoints: IConnectionPointData[];
    contourPanels: IPanelData[];
    innerPanels: IPanelData[];
    shelves: IShelfData[];
    rods: IRodData[];
    drawers: IDrawerData[];
    innerPanelPoints: IPanelPointData[];
    horizontalInnerPanels: IPanelData[];
    bottomPlinthPanels: IPanelData[];
    topPlinthPanels: IPanelData[];
    sideContours: TSideContour[];
    backFillers: IFillerData[];
    frontFillers: IFillerData[];
    doorContourPoints: IDoorPointData[];
    doorProfilePoints: IProfilePointData[];
    doorContourProfiles: IProfileData[];
    doorInnerProfiles: IFrameData[];
    doorFillers: IDoorFillerData[];
    doorContainerSizes: TDoorContainerSizes | undefined;

    constructor(
        constructorService: ConstructorService,
        formData: IConstructorFormData,
        technologMap: TTechnologMap) {
        this.constructorService = constructorService;
        this.formData = formData;
        this.technologMap = technologMap;
        this.entityId = 1;
        this.sort = 1;
        this.sidePoints = [];
        this.contourPoints = [];
        this.contourPanels = [];
        this.innerPanelPoints = [];
        this.innerPanels = [];
        this.shelves = [];
        this.rods = [];
        this.drawers = [];
        this.horizontalInnerPanels = [];
        this.bottomPlinthPanels = [];
        this.topPlinthPanels = [];
        this.sideContours = [];
        this.backFillers = [];
        this.frontFillers = [];
        this.doorContourPoints = [];
        this.doorProfilePoints = [];
        this.doorContourProfiles = [];
        this.doorInnerProfiles = [];
        this.doorFillers = [];
    }

    generate(): IWardrobeProjectData {
        return {
            id: this.formData.projectId || DEFAULT_PROJECT_ID,
            version: this.constructorService.getCurrentVersion(),
            directionType: this.formData.directionType,
            installationType: this.formData.installationType,
            name: this.formData.projectName || 'New project',
            points: this.generateSidePoints(),
            sideWardrobes: this.generateSideWardrobes()
        };
    }

    public generateSidePoints(): ISidePointData[] {
        switch (this.formData.directionType) {
            case DIRECTION_TYPE_NORMAL:
                this.generateSidePointsForSide(
                    this.formData.sideWardrobes[0],
                    {x: 0, y: 0, z: 0},
                    {x: this.formData.sideWardrobes[0].width, y: 0, z: 0}
                );
                break;
            case DIRECTION_TYPE_ANGLE:
                this.generateSidePointsForSide(
                    this.formData.sideWardrobes[0],
                    {x: 0, y: 0, z: +this.formData.sideWardrobes[0].width},
                    {x: 0, y: 0, z: 0}
                );
                this.generateSidePointsForSide(
                    this.formData.sideWardrobes[1],
                    {x: 0, y: 0, z: 0},
                    {x: this.formData.sideWardrobes[1].width, y: 0, z: 0}
                );
                break;
            case DIRECTION_TYPE_UTYPE:
                this.generateSidePointsForSide(
                    this.formData.sideWardrobes[0],
                    {x: 0, y: 0, z: +this.formData.sideWardrobes[0].width},
                    {x: 0, y: 0, z: 0}
                );
                this.generateSidePointsForSide(
                    this.formData.sideWardrobes[1],
                    {x: 0, y: 0, z: 0},
                    {x: this.formData.sideWardrobes[1].width, y: 0, z: 0}
                );
                this.generateSidePointsForSide(
                    this.formData.sideWardrobes[2],
                    {x: this.formData.sideWardrobes[1].width, y: 0, z: 0},
                    {x: this.formData.sideWardrobes[1].width, y: 0, z: this.formData.sideWardrobes[2].width}
                );
                break;
        }
        return this.sidePoints;
    }

    public generateSidePoint(position: TPoint3D, connectionType: TConnectionType): ISidePointData {
        let sidePoint: ISidePointData;
        let sort: number = 1;

        if (this.sidePoints.length > 0) {
            sort = this.sidePoints[this.sidePoints.length - 1].sort + 1;
        }
        for (sidePoint of this.sidePoints) {
            if (sidePoint.value.x === position.x &&
                sidePoint.value.y === position.y &&
                sidePoint.value.z === position.z) {
                return sidePoint;
            }
        }
        sidePoint = {
            id: +(this.entityId++),
            type: POINT_TYPE_SIDE,
            sort: +(sort++),
            connectionId: 0,
            connectionType: connectionType,
            value: position,
        };
        this.sidePoints.push(sidePoint);

        return sidePoint;
    }

    public generateSidePointsForSide(sideWardrobe: TFormSideWardrobe, pointA: TPoint3D, pointB: TPoint3D) {
        let pointCenter: TPoint3D;
        let pointX: TPoint3D;
        let pointZ: TPoint3D;
        let pointZ2D: TPoint2D;
        let pointY: TPoint3D;
        let ratio: number;
        let sideLength: number;

        pointCenter = {...pointA};
        pointY = {...pointA};
        pointY.y = +sideWardrobe.height;
        sideLength = Geometry.getLength(pointA, pointB);
        ratio = sideWardrobe.width / sideLength;
        pointX = Geometry.getPointByRatio(pointA, pointB, ratio, false);
        ratio = sideWardrobe.depth / sideLength;
        pointZ = Geometry.getPointByRatio(pointA, pointB, ratio, false);

        pointZ2D = Geometry.turnVector2D({x: pointZ.x, y: pointZ.z}, Math.PI / 2, {x: pointCenter.x, y: pointCenter.y});
        pointZ.x = pointZ2D.x;
        pointZ.z = pointZ2D.y;
        this.generateSidePoint(pointCenter, CONNECTION_TYPE_MAIN_RIGHT);
        this.generateSidePoint(pointX, CONNECTION_TYPE_MAIN_LEFT);
        this.generateSidePoint(pointZ, CONNECTION_TYPE_DEFAULT);
        this.generateSidePoint(pointY, CONNECTION_TYPE_DEFAULT);
    }

    public generateSideWardrobes(): ISideWardrobeData[] {
        let sideWardrobes: ISideWardrobeData[];
        let sidePoints: TSidePoints;

        sideWardrobes = [];

        switch (this.formData.directionType) {
            case DIRECTION_TYPE_NORMAL:
                sidePoints = {
                    pointCenter: 1,
                    pointX: 2,
                    pointY: 4,
                    pointZ: 3,
                };
                sideWardrobes.push(this.generateSideWardrobe(sidePoints, 0));
                break;
            case DIRECTION_TYPE_ANGLE:
                sidePoints = {
                    pointCenter: 1,
                    pointX: 2,
                    pointY: 4,
                    pointZ: 3,
                };
                sideWardrobes.push(this.generateSideWardrobe(sidePoints, 0));
                sidePoints = {
                    pointCenter: 2,
                    pointX: 5,
                    pointY: 7,
                    pointZ: 6,
                };
                sideWardrobes.push(this.generateSideWardrobe(sidePoints, 1));
                break;
            case DIRECTION_TYPE_UTYPE:
                sidePoints = {
                    pointCenter: 1,
                    pointX: 2,
                    pointY: 4,
                    pointZ: 3,
                };
                sideWardrobes.push(this.generateSideWardrobe(sidePoints, 0));
                sidePoints = {
                    pointCenter: 2,
                    pointX: 5,
                    pointY: 7,
                    pointZ: 6,
                };
                sideWardrobes.push(this.generateSideWardrobe(sidePoints, 1));
                sidePoints = {
                    pointCenter: 5,
                    pointX: 8,
                    pointY: 10,
                    pointZ: 9,
                };
                sideWardrobes.push(this.generateSideWardrobe(sidePoints, 2));
                break;
        }

        return sideWardrobes;
    }

    public generateSideWardrobe(sidePoints: TSidePoints, sideIndex: number): ISideWardrobeData {
        this.contourPoints = [];
        this.contourPanels = [];
        this.innerPanelPoints = [];
        this.innerPanels = [];
        this.shelves = [];
        this.rods = [];
        this.drawers = [];
        this.horizontalInnerPanels = [];
        this.bottomPlinthPanels = [];
        this.topPlinthPanels = [];
        this.sideContours = [];
        this.backFillers = [];
        this.frontFillers = [];
        this.doorContourPoints = [];
        this.doorProfilePoints = [];
        this.doorContourProfiles = [];
        this.doorInnerProfiles = [];
        this.doorFillers = [];

        this.generateSideContours(sideIndex);
        this.generateContourPanelPoints(sideIndex);
        this.generateContourPanels(sideIndex);
        this.generateInnerPanels(sideIndex);
        this.generateRods(sideIndex);
        this.generateDrawers(sideIndex);
        this.generateShelves(sideIndex);
        this.generateBackFillers();
        this.generateBottomPlinth(sideIndex);
        this.generateTopPlinth(sideIndex);

        return {
            id: +(this.entityId++),
            pointCenter: sidePoints.pointCenter,
            pointX: sidePoints.pointX,
            pointY: sidePoints.pointY,
            pointZ: sidePoints.pointZ,
            installationType: this.formData.installationType,
            points: this.generateSideWardrobePoints(),
            panels: this.generateSideWardrobePanels(),
            shelves: [...this.shelves],
            rods: [...this.rods],
            drawers: [...this.drawers],
            doorContainer: this.generateDoorContainer(sideIndex),
            backFillers: [...this.backFillers],
            frontFillers: this.generateFrontFillers(),
        };
    }

    public generateSideWardrobePoints(): Array<IConnectionPointData | IPanelPointData> {
        let sideWardrobePoints: Array<IConnectionPointData | IPanelPointData>;

        sideWardrobePoints = [...this.contourPoints, ...this.innerPanelPoints];

        return sideWardrobePoints;
    }

    public generateSideWardrobePanels(): IPanelData[] {
        let panels: IPanelData[];

        panels = [...this.contourPanels, ...this.innerPanels];

        return panels;
    }

    private calculateLeftSideConnectionType(sideIndex: number): TConnectionType | undefined {
        let point: ISidePointData;

        switch (sideIndex) {
            case 1:
                for (point of this.sidePoints) {
                    if (point.id === 2) {
                        return point.connectionType;
                    }
                }
                break;
            case 2:
                for (point of this.sidePoints) {
                    if (point.id === 5) {
                        return point.connectionType;
                    }
                }
                break;
        }

        return undefined;
    }

    private calculateRightSideConnectionType(sideIndex: number): TConnectionType | undefined {
        let point: ISidePointData;

        switch (sideIndex) {
            case 0:
                for (point of this.sidePoints) {
                    if (point.id === 2) {
                        return point.connectionType;
                    }
                }
                break;
            case 1:
                for (point of this.sidePoints) {
                    if (point.id === 5) {
                        return point.connectionType;
                    }
                }
                break;
        }

        return undefined;
    }

    public generateContourPoint(position: TPoint3D, connectionType: TConnectionType): IConnectionPointData {
        let pointSort: number = 1;
        let point: IConnectionPointData;

        if (this.contourPoints.length > 0) {
            pointSort = this.contourPoints[this.contourPoints.length - 1].sort + 1;
        }
        point = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            type: POINT_TYPE_CONNECTION,
            value: position,
            connectionId: 1,
            connectionType: connectionType,
        };
        this.contourPoints.push(point);

        return point;
    }

    public generateSideContours(sideIndex: number) {
        let sideWardrobe: TFormSideWardrobe;
        let contourCount: number;
        let sectionWidth: number;
        let contourSectionCount: number;
        let contourWidth: number;
        let leftSectionCount: number;
        let index: number;

        if (!this.formData.sideWardrobes[sideIndex]) {
            throw new Error('Error in generateSideContours: wrong sideIndex ' + sideIndex);
        }
        if (!this.technologMap) {
            debugger;
        }

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        if (sideWardrobe.width > this.technologMap.side.sizes.width.max) {
            contourCount = Math.ceil(sideWardrobe.width / this.technologMap.side.sizes.width.max);
            sectionWidth = Math.round(sideWardrobe.width / sideWardrobe.countSections);
            contourSectionCount = Math.ceil(sideWardrobe.countSections / contourCount);
            leftSectionCount = 0;
            for (index = 0; index < contourCount; index++) {
                contourWidth = sectionWidth * contourSectionCount;
                this.sideContours.push({
                    width: contourWidth,
                    countSections: contourSectionCount,
                    firstSectionIndex: leftSectionCount,
                    lastSectionIndex: leftSectionCount + contourSectionCount - 1
                });
                leftSectionCount += contourSectionCount;
                if ((contourCount - (index + 1)) > 0) {
                    contourSectionCount = Math.ceil((sideWardrobe.countSections - leftSectionCount) / (contourCount - (index + 1)));
                }
            }
        } else {
            this.sideContours.push({
                width: sideWardrobe.width,
                countSections: sideWardrobe.countSections,
                firstSectionIndex: 0,
                lastSectionIndex: sideWardrobe.countSections - 1
            });
        }
    }

    public getOutsoleHeight(): number {
        return 0;
    }

    public generateContourPanelPoints(sideIndex: number) {
        let sideWardrobe: TFormSideWardrobe;
        let leftSideWardrobe: TFormSideWardrobe | undefined;
        let rightSideWardrobe: TFormSideWardrobe | undefined;
        let index: string;
        let contourWidth: number;
        let contourLeftWidth: number;
        let position: TPoint3D;
        let connectionType: TConnectionType;

        if (!this.formData.sideWardrobes[sideIndex]) {
            throw new Error('Error in generatePanelPoints: wrong sideIndex ' + sideIndex);
        }
        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        if (this.formData.sideWardrobes[sideIndex - 1]) {
            leftSideWardrobe = this.formData.sideWardrobes[sideIndex - 1];
        }
        if (this.formData.sideWardrobes[sideIndex + 1]) {
            rightSideWardrobe = this.formData.sideWardrobes[sideIndex + 1];
        }
        contourLeftWidth = 0;
        for (index in this.sideContours) {
            contourWidth = this.sideContours[index].width;
            if (+index === 0 && leftSideWardrobe &&
                this.calculateLeftSideConnectionType(sideIndex) === CONNECTION_TYPE_MAIN_LEFT) {
                position = {x: +leftSideWardrobe.depth, y: this.getBottomOutsoleHeight(), z: 0};
            } else {
                position = {x: contourLeftWidth, y: this.getBottomOutsoleHeight(), z: 0};
            }
            if (+index === 0) {
                connectionType = CONNECTION_TYPE_MAIN_RIGHT;
            } else {
                connectionType = CONNECTION_TYPE_MAIN_LEFT;
            }
            this.generateContourPoint(position, connectionType);
            if (+index === 0) {
                connectionType = CONNECTION_TYPE_MAIN_LEFT;
            } else {
                connectionType = CONNECTION_TYPE_MAIN_RIGHT;
            }
            this.generateContourPoint({x: position.x, y: +sideWardrobe.height, z: 0}, connectionType);
            if (+index === this.sideContours.length - 1 && rightSideWardrobe &&
                this.calculateRightSideConnectionType(sideIndex) === CONNECTION_TYPE_MAIN_RIGHT) {
                position = {
                    x: contourLeftWidth + contourWidth - rightSideWardrobe.depth,
                    y: +sideWardrobe.height,
                    z: 0
                };
            } else {
                position = {x: contourLeftWidth + contourWidth, y: +sideWardrobe.height, z: 0};
            }
            if (+index === this.sideContours.length - 1) {
                connectionType = CONNECTION_TYPE_MAIN_RIGHT;
            } else {
                connectionType = CONNECTION_TYPE_MAIN_LEFT;
            }
            this.generateContourPoint(position, connectionType);
            if (+index === this.sideContours.length - 1) {
                connectionType = CONNECTION_TYPE_MAIN_LEFT;
            } else {
                connectionType = CONNECTION_TYPE_MAIN_RIGHT;
            }
            this.generateContourPoint({
                x: position.x,
                y: this.getBottomOutsoleHeight(),
                z: 0
            }, connectionType);
            contourLeftWidth += contourWidth;
        }
    }

    public generateHorizontalInnerPanel(sideWardrobe: TFormSideWardrobe, contourIndex: number): IPanelData | undefined {
        let pointA: IPanelPointData;
        let pointB: IPanelPointData;
        let horizontalInnerPanel: IPanelData | undefined;
        let ratio: number;

        if (sideWardrobe.height >= this.technologMap.side.horizontalInnerPanelHeight) {
            ratio = ((this.technologMap.side.topSectionHeight + this.getTopPlinthHeight()) /
                (sideWardrobe.height - this.getBottomOutsoleHeight()));
            pointA = this.generatePanelPoint(
                this.contourPanels[contourIndex * 4],
                1 - ratio
            );
            pointB = this.generatePanelPoint(
                this.contourPanels[contourIndex * 4 + 2],
                ratio
            );
            horizontalInnerPanel = this.generateInnerPanelData(
                sideWardrobe.depth - this.technologMap.backFiller.depth - this.technologMap.door.container.depth,
                pointA,
                pointB,
                PANEL_TYPE_HORIZONTAL_SECTION
            );
            this.horizontalInnerPanels.push(horizontalInnerPanel);
        }

        return horizontalInnerPanel;
    }

    public generateTopPlinthInnerPanel(
        sideIndex: number,
        contourIndex: number,
        panelIndex: number): IPanelData {

        let sideWardrobe: TFormSideWardrobe;
        let panel: IPanelData;

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        if (this.getTopPlinthHeight() > 0) {
            panel = this.generateInnerPanel(
                this.contourPanels[panelIndex],
                this.contourPanels[panelIndex + 2],
                this.getTopPlinthHeight() / (sideWardrobe.height - this.getBottomOutsoleHeight()),
                {panel1: false, panel2: true},
                sideWardrobe,
                sideWardrobe.depth - this.technologMap.backFiller.depth,
                PANEL_TYPE_HORIZONTAL_SECTION,
                {
                    width: +this.technologMap.backFiller.depth,
                    depth: +this.technologMap.panel.depth,
                },
                false,
                {
                    pointA: this.sideContours[contourIndex - 1] ? 2 : 1,
                    pointB: this.sideContours[contourIndex + 1] ? 2 : 1
                }
            );
            this.topPlinthPanels[contourIndex] = panel;

            return panel;
        }

        return this.contourPanels[panelIndex + 1];
    }

    public generateBottomPlinthInnerPanel(
        sideIndex: number,
        contourIndex: number,
        panelIndex: number): IPanelData {

        let sideWardrobe: TFormSideWardrobe;
        let panel: IPanelData;

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        if (this.getBottomPlinthHeight() > 0) {
            panel = this.generateInnerPanel(
                this.contourPanels[panelIndex],
                this.contourPanels[panelIndex + 2],
                (this.getBottomPlinthHeight()) /
                (sideWardrobe.height - this.getBottomOutsoleHeight()),
                {panel1: true, panel2: false},
                sideWardrobe,
                sideWardrobe.depth - this.technologMap.backFiller.depth,
                PANEL_TYPE_HORIZONTAL_SECTION,
                {
                    width: +this.technologMap.backFiller.depth,
                    depth: 0,
                },
                false,
                {
                    pointA: this.sideContours[contourIndex - 1] ? 2 : 1,
                    pointB: this.sideContours[contourIndex + 1] ? 2 : 1
                }
            );
            this.bottomPlinthPanels[contourIndex] = panel;

            return panel;
        }

        return this.contourPanels[panelIndex + 3];
    }

    public generatePanelPoint(panel: IPanelData, ratio: number, connectionId?: number): IPanelPointData {
        let point: IPanelPointData;
        let pointSort: number = 1;

        connectionId = connectionId || 1;
        if (this.innerPanelPoints.length > 0) {
            pointSort = this.innerPanelPoints[this.innerPanelPoints.length - 1].sort + 1;
        }
        point = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            connectionId: connectionId,
            connectionType: CONNECTION_TYPE_DEFAULT,
            type: POINT_TYPE_PANEL,
            panelId: +panel.id,
            ratio: ratio,
        };
        this.innerPanelPoints.push(point);

        return point;
    }

    public generateInnerPanel(
        panel1: IPanelData,
        panel2: IPanelData,
        ratio: number,
        directions: { panel1: boolean, panel2: boolean },
        sideWardrobe: TFormSideWardrobe,
        width?: number,
        type?: TPanelType,
        shift?: TPanelShift,
        isPseudo?: boolean,
        pointConnections?: { pointA: number, pointB: number }
    ): IPanelData {
        let innerPanel: IPanelData;
        let pointA: IPanelPointData;
        let pointB: IPanelPointData;

        pointA = this.generatePanelPoint(
            panel1,
            directions.panel1 ? ratio : 1 - ratio,
            pointConnections && pointConnections.pointA ? pointConnections.pointA : undefined
        );
        pointB = this.generatePanelPoint(
            panel2,
            directions.panel2 ? ratio : 1 - ratio,
            pointConnections && pointConnections.pointB ? pointConnections.pointB : undefined
        );

        width = width || sideWardrobe.depth - this.technologMap.backFiller.depth - this.technologMap.door.container.depth;
        type = type || PANEL_TYPE_SECTION;
        innerPanel = this.generateInnerPanelData(
            width,
            pointA,
            pointB,
            type,
            shift,
            isPseudo
        );

        return innerPanel;
    }

    public generateInnerPanelData(
        width: number,
        pointA: IPanelPointData,
        pointB: IPanelPointData,
        type?: TPanelType,
        shift?: TPanelShift,
        isPseudo?: boolean,
        materialId?: number): IPanelData {
        let panelSort: number = 1;
        let innerPanel: IPanelData;
        let panelMaterialId: number;

        // TODO не пойму почему ругается
        // @ts-ignore
        type = type || PANEL_TYPE_DEFAULT;
        shift = shift || {
            width: +this.technologMap.backFiller.depth,
            depth: this.technologMap.panel.depth / 2,
        };
        if (this.innerPanels.length > 0) {
            panelSort = this.innerPanels[this.innerPanels.length - 1].sort + 1;
        }
        panelMaterialId = materialId || this.formData.materials.ldsp;

        innerPanel = {
            id: +(this.entityId++),
            sort: +(panelSort++),
            depth: +this.technologMap.panel.depth,
            width: width,
            pointA: +pointA.id,
            pointB: +pointB.id,
            shift: shift,
            type: type,
            materialId: panelMaterialId,
            directionType: this.calculatePanelDirectionType(pointA, pointB),
            isPseudo: isPseudo === true,
            contourPath: {
                points: [],
                segments: [],
            },
            butts: this.generateInnerPanelButts(isPseudo === true),
        };
        this.innerPanels.push(innerPanel);

        return innerPanel;
    }

    public generateInnerPanelButts(isPseudo: boolean): TPanelButt[] {
        if (isPseudo) {
            return [];
        } else {
            return [{segmentIndex: 2}];
        }

    }

    public calculatePanelDirectionType(
        pointA: IPanelPointData | IConnectionPointData,
        pointB: IPanelPointData | IConnectionPointData
    ): TPanelDirectionType {
        let positionA: TPoint3D;
        let positionB: TPoint3D;

        positionA = this.calculatePointPosition(pointA);
        positionB = this.calculatePointPosition(pointB);

        if (Math.abs(positionB.y - positionA.y) > Math.abs(positionB.x - positionA.x)) {
            return IMPORT_PANEL_DIRECTION_TYPE_VERTICAL;
        } else {
            return IMPORT_PANEL_DIRECTION_TYPE_HORIZONTAL;
        }
    }

    public generateInnerPanels(sideIndex: number): void {
        let sideWardrobe: TFormSideWardrobe;
        let horizontalInnerPanel: IPanelData | undefined;
        let bottomPanel: IPanelData;
        let topPanel: IPanelData;
        let index: number;
        let index2: number;
        let contourIndex: number;
        let contourCountSections: number;
        let ratio: number;

        if (this.formData.installationType === INSTALLATION_TYPE_ONLY_DOORS) {
            return;
        }

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        contourIndex = 0;
        for (index = 0; index < this.contourPanels.length; index += 4) {
            // Если закончился контур (он у нас из 4-х панелей состоит
            if (!this.contourPanels[index]) {
                break;
            }
            contourCountSections = this.sideContours[contourIndex].countSections;
            horizontalInnerPanel = this.generateHorizontalInnerPanel(sideWardrobe, contourIndex);
            bottomPanel = this.generateBottomPlinthInnerPanel(sideIndex, contourIndex, index);
            topPanel = this.generateTopPlinthInnerPanel(sideIndex, contourIndex, index);
            for (index2 = 0; index2 < contourCountSections - 1; index2++) {
                ratio = (this.technologMap.panel.depth + (index2 + 1) *
                    ((sideWardrobe.width - 2 * this.technologMap.panel.depth) / contourCountSections)) / sideWardrobe.width;
                if (horizontalInnerPanel) {
                    this.generateInnerPanel(bottomPanel, horizontalInnerPanel, ratio, {
                        panel1: this.getBottomPlinthHeight() > 0,
                        panel2: true
                    }, sideWardrobe);
                    this.generateInnerPanel(horizontalInnerPanel, topPanel, ratio, {
                        panel1: true,
                        panel2: true
                    }, sideWardrobe);
                } else {
                    this.generateInnerPanel(bottomPanel, topPanel, ratio, {
                        panel1: this.getBottomPlinthHeight() > 0,
                        panel2: true
                    }, sideWardrobe);
                }
            }
            contourIndex++;
        }
    }

    public generateContourPanel(
        width: number,
        pointA: IConnectionPointData,
        pointB: IConnectionPointData,
        shift: TPanelShift,
        isPseudo: boolean,
        depth?: number,
        materialId?: number): IPanelData {
        let panel: IPanelData;
        let panelSort: number = 1;
        let panelMaterialId: number;

        if (this.contourPanels.length > 0) {
            panelSort = this.contourPanels[this.contourPanels.length - 1].sort + 1;
        }
        depth = depth !== undefined && !isNaN(+depth) ? depth : +this.technologMap.panel.depth;
        panelMaterialId = materialId || this.formData.materials.ldsp;
        panel = {
            id: +(this.entityId++),
            sort: +(panelSort++),
            width: width,
            depth: depth,
            pointA: +pointA.id,
            pointB: +pointB.id,
            shift: shift,
            type: PANEL_TYPE_CONTOUR,
            directionType: this.calculatePanelDirectionType(pointA, pointB),
            isPseudo: isPseudo,
            materialId: panelMaterialId,
            butts: this.generateContourPanelButts(isPseudo)
        };
        this.contourPanels.push(panel);

        return panel;
    }

    public generateContourPanelButts(isPseudo: boolean): TPanelButt[] {
        if (isPseudo) {
            return [];
        }

        return [
            {segmentIndex: 1},
            {segmentIndex: 2},
            {segmentIndex: 3},
        ]
    }

    public generateContourPanels(sideIndex: number) {
        let sideWardrobe: TFormSideWardrobe;
        let isPseudo: boolean;
        let isPseudoItem: boolean;
        let index: number;
        let panelWidth: number;
        let leftPanelWidth: number;
        let rightPanelWidth: number;
        let itemDepth: number;

        if (!this.formData.sideWardrobes[sideIndex]) {
            throw new Error('Error in generateContourPanels: wrong sideIndex ' + sideIndex);
        }
        sideWardrobe = this.formData.sideWardrobes[sideIndex];

        isPseudo = this.formData.installationType === INSTALLATION_TYPE_ONLY_DOORS;
        panelWidth = sideWardrobe.depth - this.technologMap.backFiller.depth;
        for (index = 0; index < this.contourPoints.length; index += 4) {
            if (!this.contourPoints[index + 3]) {
                continue;
            }
            if (index === 0) { // First contour
                leftPanelWidth = panelWidth;
                isPseudoItem = !sideWardrobe.contourPanels.left;
            } else {
                leftPanelWidth = panelWidth - this.technologMap.door.container.depth;
                isPseudoItem = false;
            }
            itemDepth = ((!sideWardrobe.contourPanels.left) || isPseudo) ? 0 : +this.technologMap.panel.depth;

            this.generateContourPanel(
                leftPanelWidth,
                this.contourPoints[index],
                this.contourPoints[index + 1],
                {
                    depth: (isPseudo ? +this.technologMap.panel.depth : 0),
                    width: +this.technologMap.backFiller.depth,
                },
                isPseudo || isPseudoItem,
                itemDepth
            );
            this.generateContourPanel(
                panelWidth,
                this.contourPoints[index + 1],
                this.contourPoints[index + 2],
                {
                    depth: ([INSTALLATION_TYPE_ONLY_DOORS, INSTALLATION_TYPE_BUILTIN].indexOf(this.formData.installationType) !== -1 ?
                            (isPseudo ? +this.technologMap.panel.depth : 0) : 0
                    ),
                    width: +this.technologMap.backFiller.depth,
                },
                (this.getTopPlinthHeight() > 0) || isPseudo || (!sideWardrobe.contourPanels.top),
                (sideWardrobe.contourPanels.top && this.getTopPlinthHeight() > 0) ?
                    this.getTopPlinthHeight() :
                    (sideWardrobe.contourPanels.top && !isPseudo ? +this.technologMap.panel.depth : 0)
            );
            if (!this.contourPoints[index + 5]) { // Last contour
                rightPanelWidth = panelWidth;
                isPseudoItem = !sideWardrobe.contourPanels.right;
            } else {
                rightPanelWidth = panelWidth - this.technologMap.door.container.depth;
                isPseudoItem = false;
            }
            itemDepth = ((!sideWardrobe.contourPanels.right) || isPseudo) ? 0 : +this.technologMap.panel.depth;
            this.generateContourPanel(
                rightPanelWidth,
                this.contourPoints[index + 2],
                this.contourPoints[index + 3],
                {
                    depth: (isPseudo ? +this.technologMap.panel.depth : 0),
                    width: +this.technologMap.backFiller.depth,
                },
                isPseudo || isPseudoItem,
                itemDepth
            );
            this.generateContourPanel(
                panelWidth,
                this.contourPoints[index + 3],
                this.contourPoints[index],
                {
                    depth: ([INSTALLATION_TYPE_ONLY_DOORS, INSTALLATION_TYPE_BUILTIN].indexOf(this.formData.installationType) !== -1 ?
                            (isPseudo ? +this.technologMap.panel.depth : 0) : 0
                    ),
                    width: +this.technologMap.backFiller.depth,
                },
                this.getBottomPlinthHeight() > 0 || isPseudo || (!sideWardrobe.contourPanels.bottom),
                (sideWardrobe.contourPanels.bottom && this.getBottomPlinthHeight() > 0) ?
                    this.getBottomPlinthHeight() :
                    ((sideWardrobe.contourPanels.bottom && !isPseudo) ? +this.technologMap.panel.depth : 0)
            );
        }
    }

    public generateShelf(
        panel1: IPanelData,
        panel2: IPanelData,
        ratios: { ratio1: number, ratio2: number },
        isPortable: boolean,
        sideWardrobe: TFormSideWardrobe,
        materialId?: number): IShelfData {
        let shelf: IShelfData;
        let pointA: IPanelPointData;
        let pointB: IPanelPointData;
        let pointSort: number = 1;
        let shelfSort: number = 1;
        let shelfMaterialId: number;

        if (this.innerPanelPoints.length > 0) {
            pointSort = this.innerPanelPoints[this.innerPanelPoints.length - 1].sort + 1;
        }
        if (this.shelves.length > 0) {
            shelfSort = this.shelves[this.shelves.length - 1].sort + 1;
        }
        shelfMaterialId = materialId || this.formData.materials.ldsp;

        pointA = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            panelId: +panel1.id,
            type: POINT_TYPE_PANEL,
            connectionId: 1,
            connectionType: CONNECTION_TYPE_DEFAULT,
            ratio: ratios.ratio1,
        };
        this.innerPanelPoints.push(pointA);
        pointB = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            panelId: +panel2.id,
            type: POINT_TYPE_PANEL,
            connectionId: 1,
            connectionType: CONNECTION_TYPE_DEFAULT,
            ratio: ratios.ratio2,
        };
        this.innerPanelPoints.push(pointB);

        shelf = {
            id: +(this.entityId++),
            sort: +(shelfSort++),
            depth: +this.technologMap.panel.depth,
            width: sideWardrobe.depth -
                this.technologMap.backFiller.depth -
                this.technologMap.door.container.depth -
                this.technologMap.shelf.frontGap,
            pointA: +pointA.id,
            pointB: +pointB.id,
            shift: {
                width: +this.technologMap.backFiller.depth,
                depth: this.technologMap.panel.depth / 2,
            },
            type: PANEL_TYPE_SHELF,
            directionType: this.calculatePanelDirectionType(pointA, pointB),
            isPortable: isPortable,
            contourPath: {
                points: [],
                segments: [],
            },
            butts: this.generateShelfButts(isPortable),
            materialId: shelfMaterialId
        };

        this.shelves.push(shelf);

        return shelf;
    }

    public generateShelfButts(isPortable: boolean): TPanelButt[] {
        if (isPortable) {
            return [
                {segmentIndex: 0},
                {segmentIndex: 1},
                {segmentIndex: 2},
                {segmentIndex: 3},
            ];
        } else {
            return [
                {segmentIndex: 1},
                {segmentIndex: 2},
                {segmentIndex: 3},
            ];
        }
    }

    public getSectionVerticalPanels(
        sideWardrobe: TFormSideWardrobe,
        sectionIndex: number
    ): { panel1: IPanelData, panel2: IPanelData } {
        let contourIndex: number;
        let panel1: IPanelData;
        let panel2: IPanelData;
        let sideContour: TSideContour;
        let skipPanelCount: number;

        contourIndex = this.getContourIndexBySectionIndex(+sectionIndex);
        sideContour = this.sideContours[contourIndex];
        skipPanelCount = 0;
        if (this.bottomPlinthPanels[contourIndex]) {
            skipPanelCount++;
        }
        if (this.topPlinthPanels[contourIndex]) {
            skipPanelCount++;
        }
        if (this.horizontalInnerPanels[contourIndex]) {
            panel1 = (sectionIndex === sideContour.firstSectionIndex) ?
                this.contourPanels[(contourIndex * 4)] :
                this.innerPanels[(+sectionIndex * 2) - 1 + (contourIndex > 0 ? contourIndex * skipPanelCount - 1 : 0) + skipPanelCount];
            panel2 = (sectionIndex === sideContour.lastSectionIndex) ?
                this.contourPanels[(contourIndex * 4) + 2] :
                this.innerPanels[(+sectionIndex * 2) + 1 + (contourIndex > 0 ? contourIndex * skipPanelCount - 1 : 0) + skipPanelCount];

        } else {
            panel1 = (sectionIndex === sideContour.firstSectionIndex) ?
                this.contourPanels[(contourIndex * 4)] :
                this.innerPanels[+sectionIndex - 1 + (contourIndex > 0 ? contourIndex * skipPanelCount - 1 : 0) + skipPanelCount];
            panel2 = (sectionIndex === sideContour.lastSectionIndex) ?
                this.contourPanels[(contourIndex * 4) + 2] :
                this.innerPanels[+sectionIndex + (contourIndex > 0 ? contourIndex * skipPanelCount - 1 : 0) + skipPanelCount];
        }
        return {panel1: panel1, panel2: panel2};
    }

    public calculateSectionShelfHeight(
        sideWardrobe: TFormSideWardrobe,
        section: TWardrobeSection,
        contourIndex: number
    ): number {

        let shelfHeight: number;
        let sectionHeight: number;

        sectionHeight = (sideWardrobe.height -
            this.getBottomOutsoleHeight() -
            (this.horizontalInnerPanels[contourIndex] ? this.technologMap.side.topSectionHeight : 0) -
            this.getBottomPlinthHeight() -
            this.getTopPlinthHeight());
        if ((sectionHeight - (this.technologMap.drawer.height * section.drawers.count)) / (section.shelves.count + 1) < this.technologMap.shelf.height.min) {
            debugger;
        }
        shelfHeight = Math.round((sectionHeight - (this.technologMap.drawer.height * section.drawers.count)) / (section.shelves.count + 1));

        shelfHeight = shelfHeight < +this.technologMap.shelf.defaultHeight ?
            shelfHeight : +this.technologMap.shelf.defaultHeight;

        return shelfHeight;
    }

    public getTopPlinthHeight(): number {
        return (INSTALLATION_TYPE_SEPARATE === this.formData.installationType &&
        this.formData.topPlinth > 0 ? +this.formData.topPlinth : 0);
    }

    public getBottomPlinthHeight(): number {
        return (INSTALLATION_TYPE_SEPARATE === this.formData.installationType &&
        this.formData.bottomPlinth > 0 ? +this.formData.bottomPlinth : 0);
    }

    public getBottomOutsoleHeight(): number {
        return (this.formData.installationType === INSTALLATION_TYPE_SEPARATE ? +this.technologMap.outsole.height : 0);
    }

    public getContourIndexBySectionIndex(sectionIndex: number): number {
        let index: string;

        for (index in this.sideContours) {
            if (sectionIndex >= this.sideContours[index].firstSectionIndex &&
                sectionIndex <= this.sideContours[index].lastSectionIndex) {
                return +index;
            }
        }

        return 0;
    }

    public generateShelves(sideIndex: number): void {
        let sideWardrobe: TFormSideWardrobe;
        let section: TWardrobeSection;
        let sectionIndex: string;
        let index: number;
        let panels: { panel1: IPanelData; panel2: IPanelData };
        let ratio1: number;
        let ratio2: number;
        let shelfHeight: number;
        let contourIndex: number;
        let sideContour: TSideContour;
        let contourPanelHeight: number;
        let innerPanelHeight: number;
        let startShelfHeight: number;

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        if (!sideWardrobe) {
            return;
        }
        if (this.formData.installationType === INSTALLATION_TYPE_ONLY_DOORS) {
            return;
        }
        for (sectionIndex in sideWardrobe.sections) {
            section = sideWardrobe.sections[sectionIndex];
            panels = this.getSectionVerticalPanels(sideWardrobe, +sectionIndex);
            contourIndex = this.getContourIndexBySectionIndex(+sectionIndex);
            sideContour = this.sideContours[contourIndex];
            shelfHeight = this.calculateSectionShelfHeight(sideWardrobe, section, contourIndex);
            startShelfHeight = this.technologMap.drawer.height * section.drawers.count;
            contourPanelHeight = sideWardrobe.height - this.getBottomOutsoleHeight();
            innerPanelHeight = sideWardrobe.height -
                this.getBottomOutsoleHeight() -
                this.getBottomPlinthHeight() -
                this.getTopPlinthHeight() -
                (this.horizontalInnerPanels[contourIndex] ? this.technologMap.side.topSectionHeight : 0);
            for (index = 0; index < section.shelves.count; index++) {
                if (+sectionIndex === sideContour.firstSectionIndex) {
                    ratio1 = (this.getBottomPlinthHeight() + ((index) * shelfHeight + startShelfHeight + this.technologMap.shelf.defaultHeight)) /
                        contourPanelHeight;
                } else {
                    ratio1 = ((index) * shelfHeight + startShelfHeight + this.technologMap.shelf.defaultHeight) /
                        innerPanelHeight;
                }
                if (+sectionIndex === sideContour.lastSectionIndex) {
                    ratio2 = (this.getBottomPlinthHeight() + ((index) * shelfHeight + startShelfHeight + this.technologMap.shelf.defaultHeight)) /
                        contourPanelHeight;
                } else {
                    ratio2 = ((index) * shelfHeight + startShelfHeight + this.technologMap.shelf.defaultHeight) /
                        innerPanelHeight;
                }
                if ((+sectionIndex === sideContour.lastSectionIndex)) {
                    ratio2 = 1 - ratio2;
                }
                this.generateShelf(
                    panels.panel1,
                    panels.panel2,
                    {ratio1: ratio1, ratio2: ratio2},
                    true,
                    sideWardrobe
                );
            }
        }
    }

    public generateRods(sideIndex: number): void {
        let sideWardrobe: TFormSideWardrobe;
        let section: TWardrobeSection;
        let sectionIndex: string;
        let index: number;
        let panels: { panel1: IPanelData; panel2: IPanelData };
        let ratio1: number;
        let ratio2: number;
        let contourPanelHeight: number;
        let innerPanelHeight: number;
        let contourIndex: number;
        let sideContour: TSideContour;
        let contourTopRodHeight: number;
        let innerTopRodHeight: number;

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        if (!sideWardrobe) {
            return;
        }
        if (this.formData.installationType === INSTALLATION_TYPE_ONLY_DOORS) {
            return;
        }

        for (sectionIndex in sideWardrobe.sections) {
            section = sideWardrobe.sections[sectionIndex];
            if (section.rods.count > 0) {
                contourIndex = this.getContourIndexBySectionIndex(+sectionIndex);
                sideContour = this.sideContours[contourIndex];
                contourPanelHeight = sideWardrobe.height - this.getBottomOutsoleHeight();
                innerPanelHeight = sideWardrobe.height -
                    this.getBottomPlinthHeight() -
                    this.getTopPlinthHeight() -
                    (this.horizontalInnerPanels[contourIndex] ? this.technologMap.side.topSectionHeight : 0);
                contourTopRodHeight = (contourPanelHeight -
                    this.getTopPlinthHeight() -
                    (this.horizontalInnerPanels[contourIndex] ? this.technologMap.side.topSectionHeight : 0) -
                    this.technologMap.rod.topGap);
                innerTopRodHeight = (innerPanelHeight - this.technologMap.rod.topGap);
                panels = this.getSectionVerticalPanels(sideWardrobe, +sectionIndex);
                for (index = 0; index < section.rods.count; index++) {
                    if (+sectionIndex === sideContour.firstSectionIndex) {
                        ratio1 = contourTopRodHeight / ((index === 0 ? 1 : 2) * contourPanelHeight);
                    } else {
                        ratio1 = innerTopRodHeight / ((index === 0 ? 1 : 2) * innerPanelHeight);
                    }
                    if (+sectionIndex === sideContour.lastSectionIndex) {
                        ratio2 = contourTopRodHeight / ((index === 0 ? 1 : 2) * contourPanelHeight);
                    } else {
                        ratio2 = innerTopRodHeight / ((index === 0 ? 1 : 2) * innerPanelHeight);
                    }
                    if (+sectionIndex === sideContour.lastSectionIndex) {
                        ratio2 = 1 - ratio2;
                    }
                    this.generateRod(panels.panel1, panels.panel2, {ratio1: ratio1, ratio2: ratio2}, sideWardrobe);
                }
            }
        }
    }

    public generateRod(
        panel1: IPanelData,
        panel2: IPanelData,
        ratios: { ratio1: number, ratio2: number },
        sideWardrobe: TFormSideWardrobe): void {
        let rod: IRodData;
        let pointA: IPanelPointData;
        let pointB: IPanelPointData;
        let pointSort: number = 1;
        let rodSort: number = 1;

        if (this.innerPanelPoints.length > 0) {
            pointSort = this.innerPanelPoints[this.innerPanelPoints.length - 1].sort + 1;
        }
        if (this.rods.length > 0) {
            rodSort = this.rods[this.rods.length - 1].sort + 1;
        }
        pointA = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            panelId: +panel1.id,
            type: POINT_TYPE_PANEL,
            connectionId: 1,
            connectionType: CONNECTION_TYPE_DEFAULT,
            ratio: ratios.ratio1,
        };
        this.innerPanelPoints.push(pointA);
        pointB = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            panelId: +panel2.id,
            type: POINT_TYPE_PANEL,
            connectionId: 1,
            connectionType: CONNECTION_TYPE_DEFAULT,
            ratio: ratios.ratio2,
        };
        this.innerPanelPoints.push(pointB);

        rod = {
            id: +(this.entityId++),
            sort: +(rodSort++),
            pointA: +pointA.id,
            pointB: +pointB.id,
            shift: Math.round(sideWardrobe.depth -
                this.technologMap.backFiller.depth -
                this.technologMap.door.container.depth) / 2,
            radius: this.technologMap.rod.radius,
            width: this.technologMap.rod.radius*2
        };

        this.rods.push(rod);
    }

    public generateDrawers(sideIndex: number): void {
        let sideWardrobe: TFormSideWardrobe;
        let section: TWardrobeSection;
        let sectionIndex: string;
        let index: number;
        let panels: { panel1: IPanelData; panel2: IPanelData };
        let ratio1: number;
        let ratio2: number;
        let contourPanelHeight: number;
        let innerPanelHeight: number;
        let drawerPositionHeight: number;
        let contourIndex: number;
        let sideContour: TSideContour;

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        if (!sideWardrobe) {
            return;
        }
        if (this.formData.installationType === INSTALLATION_TYPE_ONLY_DOORS) {
            return;
        }
        for (sectionIndex in sideWardrobe.sections) {
            section = sideWardrobe.sections[sectionIndex];
            if (section.drawers.count > 0) {
                contourIndex = this.getContourIndexBySectionIndex(+sectionIndex);
                sideContour = this.sideContours[contourIndex];
                contourPanelHeight = sideWardrobe.height - this.getBottomOutsoleHeight();
                innerPanelHeight = sideWardrobe.height -
                    this.getBottomPlinthHeight() -
                    this.getTopPlinthHeight() -
                    (this.horizontalInnerPanels[contourIndex] ? this.technologMap.side.topSectionHeight : 0);
                panels = this.getSectionVerticalPanels(sideWardrobe, +sectionIndex);
                for (index = 0; index < section.drawers.count; index++) {
                    drawerPositionHeight = this.technologMap.drawer.bottomShelfHeight +
                        (index * this.technologMap.drawer.height);
                    if (+sectionIndex === sideContour.firstSectionIndex) {
                        ratio1 = (this.getBottomPlinthHeight() - this.getBottomOutsoleHeight() + drawerPositionHeight) /
                            (contourPanelHeight);
                    } else {
                        ratio1 = (drawerPositionHeight) / (innerPanelHeight);
                    }
                    if (+sectionIndex === sideContour.lastSectionIndex) {
                        ratio2 = (this.getBottomPlinthHeight() - this.getBottomOutsoleHeight() + drawerPositionHeight) /
                            (contourPanelHeight);
                    } else {
                        ratio2 = (drawerPositionHeight) / (innerPanelHeight);
                    }
                    if (+sectionIndex === sideContour.lastSectionIndex) {
                        ratio2 = 1 - ratio2;
                    }
                    this.generateDrawer(
                        panels.panel1,
                        panels.panel2,
                        {ratio1: ratio1, ratio2: ratio2},
                        sideWardrobe,
                        this.formData.materials.ldsp,
                        this.formData.materials.dvp
                    );
                }
            }
        }
    }

    public generateDrawer(
        panel1: IPanelData,
        panel2: IPanelData,
        ratios: { ratio1: number, ratio2: number },
        sideWardrobe: TFormSideWardrobe,
        panelMaterial: number,
        bottomMaterial: number
    ): void {
        let drawer: IDrawerData;
        let pointA: IPanelPointData;
        let pointB: IPanelPointData;
        let pointSort: number = 1;

        if (this.innerPanelPoints.length > 0) {
            pointSort = this.innerPanelPoints[this.innerPanelPoints.length - 1].sort + 1;
        }

        pointA = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            panelId: +panel1.id,
            type: POINT_TYPE_PANEL,
            connectionId: 1,
            connectionType: CONNECTION_TYPE_DEFAULT,
            ratio: ratios.ratio1,
        };
        this.innerPanelPoints.push(pointA);
        pointB = {
            id: +(this.entityId++),
            sort: +(pointSort++),
            panelId: +panel2.id,
            type: POINT_TYPE_PANEL,
            connectionId: 1,
            connectionType: CONNECTION_TYPE_DEFAULT,
            ratio: ratios.ratio2,
        };
        this.innerPanelPoints.push(pointB);

        drawer = {
            id: +(this.entityId++),
            pointA: +pointA.id,
            pointB: +pointB.id,
            height: +this.technologMap.drawer.height,
            panelMaterial: panelMaterial,
            bottomMaterial: bottomMaterial,
            depth: sideWardrobe.depth -
                this.technologMap.backFiller.depth -
                this.technologMap.door.container.depth -
                this.technologMap.drawer.gap.back -
                this.technologMap.drawer.gap.front,
            box: {
                sides: {
                    depth: +this.technologMap.drawer.sides.depth,
                    indention: 0,
                },
                height: +this.technologMap.drawer.height - this.technologMap.drawer.boxGap.top - this.technologMap.drawer.boxGap.bottom,
                bottom: {
                    depth: +this.technologMap.drawer.bottom.depth
                },
                rails: {
                    length: this.calculateDrawerRailLength(),
                    height: 20,
                    width: 13
                },
                position: {
                    x: 0,
                    y: 0,
                    z: 0
                }
            },
            shift: {
                height: 0,
                depth: 0
            },
            facade: {
                depth: +this.technologMap.drawer.facade.depth,
                width: +this.technologMap.drawer.facade.gap.left,
                height: +this.technologMap.drawer.facade.gap.bottom,
                position: {
                    x: 0,
                    y: 0,
                    z: 0
                }
            },
            panels: [],
            bottomPanels: [],
            facadePanels: [],
        };

        this.drawers.push(drawer);
    }

    public calculateDrawerRailLength() {
        return 450;
    }

    public generateDoorContainer(sideIndex: number): IDoorContainerData | undefined {
        let contourPanels: IPanelData[];
        let index: number;
        let sideWardrobe: TFormSideWardrobe;
        let segments: TDoorContainerSegmentData[];
        let rails: IRailData[];

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        contourPanels = [];
        for (index = 0; index < this.sideContours.length; index++) {
            if (index === 0) {
                contourPanels.push(this.contourPanels[(index * 4)]);
            }
            contourPanels.push(this.contourPanels[(index * 4) + 1]);
            if (!this.sideContours[index + 1]) {
                contourPanels.push(this.contourPanels[(index * 4) + 2]);
            }
        }
        for (index = this.sideContours.length - 1; index >= 0; index--) {
            contourPanels.push(this.contourPanels[(+index * 4) + 3]);
        }
        if (contourPanels.length <= 0) {
            return undefined;
        }
        segments = this.generateDoorContainerSegments(contourPanels);
        rails = this.generateRails();
        return {
            id: +(this.entityId++),
            segments: segments,
            rails: rails,
            doors: this.generateDoors(sideWardrobe, segments),
        };
    }

    public generateDoorContainerSegments(segments: IPanelData[]): TDoorContainerSegmentData[] {
        let sort: number = 1;
        let index: string;
        let currentSegment: IPanelData;
        let prevSegment: IPanelData;
        let nextSegment: IPanelData;
        let fillerSegments: TDoorContainerSegmentData[] = [];

        for (index in segments) {
            currentSegment = segments[index];
            prevSegment = (segments[+index - 1]) ? segments[+index - 1] : segments[segments.length - 1];
            nextSegment = (segments[+index + 1]) ? segments[+index + 1] : segments[0];
            fillerSegments.push({
                segmentId: +currentSegment.id,
                direction: this.isSegmentFillerCoDirection(currentSegment, prevSegment, nextSegment),
                sort: +(sort++),
                connectionId: this.calculateDoorContainerConnectionId(currentSegment),
                type: this.calculateDoorContainerSegmentType(currentSegment),
            })
        }

        return fillerSegments;
    }

    /**
     * Метод расчитан на то, что в контуре только 4 панели
     * @param panel
     */
    public calculateDoorContainerSegmentType(panel: IPanelData): TContourPositionType {
        let index: string;

        for (index in this.contourPanels) {
            if (this.contourPanels[index].id === panel.id) {
                if (+index % 4 === 0) {
                    return CONTOUR_POSITION_TYPE_LEFT;
                }
                if (+index % 4 === 1) {
                    return CONTOUR_POSITION_TYPE_TOP;
                }
                if (+index % 4 === 2) {
                    return CONTOUR_POSITION_TYPE_RIGHT;
                }
                if (+index % 4 === 3) {
                    return CONTOUR_POSITION_TYPE_BOTTOM;
                }
            }
        }
        throw new Error('Not found contour panel type');
    }

    public calculateDoorContainerConnectionId(panel: IPanelData): number {
        return 12;
    }

    public generateBackFiller(segments: IPanelData[], materialId?: number): IFillerData {
        let sort: number = 1;
        let backFiller: IFillerData;
        let fillerMaterialId: number;

        if (this.backFillers.length > 0) {
            sort = this.backFillers[this.backFillers.length - 1].sort + 1;
        }
        fillerMaterialId = materialId || this.formData.materials.dvp;

        backFiller = {
            id: +(this.entityId++),
            sort: +(sort++),
            shift: {width: 0, depth: 0},
            depth: +this.technologMap.backFiller.depth,
            materialId: fillerMaterialId,
            segments: this.generateBackFillerSegments(segments),
        };
        this.backFillers.push(backFiller);

        return backFiller;
    }

    public generateBackFillerSegments(segments: IPanelData[]): TFillerSegmentData[] {
        let sort: number = 1;
        let index: string;
        let currentSegment: IPanelData;
        let prevSegment: IPanelData;
        let nextSegment: IPanelData;
        let fillerSegments: TFillerSegmentData[] = [];

        for (index in segments) {
            currentSegment = segments[index];
            prevSegment = (segments[+index - 1]) ? segments[+index - 1] : segments[segments.length - 1];
            nextSegment = (segments[+index + 1]) ? segments[+index + 1] : segments[0];
            fillerSegments.push({
                segmentId: +currentSegment.id,
                direction: this.isSegmentFillerCoDirection(currentSegment, prevSegment, nextSegment),
                sort: +(sort++),
                connectionId: this.calculateBackFillerConnectionId(currentSegment),
            })
        }

        return fillerSegments;
    }

    public isSegmentFillerCoDirection(segment: IPanelData, prevSegment: IPanelData, nextSegment: IPanelData): boolean {
        let fillerPointA: TPoint2D | undefined;
        let fillerPointB: TPoint2D | undefined;
        let panelPoints1: TPanelPointsPosition;
        let panelPoints2: TPanelPointsPosition;
        let line1: TLine;
        let line2: TLine;
        panelPoints1 = this.calculatePanelPoints(segment);
        panelPoints2 = this.calculatePanelPoints(prevSegment);
        line1 = {pointA: Geometry.point3Dto2D(panelPoints1.pointA), pointB: Geometry.point3Dto2D(panelPoints1.pointB)};
        line2 = {pointA: Geometry.point3Dto2D(panelPoints2.pointA), pointB: Geometry.point3Dto2D(panelPoints2.pointB)};

        fillerPointA = Geometry.getIntersectionPoint(line1, line2);
        if (!fillerPointA && Geometry.isEqualLines(
            Geometry.directEquation(line1.pointA, line1.pointB),
            Geometry.directEquation(line2.pointA, line2.pointB))) {
            fillerPointA = Geometry.getNearPoint2D(line1.pointA, [line2.pointA, line2.pointB]);
        }
        panelPoints2 = this.calculatePanelPoints(nextSegment);
        line2 = {pointA: Geometry.point3Dto2D(panelPoints2.pointA), pointB: Geometry.point3Dto2D(panelPoints2.pointB)};
        fillerPointB = Geometry.getIntersectionPoint(line1, line2);
        if (!fillerPointB && Geometry.isEqualLines(
            Geometry.directEquation(line1.pointA, line1.pointB),
            Geometry.directEquation(line2.pointA, line2.pointB))) {
            fillerPointB = Geometry.getNearPoint2D(line1.pointA, [line2.pointA, line2.pointB]);
        }
        if (fillerPointA && fillerPointB) {
            return Geometry.isCoDirectionVectors(
                {x: fillerPointB.x - fillerPointA.x, y: fillerPointB.y - fillerPointA.y},
                {x: panelPoints1.pointB.x - panelPoints1.pointA.x, y: panelPoints1.pointB.y - panelPoints1.pointA.y}
            )
        }

        return true;
    }


    public calculateBackFillerConnectionId(panel: IPanelData): number {
        switch (panel.type) {
            case PANEL_TYPE_CONTOUR:
                return 5;
            case PANEL_TYPE_SHELF:
            case PANEL_TYPE_SECTION:
            case PANEL_TYPE_HORIZONTAL_SECTION:
            default:
                return 6;
        }
    }

    public calculatePanelPoints(panel: IPanelData): TPanelPointsPosition {
        let pointA: IConnectionPointData | IPanelPointData;
        let pointB: IConnectionPointData | IPanelPointData;

        pointA = this.findPointById(panel.pointA);
        pointB = this.findPointById(panel.pointB);

        return {
            pointA: this.calculatePointPosition(pointA),
            pointB: this.calculatePointPosition(pointB)
        };
    }

    public calculateDoorPoints(panel: IProfileData): TPanelPointsPosition {
        let pointA: IDoorPointData | IProfilePointData;
        let pointB: IDoorPointData | IProfilePointData;

        pointA = this.findDoorPointById(panel.pointA);
        pointB = this.findDoorPointById(panel.pointB);

        return {
            pointA: this.calculateDoorPointPosition(pointA),
            pointB: this.calculateDoorPointPosition(pointB)
        };
    }

    public findDoorPointById(pointId: number): IDoorPointData | IProfilePointData {
        let point: IDoorPointData | IProfilePointData;
        for (point of this.doorContourPoints) {
            if (point.id === pointId) {
                return point;
            }
        }
        for (point of this.doorProfilePoints) {
            if (point.id === pointId) {
                return point;
            }
        }

        throw new Error('ProjectGenerator not found point by id ' + pointId)
    }


    public calculateDoorPointPosition(point: IDoorPointData | IProfilePointData): TPoint3D {
        let parentProfile: IProfileData;
        let points: TPanelPointsPosition;
        let resultPoint: TPoint3D;

        if (!this.doorContainerSizes) {
            throw new Error('Error calculateDoorPointPosition');
        }
        if ("profileId" in point) {
            parentProfile = this.findProfileById(point.profileId);
            points = this.calculateProfilePoints(parentProfile);
            resultPoint = Geometry.getPointByRatio(points.pointA, points.pointB, point.ratio, false);
        } else {

            switch (point.level) {
                case LEVEL_BOTTOM:
                    resultPoint = Geometry.getPointByRatio(
                        {x: 0, y: this.technologMap.door.verticalGap.bottom, z: 0},
                        {x: this.doorContainerSizes.width, y: this.technologMap.door.verticalGap.bottom, z: 0},
                        point.ratio,
                        false);
                    break;
                case LEVEL_TOP:
                    resultPoint = Geometry.getPointByRatio(
                        {x: 0, y: this.doorContainerSizes.height - this.technologMap.door.verticalGap.top, z: 0},
                        {
                            x: this.doorContainerSizes.width,
                            y: this.doorContainerSizes.height - this.technologMap.door.verticalGap.top,
                            z: 0
                        },
                        point.ratio,
                        false);
                    break;
            }
        }

        return resultPoint;
    }

    public calculateProfilePoints(profile: IProfileData): TPanelPointsPosition {
        let pointA: IDoorPointData | IProfilePointData;
        let pointB: IDoorPointData | IProfilePointData;

        pointA = this.findDoorPointById(profile.pointA);
        pointB = this.findDoorPointById(profile.pointB);

        return {
            pointA: this.calculateDoorPointPosition(pointA),
            pointB: this.calculateDoorPointPosition(pointB)
        };
    }

    public findProfileById(profileId: number): IProfileData {
        let profile: IProfileData;
        for (profile of this.doorContourProfiles) {
            if (profile.id === profileId) {
                return profile;
            }
        }
        for (profile of this.doorInnerProfiles) {
            if (profile.id === profileId) {
                return profile;
            }
        }

        throw new Error('ProjectGenerator not found panel by id ' + profileId)
    }


    public calculatePointPosition(point: IConnectionPointData | IPanelPointData): TPoint3D {
        if ("value" in point) {
            return {...point.value};
        } else if ("ratio" in point) {
            return this.calculatePointPositionByParent(point);
        }

        throw new Error('ProjectGenerator calculatePointPosition error ');
    }

    public calculatePointPositionByParent(point: IPanelPointData): TPoint3D {
        let parentPanel: IPanelData;
        let points: TPanelPointsPosition;

        parentPanel = this.findPanelById(point.panelId);
        points = this.calculatePanelPoints(parentPanel);

        return Geometry.getPointByRatio(points.pointA, points.pointB, point.ratio, false);
    }

    public findPanelById(panelId: number): IPanelData {
        let panel: IPanelData;
        for (panel of this.contourPanels) {
            if (panel.id === panelId) {
                return panel;
            }
        }
        for (panel of this.innerPanels) {
            if (panel.id === panelId) {
                return panel;
            }
        }

        throw new Error('ProjectGenerator not found panel by id ' + panelId)
    }

    public findPointById(pointId: number): IConnectionPointData | IPanelPointData {
        let point: IConnectionPointData | IPanelPointData;
        for (point of this.contourPoints) {
            if (point.id === pointId) {
                return point;
            }
        }
        for (point of this.innerPanelPoints) {
            if (point.id === pointId) {
                return point;
            }
        }

        throw new Error('ProjectGenerator not found point by id ' + pointId)
    }

    public generateBackFillers(): void {
        let contourIndex: string;
        let index: number;
        let index2: number;
        let contourCountSections: number;
        let horizontalInnerPanel: IPanelData;
        let topPanel: IPanelData;
        let lastInnerPanelIndex: number;
        let innerIndexGap: number;

        if (this.formData.installationType !== INSTALLATION_TYPE_SEPARATE) {
            return;
        }
        lastInnerPanelIndex = 0;
        for (contourIndex in this.sideContours) {
            horizontalInnerPanel = this.horizontalInnerPanels[contourIndex];
            contourCountSections = this.sideContours[contourIndex].countSections;
            if (horizontalInnerPanel) {
                this.generateBackFiller(
                    [
                        this.contourPanels[(+contourIndex * 4)],
                        this.contourPanels[(+contourIndex * 4) + 1],
                        this.contourPanels[(+contourIndex * 4) + 2],
                        horizontalInnerPanel
                    ],
                    this.formData.materials.dvp
                );
                topPanel = horizontalInnerPanel;
                innerIndexGap = 1;
            } else {
                topPanel = this.contourPanels[(+contourIndex * 4) + 1];
                innerIndexGap = 0;
            }
            if (this.getBottomPlinthHeight() > 0) {
                lastInnerPanelIndex++;
            }
            if (this.getTopPlinthHeight() > 0) {
                lastInnerPanelIndex++;
            }
            index2 = lastInnerPanelIndex;
            for (index = +contourIndex; index < (+contourIndex + contourCountSections); index++) {
                this.generateBackFiller(
                    [
                        (index === +contourIndex ?
                            this.contourPanels[(+contourIndex * 4)] : this.innerPanels[index + (index2 - 1)]),
                        topPanel,
                        (index < +contourIndex + contourCountSections - 1 ?
                            this.innerPanels[index + index2 + innerIndexGap] : this.contourPanels[(+contourIndex * 4) + 2]),
                        this.contourPanels[(+contourIndex * 4) + 3]
                    ],
                    this.formData.materials.dvp
                );
                if (horizontalInnerPanel) {
                    index2++;
                }
            }
            lastInnerPanelIndex += ((horizontalInnerPanel) ? (contourCountSections - 1) * 2 : (contourCountSections - 2));
        }
    }

    public generateBottomPlinth(sideIndex: number): void {
        let sideWardrobe: TFormSideWardrobe;
        let contourIndex: string;
        let index: number;
        let pointA: IConnectionPointData;
        let pointB: IConnectionPointData;
        let bottomPanel: IPanelData;
        let topPanel: IPanelData;
        let sectionCount: number;
        let sectionWidth: number;
        let ratio: number;
        let shift: TPanelShift;
        let width: number;

        if (this.formData.installationType !== INSTALLATION_TYPE_SEPARATE) {
            return;
        }
        if (this.getBottomPlinthHeight() <= this.technologMap.bottomPlinth.minHeight) {
            return;
        }
        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        for (contourIndex in this.sideContours) {
            bottomPanel = this.contourPanels[(+contourIndex * 4) + 3];
            topPanel = this.bottomPlinthPanels[contourIndex];
            if (!bottomPanel || !topPanel) {
                continue;
            }
            pointA = this.contourPoints[(+contourIndex * 4)];
            pointB = this.contourPoints[(+contourIndex * 4) + 3];
            if (!pointA || !pointB) {
                continue;
            }
            sectionCount = Math.round(this.sideContours[contourIndex].width / this.technologMap.bottomPlinth.sectionWidth);
            sectionWidth = this.sideContours[contourIndex].width / sectionCount;
            ratio = 0;
            width = sideWardrobe.depth - this.technologMap.backFiller.depth - this.technologMap.panel.depth;
            for (index = 0; index <= sectionCount; index++) {
                if ((index === 0 &&
                    pointA.connectionType !== CONNECTION_TYPE_MAIN_LEFT) ||
                    (index === sectionCount &&
                        pointB.connectionType !== CONNECTION_TYPE_MAIN_RIGHT)) {
                    continue;
                }
                if (index === 0) {
                    // Надо делать первую ножку
                    ratio = 0;
                    shift = {width: this.technologMap.backFiller.depth, depth: 0};

                } else if (index === sectionCount) {
                    // Надо делать последнюю ножку
                    ratio = 1;
                    shift = {width: this.technologMap.backFiller.depth, depth: this.technologMap.panel.depth};
                } else {
                    // делаем среднюю ножку
                    ratio = (index * sectionWidth) / this.sideContours[contourIndex].width;
                    shift = {width: this.technologMap.backFiller.depth, depth: this.technologMap.panel.depth / 2};
                }
                this.generateInnerPanel(
                    bottomPanel,
                    topPanel,
                    ratio,
                    {panel1: false, panel2: true},
                    sideWardrobe,
                    width,
                    PANEL_TYPE_DEFAULT,
                    shift,
                    false,
                    {
                        pointA: 5,
                        pointB: 1
                    }
                );
            }
            this.generateFrontFiller(sideWardrobe,
                +contourIndex,
                [
                    this.contourPanels[(+contourIndex * 4)],
                    topPanel,
                    this.contourPanels[(+contourIndex * 4) + 2],
                    this.contourPanels[(+contourIndex * 4) + 3]
                ]);
        }
    }

    public generateTopPlinth(sideIndex: number): void {
        let contourIndex: string;
        let sideWardrobe: TFormSideWardrobe;

        sideWardrobe = this.formData.sideWardrobes[sideIndex];
        for (contourIndex in this.sideContours) {
            if (!this.topPlinthPanels[contourIndex]) {
                continue;
            }
            this.generateFrontFiller(sideWardrobe,
                +contourIndex,
                [
                    this.contourPanels[(+contourIndex * 4)],
                    this.contourPanels[(+contourIndex * 4) + 1],
                    this.contourPanels[(+contourIndex * 4) + 2],
                    this.topPlinthPanels[contourIndex]
                ]);
        }
    }

    public generateFrontFillers(): IFillerData[] {
        return [...this.frontFillers];
    }

    public generateFrontFiller(
        sideWardrobe: TFormSideWardrobe,
        contourIndex: number,
        segments: IPanelData[],
        materialId?: number
    ): IFillerData {

        let sort: number = 1;
        let frontFiller: IFillerData;
        let fillerMaterialId: number;

        if (this.frontFillers.length > 0) {
            sort = this.frontFillers[this.frontFillers.length - 1].sort + 1;
        }
        fillerMaterialId = materialId || this.formData.materials.ldsp;

        frontFiller = {
            id: +(this.entityId++),
            sort: +(sort++),
            shift: {width: 0, depth: sideWardrobe.depth - this.technologMap.panel.depth},
            depth: +this.technologMap.panel.depth,
            materialId: fillerMaterialId,
            segments: this.generateFrontFillerSegments(contourIndex, segments),
        };
        this.frontFillers.push(frontFiller);

        return frontFiller;
    }

    public generateFrontFillerSegments(contourIndex: number, segments: IPanelData[]): TFillerSegmentData[] {
        let sort: number = 1;
        let index: string;
        let currentSegment: IPanelData;
        let prevSegment: IPanelData;
        let nextSegment: IPanelData;
        let fillerSegments: TFillerSegmentData[] = [];

        for (index in segments) {
            currentSegment = segments[index];
            prevSegment = (segments[+index - 1]) ? segments[+index - 1] : segments[segments.length - 1];
            nextSegment = (segments[+index + 1]) ? segments[+index + 1] : segments[0];
            fillerSegments.push({
                segmentId: +currentSegment.id,
                direction: this.isSegmentFillerCoDirection(currentSegment, prevSegment, nextSegment),
                sort: +(sort++),
                connectionId: this.calculateFrontFillerConnectionId(contourIndex, +index, currentSegment),
            })
        }

        return fillerSegments;
    }

    public calculateFrontFillerConnectionId(contourIndex: number, segmentIndex: number, panel: IPanelData): number {
        switch (panel.type) {
            case PANEL_TYPE_CONTOUR:
                if (panel.isPseudo) {
                    return 10;
                }
                if (this.sideContours[contourIndex + 1] && segmentIndex === 2) {
                    return 10;
                }
                if (this.sideContours[contourIndex - 1] && segmentIndex === 0) {
                    return 10;
                }
                return 9;
            case PANEL_TYPE_SHELF:
            case PANEL_TYPE_SECTION:
            case PANEL_TYPE_HORIZONTAL_SECTION:
            default:
                return 11;
        }
    }

    public generateRails(): IRailData[] {
        return [
            {
                id: +(this.entityId++),
                width: +this.technologMap.door.rails.bottom.width,
                height: +this.technologMap.door.rails.bottom.height,
                level: LEVEL_BOTTOM,
            },
            {
                id: +(this.entityId++),
                width: +this.technologMap.door.rails.top.width,
                height: +this.technologMap.door.rails.top.height,
                level: LEVEL_TOP,
            },
        ]
    }

    public generateDoors(
        sideWardrobe: TFormSideWardrobe,
        segments: TDoorContainerSegmentData[],
    ): IDoorData[] {
        let index: number;
        let doors: IDoorData[] = [];

        this.calculateDoorContainerSizes(sideWardrobe, segments);
        if (!this.doorContainerSizes) {
            return doors;
        }
        for (index = 0; index < sideWardrobe.countDoors; index++) {
            doors.push(this.generateDoor(index, sideWardrobe));
        }

        return doors;
    }

    public generateDoorContourPoint(ratio: number, connectionType: TConnectionType, level: TLevel, connectionId?: number) {
        let sort: number = 1;
        let doorContourPoint: IDoorPointData;

        if (this.doorContourPoints.length > 0) {
            sort = this.doorContourPoints[this.doorContourPoints.length - 1].sort + 1;
        }
        connectionId = connectionId || 1;
        doorContourPoint = {
            id: +(this.entityId++),
            sort: +(sort++),
            connectionId: connectionId,
            connectionType: connectionType,
            type: POINT_TYPE_DOOR,
            level: level,
            ratio: ratio,
        };

        this.doorContourPoints.push(doorContourPoint);

        return doorContourPoint;
    }

    public calculateDoorWidth(sideWardrobe: TFormSideWardrobe): number {
        let doorGaps: number;

        doorGaps = this.getDoorGaps(sideWardrobe);
        if (!this.doorContainerSizes) {
            return 0;
        }

        return (this.doorContainerSizes.width + doorGaps) / sideWardrobe.countDoors;
    }

    public calculateDoorHeight(): number {
        if (!this.doorContainerSizes) {
            return 0;
        }

        return this.doorContainerSizes.height -
            this.technologMap.door.verticalGap.bottom -
            this.technologMap.door.verticalGap.top;
    }

    public getDoorGaps(sideWardrobe: TFormSideWardrobe): number {
        let doorGaps: number = 0;
        if (!isNaN(+this.technologMap.door.container.doorGaps[sideWardrobe.countDoors])) {
            doorGaps = this.technologMap.door.container.doorGaps[sideWardrobe.countDoors];
        }

        return doorGaps;
    }

    public calculateDoorContainerSizes(sideWardrobe: TFormSideWardrobe, segments: TDoorContainerSegmentData[]): TDoorContainerSizes {
        let segment: TDoorContainerSegmentData;
        let panel: IPanelData;
        let sizes: TDoorContainerSizes;
        let panelPoints: TPanelPointsPosition;
        let panelsDepth: {
            left: number | undefined;
            right: number | undefined;
            top: number | undefined;
            bottom: number | undefined;
        };
        let index: TContourPositionType;

        sizes = {
            height: 0,
            depth: +this.technologMap.door.container.depth,
            width: 0,
            panelDepths: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            }
        };
        panelsDepth = {
            left: undefined,
            right: undefined,
            top: undefined,
            bottom: undefined,
        };

        // Предполагается, что все панели в одной стороне контура одинаковые
        for (segment of segments) {
            panel = this.findPanelById(segment.segmentId);
            panelPoints = this.calculatePanelPoints(panel);
            switch (segment.type) {
                case CONTOUR_POSITION_TYPE_BOTTOM:
                    if (panelsDepth.bottom === undefined) {
                        sizes.panelDepths.bottom = panelsDepth.bottom = panel.depth - panel.shift.depth;

                    }
                    sizes.width += Geometry.getLength(panelPoints.pointA, panelPoints.pointB);
                    break;
                case CONTOUR_POSITION_TYPE_LEFT:
                    if (panelsDepth.left === undefined) {
                        sizes.panelDepths.left = panelsDepth.left = panel.depth - panel.shift.depth;
                    }
                    sizes.height += Geometry.getLength(panelPoints.pointA, panelPoints.pointB);
                    break;
                case CONTOUR_POSITION_TYPE_RIGHT:
                    if (panelsDepth.right === undefined) {
                        sizes.panelDepths.right = panelsDepth.right = panel.depth - panel.shift.depth;
                    }
                    break;
                case CONTOUR_POSITION_TYPE_TOP:
                    if (panelsDepth.top === undefined) {
                        sizes.panelDepths.top = panelsDepth.top = panel.depth - panel.shift.depth;
                    }
                    break;
            }
        }
        if (panelsDepth) {
            for (index in panelsDepth) {
                if (!panelsDepth.hasOwnProperty(index) ||
                    panelsDepth[index] === undefined) {
                    continue;
                }
                if (index === CONTOUR_POSITION_TYPE_BOTTOM || index === CONTOUR_POSITION_TYPE_TOP) {
                    sizes.height -= panelsDepth[index] as number;
                } else if (index === CONTOUR_POSITION_TYPE_LEFT || index === CONTOUR_POSITION_TYPE_RIGHT) {
                    sizes.width -= panelsDepth[index] as number;

                }
            }
        }
        this.doorContainerSizes = sizes;

        return sizes;
    }

    public generateDoorContourPoints(
        doorIndex: number,
        sideWardrobe: TFormSideWardrobe
    ): void {
        let doorWidth: number;
        let startWidth: number;

        if (!this.doorContainerSizes) {
            return;
        }
        doorWidth = this.calculateDoorWidth(sideWardrobe);
        startWidth = doorIndex * doorWidth - doorIndex * this.getDoorGaps(sideWardrobe) / sideWardrobe.countDoors;

        this.generateDoorContourPoint(
            startWidth / this.doorContainerSizes.width,
            CONNECTION_TYPE_MAIN_RIGHT,
            LEVEL_BOTTOM,
            3
        );
        this.generateDoorContourPoint(
            startWidth / this.doorContainerSizes.width,
            CONNECTION_TYPE_MAIN_LEFT,
            LEVEL_TOP,
            3
        );
        this.generateDoorContourPoint(
            (startWidth + doorWidth) / this.doorContainerSizes.width,
            CONNECTION_TYPE_MAIN_RIGHT,
            LEVEL_TOP,
            3
        );
        this.generateDoorContourPoint(
            (startWidth + doorWidth) / this.doorContainerSizes.width,
            CONNECTION_TYPE_MAIN_LEFT,
            LEVEL_BOTTOM,
            3
        );
    }

    public generateDoorProfilePoints(
        doorIndex: number,
        sideWardrobe: TFormSideWardrobe
    ): void {
        // TODO пока для теста сделал генерацию промежуточных рамок в двери от балды
        let index: number;
        let interval: number;
        let doorHeight: number;
        let ratio: number;
        let countFrames: number;

        doorHeight = this.calculateDoorHeight();
        countFrames = sideWardrobe.doors[doorIndex].countDoorSections;
        if (doorHeight > 0) {
            interval = doorHeight / (countFrames);
            for (index = 0; index < countFrames - 1; index++) {
                ratio = ((index * interval) + interval) / doorHeight;
                this.generateDoorProfilePoint(
                    this.doorContourProfiles[0],
                    ratio,
                    CONNECTION_TYPE_DEFAULT
                );
                this.generateDoorProfilePoint(
                    this.doorContourProfiles[2],
                    1 - ratio,
                    CONNECTION_TYPE_DEFAULT
                );
            }
        }
    }

    public generateDoorProfilePoint(
        profile: IProfileData, ratio: number, connectionType: TConnectionType, connectionId?: number
    ) {
        let sort: number = 1;
        let doorProfilePoint: IProfilePointData;

        if (this.doorProfilePoints.length > 0) {
            sort = this.doorProfilePoints[this.doorProfilePoints.length - 1].sort + 1;
        }
        connectionId = connectionId || 1;
        doorProfilePoint = {
            id: +(this.entityId++),
            sort: +(sort++),
            connectionId: connectionId,
            connectionType: connectionType,
            type: POINT_TYPE_PROFILE,
            ratio: ratio,
            profileId: profile.id
        };

        this.doorProfilePoints.push(doorProfilePoint);

        return doorProfilePoint;
    }

    public generateDoorInnerProfiles(): void {
        let index: number;

        for (index = 0; index < this.doorProfilePoints.length - 1; index = index + 2) {
            this.generateDoorInnerProfile(
                this.doorProfilePoints[index],
                this.doorProfilePoints[index + 1],
                this.technologMap.door.innerProfile.width,
                this.technologMap.door.innerProfile.depth,
                this.technologMap.door.innerProfile.shapeId,
                PROFILE_TYPE_INNER,
                this.technologMap.door.innerProfile.shift);
        }
    }

    public generateDoorInnerProfile(
        pointA: IProfilePointData,
        pointB: IProfilePointData,
        width: number,
        depth: number,
        shapeId: number,
        type: TProfileType,
        shift?: TPanelShift,
        materialId?: number
    ): IFrameData {
        let profile: IFrameData;
        let sort: number = 1;

        if (this.doorInnerProfiles.length > 0) {
            sort = this.doorInnerProfiles[this.doorInnerProfiles.length - 1].sort + 1;
        }

        if (materialId === undefined) {
            materialId = 0;
        }
        profile = {
            id: +(this.entityId++),
            sort: +(sort++),
            pointA: pointA.id,
            pointB: pointB.id,
            shift: shift || {width: 0, depth: 0},
            shapeId: shapeId,
            depth: depth,
            width: width,
            type: type,
            materialId: materialId,
        };

        this.doorInnerProfiles.push(profile);

        return profile;
    }

    public generateDoorFillers(
        doorIndex: number,
        sideWardrobe: TFormSideWardrobe
    ): void {
        let index: number;
        let materialId: number | undefined;
        let materialType: TMaterialType;

        materialType = sideWardrobe.doors[doorIndex].doorSections[0].materialType;
        if (sideWardrobe.doors[doorIndex].doorSections[0].materialId > 0) {
            materialId = sideWardrobe.doors[doorIndex].doorSections[0].materialId;
        }
        if (!materialId || materialId <= 0) {
            debugger;
            materialId = this.formData.materials.ldsp;
        }
        this.generateDoorFiller(
            [
                this.doorContourProfiles[0],
                (this.doorInnerProfiles[0]) ? this.doorInnerProfiles[0] : this.doorContourProfiles[1],
                this.doorContourProfiles[2],
                this.doorContourProfiles[3]
            ],
            10,
            1,
            materialType,
            materialId
        );
        for (index = 0; index < this.doorInnerProfiles.length; index++) {
            if (sideWardrobe.doors[doorIndex].doorSections[index + 1] &&
                sideWardrobe.doors[doorIndex].doorSections[index + 1].materialId > 0) {
                materialId = sideWardrobe.doors[doorIndex].doorSections[index + 1].materialId;
            }
            if (!materialId || materialId <= 0) {
                materialId = this.formData.materials.ldsp;
            }
            materialType = sideWardrobe.doors[doorIndex].doorSections[index + 1].materialType;
            this.generateDoorFiller(
                [
                    this.doorContourProfiles[0],
                    (this.doorInnerProfiles[index + 1]) ? this.doorInnerProfiles[index + 1] : this.doorContourProfiles[1],
                    this.doorContourProfiles[2],
                    this.doorInnerProfiles[index]
                ],
                10,
                index + 2,
                materialType,
                materialId
            );
        }
    }

    public generateDoorFiller(segments: IProfileData[], depth: number, sort: number, materialType: TMaterialType, materialId?: number, shift?: TPanelShift): IFillerData {
        let doorFiller: IDoorFillerData;
        let fillerMaterialId: number;

        fillerMaterialId = materialId || this.formData.materials.ldsp;
        doorFiller = {
            sort: sort,
            id: +(this.entityId++),
            depth: depth,
            shift: shift || {width: 0, depth: 0},
            segments: this.generateDoorFillerSegments(segments),
            materialId: fillerMaterialId,
            materialType: materialType

        };
        this.doorFillers.push(doorFiller);

        return doorFiller;
    }

    public generateDoorFillerSegments(segments: IProfileData[]): TFillerSegmentData[] {
        let sort: number = 1;
        let index: string;
        let currentSegment: IProfileData;
        let prevSegment: IProfileData;
        let nextSegment: IProfileData;
        let fillerSegments: TFillerSegmentData[] = [];

        for (index in segments) {
            currentSegment = segments[index];
            prevSegment = (segments[+index - 1]) ? segments[+index - 1] : segments[segments.length - 1];
            nextSegment = (segments[+index + 1]) ? segments[+index + 1] : segments[0];
            fillerSegments.push({
                segmentId: +currentSegment.id,
                direction: this.isDoorSegmentFillerCoDirection(currentSegment, prevSegment, nextSegment),
                sort: +(sort++),
                connectionId: this.calculateDoorFillerConnectionId(currentSegment),
            })
        }

        return fillerSegments;
    }

    public calculateDoorFillerConnectionId(segment: IProfileData): number {
        switch (segment.type) {
            case PROFILE_TYPE_VERTICAL:
                return 1;
            case PROFILE_TYPE_TOP:
                return 2;
            case PROFILE_TYPE_BOTTOM:
                return 3;
            case PROFILE_TYPE_INNER:
                return 4;
            default:
                return 1;
        }
    }

    public isDoorSegmentFillerCoDirection(segment: IProfileData, prevSegment: IProfileData, nextSegment: IProfileData): boolean {
        let fillerPointA: TPoint2D | undefined;
        let fillerPointB: TPoint2D | undefined;
        let profilePoints1: TPanelPointsPosition;
        let profilePoints2: TPanelPointsPosition;
        let line1: TLine;
        let line2: TLine;
        profilePoints1 = this.calculateDoorPoints(segment);
        profilePoints2 = this.calculateDoorPoints(prevSegment);
        line1 = {
            pointA: Geometry.point3Dto2D(profilePoints1.pointA),
            pointB: Geometry.point3Dto2D(profilePoints1.pointB)
        };
        line2 = {
            pointA: Geometry.point3Dto2D(profilePoints2.pointA),
            pointB: Geometry.point3Dto2D(profilePoints2.pointB)
        };

        fillerPointA = Geometry.getIntersectionPoint(line1, line2);
        if (!fillerPointA && Geometry.isEqualLines(
            Geometry.directEquation(line1.pointA, line1.pointB),
            Geometry.directEquation(line2.pointA, line2.pointB))) {
            fillerPointA = Geometry.getNearPoint2D(line1.pointA, [line2.pointA, line2.pointB]);
        }
        profilePoints2 = this.calculateDoorPoints(nextSegment);
        line2 = {
            pointA: Geometry.point3Dto2D(profilePoints2.pointA),
            pointB: Geometry.point3Dto2D(profilePoints2.pointB)
        };
        fillerPointB = Geometry.getIntersectionPoint(line1, line2);
        if (!fillerPointB && Geometry.isEqualLines(
            Geometry.directEquation(line1.pointA, line1.pointB),
            Geometry.directEquation(line2.pointA, line2.pointB))) {
            fillerPointB = Geometry.getNearPoint2D(line1.pointA, [line2.pointA, line2.pointB]);
        }
        if (fillerPointA && fillerPointB) {
            return Geometry.isCoDirectionVectors(
                {x: fillerPointB.x - fillerPointA.x, y: fillerPointB.y - fillerPointA.y},
                {
                    x: profilePoints1.pointB.x - profilePoints1.pointA.x,
                    y: profilePoints1.pointB.y - profilePoints1.pointA.y
                }
            )
        }

        return true;
    }

    public generateDoorContourProfiles(): void {
        this.generateDoorContourProfile(
            this.doorContourPoints[0],
            this.doorContourPoints[1],
            +this.technologMap.door.verticalProfile.shapeId,
            +this.technologMap.door.verticalProfile.width,
            +this.technologMap.door.verticalProfile.depth,
            {...this.technologMap.door.verticalProfile.shift},
            PROFILE_TYPE_VERTICAL
        );
        this.generateDoorContourProfile(
            this.doorContourPoints[1],
            this.doorContourPoints[2],
            +this.technologMap.door.topProfile.shapeId,
            +this.technologMap.door.topProfile.width,
            +this.technologMap.door.topProfile.depth,
            {...this.technologMap.door.topProfile.shift},
            PROFILE_TYPE_TOP
        );
        this.generateDoorContourProfile(
            this.doorContourPoints[2],
            this.doorContourPoints[3],
            +this.technologMap.door.verticalProfile.shapeId,
            +this.technologMap.door.verticalProfile.width,
            +this.technologMap.door.verticalProfile.depth,
            {...this.technologMap.door.verticalProfile.shift},
            PROFILE_TYPE_VERTICAL
        );
        this.generateDoorContourProfile(
            this.doorContourPoints[3],
            this.doorContourPoints[0],
            +this.technologMap.door.bottomProfile.shapeId,
            +this.technologMap.door.bottomProfile.width,
            +this.technologMap.door.bottomProfile.depth,
            {...this.technologMap.door.bottomProfile.shift},
            PROFILE_TYPE_BOTTOM
        );
    }

    public generateDoorContourProfile(
        pointA: IDoorPointData,
        pointB: IDoorPointData,
        shapeId: number,
        width: number,
        depth: number,
        shift: TPanelShift,
        type: TProfileType
    ): IProfileData {
        let sort: number = 1;
        let profile: IProfileData;

        if (this.doorContourProfiles.length > 0) {
            sort = this.doorContourProfiles[this.doorContourProfiles.length - 1].sort + 1;
        }
        profile = {
            id: +(this.entityId++),
            sort: +(sort++),
            pointA: pointA.id,
            pointB: pointB.id,
            width: width,
            depth: depth,
            shift: shift,
            shapeId: shapeId,
            type: type,
            materialId: 0, // TODO добавить ид материала
        };

        this.doorContourProfiles.push(profile);

        return profile;
    }

    public generateDoor(
        doorIndex: number,
        sideWardrobe: TFormSideWardrobe
    ): IDoorData {
        this.doorContourPoints = [];
        this.doorProfilePoints = [];
        this.doorContourProfiles = [];
        this.doorInnerProfiles = [];
        this.doorFillers = [];
        this.generateDoorContourPoints(doorIndex, sideWardrobe);
        this.generateDoorContourProfiles();
        this.generateDoorProfilePoints(doorIndex, sideWardrobe);
        this.generateDoorInnerProfiles();
        this.generateDoorFillers(doorIndex, sideWardrobe);

        return {
            id: +(this.entityId++),
            railIndex: doorIndex % 2 > 0 ? 1 : 0,
            points: [...this.doorContourPoints, ...this.doorProfilePoints],
            profiles: [...this.doorContourProfiles],
            frames: [...this.doorInnerProfiles],
            fillers: [...this.doorFillers],
        }
    }

    public static getDefaultDoors(count: number, materialId: number): TFormDoor[] {
        let doors: TFormDoor[] = [];
        let index: number;

        for (index = 0; index < count; index++) {
            doors.push(this.getDefaultDoor(materialId));
        }
        return doors;
    }

    public static getDefaultDoor(materialId: number): TFormDoor {
        return {
            countDoorSections: DEFAULT_COUNT_DOORS_SECTIONS,
            doorSections: this.getDefaultDoorSections(DEFAULT_COUNT_DOORS_SECTIONS, materialId)
        };
    }

    public static getDefaultDoorSections(count: number, materialId: number): TFormDoorSection[] {
        let doorSections: TFormDoorSection[] = [];
        let index: number;

        for (index = 0; index < count; index++) {
            doorSections.push(this.getDefaultDoorSection(materialId));
        }

        return doorSections;
    }

    public static getDefaultDoorSection(materialId: number): TFormDoorSection {
        return {
            materialType: MATERIAL_TYPE_LDSP,
            materialId: materialId,
        }
    }

    public static getDefaultSections(count: number): TWardrobeSection[] {
        let sections: TWardrobeSection[];
        let index: number;

        sections = [];
        for (index = 0; index < count; index++) {
            sections.push(this.getDefaultSection());
        }

        return sections;
    }

    public static getDefaultSection(): TWardrobeSection {
        return {
            rods: {
                count: DEFAULT_COUNT_RODS
            },
            drawers: {
                count: DEFAULT_COUNT_DRAWERS
            },
            shelves: {
                count: DEFAULT_COUNT_SHELVES
            }
        }
    }

    public static getDefaultFormSideWardrobe(
        sizes: TBoxSizes,
        materialId: number,
        countSections?: number,
        countDoors?: number,
        shelvesType?: TShelvesType,
        contourPanels?: TFormContourPanels
    ): TFormSideWardrobe {
        countSections = countSections || 2;
        countDoors = countDoors || 2;
        shelvesType = shelvesType || SHELVES_TYPE_REMOVABLE;
        contourPanels = contourPanels || {
            left: true,
            bottom: true,
            top: true,
            right: true
        }
        return {
            width: sizes.width,
            height: sizes.height,
            depth: sizes.depth,
            countSections: countSections,
            countDoors: countDoors,
            sections: this.getDefaultSections(countSections),
            doors: this.getDefaultDoors(countDoors, materialId),
            shelvesType: shelvesType,
            contourPanels: contourPanels,
        };
    }
}