import {CHANGE_EDITOR_OPTIONS, CHANGE_PROJECT_DATA} from '../constants';
import {IEditorOptions} from '../interfaces';
import {Dispatch} from 'redux';
import {INSTALLATION_TYPE_ONLY_DOORS} from '../../common-code/constants';
import {IWardrobeProjectData} from '../../common-code/domain/interfaces/IWardrobeProjectData/IWardrobeProjectData';

export default function changeProject(dispatch: Dispatch, editorOptions: IEditorOptions, projectData: IWardrobeProjectData | undefined) {
    if (projectData &&
        projectData.installationType === INSTALLATION_TYPE_ONLY_DOORS &&
        !editorOptions.showDoors) {
        dispatch({
            type: CHANGE_EDITOR_OPTIONS,
            payload: {...editorOptions, showDoors: true}
        });
    }

    dispatch({
        type: CHANGE_PROJECT_DATA,
        payload: projectData
    });
}