import {IConstructorFormData} from '../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TWardrobeSection} from '../../common-code/domain/types';
import {ProjectGenerator} from '../../domain/services/ProjectGenerator/ProjectGenerator';
import {MIN_COUNT_SECTIONS, MAX_COUNT_SECTIONS} from '../../common-code/constants';

export default function useNewSections() {

    const createNewSections = (constructorFormData: IConstructorFormData, sideIndex: number, width: number, countSections: number): IConstructorFormData => {
        let index: number;
        let newCountSections: number;
        let oldSections: TWardrobeSection[];
        let newSections: TWardrobeSection[] = [];

        newCountSections = calcCountNewSections(width, countSections);
        constructorFormData.sideWardrobes[sideIndex].countSections = newCountSections;
        oldSections = [...constructorFormData.sideWardrobes[sideIndex].sections];
        for (index = 0; index < newCountSections; index++) {
            if (oldSections[index] !== undefined) {
                newSections.push(oldSections[index]);
            } else {
                newSections.push(ProjectGenerator.getDefaultSection());
            }
        }
        constructorFormData.sideWardrobes[sideIndex].sections = newSections;

        return constructorFormData;
    };

    // TODO Сделать корректный расчет количества секций по ширине шкафа
    const calcCountNewSections = (width: number, countSections: number): number => {
        let newCountSections: number;
        
        newCountSections = countSections;
        if (newCountSections < MIN_COUNT_SECTIONS) {
            newCountSections = MIN_COUNT_SECTIONS;
        }
        if (newCountSections > MAX_COUNT_SECTIONS) {
            newCountSections = MAX_COUNT_SECTIONS;
        }
        
        return newCountSections;
    };

    return { createNewSections };
}