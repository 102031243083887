import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnRadioboxInput, RadioboxInput} from '../../../all/RadioboxInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {Dispatch} from 'redux';
import {CHANGE_INSTALLATION_TYPE} from '../../../../constants';
import {TTechnologMap} from '../../../../../common-code/domain/types';
import {TRadioboxOption} from '../../../../../common-code/domain/types/TRadioboxOption';
import {TInstallationType} from '../../../../../common-code/domain/types';

export const cnInstallationTypeFragment = cn('InstallationTypeFragment');

export interface IInstallationTypeFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    technologMap: TTechnologMap | undefined,
    dispatch: Dispatch,
    options: Array<TRadioboxOption>
}

export const InstallationTypeFragment: React.FC<IInstallationTypeFragmentProps> = ({ className, constructorFormData, technologMap, dispatch, options }) => {

    const changeInstallationType = (event: ChangeEvent<HTMLInputElement>) => {
        if (!technologMap) {
            return;
        }

        dispatch({
            type: CHANGE_INSTALLATION_TYPE,
            payload: event.target.value as TInstallationType,
        });
    };

    return (
        <div className={cnConstructorForm(cnInstallationTypeFragment(), [className])}>
            <RadioboxInput
                className={cnConstructorForm(cnRadioboxInput())}
                options={options}
                value={constructorFormData.installationType}
                callBack={changeInstallationType}
            />
        </div>
    );
};