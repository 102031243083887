import {
    BufferGeometry,
    EdgesGeometry, Group, Line,
    LineBasicMaterial,
    LineSegments,
    Mesh,
    MeshStandardMaterial,
    Object3D, Vector2, Vector3
} from "three";
import {ICommonObject} from "../../../../../domain/intarfaces";
import {TSizes} from "../../../../../domain/types";
import {ThreeConstructor} from "../../ThreeConstructor";
import {LineBasicMaterialParameters} from "three/src/materials/LineBasicMaterial";
import {ThreeGeometry} from "../../helpers";
import {IPriceData} from '../../../../../common-code/domain/interfaces/IPriceData/IPriceData';
import {ICommonObjectData} from '../../../../../common-code/domain/interfaces/ICommonObjectData/ICommonObjectData';

export class ThreeObject implements ICommonObject {
    id: number;
    mainConstructor: ThreeConstructor;
    priceData?: IPriceData;
    sizes?: TSizes;
    view3d: Object3D;
    body?: Mesh;
    bodyMaterial?: MeshStandardMaterial;
    ready: boolean;

    constructor(mainConstructor: ThreeConstructor, options: ICommonObjectData) {
        this.mainConstructor = mainConstructor;
        this.id = this.mainConstructor.getConstructorService().createId(options.id);
        this.view3d = new Object3D();
        this.ready = false;
    }

    createView(): void {
        this.ready = true;
    }

    getSaveData(): ICommonObjectData {
        if (!this.ready) {
            throw new Error('error-ThreeObject-getSaveData');
        }
        return {
            id: this.getId()
        };
    }

    remove(): void {
        this.mainConstructor.getConstructorService().removeId(this.getId());
    }

    setPosition(): void {
    }

    setRotation(): void {
    }

    getId(): number {
        return this.id;
    }

    public updateAllMatrices() {
        this.view3d.updateMatrixWorld();
        if (!this.view3d.matrixAutoUpdate) {
            this.view3d.updateMatrix();
        }
        this.view3d.traverse(function (node) {
            if (((node as Line).isLine || (node as Mesh).isMesh || (node as Group).isGroup) &&
                !node.matrixAutoUpdate) {
                node.updateMatrix();
            }
        });
    }

    protected createCarcass() {
        let carcassGeometry;
        let carcassMaterial;
        let carcass;

        if (!this.body) {
            return;
        }
        carcassGeometry = new EdgesGeometry(this.body.geometry);
        carcassMaterial = new LineBasicMaterial({color: '#000000', linewidth: 3});
        carcass = new LineSegments(carcassGeometry, carcassMaterial);
        this.view3d.add(carcass);
    }

    protected createBodyMaterial() {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.mainConstructor.getConstructorService().getBodyMaterial();
        }

        return this.bodyMaterial;
    }

    protected createCarcassMesh(geometry: BufferGeometry, materialParameters?: LineBasicMaterialParameters): LineSegments {
        if (!materialParameters) {
            materialParameters = {color: '#000000', linewidth: 3, transparent: true, opacity: 0.3};
        }
        return new LineSegments(
            new EdgesGeometry(geometry),
            new LineBasicMaterial(materialParameters)
        );
    }

    protected calculateShiftTopPoint(bottomPoint: Vector3, shift: number): Vector3 {
        return new Vector3(bottomPoint.x, bottomPoint.y, shift);
    }

    protected calculateShiftBottomPoint(point: Vector2, pointA: Vector2, pointB: Vector2, shift: number): Vector3 {
        let shiftPoint: Vector2;

        shiftPoint = ThreeGeometry.getShiftPoint2D(point, pointA, pointB, shift);

        return new Vector3(
            shiftPoint.x,
            shiftPoint.y,
            0
        );
    }
}