import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {ColorInput} from '../../../all/ColorInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TTechnologMap} from '../../../../../common-code/domain/types';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import {ThreeConstructorService} from '../../../3d';
import {IEntityData} from '../../../../../common-code/domain/interfaces/IEntityData';
import {ISquareEntityData} from '../../../../../../../common/domain/interfaces/ISquareEntityData';

export const cnWardrobeColorsFragment = cn('WardrobeColorsFragment');

export interface IWardrobeColorsFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    constructorService: ThreeConstructorService,
    technologMap: TTechnologMap | undefined,
    ldspMaterials: IEntityData[],
    dvpMaterials: IEntityData[]
}

export const WardrobeColorsFragment: React.FC<IWardrobeColorsFragmentProps> =
    ({
         className,
         constructorFormData,
         constructorService,
         technologMap,
         ldspMaterials,
         dvpMaterials
     }) => {

        const selectLdsp: IEntityData | undefined = useSelector((state: AppState) => state.constructorData.selectLdsp);
        const selectDvp: IEntityData | undefined = useSelector((state: AppState) => state.constructorData.selectDvp);

        const changeBodyColor = (color: IEntityData) => {
            if (!technologMap) {
                return;
            }
            constructorService.setSelectLdsp(color as ISquareEntityData);
        };

        const changeDvpMaterial = (material: IEntityData) => {
            if (!technologMap) {
                return;
            }
            constructorService.setSelectDvp(material as ISquareEntityData);
        };

        return (
            <div className={cnConstructorForm(cnWardrobeColorsFragment(), [className])}>
                <div className={cnConstructorForm('BodyColorsSection')}>
                    {/*<h4 className={cnConstructorForm('SubTitle', {size: 'lg', mb: true})}>*/}
                    {/*    Корпус</h4>*/}
                    <div
                        className={cnConstructorForm('BodyColors')}
                        style={{
                            gridTemplateColumns: `repeat(${ldspMaterials.length}, calc((100% - 16px * ${ldspMaterials.length - 1}) / ${ldspMaterials.length}))`,
                            columnGap: '16px'
                        }}
                    >
                        {ldspMaterials.map((bodyColor, bodyColorIndex) => {
                            return (
                                <ColorInput
                                    key={bodyColorIndex}
                                    active={selectLdsp !== undefined && constructorFormData.materials.ldsp === bodyColor.id}
                                    backgroundColor={bodyColor}
                                    callBack={changeBodyColor}
                                />
                            );
                        })}
                    </div>
                    {selectLdsp ? <>
                        <span className={cnConstructorForm('Label', {size: 'sm', color: 'lightNavy'})}>Выбрано: </span>
                        <span className={cnConstructorForm('Label', {
                            size: 'sm',
                            color: 'black'
                        })}>{selectLdsp.name}</span>
                    </> : null}

                </div>
                {dvpMaterials.length > 1 ?
                    <div className={cnConstructorForm('BackWallColorsSection')}>
                        <h4 className={cnConstructorForm('SubTitle', {size: 'lg', mb: true})}>Задняя стенка</h4>
                        <div
                            className={cnConstructorForm('DvpMaterials')}
                            style={{
                                gridTemplateColumns: `repeat(${dvpMaterials.length}, calc((100% - 16px * ${dvpMaterials.length - 1}) / ${dvpMaterials.length}))`,
                                columnGap: '16px'
                            }}
                        >
                            {dvpMaterials.map((dvpMaterial, dvpMaterialIndex) => {
                                return (
                                    <ColorInput
                                        key={dvpMaterialIndex}
                                        active={selectDvp !== undefined && constructorFormData.materials.dvp === dvpMaterial.id}
                                        backgroundColor={dvpMaterial}
                                        callBack={changeDvpMaterial}
                                    />
                                );
                            })}
                        </div>
                        {selectDvp ? <>
                            <span className={cnConstructorForm('Label', {
                                size: 'sm',
                                color: 'lightNavy'
                            })}>Выбрано: </span>
                            <span className={cnConstructorForm('Label', {
                                size: 'sm',
                                color: 'black'
                            })}>{selectDvp.name}</span>
                        </> : null}

                    </div>
                : null
            }

        </div>
    );
};