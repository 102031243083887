import {Dispatch} from "redux";
import {ICommonConstructor, IPoint} from "../../../../domain/intarfaces";
import {WardrobeProject} from "../WardrobeProject";
import {ThreeConstructorService} from "../ThreeConstructorService";
import {ThreeObject} from "../models/ThreeObject";
import {ThreePanel, ThreeShelf} from "../models/panels";
import {ThreeEditor} from "../ThreeEditor";
import {UserControls} from "../UserControls";
import {Mesh, MeshStandardMaterial, SphereGeometry, Vector3} from "three";
import {TLevel, TProfileType, TTechnologMap} from '../../../../common-code/domain/types';
import {TFillerConnection} from '../../../../common-code/domain/types';
import {TPointConnection} from '../../../../common-code/domain/types';
import {TShapeData} from '../../../../common-code/domain/types';
import {
    IWardrobeProjectData
} from '../../../../common-code/domain/interfaces/IWardrobeProjectData/IWardrobeProjectData';
import {TextGeometryParameters} from 'three/examples/jsm/geometries/TextGeometry';
import {Font} from 'three/examples/jsm/loaders/FontLoader';
import {TViewMode} from '../../../../common-code/domain/types';
import {IMaterialTextures} from '../interfaces/IMaterialTextures/IMaterialTextures';
import {IEntityData} from '../../../../common-code/domain/interfaces/IEntityData';
import {ISquareMaterialPriceData} from '../../../../common-code/domain/interfaces/IPriceData/ISquareMaterialPriceData';
import {IProjectPriceData} from '../../../../common-code/domain/interfaces/IProjectPriceData/IProjectPriceData';
import {ISquareEntityData} from '../../../../common-code/domain/interfaces/ISquareEntityData';
import {ISaveProjectData} from '../../../../common-code/domain/interfaces/ISaveProjectData/ISaveProjectData';
import axios from 'axios';
import {CHANGE_CONSTRUCTOR_FORM_DATA} from '../../../constants';
import changeProject from '../../../helpers/changeProject';
import {
    IWardrobeProjectSpec
} from '../../../../common-code/domain/interfaces/IWardrobeProjectSpec/IWardrobeProjectSpec';
import {INSTALLATION_TYPE_SEPARATE} from '../../../../common-code/constants';
import {IPlankEntityData} from '../../../../common-code/domain/interfaces/IPlankEntityData';
import {IServiceData} from '../../../../../../common/domain/interfaces/IServiceData';
import {TRodMaterials} from '../../../types/TRodMaterials';

export class ThreeConstructor implements ICommonConstructor {
    private editor: ThreeEditor;
    private userControls: UserControls;
    public reduxDispatch: Dispatch;
    private readonly constructorService: ThreeConstructorService;
    private projectData?: IWardrobeProjectData;
    private exportProjectData?: IWardrobeProjectData;
    private wardrobeProject?: WardrobeProject;
    private id: number;

    private technologMap: TTechnologMap;

    threeEditorOnResize: () => void;
    threeEditorPointerMove: (event: PointerEvent) => void;

    constructor(
        constructorService: ThreeConstructorService,
        editor: ThreeEditor,
        userControls: UserControls,
        technologMap: TTechnologMap,
        reduxDispatch: Dispatch
    ) {
        this.id = +new Date();
        this.constructorService = constructorService;
        this.editor = editor;
        this.editor.setConstructor(this);
        this.technologMap = technologMap;
        this.userControls = userControls;
        this.threeEditorOnResize = () => this.editor.onResize();
        this.threeEditorPointerMove = (event: PointerEvent) => this.editor.onPointerMove(event);
        this.reduxDispatch = reduxDispatch;
    }

    public getId() {
        return this.id;
    }

    public initState(container: HTMLDivElement) {
        this.editor.initState(container);
        this.onInit();
        window.addEventListener('resize', this.threeEditorOnResize, false);
        window.addEventListener('pointermove', this.threeEditorPointerMove, false);
        this.userControls.run(container);
    };

    public addToScene(object: ThreeObject): void {
        this.editor.addToScene(object);
    }

    public removeFromScene(object: ThreeObject): void {
        this.editor.removeFromScene(object);
    }

    public fitAll() {
        this.editor.fitAll();
    }

    public getRailByLevel(level: TLevel): IPlankEntityData | undefined {
        return this.constructorService.getRailByLevel(level);
    }

    public initSquareMaterialPriceData(): ISquareMaterialPriceData {
        return {
            materialId: 0,
            errors: [],
            sum: 0,
            sheetPrice: 0,
            sheetCount: 0,
            square: 0,
            sheetSquare: 0,
            store: '-',
            code: '',
            name: ''
        };
    }

    public initProjectPriceData(): IProjectPriceData {
        return {
            ldsp: [],
            dvp: [],
            mirror: [],
            rods: [],
            furniture: [],
            butts: [],
            profiles: [],
            other: [],
            services: [],
            errors: [],
            full: 0,
        }
    }

    public addToSceneTestPoint(point: Vector3, color: string = "#ff0eb9", size: number = 50) {
        let scene;

        scene = this.editor.getScene();
        if (scene) {
            scene.add(this.getTestPoint(point, color, size));
        }
    }

    public getTestPoint(point: Vector3, color: string = "#ff0eb9", size: number = 50): Mesh {
        let pointMesh;
        pointMesh = new Mesh(new SphereGeometry(size), new MeshStandardMaterial({color: color}));
        pointMesh.position.copy(point);

        return pointMesh;
    }

    public getService(): IServiceData | undefined {
        return this.constructorService.getService();
    }

    public getProfileMaterial(type: TProfileType): IPlankEntityData | undefined {
        return this.constructorService.getProfileMaterial(type);
    }

    public getRodMaterials(): TRodMaterials | undefined {
        return this.constructorService.getRodMaterials();
    }

    public getPanelMaterialId(initMaterialId?: number): number {
        return this.constructorService.getPanelMaterialId(initMaterialId);
    }

    public getPanelMaterial(initMaterialId: number): IEntityData {
        return this.constructorService.getPanelMaterial(initMaterialId);
    }

    public getButtMaterial(panelMaterialId: number): IPlankEntityData {
        return this.constructorService.getButtMaterial(panelMaterialId);
    }

    public loadMaterialTextures(material: IEntityData): IMaterialTextures {
        return this.constructorService.loadMaterialTextures(material);
    }

    public getPointById(id: number): IPoint {
        if (this.wardrobeProject) {
            return this.wardrobeProject.getPointById(id);
        }
        throw new Error("Error! not found point! by id: " + id);
    }

    public getVisibleDoors(): boolean {
        return this.editor.getVisibleDoors();
    }

    public getPanelById(id: number): ThreePanel | ThreeShelf {
        let sideWardrobeId;

        if (this.wardrobeProject) {
            for (sideWardrobeId in this.wardrobeProject.sideWardrobes) {
                if (this.wardrobeProject.sideWardrobes[sideWardrobeId].panels[id]) {
                    return this.wardrobeProject.sideWardrobes[sideWardrobeId].panels[id] as ThreePanel;
                }
                if (this.wardrobeProject.sideWardrobes[sideWardrobeId].shelves[id]) {
                    return this.wardrobeProject.sideWardrobes[sideWardrobeId].shelves[id] as ThreeShelf;
                }
            }
        }

        throw new Error("Error! not found panel! by id: " + id);
    }

    public remove() {
        window.removeEventListener('resize', this.threeEditorOnResize, false);
        window.removeEventListener('pointermove', this.threeEditorPointerMove, false);
        this.userControls.stop();
        this.removeProject();
    };

    public startRender() {
        this.editor.startRender();
    }

    public setProjectData(projectData: IWardrobeProjectData) {
        this.projectData = projectData;
    }

    public getProjectData(): IWardrobeProjectData | undefined {
        return this.projectData;
    }

    public getBottomOutsoleHeight(): number {
        return this.constructorService.getFormData().installationType === INSTALLATION_TYPE_SEPARATE ?
            this.technologMap.outsole.height : 0;
    }

    public async saveProject(): Promise<ISaveProjectData | undefined> {
        return new Promise((resolve, reject) => {
            let saveProjectData: ISaveProjectData | undefined;
            saveProjectData = this.getExportSaveProjectData();
            if (!saveProjectData) {
                resolve(undefined);
            }
            axios.post('/api/project/save', saveProjectData)
                .then((response: any) => {
                    if (response.data && response.data.project && response.data.formData) {
                        changeProject(this.reduxDispatch, this.editor.getOptions(), response.data.project);
                        this.reduxDispatch({
                            type: CHANGE_CONSTRUCTOR_FORM_DATA,
                            payload: response.data.formData
                        });
                        resolve(response.data);
                    }
                })
                .catch(() => {
                    console.error('project save error!');
                    reject();
                });
        })

    }

    public setExportProjectData(projectData: IWardrobeProjectData) {
        this.exportProjectData = projectData;
    }

    public getExportSaveProjectData(): ISaveProjectData | undefined {
        if (this.exportProjectData) {
            return {
                project: this.exportProjectData,
                formData: this.constructorService.getFormData()
            };
        }
        return undefined;
    }

    public getExportWardrobeProjectData(): IWardrobeProjectData | undefined {
        return this.exportProjectData;
    }

    public getWardrobeProjectSpec(): IWardrobeProjectSpec | undefined {
        return this.wardrobeProject?.getSpec();
    }

    public getSizeLineMaterial() {
        return this.constructorService.getSizeLineMaterial();
    }

    public createTextSizeMesh(text: string, parameters?: TextGeometryParameters) {
        return this.constructorService.createTextSizeMesh(text, parameters);
    }

    public getFont(): Font {
        return this.constructorService.getFont();
    }

    private removeProject() {
        if (this.wardrobeProject) {
            this.wardrobeProject.remove();
        }
    }

    private async onInit() {
        this.buildProject();
    }

    private buildProject() {
        if (!this.projectData) {
            return;
        }
        this.wardrobeProject = new WardrobeProject(this, this.projectData);
        this.wardrobeProject.createView();
        this.setExportProjectData(this.wardrobeProject.getSaveData())
    }

    public rebuildProject() {
        if (!this.wardrobeProject && this.constructorService.isReady()) {
            this.buildProject();
        }
        if (this.wardrobeProject && this.projectData) {
            this.wardrobeProject.rebuild(this.projectData);
            this.setExportProjectData(this.wardrobeProject.getSaveData())
            this.wardrobeProject.calculatePrice();
        }
        this.fitAll();
    }

    public getViewMode(): TViewMode {
        return this.editor.getViewMode();
    }

    getConstructorService(): ThreeConstructorService {
        return this.constructorService;
    }

    public getFillerConnectionById(id: number): TFillerConnection {
        return this.constructorService.getFillerConnectionById(id);
    }

    public getPointConnectionById(id: number): TPointConnection {
        return this.constructorService.getPointConnectionById(id);
    }

    public getShapeById(shapeId: number | undefined): TShapeData | undefined {
        return this.constructorService.getShapeById(shapeId);
    }

    public getDvpById(materialId: number): ISquareEntityData | undefined {
        return this.constructorService.getDvpById(materialId);
    }

    public getLdspById(materialId: number): ISquareEntityData | undefined {
        return this.constructorService.getLdspById(materialId);
    }

    public getMirrorById(materialId: number): ISquareEntityData | undefined {
        return this.constructorService.getMirrorById(materialId);
    }
}