import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {useNewDoorSections} from '../../../../hooks';
import {SelectInput, cnSelectInput} from '../../../all/SelectInput';
import {ButtonInput, cnButtonInput} from '../../../all/ButtonInput';
import {ColorInput} from '../../../all/ColorInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TSelectOption} from '../../../../../common-code/domain/types';
import {Dispatch} from 'redux';
import {CHANGE_CONSTRUCTOR_FORM_DATA, CHANGE_DOOR_MATERIAL_ID, CHANGE_DOOR_MATERIAL_TYPE} from '../../../../constants';
import {CommonHelper} from '../../../../helpers/CommonHelper';
import {IEntityData} from '../../../../../common-code/domain/interfaces/IEntityData';

export const cnDoorSectionFragment = cn('DoorSectionFragment');

export interface IDoorSectionFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    sideIndex: number,
    doorIndex: number,
    doorSectionIndex: number,
    isDeleted: boolean,
    dispatch: Dispatch,
    materialTypes: {
        placeholder: string,
        options: Array<TSelectOption>
    },
    materials: IEntityData[]
}

export const DoorSectionFragment: React.FC<IDoorSectionFragmentProps> =
    ({
         className,
         constructorFormData,
         sideIndex,
         doorIndex,
         doorSectionIndex,
         isDeleted,
         dispatch,
         materialTypes,
         materials
     }) => {

        const {removeDoorSection} = useNewDoorSections();

        const changeMaterialDoorSection = (event: ChangeEvent<HTMLSelectElement>) => {
            dispatch({
                type: CHANGE_DOOR_MATERIAL_TYPE,
                sideIndex: sideIndex,
                doorIndex: doorIndex,
                doorSectionIndex: doorSectionIndex,
                payload: event.target.value,
            });
        };

        const deleteDoorSection = () => {
            let newCountDoorSections: number;
            let newConstructorFormData = CommonHelper.deepCopy(constructorFormData);

            newCountDoorSections = newConstructorFormData.sideWardrobes[sideIndex].doors[doorIndex].countDoorSections;
            newCountDoorSections -= 1;
            newConstructorFormData = removeDoorSection(newConstructorFormData, sideIndex, doorIndex, doorSectionIndex, newCountDoorSections);
            dispatch({
                type: CHANGE_CONSTRUCTOR_FORM_DATA,
                payload: newConstructorFormData,
            });
        };

        const changeDoorSectionMaterial = (material: IEntityData) => {
            dispatch({
                type: CHANGE_DOOR_MATERIAL_ID,
                sideIndex: sideIndex,
                doorIndex: doorIndex,
                doorSectionIndex: doorSectionIndex,
                payload: material.id,
            });
        };

        const selectMaterial: IEntityData | undefined = materials.filter(item => {
            return item.id === constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].doorSections[doorSectionIndex].materialId
        })[0];

        return (
            <div className={cnConstructorForm(cnDoorSectionFragment(), [className])}>
                <span className={cnConstructorForm('Label', {
                    size: 'sm',
                    color: 'lightNavy'
                })}>Секция {doorSectionIndex + 1}</span>
                <SelectInput
                    className={cnConstructorForm(cnSelectInput(), {canDelete: isDeleted})}
                    placeholder={materialTypes.placeholder}
                    showPlaceholder={false}
                    options={materialTypes.options}
                    value={'' + constructorFormData.sideWardrobes[sideIndex].doors[doorIndex].doorSections[doorSectionIndex].materialType}
                    callBack={changeMaterialDoorSection}
                />
                {isDeleted ?
                    <div className={cnConstructorForm('RemoveButton')}>
                        <ButtonInput
                            className={`${cnButtonInput()} ${cnConstructorForm(cnButtonInput())}`}
                            leftIcon={'remove'}
                            modsIcon={{size: 'xs'}}
                            callBack={deleteDoorSection}
                        />
                    </div> : null
                }
                {materials.length > 1 ?
                    <div
                        className={cnConstructorForm('DoorColors')}
                        style={{
                            gridTemplateColumns: `repeat(${materials.length}, calc((100% - 16px * ${materials.length - 1}) / ${materials.length}))`,
                            columnGap: '16px'
                        }}
                    >
                        {materials.map((material, materialIndex) => {
                            return (
                                <ColorInput
                                    key={materialIndex}
                                    active={selectMaterial && selectMaterial.id === material.id}
                                    backgroundColor={material}
                                    callBack={changeDoorSectionMaterial}
                                />
                            );
                        })}
                    </div> : null
                }
                {selectMaterial ? <div className={cnConstructorForm('Select')}>
                    <span className={cnConstructorForm('Label', {size: 'sm', color: 'lightNavy'})}>Выбрано: </span>
                    <span className={cnConstructorForm('Label', {
                        size: 'sm',
                        color: 'black'
                    })}>{selectMaterial.name}</span>
                </div> : null}
            </div>
    );
};