import {IFrame} from "../../../../../../domain/intarfaces/IFrame/IFrame";
import {ThreeDoor} from "../../ThreeDoor/ThreeDoor";
import {ThreeProfile} from "../ThreeProfile/ThreeProfile";
import {ThreeProfilePoint} from "../../points/ThreeProfilePoint/ThreeProfilePoint";
import {IThreePanelPoints} from "../../../interfaces";
import {ThreeGeometry} from "../../../helpers";
import {AXIS_X, AXIS_Y} from "../../../../../../domain/constants";
import {Vector3} from "three";
import {IFrameData} from "../../../../../../common-code/domain/interfaces/IFrameData/IFrameData";
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";

export class ThreeFrame extends ThreeProfile implements IFrame {
    leftParent: ThreeProfile;
    rightParent: ThreeProfile;
    pointA: ThreeProfilePoint;
    pointB: ThreeProfilePoint;


    constructor(door: ThreeDoor, options: IFrameData) {
        super(door, options);
        this.pointA = this.door.getPointById(options.pointA) as ThreeProfilePoint;
        this.pointB = this.door.getPointById(options.pointB) as ThreeProfilePoint;
        this.leftParent = this.pointA.profile;
        this.rightParent = this.pointB.profile;
    }

    public getSaveData(): IFrameData {
        super.getSaveData();
        let globalPoints: IStartEndPoints | undefined;
        globalPoints = this.calculateGlobalPoints();
        return {
            id: this.getId(),
            pointA: this.pointA.getId(),
            pointB: this.pointB.getId(),
            sort: this.sort,
            depth: this.depth,
            shift: this.shift,
            shapeId: this.shapeId,
            type: this.type,
            width: this.width,
            materialId: this.materialId,
            startPoint: globalPoints?.start,
            endPoint: globalPoints?.end,
            length: globalPoints?.length,
            rotation: globalPoints?.rotation
        };
    }

    public calculateVisiblePoints() {
        if (!this.points) {
            return;
        }
        this.visiblePoints = {...this.points} as IThreePanelPoints;

        if (this.leftParent) {
            this.calculateLeftParentIntersectPoints();
        }
        if (this.rightParent) {
            this.calculateRightParentIntersectPoints();
        }
    }

    private calculateLeftParentIntersectPoints() {
        if (!this.points || !this.visiblePoints || !this.leftParent) {
            return;
        }
        this.leftParent.calculatePoints();
        if (!this.leftParent.points) {
            return;
        }
        let intersectPoint;
        let intersectPoints = [];
        let intersectPointA;
        let intersectPointB;
        let parentIndentionPointA;
        let parentIndentionPointB;
        let indentionValue: number;

        indentionValue = this.mainConstructor.getConstructorService().getIndentionValue(
            this.pointA.connection.indention,
            this
        );
        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointA = ThreeGeometry.getNearPoint2D(ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y), intersectPoints);
        if (intersectPointA) {
            this.visiblePoints.A = new Vector3(intersectPointA.x, intersectPointA.y, this.shift.depth);
            this.visiblePoints.A2 = new Vector3(intersectPointA.x, intersectPointA.y, this.depth + this.shift.depth);
        }

        intersectPoints = [];
        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.leftParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointB = ThreeGeometry.getNearPoint2D(ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y), intersectPoints);
        if (intersectPointB) {
            this.visiblePoints.C = new Vector3(intersectPointB.x, intersectPointB.y, this.shift.depth);
            this.visiblePoints.C2 = new Vector3(intersectPointB.x, intersectPointB.y, this.depth + this.shift.depth);
        }
    }

    private calculateRightParentIntersectPoints() {
        if (!this.points || !this.visiblePoints || !this.rightParent) {
            return;
        }
        this.rightParent.calculatePoints();
        if (!this.rightParent.points) {
            return;
        }
        let intersectPoint;
        let intersectPoints = [];
        let intersectPointA;
        let intersectPointB;
        let parentIndentionPointA;
        let parentIndentionPointB;
        let indentionValue: number;

        indentionValue = this.mainConstructor.getConstructorService().getIndentionValue(
            this.pointB.connection.indention,
            this
        );
        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );

        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointA = ThreeGeometry.getNearPoint2D(ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y), intersectPoints);
        if (intersectPointA) {
            this.visiblePoints.B = new Vector3(intersectPointA.x, intersectPointA.y, this.shift.depth);
            this.visiblePoints.B2 = new Vector3(intersectPointA.x, intersectPointA.y, this.depth + this.shift.depth);
        }
        intersectPoints = [];

        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.A, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.B, AXIS_X, AXIS_Y),
            indentionValue
        );
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        parentIndentionPointA = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        parentIndentionPointB = ThreeGeometry.getShiftPoint2D(
            ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.C, AXIS_X, AXIS_Y),
            ThreeGeometry.toVector2D(this.rightParent.points.D, AXIS_X, AXIS_Y),
            -indentionValue
        );
        intersectPoint = ThreeGeometry.getIntersectionPoint(
            {
                pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
            },
            {
                pointA: parentIndentionPointA,
                pointB: parentIndentionPointB
            }
        );
        if (intersectPoint) {
            intersectPoints.push(intersectPoint);
        }
        intersectPointB = ThreeGeometry.getNearPoint2D(ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y), intersectPoints);
        if (intersectPointB) {
            this.visiblePoints.D = new Vector3(intersectPointB.x, intersectPointB.y, this.shift.depth);
            this.visiblePoints.D2 = new Vector3(intersectPointB.x, intersectPointB.y, this.depth + this.shift.depth);
        }
    }
}