import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnRangeInput, RangeInput} from '../../../all/RangeInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {Dispatch} from 'redux';
import {CHANGE_WARDROBE_WIDTH} from '../../../../constants';
import {
    WARDROBE_MIN_WIDTH,
    WARDROBE_MAX_WIDTH
} from '../../../../../common-code/constants';

export const cnWidthFragment = cn('WidthFragment');

export interface IWidthFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    sideIndex: number,
    dispatch: Dispatch,
    min: number,
    max: number,
    step?: number
}

export const WidthFragment: React.FC<IWidthFragmentProps> =
    ({
         className,
         constructorFormData,
         sideIndex,
         dispatch,
         min,
         max,
         step
     }) => {

        const changeWidth = (width: number) => {
            if (width < WARDROBE_MIN_WIDTH) {
                width = WARDROBE_MIN_WIDTH;
            }
            if (width > WARDROBE_MAX_WIDTH) {
                width = WARDROBE_MAX_WIDTH;
            }

            dispatch({
                type: CHANGE_WARDROBE_WIDTH,
                sideIndex: sideIndex,
                payload: width,
            });
        };

        return (
            <div
                className={cnConstructorForm(cnWidthFragment(), [className, cnConstructorForm('RangeInputContainer')])}>
            <span className={cnConstructorForm(
                'Label',
                {size: 'sm', color: 'lightNavy'},
                [cnConstructorForm('RangeInputLabel')])
            }>Ширина,мм</span>
            <RangeInput
                className={cnConstructorForm(cnRangeInput())}
                min={min}
                max={max}
                step={step}
                value={constructorFormData.sideWardrobes[sideIndex].width}
                callBack={changeWidth}
                viewControl={true}
            />
        </div>
    );
};