import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnCheckboxInput, CheckboxInput} from '../../../all/CheckboxInput';
import {cnNumberInput, NumberInput} from '../../../all/NumberInput';
import {
    CHANGE_SECTION_DRAWERS_COUNT,
    CHANGE_SECTION_RODS_COUNT, CHANGE_SECTION_SHELVES_COUNT
} from '../../../../constants';

import {TWardrobeSection} from '../../../../../common-code/domain/types';
import {TTechnologMap} from '../../../../../common-code/domain/types';
import {useDispatch} from 'react-redux';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {INSTALLATION_TYPE_SEPARATE} from '../../../../../common-code/constants';

export const cnSectionFragment = cn('SectionFragment');

export interface ISectionFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData;
    sectionData: TWardrobeSection,
    technologMap: TTechnologMap,
    sideIndex: number,
    sectionIndex: number,
}

export const SectionFragment: React.FC<ISectionFragmentProps> = ({
                                                                     className,
                                                                     constructorFormData,
                                                                     sectionData,
                                                                     sideIndex,
                                                                     sectionIndex,
                                                                     technologMap
                                                                 }) => {

    const dispatch = useDispatch();

    const calculateRodsDefaultCount = (): number => {
        return 1;
    }

    const calculateDrawersDefaultCount = (): number => {
        return 1;
    }

    const calculateShelvesDefaultCount = (): number => {
        return 1;
    }


    const toggleActiveElement = (event: ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'rods':
                changeRods(!event.target.checked ? 0 : calculateRodsDefaultCount());
                break;
            case 'drawers':
                changeDrawers(!event.target.checked ? 0 : calculateDrawersDefaultCount());
                break;
            case 'shelves':
                changeShelves(!event.target.checked ? 0 : calculateShelvesDefaultCount());
                break;
        }
    };

    const getTopPlinthHeight = (): number => {
        return (INSTALLATION_TYPE_SEPARATE === constructorFormData.installationType &&
        constructorFormData.topPlinth > 0 ? +constructorFormData.topPlinth : 0);
    }

    const getBottomPlinthHeight = (): number => {
        return (INSTALLATION_TYPE_SEPARATE === constructorFormData.installationType &&
        constructorFormData.bottomPlinth > 0 ? +constructorFormData.bottomPlinth : 0);
    }

    const getBottomOutsoleHeight = (): number => {
        return (constructorFormData.installationType === INSTALLATION_TYPE_SEPARATE ? technologMap.outsole.height : 0);
    }
    const getTopSectionHeight = (): number => {
        return (constructorFormData.sideWardrobes[sideIndex].height >= technologMap.side.horizontalInnerPanelHeight ?
            technologMap.side.topSectionHeight : 0);
    }

    const getSectionHeight = (): number => {
        return (constructorFormData.sideWardrobes[sideIndex].height -
            getBottomOutsoleHeight() -
            getTopSectionHeight() -
            getBottomPlinthHeight() -
            getTopPlinthHeight());
    }

    const changeRods = (value: number) => {
        const sectionHeight = getSectionHeight();
        if (value < technologMap.section.rods.min) {
            value = technologMap.section.rods.min;
        }
        if (value > technologMap.section.rods.max) {
            value = technologMap.section.rods.max;
        }
        if (value > 0 && sectionHeight / value <= technologMap.section.rodHeight) {
            value = value - 1;
        }
        dispatch({
            type: CHANGE_SECTION_RODS_COUNT,
            sideIndex: sideIndex,
            sectionIndex: sectionIndex,
            payload: value,
        });
        if (value > 0) {
            dispatch({
                type: CHANGE_SECTION_DRAWERS_COUNT,
                sideIndex: sideIndex,
                sectionIndex: sectionIndex,
                payload: 0,
            });
            dispatch({
                type: CHANGE_SECTION_SHELVES_COUNT,
                sideIndex: sideIndex,
                sectionIndex: sectionIndex,
                payload: 1,
            });
        }
        if (value > 1) {
            dispatch({
                type: CHANGE_SECTION_SHELVES_COUNT,
                sideIndex: sideIndex,
                sectionIndex: sectionIndex,
                payload: 0,
            });
        }
    };

    const changeDrawers = (value: number) => {
        const sectionHeight = getSectionHeight();
        let shelvesCount: number;
        if (value < technologMap.section.drawers.min) {
            value = technologMap.section.drawers.min;
        }
        if (value > technologMap.section.drawers.max) {
            value = technologMap.section.drawers.max;
        }
        dispatch({
            type: CHANGE_SECTION_DRAWERS_COUNT,
            sideIndex: sideIndex,
            sectionIndex: sectionIndex,
            payload: value,
        });
        if (value > 0) {
            dispatch({
                type: CHANGE_SECTION_RODS_COUNT,
                sideIndex: sideIndex,
                sectionIndex: sectionIndex,
                payload: 0,
            });
        }
        if (sectionData.shelves.count > 0 &&
            (sectionHeight - (technologMap.drawer.height * value) - technologMap.shelf.defaultHeight) / (sectionData.shelves.count + 1) <
            technologMap.shelf.height.min) {
            shelvesCount = Math.floor((sectionHeight - (technologMap.drawer.height * value) - technologMap.shelf.defaultHeight) /
                technologMap.shelf.height.min);
            dispatch({
                type: CHANGE_SECTION_SHELVES_COUNT,
                sideIndex: sideIndex,
                sectionIndex: sectionIndex,
                payload: shelvesCount,
            });
        }
    };

    const changeShelves = (value: number) => {
        const sectionHeight = getSectionHeight();
        if (value < technologMap.section.shelves.min) {
            value = technologMap.section.shelves.min;
        }
        if (value > technologMap.section.shelves.max) {
            value = technologMap.section.shelves.max;
        }
        if (value > 0 &&
            (sectionHeight - (technologMap.drawer.height * sectionData.drawers.count) - technologMap.shelf.defaultHeight) / (value + 1) <
            technologMap.shelf.height.min) {
            value = Math.floor((sectionHeight - (technologMap.drawer.height * sectionData.drawers.count) - technologMap.shelf.defaultHeight) /
                technologMap.shelf.height.min);
        }

        dispatch({
            type: CHANGE_SECTION_SHELVES_COUNT,
            sideIndex: sideIndex,
            sectionIndex: sectionIndex,
            payload: value,
        });
        if (value > 0 && sectionData.rods.count === 2) {
            dispatch({
                type: CHANGE_SECTION_RODS_COUNT,
                sideIndex: sideIndex,
                sectionIndex: sectionIndex,
                payload: 1,
            });
        }
        if (value > 2 && sectionData.rods.count > 0) {
            dispatch({
                type: CHANGE_SECTION_RODS_COUNT,
                sideIndex: sideIndex,
                sectionIndex: sectionIndex,
                payload: 0,
            });
        }
    };

    return (
        <div className={cnConstructorForm(cnSectionFragment(), [className])}>
            <div className={cnConstructorForm('SectionChecked')}>
                <h4 className={cnConstructorForm('SubTitle', {size: 'lg'})}>Секция {sectionIndex + 1}</h4>
                <CheckboxInput
                    className={cnConstructorForm(cnCheckboxInput(), {})}
                    name={'rods'}
                    checked={sectionData.rods.count > 0}
                    callBack={toggleActiveElement}
                />
                <CheckboxInput
                    className={cnConstructorForm(cnCheckboxInput(), {})}
                    name={'drawers'}
                    checked={sectionData.drawers.count > 0}
                    callBack={toggleActiveElement}
                />
                <CheckboxInput
                    className={cnConstructorForm(cnCheckboxInput(), {})}
                    name={'shelves'}
                    checked={sectionData.shelves.count > 0}
                    callBack={toggleActiveElement}
                />
            </div>
            <div className={cnConstructorForm('SectionItems')}>
                <span className={cnConstructorForm('Label', {size: 'sm', color: 'lightNavy'})}>Количество</span>
                <NumberInput
                    className={cnConstructorForm(cnNumberInput())}
                    min={technologMap.section.rods.min}
                    max={technologMap.section.rods.max}
                    step={1}
                    viewControl={true}
                    disabled={sectionData.rods.count === 0}
                    value={sectionData.rods.count}
                    callBack={changeRods}
                />
                <NumberInput
                    className={cnConstructorForm(cnNumberInput())}
                    min={technologMap.section.drawers.min}
                    max={technologMap.section.drawers.max}
                    step={1}
                    viewControl={true}
                    disabled={sectionData.drawers.count === 0}
                    value={sectionData.drawers.count}
                    callBack={changeDrawers}
                />
                <NumberInput
                    className={cnConstructorForm(cnNumberInput())}
                    min={technologMap.section.shelves.min}
                    max={technologMap.section.shelves.max}
                    step={1}
                    viewControl={true}
                    disabled={sectionData.shelves.count === 0}
                    value={sectionData.shelves.count}
                    callBack={changeShelves}
                />
            </div>
        </div>
    );
};