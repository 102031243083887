import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {resources} from './languages';
import {initReactI18next} from 'react-i18next';

const DEFAULT_LANGUAGE = 'ru';
// const DEFAULT_LANGUAGE = 'en';

document.documentElement.lang = DEFAULT_LANGUAGE;

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(initReactI18next)
    .init({
        debug: false,
        lng: DEFAULT_LANGUAGE,
        resources,
    });