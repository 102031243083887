import React from 'react';
import { IClassNameProps } from '@bem-react/core'
import {cn} from "@bem-react/classname";
import './ProjectsPage.css';
import {useRegistry} from "@bem-react/di";
import {appId} from "../../common/App/App";
import {PageWrapper} from "../../blocks";
import '../../i18n/';
import {useTranslation} from 'react-i18next';
import {Projects} from '../../blocks/all/Projects/Projects';

export interface IProjectsPageProps extends IClassNameProps {
}

export const cnProjectsPage = cn('ProjectsPage');

export const ProjectsPage: React.FC<IProjectsPageProps> = ({className}) => {
    const {t} = useTranslation();
    const {Header, Footer} = useRegistry(appId);

    return (
        <div className={cnProjectsPage({}, [className, 'CommonPage'])}>
            <Header title={t('projects-page-title')}/>
            <PageWrapper>
                <h1 className={cnProjectsPage('Title')}>Мои проекты</h1>
                <Projects/>
            </PageWrapper>
            <Footer/>
        </div>
    )
};

