import {Reducer} from "redux";
import {HIDE_LOADING, SHOW_LOADING} from "../constants";
import {ICommonSettings} from '../interfaces/ICommonSettings';

const initState: ICommonSettings = {
    loading: false
};
export const CommonReducer: Reducer = (state = initState, action) => {
    switch (action.type) {
        case SHOW_LOADING: {
            return {...state, loading: true};
        }
        case HIDE_LOADING: {
            return {...state, loading: false};
        }
        default:
            return state;
    }
};