import {Registry, withRegistry} from '@bem-react/di'
import {App as AppCommon, appId} from './App'

import {
    Header,
    Footer
} from "../../blocks/"
import {
    MainPage,
    ProjectsPage,
    ConstructorPage,
    ProfilePage
} from '../../pages/index@desktop'
import React from "react";
import {IHeaderProps} from "../../blocks/layout/Header/Header";

export const registry = new Registry({id: appId});

registry.set('MainPage', MainPage);
registry.set('ProjectsPage', ProjectsPage);
registry.set('ConstructorPage', ConstructorPage);
registry.set('ProfilePage', ProfilePage);
registry.set<React.FC<IHeaderProps>>('Header', Header);
registry.set('Footer', Footer);

export const AppDesktop = withRegistry(registry)(AppCommon);