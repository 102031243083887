import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {CheckboxInput, cnCheckboxInput} from '../../../all/CheckboxInput';
import {cnConstructorForm} from '../ConstructorForm';
import {useDispatch} from 'react-redux';
import {CHANGE_SIDE_CONTOUR_PANEL} from '../../../../constants';
import {TFormSideWardrobe} from '../../../../../common-code/domain/types';

export const cnSidesFragment = cn('SidesFragment');

export interface ISidesFragmentProps extends IClassNameProps {
    sideWardrobe: TFormSideWardrobe;
    sideIndex: number;
}

export const SideContourPanelsFragment: React.FC<ISidesFragmentProps> = ({className, sideWardrobe, sideIndex}) => {

    const dispatch = useDispatch();
    const changeContourPanel = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: CHANGE_SIDE_CONTOUR_PANEL,
            sideIndex: sideIndex,
            name: event.target.name,
            payload: event.target.checked

        })
    }

    return (
        <div className={cnSidesFragment({}, [className])}>
            <div className={cnConstructorForm('Sides')}>
                <div className={cnConstructorForm('Checkbox')}>
                    <CheckboxInput
                        className={cnConstructorForm(cnCheckboxInput(), {})}
                        checked={sideWardrobe.contourPanels.left}
                        name={'left'}
                        callBack={changeContourPanel}
                    />
                    <div className={cnConstructorForm('CheckboxTitle')}>Левая сторона</div>
                </div>
                <div className={cnConstructorForm('Checkbox')}>
                    <CheckboxInput
                        className={cnConstructorForm(cnCheckboxInput(), {})}
                        checked={sideWardrobe.contourPanels.right}
                        name={'right'}
                        callBack={changeContourPanel}
                    />
                    <div className={cnConstructorForm('CheckboxTitle')}>Правая сторона</div>
                </div>
                <div className={cnConstructorForm('Checkbox')}>
                    <CheckboxInput
                        className={cnConstructorForm(cnCheckboxInput(), {})}
                        checked={sideWardrobe.contourPanels.bottom}
                        name={'bottom'}
                        callBack={changeContourPanel}
                    />
                    <div className={cnConstructorForm('CheckboxTitle')}>Нижняя сторона</div>
                </div>
                <div className={cnConstructorForm('Checkbox')}>
                    <CheckboxInput
                        className={cnConstructorForm(cnCheckboxInput(), {})}
                        checked={sideWardrobe.contourPanels.top}
                        name={'top'}
                        callBack={changeContourPanel}
                    />
                    <div className={cnConstructorForm('CheckboxTitle')}>Верхняя сторона</div>
                </div>
            </div>
        </div>
    );
}