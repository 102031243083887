import React, {useEffect} from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./LocationMenu.css"
import SvgIcon from "../../../helpers/SvgIcon/SvgIcon";
import '../../../i18n';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {SET_LOCATION, TOGGLE_MENU} from "../../../constants";
import {useComponentVisible} from "../../../hooks";
import {TLocationData} from '../../../../common-code/domain/types';
import {LocationModal} from '../../modals/LocationModal/LocationModal';

export const cnLocationMenu = cn('LocationMenu');

export interface ILocationMenuProps extends IClassNameProps {
}

export const LocationMenu: React.FC<ILocationMenuProps> = ({className}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const currentLocation: TLocationData | undefined = useSelector((state: AppState) => state.location.current);
    const {ref, setIsComponentVisible} = useComponentVisible("LocationMenu", false);
    const isMenuOpen = useSelector((state: AppState) => state.menus.LocationMenu.visible);
    const showMenu = () => {
        if (!isMenuOpen) {
            dispatch({
                type: TOGGLE_MENU,
                payload: {
                    forMenu: "LocationMenu",
                    value: true
                },
            });
        }
    };

    useEffect(() => {
        if (currentLocation) {
            return;
        }
        axios.get('/api/location/current')
            .then((response) => {
                dispatch({type: SET_LOCATION, payload: response.data});
            });
    }, [currentLocation, dispatch]);

    return (
        <div ref={ref} className={cnLocationMenu({}, [className])} onClick={showMenu}>
            <SvgIcon className={cnLocationMenu('Icon')}
                     icon="location" label={t('user-city')}/>
            <div className={cnLocationMenu('Value')}>
                {currentLocation ? currentLocation.title : t('city-not-init')}
            </div>
            <LocationModal setIsComponentVisible={setIsComponentVisible}/>
        </div>
    );
};