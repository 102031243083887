import {BufferAttribute, BufferGeometry, Vector2, Vector3} from "three";
import {
    AXIS_X,
    AXIS_Z,
} from "../../../../../domain/constants";
import {
    TAxisType,
} from "../../../../../domain/types";
import {IThreePanelPoints} from "../../interfaces";
import {TPoint3D} from "../../../../../../../common/domain/types";
import {TPoint2D} from "../../../../../../../common/domain/types";
import {TThreeLine} from "../../../../types/TThreeLine";
import {Geometry} from '../../../../../domain/helpers/Geometry';
import {TLine} from '../../../../../../../common/domain/types';
import {TDirectEquation} from '../../../../../common-code/domain/types/TDirectEquation';

export class ThreeGeometry extends Geometry {
    /**
     * Метод возвращает длину векторов (расстояние между точками).
     *
     * @param pointA
     * @param pointB
     */
    public static getLength(pointA: Vector3, pointB: Vector3): number {
        return pointA.clone().distanceTo(pointB.clone());
    }

    public static getLength2D(pointA: Vector2, pointB: Vector2): number {
        return pointA.clone().distanceTo(pointB.clone());
    }


    public static getPointByRatio2D(
        startPoint: Vector2, endPoint: Vector2, ratio: number, isInclude: boolean = true): Vector2 {
        let lambda: number;
        let point: Vector2;

        if (ratio >= 1 && isInclude) {
            return endPoint;
        }
        if (ratio === 1) {
            return endPoint;
        }
        lambda = ratio / (1 - ratio);
        point = new Vector2(
            +((startPoint.x + lambda * endPoint.x) / (1 + lambda)).toFixed(3),
            +((startPoint.y + lambda * endPoint.y) / (1 + lambda)).toFixed(3)
        );

        return point;
    }

    /**
     * Получение координат точки на отрезке между startPoint и endPoint
     * на расстоянии равном коэфициенту длины ratio
     * относительно длины отрезка startPoint-endPoint
     *
     * @param startPoint
     * @param endPoint
     * @param ratio
     * @param isInclude
     * @returns Vector3
     */
    public static getPointByRatio(
        startPoint: Vector3, endPoint: Vector3, ratio: number, isInclude: boolean = true): Vector3 {
        let point: TPoint3D;

        point = super.getPointByRatio(this.toPoint3D(startPoint), this.toPoint3D(endPoint), ratio, isInclude);

        return new Vector3(point.x, point.y, point.z);
    }

    public static toPoint3D(vector: Vector3): TPoint3D {
        return {x: +vector.x, y: +vector.y, z: +vector.z};
    }

    public static toPoint2D(vector: Vector2): TPoint2D {
        return {x: +vector.x, y: +vector.y};
    }

    public static toVector2D(vector: Vector3, axis1: TAxisType = AXIS_X, axis2: TAxisType = AXIS_Z): Vector2 {
        return new Vector2(vector[axis1], vector[axis2]);
    }

    public static getShiftPoint2D(point: Vector2, pointA: Vector2, pointB: Vector2, shift: number): Vector2 {
        let shiftPoint: TPoint2D;

        shiftPoint = super.getShiftPoint2D(this.toPoint2D(point), this.toPoint2D(pointA), this.toPoint2D(pointB), shift);

        return new Vector2(+shiftPoint.x, +shiftPoint.y);
    }

    /**
     * Метод возвращает true, если прямые равны друг другу (являются одной прямой).
     *
     * @param line1
     * @param line2
     * @returns boolean
     */
    public static isEqualThreeLines(line1: TThreeLine, line2: TThreeLine): boolean {
        let directEquation1: TDirectEquation;
        let directEquation2: TDirectEquation;

        directEquation1 = this.directEquation(line1.pointA, line1.pointB);
        directEquation2 = this.directEquation(line2.pointA, line2.pointB);

        return super.isEqualLines(directEquation1, directEquation2);
    }

    public static getParallelLine(line: TThreeLine, shift: number): TThreeLine {
        let pointA;
        let pointB;

        pointA = this.getShiftPoint2D(line.pointA, line.pointA, line.pointB, shift);
        pointB = this.getShiftPoint2D(line.pointB, line.pointA, line.pointB, shift);

        return {pointA: pointA, pointB: pointB};
    }

    /**
     * Метод возвращает координаты точки пересечения прямых line1 и line2, заданных формулой y = kx + b,
     * или undefined для параллельных прямых.
     *
     * @param line1
     * @param line2
     */
    public static getIntersectionPoint(line1: TLine, line2: TLine): Vector2 | undefined {
        let point: TPoint2D | undefined;

        point = super.getIntersectionPoint(line1, line2);

        return (point) ? new Vector2(point.x, point.y) : undefined;
    }

    /**
     * Метод возвращает угол между переданным вектором и нормалью в радианах
     *
     * @param vector
     * @returns number
     */
    public static getNormalAngle(vector: Vector2): number {
        return super.getNormalAngle(this.toPoint2D(vector));
    }

    public static turnVector2D(vector: Vector2, angle: number, pointCenter?: Vector2): Vector2 {
        let result: TPoint2D;

        result = super.turnVector2D(
            this.toPoint2D(vector),
            angle,
            (pointCenter ? this.toPoint2D(pointCenter) : undefined)
        );

        return new Vector2(
            result.x,
            result.y
        );
    }

    /**
     * Получение формулы прямой y = k*x + b по двум точкам
     *
     * @param pointA
     * @param pointB
     * @returns TDirectEquation
     */
    public static directEquation(pointA:Vector2, pointB: Vector2): TDirectEquation
    {
        return super.directEquation(this.toPoint2D(pointA), this.toPoint2D(pointB));
    }

    /**
     * Возвращает true, если разница между координатами точек не превышает epsilon
     *
     * @param point1
     * @param point2
     * @param epsilon
     * @returns boolean
     */
    public static isEqualPoints2D(point1:Vector2, point2: Vector2, epsilon?: number): boolean
    {
        return super.isEqualPoints2D(this.toPoint2D(point1), this.toPoint2D(point2), epsilon);
    }


    public static createBoxGeometry(points: IThreePanelPoints): BufferGeometry
    {
        let boxGeometry;
        let positions = [];
        let normals = [];
        let uvs = [];
        let vertices;
        const positionNumComponents = 3;
        const normalNumComponents = 3;
        const uvNumComponents = 2;

        vertices = [
            // front
            { pos: points.A2.toArray(), norm: [ 0,  0,  1], uv: [0, 0], },
            { pos: points.C2.toArray(), norm: [ 0,  0,  1], uv: [1, 0], },
            { pos: points.B2.toArray(), norm: [ 0,  0,  1], uv: [0, 1], },
            { pos: points.B2.toArray(), norm: [ 0,  0,  1], uv: [0, 1], },
            { pos: points.C2.toArray(), norm: [ 0,  0,  1], uv: [1, 0], },
            { pos: points.D2.toArray(), norm: [ 0,  0,  1], uv: [1, 1], },
            // right
            { pos: points.C2.toArray(), norm: [ 1,  0,  0], uv: [0, 0], },
            { pos: points.C.toArray(), norm: [ 1,  0,  0], uv: [1, 0], },
            { pos: points.D2.toArray(), norm: [ 1,  0,  0], uv: [0, 1], },
            { pos: points.D2.toArray(), norm: [ 1,  0,  0], uv: [0, 1], },
            { pos: points.C.toArray(), norm: [ 1,  0,  0], uv: [1, 0], },
            { pos: points.D.toArray(), norm: [ 1,  0,  0], uv: [1, 1], },
            // back
            { pos: points.C.toArray(), norm: [ 0,  0, -1], uv: [0, 0], },
            { pos: points.A.toArray(), norm: [ 0,  0, -1], uv: [1, 0], },
            { pos: points.D.toArray(), norm: [ 0,  0, -1], uv: [0, 1], },
            { pos: points.D.toArray(), norm: [ 0,  0, -1], uv: [0, 1], },
            { pos: points.A.toArray(), norm: [ 0,  0, -1], uv: [1, 0], },
            { pos: points.B.toArray(), norm: [ 0,  0, -1], uv: [1, 1], },
            // left
            { pos: points.A.toArray(), norm: [-1,  0,  0], uv: [0, 0], },
            { pos: points.A2.toArray(), norm: [-1,  0,  0], uv: [1, 0], },
            { pos: points.B.toArray(), norm: [-1,  0,  0], uv: [0, 1], },
            { pos: points.B.toArray(), norm: [-1,  0,  0], uv: [0, 1], },
            { pos: points.A2.toArray(), norm: [-1,  0,  0], uv: [1, 0], },
            { pos: points.B2.toArray(), norm: [-1,  0,  0], uv: [1, 1], },
            // top
            { pos: points.B.toArray(), norm: [ 0,  1,  0], uv: [1, 0], },
            { pos: points.D2.toArray(), norm: [ 0,  1,  0], uv: [0, 1], },
            { pos: points.D.toArray(), norm: [ 0,  1,  0], uv: [0, 0], },
            { pos: points.D2.toArray(), norm: [ 0,  1,  0], uv: [0, 1], },
            { pos: points.B.toArray(), norm: [ 0,  1,  0], uv: [1, 0], },
            { pos: points.B2.toArray(), norm: [ 0,  1,  0], uv: [1, 1], },
            // bottom
            { pos: points.A2.toArray(), norm: [ 0, -1,  0], uv: [0, 1], },
            { pos: points.A.toArray(), norm: [ 0, -1,  0], uv: [1, 0], },
            { pos: points.C2.toArray(), norm: [ 0, -1,  0], uv: [0, 0], },
            { pos: points.C2.toArray(), norm: [ 0, -1,  0], uv: [1, 0], },
            { pos: points.A.toArray(), norm: [ 0, -1,  0], uv: [0, 1], },
            { pos: points.C.toArray(), norm: [ 0, -1,  0], uv: [1, 1], },
        ];

        for (const vertex of vertices) {
            positions.push(...vertex.pos);
            normals.push(...vertex.norm);
            uvs.push(...vertex.uv);
        }
        boxGeometry = new BufferGeometry();
        boxGeometry.setAttribute(
            'position',
            new BufferAttribute(new Float32Array(positions), positionNumComponents));
        boxGeometry.setAttribute(
            'normal',
            new BufferAttribute(new Float32Array(normals), normalNumComponents));
        boxGeometry.setAttribute(
            'uv',
            new BufferAttribute(new Float32Array(uvs), uvNumComponents));

        return boxGeometry;
    }

    public static  isCoDirectionVectors(vector1: Vector2, vector2: Vector2): boolean
    {
        let coDirectionAngle;
        coDirectionAngle = this.getAngle2D(vector1, vector2);
        coDirectionAngle = coDirectionAngle * 180 / Math.PI;

        return !(Math.round(coDirectionAngle) > 90 && Math.round(coDirectionAngle) < 270);
    }

    /**
     * Метод возвращает угол между двумя векторами
     *
     * @param vector1
     * @param vector2
     * @returns number
     */
    public static getAngle2D(vector1: Vector2, vector2: Vector2): number {
        return super.getAngle2D(this.toPoint2D(vector1), this.toPoint2D(vector2));
    }

    public static getNearPoint2D(point: Vector2, points: Vector2[]): Vector2 | undefined
    {
        let selectedPoint,
            currentPoint,
            index;

        if (point && points && points.length > 0) {
            for (index in points) {
                currentPoint = points[index];
                if (selectedPoint === undefined) {
                    selectedPoint = currentPoint;
                } else if (selectedPoint &&
                    this.getLength2D(point, currentPoint) < this.getLength2D(point, selectedPoint)) {
                    selectedPoint = currentPoint;
                }
            }
            return selectedPoint;
        }
    }
}