import {ThreePlank} from "..";
import {IProfile} from "../../../../../../domain/intarfaces/IProfile/IProfile";
import {ThreeDoor} from "../../ThreeDoor/ThreeDoor";
import {ThreeDoorPoint} from "../../points/ThreeDoorPoint/ThreeDoorPoint";
import {IThreePanelIndentionPoints, IThreePanelPoints} from "../../../interfaces";
import {Box3, Euler, ExtrudeGeometry, Mesh, Shape, Vector2, Vector3} from "three";
import {ThreeGeometry} from "../../../helpers";
import {AXIS_X, AXIS_Y, AXIS_Z,} from "../../../../../../domain/constants";
import {IProfileData} from "../../../../../../common-code/domain/interfaces/IProfileData/IProfileData";
import {
    CONNECTION_TYPE_MAIN_LEFT,
    CONNECTION_TYPE_MAIN_RIGHT,
} from "../../../../../../common-code/constants";
import {TPoint3D, TShapeData} from "../../../../../../common-code/domain/types";
import {TPanelShift} from '../../../../../../common-code/domain/types';
import {TPoint2D} from '../../../../../../common-code/domain/types';
import {TProfileType} from '../../../../../../common-code/domain/types';
import {ThreeProfilePoint} from "../../points/ThreeProfilePoint/ThreeProfilePoint";
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {IEntityData} from "../../../../../../common-code/domain/interfaces/IEntityData";
import {Geometry} from "../../../../../../domain/helpers/Geometry";
import {
    IPlankMaterialPriceData
} from '../../../../../../common-code/domain/interfaces/IPriceData/IPlankMaterialPriceData';
import {IPlankEntityData} from '../../../../../../common-code/domain/interfaces/IPlankEntityData';

export class ThreeProfile extends ThreePlank implements IProfile {
    door: ThreeDoor;
    pointA: ThreeDoorPoint | ThreeProfilePoint;
    pointB: ThreeDoorPoint | ThreeProfilePoint;
    sort: number;
    width: number;
    depth: number;
    shift: TPanelShift;
    points?: IThreePanelPoints;
    indentionPoints?: IThreePanelIndentionPoints;
    visiblePoints?: IThreePanelPoints;
    shape: Shape;
    leftNeighbor?: ThreeProfile;
    rightNeighbor?: ThreeProfile;
    type: TProfileType;
    shapeId?: number;
    materialId: number;
    material?: IEntityData;
    
    constructor(door: ThreeDoor, options: IProfileData) {
        super(door.mainConstructor, options);
        this.door = door;
        this.pointA = this.door.getPointById(options.pointA) as ThreeDoorPoint;
        this.pointB = this.door.getPointById(options.pointB) as ThreeDoorPoint;
        this.sort = options.sort;
        this.shift = options.shift;
        this.width = options.width;
        this.depth = options.depth;
        this.type = options.type;
        this.shapeId = options.shapeId;
        this.shape = this.createShape(this.mainConstructor.getShapeById(options.shapeId));
        this.materialId = options.materialId;
        this.material = this.initMaterialData();
    }

    public initState() {
        this.calculatePoints();
    }

    public createView(): void {
        let bodyPosition;
        let bodyRotation;
        let directionVector;
        let box;

        if (!this.visiblePoints) {
            return;
        }
        this.body = new Mesh(this.createBodyGeometry(), this.createBodyMaterial());
        this.body.add(this.createCarcassMesh(this.body.geometry));
        box = new Box3().setFromPoints(Object.values(this.visiblePoints));
        bodyPosition = new Vector3();
        bodyPosition = box.getCenter(bodyPosition);
        directionVector = this.pointB.value.clone().sub(this.pointA.value.clone());
        bodyRotation = new Euler(
            ThreeGeometry.getAngle2D(ThreeGeometry.toVector2D(directionVector, AXIS_X, AXIS_Y), new Vector2(1, 0)),
            ThreeGeometry.getAngle2D(ThreeGeometry.toVector2D(directionVector, AXIS_X, AXIS_Y), new Vector2(0, 1)),
            ThreeGeometry.getAngle2D(ThreeGeometry.toVector2D(directionVector, AXIS_X, AXIS_Z), new Vector2(0, 1))
        );
        this.body.rotation.copy(bodyRotation);
        this.body.rotateZ(-Math.PI/2);
        this.body.position.copy(bodyPosition);
        this.view3d.add(this.body);
        super.createView();
    }

    public getSaveData(): IProfileData {
        super.getSaveData();
        let globalPoints: IStartEndPoints | undefined;
        globalPoints = this.calculateGlobalPoints();
        return {
            id: this.getId(),
            materialId: this.material ? this.material.id : 0,
            shapeId: this.shapeId,
            pointA: this.pointA.getId(),
            pointB: this.pointB.getId(),
            width: this.width,
            type: this.type,
            shift: this.shift,
            depth: this.depth,
            sort: this.sort,
            startPoint: globalPoints?.start,
            endPoint: globalPoints?.end,
            length: globalPoints?.length,
            rotation: globalPoints?.rotation
        };
    }

    public getPriceData(): IPlankMaterialPriceData | undefined {
        let material: IPlankEntityData | undefined;
        let plankCount: number;

        material = this.mainConstructor.getProfileMaterial(this.getType());
        if (!material) {
            return undefined;
        }

        plankCount = Math.ceil((this.getLength() / material.length));
        return {
            materialId: material.id,
            store: material.store ? '' + material.store : '-',
            name: material.name,
            length: this.getLength(),
            errors: [],
            plankPrice: material.price,
            code: material.product.code,
            plankLength: material.length,
            sum: plankCount * material.price,
            price: material.price,
            amount: material.store ? material.store : 0,
            count: plankCount
        };
    }

    public getType(): TProfileType {
        return this.type;
    }

    public getLength() {
        if (!this.visiblePoints) {
            return 0;
        }
        return Math.max(
            ThreeGeometry.getLength(this.visiblePoints.A, this.visiblePoints.B),
            ThreeGeometry.getLength(this.visiblePoints.C, this.visiblePoints.D)
        );
    }


    public setLeftNeighbor(profile: ThreeProfile):void {
        this.leftNeighbor = profile;
    }

    public setRightNeighbor(profile: ThreeProfile): void {
        this.rightNeighbor = profile;
    }

    public calculatePoints() {
        // Если уже рассчитали точки, то не нужно заново считать
        if (this.points) {
            return;
        }
        let localPointA;
        let localPointB;
        let bottomPoints: { A: Vector3; B: Vector3; C: Vector3; D: Vector3 };
        let topPoints: { A: Vector3; B: Vector3; C: Vector3; D: Vector3 };

        localPointA = ThreeGeometry.toVector2D(
            this.pointA.value,
            AXIS_X,
            AXIS_Y
        );
        localPointB = ThreeGeometry.toVector2D(
            this.pointB.value,
            AXIS_X,
            AXIS_Y
        );
        bottomPoints = {
            A: this.calculateShiftBottomPoint(localPointA, localPointA, localPointB, -this.shift.width),
            B: this.calculateShiftBottomPoint(localPointB, localPointA, localPointB, -this.shift.width),
            C: this.calculateShiftBottomPoint(localPointA, localPointA, localPointB, -this.shift.width + this.width),
            D: this.calculateShiftBottomPoint(localPointB, localPointA, localPointB, -this.shift.width + this.width),
        };
        topPoints = {
            A: this.calculateShiftTopPoint(bottomPoints.A, this.depth + this.shift.depth),
            B: this.calculateShiftTopPoint(bottomPoints.B, this.depth + this.shift.depth),
            C: this.calculateShiftTopPoint(bottomPoints.C, this.depth + this.shift.depth),
            D: this.calculateShiftTopPoint(bottomPoints.D, this.depth + this.shift.depth),
        };
        this.points = {
            A: bottomPoints.A,
            B: bottomPoints.B,
            C: bottomPoints.C,
            D: bottomPoints.D,
            A2: topPoints.A,
            B2: topPoints.B,
            C2: topPoints.C,
            D2: topPoints.D,
        };
    }

    public calculateVisiblePoints() {
        if (!this.points) {
            return;
        }
        this.visiblePoints = {...this.points} as IThreePanelPoints;

        if (this.leftNeighbor) {
            this.calculateLeftNeighborIntersectPoints();
        }
        if (this.rightNeighbor) {
            this.calculateRightNeighborIntersectPoints();
        }
    }

    protected calculateGlobalPoints(): IStartEndPoints | undefined {
        let globalPoints: IStartEndPoints | undefined;
        let pointA: Vector3;
        let directionVector: TPoint3D;
        let bufferPoint: TPoint3D;

        globalPoints = super.calculateGlobalPoints();
        pointA = this.pointA.value.clone().applyMatrix4(this.view3d.matrixWorld);
        if (globalPoints) {
            if (Geometry.getLength(ThreeGeometry.toPoint3D(pointA), globalPoints.end) < Geometry.getLength(ThreeGeometry.toPoint3D(pointA), globalPoints.start)) {
                bufferPoint = globalPoints.end;
                globalPoints.end = globalPoints.start;
                globalPoints.start = bufferPoint;
            }
            directionVector = {
                x: (globalPoints.end.x - globalPoints.start.x),
                y: (globalPoints.end.y - globalPoints.start.y), 
                z: (globalPoints.end.z - globalPoints.start.z)
            }; 
            globalPoints.rotation = {
                x: Geometry.getAngle2D({x: directionVector.x, y: directionVector.z}, {x: 1, y: 0}),
                y: Geometry.getAngle2D({x: directionVector.x, y: directionVector.z}, {x: 1, y: 0}),
                z: Geometry.getAngle2D({x: directionVector.x, y: directionVector.y}, {x: 1, y: 0})
            }; 
        }

        return globalPoints;
    }

    protected calculateShiftBottomPoint(point: Vector2, pointA: Vector2, pointB: Vector2, shift: number): Vector3 {
        let shiftPoint: Vector2;

        shiftPoint = ThreeGeometry.getShiftPoint2D(point, pointA, pointB, shift);

        return new Vector3(
            shiftPoint.x,
            shiftPoint.y,
            this.shift.depth
        );
    }

    protected initMaterialData(): IEntityData | undefined {
        if (this.materialId > 0) {
            debugger;
        }
        return undefined;
    }

    private calculateLeftNeighborIntersectPoints() {
        let intersectPoint;
        if (!this.points || !this.visiblePoints || !this.leftNeighbor) {
            return;
        }
        this.leftNeighbor.calculatePoints();
        this.leftNeighbor.calculateIndentionPoints();
        if (this.leftNeighbor.points && this.leftNeighbor.indentionPoints) {
            switch (this.pointA.connectionType) {
                case CONNECTION_TYPE_MAIN_LEFT:
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.leftNeighbor.indentionPoints.rightA, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.leftNeighbor.indentionPoints.rightB, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.A = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.depth);
                        this.visiblePoints.A2 = new Vector3(intersectPoint.x, intersectPoint.y, this.depth + this.shift.depth);
                    }
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.leftNeighbor.indentionPoints.rightA, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.leftNeighbor.indentionPoints.rightB, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.C = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.depth);
                        this.visiblePoints.C2 = new Vector3(intersectPoint.x, intersectPoint.y, this.depth + this.shift.depth);
                    }
                    break;
            }
        }
    }

    private calculateRightNeighborIntersectPoints() {
        let intersectPoint;
        if (!this.points || !this.visiblePoints || !this.rightNeighbor) {
            return;
        }
        this.rightNeighbor.calculatePoints();
        this.rightNeighbor.calculateIndentionPoints();
        if (this.rightNeighbor.points && this.rightNeighbor.indentionPoints) {
            switch (this.pointB.connectionType) {
                case CONNECTION_TYPE_MAIN_RIGHT:
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.A, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.B, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.rightNeighbor.indentionPoints.leftA, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.rightNeighbor.indentionPoints.leftB, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.B = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.depth);
                        this.visiblePoints.B2 = new Vector3(intersectPoint.x, intersectPoint.y, this.depth + this.shift.depth);
                    }
                    intersectPoint = ThreeGeometry.getIntersectionPoint(
                        {
                            pointA: ThreeGeometry.toVector2D(this.points.C, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.points.D, AXIS_X, AXIS_Y)
                        },
                        {
                            pointA: ThreeGeometry.toVector2D(this.rightNeighbor.indentionPoints.leftA, AXIS_X, AXIS_Y),
                            pointB: ThreeGeometry.toVector2D(this.rightNeighbor.indentionPoints.leftB, AXIS_X, AXIS_Y)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.D = new Vector3(intersectPoint.x, intersectPoint.y, this.shift.depth);
                        this.visiblePoints.D2 = new Vector3(intersectPoint.x, intersectPoint.y, this.depth + this.shift.depth);
                    }
                    break;
            }
        }
    }

    public calculateIndentionPoints() {
        if (!this.points) {
            return;
        }
        let pointA;
        let pointB;
        let pointAIndentionValue: number;
        let pointBIndentionValue: number;

        pointA = ThreeGeometry.toVector2D(
            this.points.C,
            AXIS_X,
            AXIS_Y
        );
        pointB = ThreeGeometry.toVector2D(
            this.points.D,
            AXIS_X,
            AXIS_Y
        );

        pointAIndentionValue = this.mainConstructor.getConstructorService().getIndentionValue(
            this.pointA.connection.indention,
            this
        );
        pointBIndentionValue = this.mainConstructor.getConstructorService().getIndentionValue(
            this.pointA.connection.indention,
            this
        );
        this.indentionPoints = {
            leftA: this.calculateShiftBottomPoint(pointA, pointA, pointB, -pointAIndentionValue),
            leftB: this.calculateShiftBottomPoint(pointB, pointA, pointB, -pointAIndentionValue),
            rightA: this.calculateShiftBottomPoint(pointA, pointA, pointB, -pointBIndentionValue),
            rightB: this.calculateShiftBottomPoint(pointB, pointA, pointB, -pointBIndentionValue)
        };
    }

    private createBodyGeometry() {
        let geometry;

        geometry = new ExtrudeGeometry(this.shape, {depth: this.getLength(), bevelEnabled: false});
        geometry.center();

        return geometry;
    }

    private createShape(initShape?: TShapeData | undefined): Shape {
        let points: TPoint2D[];
        let shapePoints: Vector2[];
        let shape: Shape;
        let point;

        if (initShape) {
            points = initShape.points;
        } else {
            points = [
                {x: -this.width / 2, y: -this.depth / 2},
                {x: -this.width / 2, y: this.depth / 2},
                {x: this.width / 2, y: this.depth / 2},
                {x: this.width / 2, y: -this.depth / 2},

            ];
        }
        shapePoints = [];
        for (point of points) {
            shapePoints.push(new Vector2(point.x, point.y));
        }
        if (shapePoints.length <= 0) {
            throw new Error("Shape of ThreeRail cant be empty");
        }
        shape = new Shape();
        shape.setFromPoints(shapePoints);

        return shape;
    }
}