import {ThreeEditor} from "../ThreeEditor";

export class UserControls {
    editor: ThreeEditor;
    htmlContainer?: HTMLDivElement;

    constructor(editor: ThreeEditor) {
        this.editor = editor;
    }

    public run(htmlContainer: HTMLDivElement) {
        this.htmlContainer = htmlContainer;
        this.htmlContainer.addEventListener('pointerdown', this.onClickCanvas.bind(this), false);
    };

    public stop() {
        if (this.htmlContainer instanceof HTMLDivElement) {
            this.htmlContainer.removeEventListener('pointerdown', this.onClickCanvas.bind(this), false);
        }
    };

    onClickCanvas (event:PointerEvent) {
        event.preventDefault();
        this.editor.trySelect(event);
    };
}