import {ISizeData} from '../../../../../common-code/domain/interfaces/ISizeData/ISizeData';
import {BufferAttribute, BufferGeometry, Euler, LineSegments, Mesh, Vector3} from 'three';
import {ThreeObject} from '../../models/ThreeObject';
import {ThreeGeometry} from '../../helpers';
import {SIZE_DEFAULT_LINE_HEIGHT, SIZE_DEFAULT_LINE_INDENT, SIZE_DEFAULT_TEXT_INDENT} from '../../../../constants';
import {TextGeometryParameters} from 'three/examples/jsm/geometries/TextGeometry';

export class ThreeSize extends ThreeObject {
    saveData: ISizeData;
    pointA: Vector3;
    pointB: Vector3;
    length: number;
    direction: boolean;
    decimal: number;
    unit: ThreeObject;
    lineIndent: number;
    lineHeight: number;
    textIndent: number;
    textInvert: boolean;
    textSize?: number;
    meshPointA: Mesh;
    meshPointB: Mesh;
    textMesh: Mesh;

    constructor(options: ISizeData, unit: ThreeObject) {
        super(unit.mainConstructor, options);
        this.saveData = options;
        this.unit = unit;
        this.pointA = new Vector3(options.pointA.x, options.pointA.y, options.pointA.z);
        this.pointB = new Vector3(options.pointB.x, options.pointB.y, options.pointB.z);
        this.meshPointA = new Mesh();
        this.meshPointB = new Mesh();
        this.decimal = options.decimal !== undefined && !isNaN(+options.decimal) ?
            +options.decimal : 3;
        this.length = options.length !== undefined && !isNaN(+options.length) ?
            +options.length :
            +(ThreeGeometry.getLength(this.pointA, this.pointB)).toFixed(this.decimal);
        this.direction = options.direction !== undefined ? options.direction : true;
        this.lineIndent = options.lineIndent !== undefined && !isNaN(+options.lineIndent) ?
            +options.lineIndent : SIZE_DEFAULT_LINE_INDENT;
        this.lineHeight = options.lineHeight !== undefined && !isNaN(+options.lineHeight) ?
            +options.lineHeight : SIZE_DEFAULT_LINE_HEIGHT;
        this.textIndent = options.textIndent !== undefined && !isNaN(+options.textIndent) ?
            +options.textIndent : SIZE_DEFAULT_TEXT_INDENT;
        this.textInvert = options.textInvert !== undefined ? options.textInvert : false;
        this.textSize = options.textSize;
        this.view3d.userData.notSwitchView = true;
        this.textMesh = new Mesh();
    }

    public initState() {
        this.createPoints();
        this.createLines();
        this.createText();
        this.initPosition();
        this.initRotation();
    }

    public createView() {
        this.unit.view3d.add(this.view3d);
        this.view3d.name = 'Size';
        this.view3d.userData.vector = new Vector3();
        this.view3d.matrixAutoUpdate = false;
        this.updateAllMatrices();
        super.createView();
    }

    protected getLengthText() {
        return '' + this.length;
    }

    protected initPosition() {
        this.view3d.position.set(
            (this.pointB.x + this.pointA.x) / 2,
            (this.pointB.y + this.pointA.y) / 2,
            (this.pointB.z + this.pointA.z) / 2
        )
    }

    protected initRotation() {
        let rotation: Euler;
        rotation = new Euler();
        if (this.saveData.rotation) {
            if (this.saveData.rotation.x !== undefined && !Number.isNaN(+this.saveData.rotation.x)) {
                rotation.x = +this.saveData.rotation.x;
            }
            if (this.saveData.rotation.y !== undefined && !Number.isNaN(+this.saveData.rotation.y)) {
                rotation.y = +this.saveData.rotation.y;
            }
            if (this.saveData.rotation.z !== undefined && !Number.isNaN(+this.saveData.rotation.z)) {
                rotation.z = +this.saveData.rotation.z;
            }
        }
        this.view3d.rotation.copy(rotation);
    }

    protected createPoints() {
        this.meshPointA.position.set(-this.length / 2, 0, 0);
        this.meshPointB.position.set(this.length / 2, 0, 0);
        this.meshPointA.userData.centerPosition = this.meshPointA.position.clone();
        this.meshPointB.userData.centerPosition = this.meshPointB.position.clone();
        this.view3d.add(this.meshPointA);
        this.view3d.add(this.meshPointB);
    }

    protected createLines() {
        let geometry: BufferGeometry;
        let line: LineSegments;
        let positions;
        let indent: number;

        geometry = new BufferGeometry();

        indent = this.direction ? 1 : -1;
        positions = [
            -this.length / 2, 0, 0,
            -this.length / 2, indent * this.lineHeight, 0,
            this.length / 2, 0, 0,
            this.length / 2, indent * this.lineHeight, 0,
            -this.length / 2, indent * this.lineIndent, 0,
            this.length / 2, indent * this.lineIndent, 0
        ];
        geometry.setAttribute(
            'position',
            new BufferAttribute(new Float32Array(positions), 3));
        line = new LineSegments(geometry, this.mainConstructor.getSizeLineMaterial());
        line.name = 'line';
        line.matrixAutoUpdate = false;
        line.userData.notTransparentForBack = true;
        this.view3d.add(line);
    }

    protected createText() {
        let indent: number;

        indent = this.direction ? 1 : -1;
        this.textMesh = this.mainConstructor.createTextSizeMesh(this.getLengthText(), this.getTextParams());
        if (this.textInvert) {
            this.textMesh.rotation.z = -Math.PI * indent;
        }
        this.textMesh.position.y += this.lineIndent * indent + this.textIndent * indent;

        this.textMesh.userData.notTransparentForBack = true;
        this.view3d.add(this.textMesh);
    }

    protected getTextParams(): TextGeometryParameters | undefined {
        if (this.saveData.textSize) {
            return {size: this.saveData.textSize, font: this.mainConstructor.getFont()};
        }
        return undefined;
    }

}