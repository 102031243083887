import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnRangeInput, RangeInput} from '../../../all/RangeInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {Dispatch} from 'redux';
import {CHANGE_WARDROBE_DEPTH} from '../../../../constants';
import {
    WARDROBE_MIN_DEPTH,
    WARDROBE_MAX_DEPTH
} from '../../../../../common-code/constants';

export const cnDepthFragment = cn('DepthFragment');

export interface IDepthFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    sideIndex: number,
    dispatch: Dispatch,
    min: number,
    max: number,
    step?: number
}

export const DepthFragment: React.FC<IDepthFragmentProps> = ({ className, constructorFormData, sideIndex, dispatch, min, max, step }) => {

    const changeDepth = (depth: number) => {
        if (depth < WARDROBE_MIN_DEPTH) {
            depth = WARDROBE_MIN_DEPTH;
        }
        if (depth > WARDROBE_MAX_DEPTH) {
            depth = WARDROBE_MAX_DEPTH;
        }

        dispatch({
            type: CHANGE_WARDROBE_DEPTH,
            sideIndex: sideIndex,
            payload: depth,
        });
    };

    return (
        <div className={cnConstructorForm(cnDepthFragment(), [className, cnConstructorForm('RangeInputContainer')])}>
            <span className={cnConstructorForm(
                'Label',
                {size: 'sm', color: 'lightNavy'},
                [cnConstructorForm('RangeInputLabel')]
            )}>Глубина, мм</span>
            <RangeInput
                className={cnConstructorForm(cnRangeInput())}
                min={min}
                max={max}
                step={step}
                value={constructorFormData.sideWardrobes[sideIndex].depth}
                callBack={changeDepth}
                viewControl={true}
            />
        </div>
    );
};