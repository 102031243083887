import {ThreePoint} from "..";
import {Vector3} from "three";
import {ThreeDoor} from "../../ThreeDoor/ThreeDoor";
import {IDoorPointData} from "../../../../../../common-code/domain/interfaces/IDoorPointData/IDoorPointData";
import {ThreeGeometry} from "../../../helpers/ThreeGeometry";
import {LEVEL_BOTTOM, LEVEL_TOP} from "../../../../../../common-code/constants";
import {TLevel} from "../../../../../../common-code/domain/types/TLevel";
import {TPointConnection} from '../../../../../../common-code/domain/types/TPointConnection';

export class ThreeDoorPoint extends ThreePoint {
    value: Vector3;
    door: ThreeDoor;
    connection: TPointConnection;
    level: TLevel;
    ratio: number;

    constructor(door: ThreeDoor, options: IDoorPointData) {
        super(door.mainConstructor, options);
        this.door = door;
        this.level = options.level;
        this.ratio = options.ratio;
        this.value = new Vector3();
        this.connection = this.mainConstructor.getPointConnectionById(options.connectionId);
    }

    public getSaveData(): IDoorPointData {
        super.getSaveData();
        return {
            connectionId: this.connectionId,
            connectionType: this.connectionType,
            sort: this.sort,
            type: this.type,
            id: this.id,
            ratio: this.ratio,
            level: this.level,
        }
    }

    public calculatePosition(): Vector3 {
        if (!this.door.doorContainer) {
            throw new Error('ThreeProfilePoint required profile!');
        }
        this.value = this.getPositionByRatio();
        if (!this.isInit) {
            this.isInit = true;
        }

        return this.value;
    }

    public getPositionByRatio(): Vector3 {
        let pointA: Vector3;
        let pointB: Vector3;
        let position: Vector3;

        pointA = this.door.doorContainer.pointA.clone();
        pointB = this.door.doorContainer.pointB.clone();
        switch (this.level) {
            case LEVEL_BOTTOM:
                pointA.y = this.mainConstructor.getConstructorService().technologMap.door.verticalGap.bottom;
                pointB.y = this.mainConstructor.getConstructorService().technologMap.door.verticalGap.bottom;
                break;
            case LEVEL_TOP:
                pointA.y = this.door.doorContainer.getHeight() - this.mainConstructor.getConstructorService().technologMap.door.verticalGap.top;
                pointB.y = this.door.doorContainer.getHeight() - this.mainConstructor.getConstructorService().technologMap.door.verticalGap.top;
                break;
        }

        position = ThreeGeometry.getPointByRatio(
            pointA,
            pointB,
            this.ratio
        );

        return position;
    }
}