import {Reducer} from "redux";
import {ISessionData} from "../interfaces";
import {CLOSE_SESSION, START_SESSION} from "../constants";

export const SessionReducer: Reducer = (state: ISessionData | null = null, action) => {
    switch (action.type) {
        case START_SESSION: {
            state = action.payload;

            return state;
        }
        case CLOSE_SESSION: {
            state = {};

            return state;
        }
        default: {
            return state;
        }
    }
};