import { useEffect, useRef } from 'react';
import {useDispatch} from "react-redux";
import {TOGGLE_MENU} from "../constants";
import {IsComponentVisible} from '../interfaces/IsComponentVisible';

export default function useComponentVisible(forMenu: string, isClickOutSide: boolean = true) {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const setIsComponentVisible: IsComponentVisible = (isMenuOpen: boolean) => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: forMenu,
                value: isMenuOpen
            },
        });
    };
    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        if (isClickOutSide) {
            document.addEventListener('keydown', handleHideDropdown, true);
            document.addEventListener('click', handleClickOutside, true);
        }
        return () => {
            if (isClickOutSide) {
                document.removeEventListener('keydown', handleHideDropdown, true);
                document.removeEventListener('click', handleClickOutside, true);
            }
        };
    });

    return { ref, setIsComponentVisible };
}