import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnRadioboxInput, RadioboxInput} from '../../../all/RadioboxInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {ProjectGenerator} from '../../../../../domain/services/ProjectGenerator/ProjectGenerator';
import {Dispatch} from 'redux';
import {CHANGE_CONSTRUCTOR_FORM_DATA} from '../../../../constants';
import {
    DIRECTION_TYPE_NORMAL,
    DIRECTION_TYPE_ANGLE,
    DIRECTION_TYPE_UTYPE,
    WARDROBE_DEFAULT_WIDTH,
    WARDROBE_DEFAULT_DEPTH,
    WARDROBE_DEFAULT_HEIGHT,
    WARDROBE_DEFAULT_SMALL_WIDTH,
    DEFAULT_COUNT_SECTIONS,
    DEFAULT_COUNT_DOORS,
    SHELVES_TYPE_REMOVABLE,
} from '../../../../../common-code/constants';
import {TTechnologMap} from '../../../../../common-code/domain/types';
import {TRadioboxOption} from '../../../../../common-code/domain/types/TRadioboxOption';
import {TFormSideWardrobe} from '../../../../../common-code/domain/types';
import {TDirectionType} from '../../../../../common-code/domain/types';
import {CommonHelper} from '../../../../helpers/CommonHelper';
import {ThreeConstructorService} from '../../../3d';

export const cnDirectionTypeFragment = cn('DirectionTypeFragment');

export interface IDirectionTypeFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    constructorService: ThreeConstructorService,
    technologMap: TTechnologMap | undefined,
    dispatch: Dispatch,
    options: Array<TRadioboxOption>
}

export const DirectionTypeFragment: React.FC<IDirectionTypeFragmentProps> = ({
                                                                                 className,
                                                                                 constructorFormData,
                                                                                 constructorService,
                                                                                 technologMap,
                                                                                 dispatch,
                                                                                 options
                                                                             }) => {

    const changeDirectionType = (event: ChangeEvent<HTMLInputElement>) => {
        let newConstructorFormData = CommonHelper.deepCopy(constructorFormData);

        if (!technologMap) {
            return;
        }

        let oldSide: TFormSideWardrobe;

        oldSide = constructorFormData.sideWardrobes[0] ?
            {...constructorFormData.sideWardrobes[0]} : ProjectGenerator.getDefaultFormSideWardrobe(
                {
                    width: WARDROBE_DEFAULT_WIDTH,
                    depth: WARDROBE_DEFAULT_DEPTH,
                    height: WARDROBE_DEFAULT_HEIGHT
                },
                constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0,
                DEFAULT_COUNT_SECTIONS,
                DEFAULT_COUNT_DOORS,
                SHELVES_TYPE_REMOVABLE
            );
        newConstructorFormData.directionType = event.target.value as TDirectionType;
        switch (newConstructorFormData.directionType) {
            case DIRECTION_TYPE_NORMAL:
                newConstructorFormData.sideWardrobes = [
                    ProjectGenerator.getDefaultFormSideWardrobe(
                        {
                            width: WARDROBE_DEFAULT_WIDTH,
                            depth: oldSide.depth,
                            height: oldSide.height
                        },
                        constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0,
                        DEFAULT_COUNT_SECTIONS,
                        DEFAULT_COUNT_DOORS,
                        SHELVES_TYPE_REMOVABLE
                    )
                ];
                break;
            case DIRECTION_TYPE_ANGLE:
                newConstructorFormData.sideWardrobes = [
                    ProjectGenerator.getDefaultFormSideWardrobe(
                        {
                            width: WARDROBE_DEFAULT_SMALL_WIDTH,
                            depth: oldSide.depth,
                            height: oldSide.height
                        },
                        constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0,
                        DEFAULT_COUNT_SECTIONS,
                        DEFAULT_COUNT_DOORS,
                        SHELVES_TYPE_REMOVABLE
                    ),
                    ProjectGenerator.getDefaultFormSideWardrobe(
                        {
                            width: WARDROBE_DEFAULT_WIDTH,
                            depth: oldSide.depth,
                            height: oldSide.height
                        },
                        constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0,
                        DEFAULT_COUNT_SECTIONS,
                        DEFAULT_COUNT_DOORS,
                        SHELVES_TYPE_REMOVABLE
                    )
                ];
                break;
            case DIRECTION_TYPE_UTYPE:
                newConstructorFormData.sideWardrobes = [
                    ProjectGenerator.getDefaultFormSideWardrobe(
                        {
                            width: WARDROBE_DEFAULT_SMALL_WIDTH,
                            depth: oldSide.depth,
                            height: oldSide.height
                        },
                        constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0,
                        DEFAULT_COUNT_SECTIONS,
                        DEFAULT_COUNT_DOORS,
                        SHELVES_TYPE_REMOVABLE
                    ),
                    ProjectGenerator.getDefaultFormSideWardrobe(
                        {
                            width: WARDROBE_DEFAULT_WIDTH,
                            depth: oldSide.depth,
                            height: oldSide.height
                        },
                        constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0,
                        DEFAULT_COUNT_SECTIONS,
                        DEFAULT_COUNT_DOORS,
                        SHELVES_TYPE_REMOVABLE
                    ),
                    ProjectGenerator.getDefaultFormSideWardrobe(
                        {
                            width: WARDROBE_DEFAULT_SMALL_WIDTH,
                            depth: oldSide.depth,
                            height: oldSide.height
                        },
                        constructorService.selectDoorLdsp ? constructorService.selectDoorLdsp.id : 0,
                        DEFAULT_COUNT_SECTIONS,
                        DEFAULT_COUNT_DOORS,
                        SHELVES_TYPE_REMOVABLE
                    )
                ];
                break;
        }
        dispatch({
            type: CHANGE_CONSTRUCTOR_FORM_DATA,
            payload: newConstructorFormData,
        });
    };

    return (
        <div className={cnConstructorForm(cnDirectionTypeFragment(), [className])}>
            <RadioboxInput
                className={cnConstructorForm(cnRadioboxInput())}
                options={options}
                value={constructorFormData.directionType}
                callBack={changeDirectionType}
            />
        </div>
    );
};