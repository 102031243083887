import React, {useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {cn} from "@bem-react/classname";
import {useRegistry} from "@bem-react/di";
import './App.css';
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../redux/AppStore";
import {configureRootTheme} from '@yandex/ui/Theme'
import {theme} from '@yandex/ui/Theme/presets/default'
import {CHANGE_TECHNOLOG_MAP, SET_USER, START_SESSION} from "../../constants";
import {ISessionData} from '../../interfaces';
import {useEffectDidMount} from '../../hooks/useEffectDidMount';

export const cnApp = cn('App');
export const appId = cnApp();

configureRootTheme({theme});

export const App = () => {
    const {MainPage, ProjectsPage, ConstructorPage} = useRegistry(appId);
    const [configError, setConfigError] = useState<boolean>(false);
    const session: ISessionData | null = useSelector((state: AppState) => state.session);
    const dispatch = useDispatch();

    useEffectDidMount(() => {
        axios.post('/api/session/start')
            .then((response: any) => {
                dispatch({
                    type: SET_USER,
                    payload: response.data.userData,
                });
                delete response.data.userData;
                dispatch({
                    type: START_SESSION,
                    payload: response.data,
                });
            })
            .catch(() => {
                dispatch({
                    type: START_SESSION,
                    payload: {success: false},
                });
            });
        axios.get('/api/entity/technolog-map')
            .then((response) => {
                dispatch({
                    type: CHANGE_TECHNOLOG_MAP,
                    payload: response.data,
                });
            })
            .catch(() => {
                setConfigError(true);
            });
    });

    const {t} = useTranslation();

    if (configError) {
        return (<div className={cnApp('LoadingData')}>
            <div className={cnApp('Loading', {error: true})}>
                {t('Ошибка загрузки конфигурации приложения')}!
            </div>
        </div>);
    }

    if (!session) {
        return (<div className={cnApp('LoadingData')}>
            <div className={cnApp('Loading')}>
                {t('loading')}...
            </div>
        </div>);
    }

    if (!session.success) {
        return (
            <div className={cnApp('LoadingData')}>
                <div className={cnApp('Error')}>{t('error-start-app')}!</div>
            </div>
        );
    }

    return (
        <Router>
            <Routes>
                <Route path="/projects/" element={<ProjectsPage/>}/>
                <Route path="/constructor/:project/" element={<ConstructorPage/>}/>
                <Route path="/" element={<MainPage/>}/>
            </Routes>
        </Router>
    );
};
