import React, {ChangeEvent, ReactNode} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './CheckboxInput.css';
import {Checkbox} from '@yandex/ui/Checkbox/desktop/bundle';

export const cnCheckboxInput = cn('CheckboxInput');

export interface ICheckboxInputProps extends IClassNameProps {
    checked: boolean,
    callBack: (event: ChangeEvent<HTMLInputElement>) => void,
    label?: ReactNode,
    name?: string,
}

export const CheckboxInput: React.FC<ICheckboxInputProps> = ({className, checked, callBack, name, label}) => {
    return (
        <div className={cnCheckboxInput({}, [className])}>
            <Checkbox
                size={'m'}
                view={'default'}
                checked={checked}
                onChange={callBack}
                label={label}
                name={name}
            />
        </div>
    );
};