import React from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import SvgIcon from "../../../helpers/SvgIcon/SvgIcon";
import './Header.css'
import {MenuBurger, MainMenu, LocationMenu, UserMenu} from "../../index";
import {Link} from "@yandex/ui/Link";
import {NavLink} from "react-router-dom";
import '../../../i18n';
import {useTranslation} from 'react-i18next';
import {cnConstructorPage} from "../../../pages/ConstructorPage/ConstructorPage";

export const cnHeader = cn('Header');

export interface IHeaderProps extends IClassNameProps {
    title: string;
}

export const Header: React.FC<IHeaderProps> = ({className, title}) => {

    const {t} = useTranslation();

    return (
        <div className={cnHeader({}, [cnHeader({fixed: true}), cnConstructorPage(cnHeader()), className])}>
            <div className={cnHeader('Wrapper')}>
                <div className={cnHeader('LogoMenu', [cnHeader('FirstItem')])}>
                    <NavLink className={cnHeader('Home')} to="/">
                        <SvgIcon className={cnHeader('Logo')} icon="logo" label={t('logo-label')}/>
                    </NavLink>
                    <MenuBurger forMenu="MainMenu" size="small" className={cnHeader('MenuBurger')}>
                        <MainMenu/>
                    </MenuBurger>
                </div>
                <div className={cnHeader('PhoneContainer', [cnHeader('FlexContainer')])}>
                    <Link className={cnHeader('PhoneLink')} href="tel:88003337660">
                        <SvgIcon className={cnHeader('PhoneIcon')} icon="phone" label={t('call-label')}/>
                        <div className={cnHeader('PhoneText')}>
                            8 800 333-76-60
                        </div>
                    </Link>
                </div>
                <div className={cnHeader('TitleContainer', [cnHeader('FlexContainer')])}>
                    {t(title)}
                </div>
                <div className={cnHeader('LocationContainer', [cnHeader('FlexContainer')])}>
                    <LocationMenu/>
                </div>
                <div className={cnHeader('UserContainer', [cnHeader('FlexContainer'), cnHeader('LastItem')])}>
                    <UserMenu/>
                </div>
            </div>
        </div>
    );
};