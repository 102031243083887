import {Vector3} from "three";
import {ThreeGeometry} from "../../../helpers";
import {ThreePoint} from "..";
import {ThreeSideWardrobe} from "../../ThreeSideWardrobe";
import {IPanelPointData} from '../../../../../../common-code/domain/interfaces/IPanelPointData/IPanelPointData';

export class ThreePanelPoint extends ThreePoint {
    panelId: number;
    ratio: number;
    isInit: boolean;
    sideWardrobe: ThreeSideWardrobe;

    constructor(sideWardrobe: ThreeSideWardrobe, pointData: IPanelPointData) {
        super(sideWardrobe.mainConstructor, pointData);
        this.sideWardrobe = sideWardrobe;
        this.ratio = pointData.ratio;
        this.panelId = pointData.panelId;
        this.isInit = false;
    }

    public getSaveData(): IPanelPointData {
        super.getSaveData();
        return {
            connectionId: this.connectionId,
            connectionType: this.connectionType,
            sort: this.sort,
            type: this.type,
            id: this.id,
            panelId: this.panelId,
            ratio: this.ratio,
        }
    }

    public calculatePosition(): Vector3
    {
        if (!this.parentPanel) {
            throw new Error('ThreePanelPoint required parentPanel!');
        }
        this.value = this.getPositionByRatio();
        if (!this.isInit) {
            this.isInit = true;
        }

        return this.value;
    }

    public getPositionByRatio(): Vector3
    {
        if (this.parentPanel) {
            if (!this.parentPanel.pointA.isInited()) {
                this.parentPanel.pointA.calculatePosition();
            }
            if (!this.parentPanel.pointB.isInited()) {
                this.parentPanel.pointB.calculatePosition();
            }
            if (this.parentPanel.pointA.isInited() &&
                this.parentPanel.pointB.isInited()) {
                return ThreeGeometry.getPointByRatio(
                    this.parentPanel.pointA.getPosition(),
                    this.parentPanel.pointB.getPosition(),
                    this.ratio
                );
            }
        }
        return this.value;
    }

    // public createView(): void
    // {
    //     if (!this.parentPanel) {
    //         throw new Error('ThreePanelPoint required parentPanel!');
    //     }
    //     let mesh = new Mesh(new SphereGeometry(30), new MeshStandardMaterial({color: '#0000ff'}));
    //     this.view3d.add(mesh);
    //     this.mainConstructor.addToScene(this);
    // }
}