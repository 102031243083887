import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {useNewSections} from '../../../../hooks';
import {cnRangeInput, RangeInput} from '../../../all/RangeInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {Dispatch} from 'redux';
import {CHANGE_CONSTRUCTOR_FORM_DATA} from '../../../../constants';
import {CommonHelper} from '../../../../helpers/CommonHelper';

export const cnCountSectionsFragment = cn('CountSectionsFragment');

export interface ICountSectionsFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    sideIndex: number,
    dispatch: Dispatch,
    min: number,
    max: number,
    step?: number
}

export const CountSectionsFragment: React.FC<ICountSectionsFragmentProps> = ({ className, constructorFormData, sideIndex, dispatch, min, max, step }) => {

    const {createNewSections} = useNewSections();

    const changeCountSections = (newCountSections: number) => {
        let newConstructorFormData = CommonHelper.deepCopy(constructorFormData);

        newConstructorFormData = createNewSections(newConstructorFormData, sideIndex, newConstructorFormData.sideWardrobes[sideIndex].width, newCountSections);
        dispatch({
            type: CHANGE_CONSTRUCTOR_FORM_DATA,
            payload: newConstructorFormData,
        });
    };

    return (
        <div className={cnConstructorForm(cnCountSectionsFragment(), [className])}>
            <RangeInput
                className={cnConstructorForm(cnRangeInput(), { wide: true })}
                min={min}
                max={max}
                step={step}
                value={constructorFormData.sideWardrobes[sideIndex].countSections}
                callBack={changeCountSections}
                viewControl={true}
            />
        </div>
    );
};