import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './EditorButtons.css';
import '../../../i18n';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {IEditorOptions} from '../../../interfaces';
import {TViewMode} from '../../../../common-code/domain/types';
import {CHANGE_EDITOR_OPTIONS, HIDE_LOADING, SHOW_LOADING} from '../../../constants';
import {VIEW_MODE_2D, VIEW_MODE_3D} from '../../../../common-code/constants';
import {RadioButtonInput, cnRadioButtonInput} from '../RadioButtonInput';
import {CheckboxInput, cnCheckboxInput} from '../CheckboxInput';
import {ButtonInput, cnButtonInput} from '../ButtonInput';
import {ThreeConstructor} from "../../3d";
import {useNavigate, useParams} from 'react-router-dom';

export const cnEditorButtons = cn('EditorButtons');

export interface IEditorButtonsProps extends IClassNameProps {
    threeConstructor: ThreeConstructor;
}

export const EditorButtons: React.FC<IEditorButtonsProps> = ({className, threeConstructor}) => {
    const {t} = useTranslation();
    const editorOptions = useSelector((state: AppState) => state.constructorData.editorOptions);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {project} = useParams();
    
    const toggleViewMode = (event: ChangeEvent<HTMLInputElement>) => {
        let newEditorOptions: IEditorOptions = {
            ...editorOptions
        };

        newEditorOptions.viewMode = event.target.value as TViewMode;
        dispatch({type: CHANGE_EDITOR_OPTIONS, payload: newEditorOptions});
    };

    const toggleShowDoors = () => {
        let newEditorOptions: IEditorOptions = {
            ...editorOptions
        };

        newEditorOptions.showDoors = !editorOptions.showDoors;
        dispatch({type: CHANGE_EDITOR_OPTIONS, payload: newEditorOptions});
    };

    const saveProject = async () => {
        dispatch({
            type: SHOW_LOADING
        });
        threeConstructor.saveProject().then(projectData => {
            console.log('saveProject', projectData);
            dispatch({
                type: HIDE_LOADING
            });
            if (projectData && project !== projectData.project.id) {
                navigate('/constructor/' + projectData.project.id + '/');
            }
        }).catch(reason => {
            dispatch({
                type: HIDE_LOADING
            });
            console.log(reason);
        });
    }

    // const downloadProjectFile = async () => {
    //     let saveProjectData: IWardrobeProjectData | undefined;
    //     let strSaveProjectData: string;
    //
    //     saveProjectData = threeConstructor.getExportWardrobeProjectData();
    //     if (!saveProjectData) {
    //         return;
    //     }
    //
    //     strSaveProjectData = JSON.stringify(saveProjectData);
    //     const fileName = 'project_' + new Date().toISOString().slice(0, 10);
    //     const blob = new Blob([strSaveProjectData], {type: 'application/json'});
    //     const href = await URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = href;
    //     link.download = fileName + '.json';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    return (
        <div className={cnEditorButtons({}, [className])}>
            <RadioButtonInput
                className={cnEditorButtons(cnRadioButtonInput(), {})}
                options={[
                    {value: VIEW_MODE_2D, children: '2d'},
                    {value: VIEW_MODE_3D, children: '3d'}
                ]}
                value={editorOptions.viewMode}
                callBack={toggleViewMode}
            />
            <div className={cnEditorButtons('Checkbox', {bordered: true, bkg: 'grey'})}>
                <CheckboxInput
                    className={cnEditorButtons(cnCheckboxInput(), {})}
                    checked={editorOptions.showDoors}
                    callBack={toggleShowDoors}
                    label={<span className={cnEditorButtons('Label', {size: 'lg'})}>
                    {t('Показать/скрыть двери')}
                </span>}
                />
            </div>
            {/*<ButtonInput*/}
            {/*    className={cnButtonInput({*/}
            {/*        size: 'md',*/}
            {/*        hovered: true,*/}
            {/*        bordered: true*/}
            {/*    }, [cnEditorButtons(cnButtonInput(), {margin: 'right'})])}*/}
            {/*    text={t('Скачать проект')}*/}
            {/*    callBack={downloadProjectFile}*/}
            {/*/>*/}
            <ButtonInput
                className={cnButtonInput({
                    size: 'md',
                    hovered: true,
                    bordered: true
                }, [cnEditorButtons(cnButtonInput())])}
                text={t('save-project')}
                callBack={saveProject}
            />
        </div>
    );
};