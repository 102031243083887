import {Reducer} from "redux";
import {SET_USER} from "../constants";

const userGuest = {
    name: 'Гость',
    roles: ['GUEST']
};

export const UserReducer:Reducer = (state = userGuest, action) => {
    switch (action.type) {
        case SET_USER: {
            if (action.payload && action.payload.id) {
                state = action.payload;
            } else {
                state = userGuest;
            }

            return state;
        }
        default: {
            return state;
        }
    }
};