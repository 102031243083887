import {
    AmbientLight, Box3,
    DirectionalLight,
    OrthographicCamera,
    PerspectiveCamera,
    Raycaster,
    Scene, Vector2,
    Vector3,
    WebGLRenderer
} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {ThreeConstructor} from "../ThreeConstructor";
import {Dispatch} from "redux";
import {
    CHANGE_EDITOR_OPTIONS,
    EDITOR_INIT_OPTIONS,
    SCREEN_TYPE_HORIZONTAL,
    SCREEN_TYPE_VERTICAL
} from "../../../constants";
import {IEditorOptions} from "../../../interfaces";
import {TMousePosition} from "../../../../domain/types";
import {ThreeObject} from "../models/ThreeObject";
import Stats from "three/examples/jsm/libs/stats.module";
import {VIEW_MODE_2D, VIEW_MODE_3D} from "../../../../common-code/constants";
import {TViewMode} from '../../../../common-code/domain/types';
import {ICameraTargetPosition} from '../interfaces/ICameraTargetPosition/ICameraTargetPosition';
import {Geometry} from '../../../../domain/helpers/Geometry';
import {TScreenType} from '../../../types/TScreenType';
import {TEditorScreenData} from '../../../types/TEditorScreenData';
import {TUnitsVectors} from '../../../types/TUnitsVectors';
import {ThreeGeometry} from '../helpers';
import {TTwoDLine} from '../../../types/TTwoDLine';
import {TThreeLine3d} from '../../../types/TThreeLine3d';

export class ThreeEditor {

    public readonly CAMERA_FOV: number = 45;
    public readonly CAMERA_NEAR: number = 1;
    public readonly CAMERA_FAR: number = 100000;
    public readonly MAX_FPS: number = 60;
    /**
     * идентификатор
     */
    private readonly uID: number;

    private readonly win: Window | null;

    /**
     * Контейнер для канваса
     */
    public htmlContainer: HTMLDivElement | null;

    private isReady: boolean;

    /**
     * Object Scene class
     */
    private threeScene?: Scene;

    /**
     * Editor width
     */
    private width: number | undefined;

    /**
     * Editor height
     */
    private height: number | undefined;

    /**
     * Camera
     */
    private camera?: PerspectiveCamera | OrthographicCamera;

    /**
     *
     */
    private orbitControl?: OrbitControls;

    /**
     * Renderer
     */
    private renderer?: WebGLRenderer;

    private raycaster?: Raycaster;

    private mousePosition: TMousePosition;

    private directionLight?: DirectionalLight;

    private ambientLight?: AmbientLight;

    private stats?: Stats;

    private fpsInterval?: number;
    private currentTime?: number;
    private startTime?: number;
    private elapsedTime?: number;
    private editorAnimationFrame?: number;

    private options: IEditorOptions;
    private sceneData: { [n: number]: ThreeObject };

    private threeConstructor?: ThreeConstructor;
    private reduxDispatch: Dispatch;

    constructor(win: Window, options: IEditorOptions = EDITOR_INIT_OPTIONS, dispatch: Dispatch) {
        this.htmlContainer = null;
        this.win = win;
        this.uID = Date.now();
        this.isReady = false;
        this.setWidth();
        this.setHeight();
        this.mousePosition = {x: 0, y: 0};
        this.options = options;
        this.reduxDispatch = dispatch;
        this.sceneData = {};
    }

    public setConstructor(constructor: ThreeConstructor) {
        this.threeConstructor = constructor;
    }

    public setOptions(options: IEditorOptions) {
        this.options = options;
    }

    public getOptions(): IEditorOptions {
        return this.options;
    }

    public getVisibleDoors(): boolean {
        return this.options.showDoors;
    }

    public setVisibleDoors(value: boolean): void {
        let editorOptions: IEditorOptions = {
            ...this.options,
            showDoors: value
        };
        this.reduxDispatch({type: CHANGE_EDITOR_OPTIONS, payload: editorOptions})
    }

    public getScene(): Scene | undefined {
        return this.threeScene;
    }

    public onResize(): void {
        let aspect: number;

        console.log('onResize');
        if (this.renderer && this.camera) {
            this.calculateContainerSizes();
            this.renderer.setSize(this.getWidth(), this.getHeight());
            aspect = this.getWidth() / this.getHeight();
            if (this.camera instanceof PerspectiveCamera) {
                this.camera.aspect = aspect;
            }
            this.camera.updateProjectionMatrix();
            this.fitAll();
        }
    };

    public onPointerMove(event: PointerEvent) {
        if (this.renderer) {
            event.preventDefault();
            const rectContainer = this.renderer.domElement.getBoundingClientRect();
            this.mousePosition.x = ((event.clientX - rectContainer.left) / (rectContainer.width - rectContainer.left)) * 2 - 1;
            this.mousePosition.y = -((event.clientY - rectContainer.top) / (rectContainer.bottom - rectContainer.top)) * 2 + 1;
        }
        return true;
    }

    public trySelect(event: PointerEvent) {
        console.log('editor trySelect');
    }

    public setViewMode(viewMode: TViewMode) {
        this.options.viewMode = viewMode;
        this.rebuildState();
    }

    public getViewMode(): TViewMode {
        return this.options.viewMode;
    }

    public initState(container: HTMLDivElement | null): void {
        if (!container) {
            throw new Error('ThreeEditor needs html Container');
        }
        if (this.isReady) {
            return;
        }
        this.htmlContainer = container;
        this.calculateContainerSizes();
        this.mousePosition = {x: 0, y: 0};
        this.initCamera();
        this.initScene();
        this.initRenderer();
        this.initOrbitControl();
        this.initLights();
        this.initStats();
        this.isReady = true;
    };

    public rebuildState(): void {
        if (!this.isReady) {
            return;
        }
        this.initCamera();
        this.initOrbitControl();
        if (this.threeConstructor) {
            this.threeConstructor.rebuildProject();
        }
    }

    public startRender() {
        this.fpsInterval = 1000 / this.MAX_FPS;
        this.currentTime = this.startTime = Date.now();
        this.editorAnimationFrame = requestAnimationFrame(() => this.renderStep());
    };

    public renderStep() {
        if (!this.isReady || !this.startTime ||
            !this.fpsInterval || !this.renderer ||
            !this.camera || !this.orbitControl || !this.threeScene) {
            return null;
        }
        this.editorAnimationFrame = requestAnimationFrame(() => this.renderStep());
        this.currentTime = Date.now();
        this.elapsedTime = this.currentTime - this.startTime;
        if (this.elapsedTime > this.fpsInterval) {
            this.startTime = this.currentTime - (this.elapsedTime % this.fpsInterval);
            this.renderer.setSize(this.getWidth(), this.getHeight());
            this.renderer.clear();
            this.renderer.render(this.threeScene, this.camera);
            if (this.stats) {
                this.stats.update();
            }
            this.orbitControl.update();
        }
    }


    public calculateContainerSizes() {
        if (!this.htmlContainer) {
            this.setWidth(0);
            this.setHeight(0);

            return {width: this.getWidth(), height: this.getHeight()};
        }
        this.setWidth(this.htmlContainer.clientWidth);
        this.setHeight(this.htmlContainer.clientHeight);

        return {width: this.getWidth(), height: this.getHeight()};
    };

    public setCameraZoom(zoom: number): void {
        if (this.camera && this.camera.zoom) {
            this.camera.zoom = zoom;
            this.camera.updateProjectionMatrix();
        }
    }


    public calculateResetCameraPosition(): Vector3 {
        return new Vector3(200, 200, 200);
    };

    /**
     * Метод добавляет объект на сцену.
     *
     * @param object
     */
    public addToScene(object: ThreeObject): void {
        if (!this.threeScene) {
            return;
        }
        this.threeScene.add(object.view3d);
        this.sceneData[object.getId()] = object;
    };

    public removeFromScene(object: ThreeObject): void {
        if (!this.threeScene) {
            return;
        }
        this.threeScene.remove(object.view3d);
        delete this.sceneData[object.getId()];
    }

    public setCameraTargetPosition(cameraTargetPosition: ICameraTargetPosition | undefined) {
        if (cameraTargetPosition) {
            this.camera?.position.copy(cameraTargetPosition.camera);
            this.orbitControl?.target.copy(cameraTargetPosition.target);
        }
    }

    public getCameraTargetPosition(): ICameraTargetPosition | undefined {
        let cameraTargetPosition: ICameraTargetPosition | undefined;
        let box: Box3;

        box = this.getSceneBox();


        cameraTargetPosition = {
            camera: new Vector3(box.max.x, box.max.y, box.max.z + 4000),
            target: box.getCenter(new Vector3()),
        };

        return cameraTargetPosition;
    }

    public fitAll() {
        this.setCameraTargetPosition(this.getCameraTargetPosition());
        if (this.camera instanceof OrthographicCamera) {
            this.orthographicCameraFitAll();
        }
        if (this.camera instanceof PerspectiveCamera) {
            this.perspectiveCameraFilAll();
        }
    }

    protected orthographicCameraFitAll() {
        if (!(this.camera instanceof OrthographicCamera)) {
            return;
        }
        let aspect = this.getWidth() / this.getHeight();
        let frustumHeight = this.getFrustumHeight();
        if (aspect > 1) {
            frustumHeight = frustumHeight / aspect;
        }
        this.camera.left = -frustumHeight * aspect / 2;
        this.camera.right = frustumHeight * aspect / 2;
        this.camera.top = frustumHeight / 2;
        this.camera.bottom = -frustumHeight / 2;
        this.camera.updateProjectionMatrix();
    }

    protected perspectiveCameraFilAll() {
        let box: Box3;
        let target: Vector3;
        let viewPosition;
        let vectors: TUnitsVectors;

        box = this.getSceneBox();
        target = box.getCenter(new Vector3());
        vectors = this.calculateUnitVectors(box);
        viewPosition = this.getViewPosition(vectors, target);
        this.setCameraTargetPosition({
            camera: viewPosition,
            target: target
        });

    }

    protected calculateUnitVectors(box: Box3): TUnitsVectors {
        let vectors: { [s: string]: TThreeLine3d };
        let unitsBoxPoints;
        let width;
        let pointA: Vector3;
        let pointB: Vector3;

        unitsBoxPoints = [box.min, box.max];
        pointA = box.getCenter(new Vector3());
        width = ThreeGeometry.getLength(box.max, box.min) + 100;
        pointB = new Vector3(
            pointA.x + width * Math.sin(0.26),
            pointA.y,
            pointA.z + width * Math.cos(0.26)
        );
        vectors = {
            all: {
                pointA: pointA,
                pointB: pointB
            }
        }

        return {vectors: vectors, unitsBoxPoints: unitsBoxPoints};
    }

    protected getViewPosition(unitVectors: TUnitsVectors, target: Vector3): Vector3 {
        let index;
        let result;
        let delta;
        let viewVector;
        let ratio;

        // Нахождение для каждой группы юнитов (которые направлены в одном направлении) координаты точки delta (точки
        // общего направления), которая рассчитывается путем вычитания координаты точки А (центр каждого юнита)
        // от точки В (вектор от центра юнита прямо), т.е. получаем вектор (точку) общего направления по осям
        // X и Z для всех групп
        delta = new Vector2();
        for (index in unitVectors.vectors) {
            if (unitVectors.vectors.hasOwnProperty(index)) {
                delta.x += unitVectors.vectors[index].pointB.x - unitVectors.vectors[index].pointA.x;
                delta.y += unitVectors.vectors[index].pointB.z - unitVectors.vectors[index].pointA.z;
            }
        }

        // Получение векторов (координат точек)
        // Координата центра вращения камерой и координата общего направления юнитов
        viewVector = {
            pointA: new Vector2(+target.x, +target.z),
            pointB: new Vector2(+target.x + delta.x, +target.z + delta.y)
        };
        // Выполнение расчета ratio
        ratio = this.calculateViewRatio(unitVectors, viewVector, target);
        // Если ratio меньше единицы
        if (ratio < 1 || ratio > 2) {
            viewVector.pointB = ThreeGeometry.getPointByRatio2D(viewVector.pointA, viewVector.pointB, ratio, false);
            ratio = this.calculateViewRatio(unitVectors, viewVector, target);
        }
        // Нахождение координат точки для установки камеры
        delta = ThreeGeometry.getPointByRatio2D(viewVector.pointA, viewVector.pointB, ratio, false);
        result = new Vector3(delta.x, target.y + 200, delta.y);

        return result;
    }

    protected calculateViewRatio(vectors: TUnitsVectors, viewVector: TTwoDLine, target: Vector3): number {
        let box;
        let directVector: TTwoDLine;
        let directLine: TTwoDLine;
        let screenData;
        let width;
        let height;
        let distance;
        let vFOV;
        let hFOV;
        let ratio;

        if (!this.camera ||
            !(this.camera instanceof PerspectiveCamera)) {
            throw new Error('error-KitchenEditor-getViewPosition');
        }
        // Построение общего BoundingBox для всех юнитов
        box = new Box3().setFromPoints(vectors.unitsBoxPoints);

        // Получение векторов (координат точек)
        // Координата центра вращения камерой и координата линии направления, полученная путем поворота вектора
        // viewVector на 90 градусов (перпендикулярно) относительно координаты центра вращения камерой
        directLine = {
            pointA: new Vector2(+target.x, +target.z),
            pointB: ThreeGeometry.turnVector2D(
                viewVector.pointB.clone().sub(viewVector.pointA),
                Math.PI / 2,
                viewVector.pointA
            )
        };

        // Получение вектора направления, путем получения максимальных углов (положительного и отрицательного) от точки
        // установки камеры до каждой вершины (для вида сверху) BoundingBox каждого юнита
        directVector = this.getViewDirectionPoints(vectors.unitsBoxPoints, directLine, viewVector);

        // Получение ширины, путем вычисления длины вектора направления
        width = Geometry.getLength2D(directVector.pointA, directVector.pointB);
        // Получение высоты, путем вычета нижней координаты общего BoundingBox от верхней
        height = (box.max.y - box.min.y) + 700;

        // Получение информации о характеристиках экрана и canvas
        screenData = this.getScreenData();
        // Вертикальный угол обзора камеры (в радианах)
        vFOV = this.camera.fov * (Math.PI / 180);
        // Горизонтальный угол обзора камеры (в радианах)
        hFOV = 2 * Math.atan(Math.tan(vFOV / 2) * (screenData.frameWidth / screenData.frameHeight));

        distance = {
            // Нахождение расстояния на основе вертикального угла камеры и высоты
            height: (height / 2) / Math.tan(vFOV / 2),
            // Нахождение расстояния на основе горизонтального угла камеры и ширины
            width: (width / 2) / Math.tan(hFOV / 2)
        };

        // Нахождение соотношения высоты / ширины к расстоянию
        ratio = (Math.max(distance.height, distance.width) * 1.05) / screenData.scale /
            Geometry.getLength2D(viewVector.pointA, viewVector.pointB);

        return ratio;
    }

    protected getViewDirectionPoints(unitsBoxPoints: Vector3[], directLine: TTwoDLine, viewVector: TTwoDLine): TTwoDLine {
        let points, boxPoints: { [n: number]: Vector2 }, index, index1, angle, angles, boxVector,
            line1: TTwoDLine, line2: TTwoDLine, intersectionPoint1, intersectionPoint2;

        points = [];
        for (index in unitsBoxPoints) {
            if (unitsBoxPoints.hasOwnProperty(index)) {
                if (!unitsBoxPoints[(+index + 1)]) {
                    break;
                }
                points.push(new Vector2(unitsBoxPoints[index].x, unitsBoxPoints[index].z));
                points.push(new Vector2(unitsBoxPoints[(+index + 1)].x, unitsBoxPoints[(+index + 1)].z));
                points.push(new Vector2(unitsBoxPoints[index].x, unitsBoxPoints[(+index + 1)].z));
                points.push(new Vector2(unitsBoxPoints[(+index + 1)].x, unitsBoxPoints[index].z));
            }
        }

        boxPoints = {};
        for (index1 in points) {
            if (!points.hasOwnProperty(index1)) {
                continue;
            }
            angle = Geometry.getNormalAngle({
                    x: points[index1].x - viewVector.pointB.x,
                    y: points[index1].y - viewVector.pointB.y
                }) * 180 / Math.PI -
                Geometry.getNormalAngle({
                    x: viewVector.pointA.x - viewVector.pointB.x,
                    y: viewVector.pointA.y - viewVector.pointB.y
                }) * 180 / Math.PI;
            boxPoints[angle] = points[index1];
        }

        angles = Object.keys(boxPoints).map(function (x) {
            return parseFloat(x);
        });

        boxVector = {
            pointA: {x: boxPoints[Math.min.apply(Math, angles)].x, y: boxPoints[Math.min.apply(Math, angles)].y},
            pointB: {x: boxPoints[Math.max.apply(Math, angles)].x, y: boxPoints[Math.max.apply(Math, angles)].y}
        };

        line1 = {
            pointA: viewVector.pointB,
            pointB: new Vector2(boxVector.pointA.x, boxVector.pointA.y)
        };

        line2 = {
            pointA: viewVector.pointB,
            pointB: new Vector2(boxVector.pointB.x, boxVector.pointB.y)
        };

        intersectionPoint1 = ThreeGeometry.getIntersectionPoint(line1, directLine);
        intersectionPoint2 = ThreeGeometry.getIntersectionPoint(line2, directLine);

        if (intersectionPoint1 && intersectionPoint2) {
            return {
                pointA: intersectionPoint1,
                pointB: intersectionPoint2
            }
        }

        throw new Error('error-KitchenEditor-getViewDirectionPoints');
    }

    public getCanvasSize(ratio?: number) {
        ratio = ratio ?? 1;
        return {
            width: this.getWidth() * ratio,
            height: this.getHeight() * ratio
        };
    }

    protected getScreenData(): TEditorScreenData {
        let canvasSize, frameHeight, frameWidth, ratio, scale, type: TScreenType;

        ratio = (window) ? window.devicePixelRatio : 1;
        canvasSize = this.getCanvasSize(ratio);
        frameWidth = canvasSize.width;
        frameHeight = frameWidth / 1.777777778;
        type = SCREEN_TYPE_HORIZONTAL;
        scale = (frameHeight / canvasSize.height);
        if (frameHeight > canvasSize.height) {
            frameHeight = canvasSize.height;
            frameWidth = frameHeight * 1.777777778;
            scale = frameWidth / canvasSize.width;
            type = SCREEN_TYPE_VERTICAL;
        }

        return {
            width: canvasSize.width,
            height: canvasSize.height,
            frameWidth: frameWidth,
            frameHeight: frameHeight,
            scale: scale,
            type: type,
            ratio: ratio
        };
    }

    public getSceneBox(): Box3 {
        let index;
        let points: Vector3[] = [];
        let box: Box3;

        box = new Box3();
        for (index in this.sceneData) {
            if (this.sceneData[index] instanceof ThreeObject) {
                box.setFromObject(this.sceneData[index].view3d);
                points.push(box.min.clone(), box.max.clone());
            }
        }
        if (points.length <= 0) {
            points.push(new Vector3(0, 0, 0), new Vector3(2000, 2000, 600));
        }
        box.setFromPoints(points);

        return box;
    }

    public getFrustumHeight(): number {
        let frustumHeight: number;
        let box: Box3;

        box = this.getSceneBox();
        frustumHeight = box.max.y - box.min.y > box.max.x - box.min.x ?
            box.max.y - box.min.y : box.max.x - box.min.x;
        frustumHeight += 1350;


        return frustumHeight;
    }

    private initScene() {
        this.threeScene = new Scene();
        this.raycaster = new Raycaster();
    };

    private initRenderer() {
        if (!this.htmlContainer || !this.win) {
            return null;
        }
        this.renderer = new WebGLRenderer({
            antialias: true,
            logarithmicDepthBuffer: true,
            // preserveDrawingBuffer: true,
        });
        // this.renderer.autoClear = false;
        this.renderer.setPixelRatio(this.win.devicePixelRatio);
        this.renderer.setClearColor(0xffffff, 1.0);
        this.renderer.domElement.className = this.options.canvasClass || 'ThreeEditor-Canvas';
        this.htmlContainer.appendChild(this.renderer.domElement);

        return this.renderer;
    };

    private initOrbitControl() {
        if (!this.camera || !this.renderer) {
            return null;
        }
        this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
        this.orbitControl.maxPolarAngle = Math.PI / 2;
        this.orbitControl.minDistance = 0;
        this.orbitControl.maxDistance = 10000;
        this.orbitControl.enableDamping = true;
        this.orbitControl.target.set(0, 0, 0);
        switch (this.options.viewMode) {
            case VIEW_MODE_2D:
                this.orbitControl.enablePan = false;
                this.orbitControl.enableRotate = false;
                break;
            case VIEW_MODE_3D:
                this.orbitControl.enablePan = true;
                this.orbitControl.enableRotate = true;
                break;
        }
    };

    private initLights() {
        if (!this.threeScene) {
            return null;
        }
        this.ambientLight = new AmbientLight("#ffffff", 0.8);
        this.threeScene.add(this.ambientLight);
        this.directionLight = new DirectionalLight(0xffffff, 0.5);
        this.directionLight.position.set(0.5, 0.5, 0.5).normalize();
        this.threeScene.add(this.directionLight);
    };

    private initStats() {
        if (this.htmlContainer && this.options.stats) {
            this.stats = Stats();
            this.stats.dom.style.position = 'absolute';
            this.stats.dom.style.right = '0px';
            this.stats.dom.style.left = 'auto';
            this.htmlContainer.appendChild(this.stats.dom);
        }
    }

    private initCamera() {
        if (!this.getWidth() || !this.getHeight()) {
            return null;
        }
        switch (this.options.viewMode) {
            case VIEW_MODE_2D:
                this.camera = new OrthographicCamera(
                    this.getWidth() / -2,
                    this.getWidth() / 2,
                    this.getHeight() / 2,
                    this.getHeight() / -2,
                    this.CAMERA_NEAR,
                    this.CAMERA_FAR
                );
                break;
            case VIEW_MODE_3D:
                this.camera = new PerspectiveCamera(
                    this.CAMERA_FOV,
                    this.getWidth() / this.getHeight(),
                    this.CAMERA_NEAR,
                    this.CAMERA_FAR
                );
                break;
        }
        if (this.camera) {
            this.camera.lookAt(new Vector3());
            this.camera.updateProjectionMatrix();
        }
    };

    /**
     * Set this.width parameter
     *
     * @param size
     * @private
     */
    private setWidth(size?: number): void {
        this.width = size ? size : 0;
    };

    /**
     * Set this.height parameter
     *
     * @param size
     * @private
     */
    private setHeight(size?: number): void {
        this.height = size ? size : 0;
    };

    /**
     * Get this.width parameter value
     *
     * @private
     */
    private getWidth(): number {
        if (this.width) {
            return this.width;
        }

        return 0;
    };

    /**
     * Get this.height parameter value
     *
     * @private
     */
    private getHeight(): number {
        if (this.height) {
            return this.height;
        }

        return 0;
    };
}

