import React, {ChangeEvent, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {cnRadioboxInput, RadioboxInput} from '../../../all/RadioboxInput';
import {cnRangeInput, RangeInput} from '../../../all/RangeInput';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {TTechnologMap} from '../../../../../common-code/domain/types';
import {TRadioboxOption} from '../../../../../common-code/domain/types/TRadioboxOption';
import {Dispatch} from 'redux';
import {CHANGE_CONSTRUCTOR_FORM_DATA} from '../../../../constants';
import {
    MIN_TOP_PLINTH,
    MAX_TOP_PLINTH,
    DEFAULT_TOP_PLINTH
} from '../../../../../common-code/constants';

export const cnTopPlintFragment = cn('TopPlintFragment');

export interface ITopPlintFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    technologMap: TTechnologMap | undefined,
    dispatch: Dispatch,
    min: number,
    max: number,
    step?: number,
    options: Array<TRadioboxOption>
}

export const TopPlintFragment: React.FC<ITopPlintFragmentProps> = ({ className, constructorFormData, technologMap, dispatch, min, max, step, options }) => {

    const [enabled, setEnabled] = useState<string>('enabled');

    const changeActive = (event: ChangeEvent<HTMLInputElement>) => {
        let newConstructorFormData = {...constructorFormData};

        setEnabled(event.target.value);
        if (event.target.value === 'enabled') {
            newConstructorFormData.topPlinth = DEFAULT_TOP_PLINTH;
        }
        if (event.target.value === 'disabled') {
            newConstructorFormData.topPlinth = 0;
        }
        dispatch({
            type: CHANGE_CONSTRUCTOR_FORM_DATA,
            payload: newConstructorFormData,
        });
    };

    const changeTopPlint = (value: number) => {
        let newConstructorFormData = {...constructorFormData};

        if (!technologMap) {
            return;
        }

        if (value < MIN_TOP_PLINTH) {
            value = MIN_TOP_PLINTH;
        }
        if (value > MAX_TOP_PLINTH) {
            value = MAX_TOP_PLINTH;
        }

        newConstructorFormData.topPlinth = value;
        dispatch({
            type: CHANGE_CONSTRUCTOR_FORM_DATA,
            payload: newConstructorFormData,
        });
    };

    return (
        <div className={cnConstructorForm(cnTopPlintFragment(), [className])}>
            <RadioboxInput
                className={cnConstructorForm(cnRadioboxInput(), [cnRadioboxInput({flex: 'row'})])}
                options={options}
                value={enabled}
                callBack={changeActive}
            />
            {enabled === 'enabled' ?
                <div className={cnConstructorForm('TopPlintWrapper', [cnConstructorForm('RangeInputContainer')])}>
                    <span className={cnConstructorForm(
                        'Label',
                        {size: 'sm', color: 'lightNavy'},
                        [cnConstructorForm('RangeInputLabel')]
                    )}>Высота,мм</span>
                    <RangeInput
                        className={cnConstructorForm(cnRangeInput())}
                        min={min}
                        max={max}
                        step={step}
                        value={constructorFormData.topPlinth}
                        callBack={changeTopPlint}
                        viewControl={true}
                    />
                </div> : null
            }
        </div>
    );
};