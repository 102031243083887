import {ThreeObject} from "../ThreeObject";
import {IDoorContainer} from "../../../../../domain/intarfaces";
import {ThreeSideWardrobe} from "../ThreeSideWardrobe";
import {ThreeRail} from "../planks/ThreeRail/ThreeRail";
import {ThreeDoor} from "../ThreeDoor/ThreeDoor";
import {ThreeGeometry} from "../../helpers";
import {AXIS_X, AXIS_Y} from "../../../../../domain/constants";
import {Vector2, Vector3} from "three";
import {IDoorContainerData} from "../../../../../common-code/domain/interfaces/IDoorContainerData/IDoorContainerData";
import {IDoorContainerSegmentInfo} from "../../interfaces/IDoorContainerSegmentInfo/IDoorContainerSegmentInfo";
import {TThreeLine} from "../../../../types/TThreeLine";
import {
    CONTOUR_POSITION_TYPE_BOTTOM,
    CONTOUR_POSITION_TYPE_LEFT,
} from "../../../../../common-code/constants";
import {IDoorData} from "../../../../../common-code/domain/interfaces/IDoorData/IDoorData";
import {IRailData} from "../../../../../common-code/domain/interfaces/IRailData/IRailData";
import {TDoorContainerSegmentData} from "../../../../../common-code/domain/types";

export class ThreeDoorContainer extends ThreeObject implements IDoorContainer {
    segments: IDoorContainerSegmentInfo[];
    initData: IDoorContainerData;
    points: Vector2[];
    sideWardrobe: ThreeSideWardrobe;
    doors: { [n: number]: ThreeDoor };
    rails: { [n: number]: ThreeRail };
    pointA: Vector3;
    pointB: Vector3;

    constructor(sideWardrobe: ThreeSideWardrobe, options: IDoorContainerData) {
        super(sideWardrobe.mainConstructor, options);
        this.sideWardrobe = sideWardrobe;
        this.segments = [];
        this.initData = options;
        this.points = [];
        this.doors = {};
        this.rails = {};
        this.pointA = new Vector3();
        this.pointB = new Vector3();
        this.view3d.visible = this.mainConstructor.getVisibleDoors();
    }

    public initState() {
        this.setSegments();
        this.calculatePoints();
        this.setPosition();
        this.createRails();
        this.createDoors();
    }

    public createView(): void {
        this.createRailViews();
        this.createDoorViews();
        this.sideWardrobe.view3d.add(this.view3d);
        super.createView();
    }

    public getSaveData(): IDoorContainerData {
        super.getSaveData();
        return {
            id: this.getId(),
            doors: this.getDoorsSaveData(),
            rails: this.getRailsSaveData(),
            segments: this.getSegmentsSaveData(),
        };
    }

    protected getDoorsSaveData(): IDoorData[] {
        let doors: IDoorData[] = [];
        let index;

        for (index in this.doors) {
            doors.push(this.doors[index].getSaveData());
        }

        return doors;
    }

    protected getSegmentsSaveData(): TDoorContainerSegmentData[] {
        let segments: TDoorContainerSegmentData[] = [];
        let index;

        for (index in this.segments) {
            segments.push(this.segments[index].info);
        }

        return segments;
    }

    protected getRailsSaveData(): IRailData[] {
        let rails: IRailData[] = [];
        let index;

        for (index in this.rails) {
            rails.push(this.rails[index].getSaveData());
        }

        return rails;
    }

    protected setSegments() {
        let segmentData;

        for (segmentData of this.initData.segments) {
            this.segments.push({
                segment: this.mainConstructor.getPanelById(segmentData.segmentId),
                info: segmentData,
                connection: this.mainConstructor.getFillerConnectionById(segmentData.connectionId)
            });
        }
        this.segments.sort((a, b) => a.info.sort - b.info.sort);
    }

    public remove(): void {
        let index;

        for (index in this.rails) {
            this.rails[index].remove();
        }
        for (index in this.doors) {
            this.doors[index].remove();
        }
        super.remove();
    }

    setPosition(): void {
        let segment;
        let leftSegment;
        let bottomSegment;

        for (segment of this.segments) {
            switch (segment.info.type) {
                case CONTOUR_POSITION_TYPE_LEFT:
                    leftSegment = segment.segment;
                    break;
                case CONTOUR_POSITION_TYPE_BOTTOM:
                    bottomSegment = segment.segment;
                    break;

            }
        }
        if (leftSegment && bottomSegment) {
            this.view3d.position.set(
                leftSegment.getDepth(),
                bottomSegment.getDepth() + this.mainConstructor.getBottomOutsoleHeight(),
                this.segments[0].segment.width +
                this.mainConstructor.getConstructorService().technologMap.backFiller.depth -
                this.getDepth()
            );
        }
    }

    public getPosition(): Vector3 {
        return this.view3d.position.clone();
    }

    protected calculatePoints() {
        let index;
        let prevSegmentInfo: IDoorContainerSegmentInfo;
        let currentSegmentInfo: IDoorContainerSegmentInfo;
        let leftSegmentPoints: TThreeLine;
        let rightSegmentPoints: TThreeLine;
        let intersectPoint;

        this.points = [];
        // this.pointB.x = this.getLength();
        for (index in this.segments) {
            currentSegmentInfo = this.segments[index];
            if (this.segments[+index - 1]) {
                prevSegmentInfo = this.segments[+index - 1];
            } else {
                prevSegmentInfo = this.segments[this.segments.length - 1];
            }
            if (currentSegmentInfo.info.type === prevSegmentInfo.info.type) {
                continue;
            }
            currentSegmentInfo.segment.calculatePoints();
            prevSegmentInfo.segment.calculatePoints();
            if (currentSegmentInfo.segment.points && prevSegmentInfo.segment.points) {
                leftSegmentPoints = currentSegmentInfo.info.direction ?
                    {
                        pointA: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.C, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.D, AXIS_X, AXIS_Y)
                    } :
                    {
                        pointA: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.B, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.A, AXIS_X, AXIS_Y)
                    };
                rightSegmentPoints = prevSegmentInfo.info.direction ?
                    {
                        pointA: ThreeGeometry.toVector2D(prevSegmentInfo.segment.points.C, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(prevSegmentInfo.segment.points.D, AXIS_X, AXIS_Y)
                    } :
                    {
                        pointA: ThreeGeometry.toVector2D(prevSegmentInfo.segment.points.B, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(prevSegmentInfo.segment.points.A, AXIS_X, AXIS_Y)
                    };

                leftSegmentPoints = ThreeGeometry.getParallelLine(
                    leftSegmentPoints,
                    -this.mainConstructor.getConstructorService().getIndentionValue(
                        currentSegmentInfo.connection.indention,
                        currentSegmentInfo.segment
                    )
                );
                rightSegmentPoints = ThreeGeometry.getParallelLine(
                    rightSegmentPoints, -this.mainConstructor.getConstructorService().getIndentionValue(
                        prevSegmentInfo.connection.indention,
                        prevSegmentInfo.segment
                    )
                );
                intersectPoint = ThreeGeometry.getIntersectionPoint(leftSegmentPoints, rightSegmentPoints);
                if (!intersectPoint && ThreeGeometry.isEqualThreeLines(leftSegmentPoints, rightSegmentPoints)) {
                    intersectPoint = ThreeGeometry.getNearPoint2D(
                        leftSegmentPoints.pointA,
                        [rightSegmentPoints.pointA, rightSegmentPoints.pointB]
                    );
                }
                if (!intersectPoint) {
                    throw new Error('Not connected segments in filler');
                } else {
                    this.points.push(intersectPoint.clone());
                }
            } else {
                throw new Error('Not calculated points in segments in filler');
            }
        }
        if (!this.points[0] || !this.points[3]) {
            throw new Error('Not set pointA in DoorContainer');
        }
        this.pointB.setX(ThreeGeometry.getLength2D(this.points[0], this.points[3]));
    }


    public getLength(): number {
        let length: number = 0;

        if (!this.points[1] || !this.points[2]) {
            this.calculatePoints();
        }
        if (!this.points[1] || !this.points[2]) {
            throw new Error('error calculate getLength')
        }
        length = ThreeGeometry.getLength2D(this.points[1], this.points[2]);

        return length;
    }

    public getHeight(): number {
        let height: number = 0;

        if (!this.points[0] || !this.points[1]) {
            this.calculatePoints();
        }
        if (!this.points[0] || !this.points[1]) {
            throw new Error('error calculate getHeight');
        }
        height = ThreeGeometry.getLength2D(this.points[0], this.points[1]);

        return height;
    }

    public getRailById(railId: number): ThreeRail {
        if (!this.rails[railId]) {
            throw new Error('Тще ащгт');
        }
        return this.rails[railId];
    }

    public getDepth(): number {
        return +this.mainConstructor.getConstructorService().technologMap.door.container.depth;
    }

    private createRails() {
        let index;
        let rail: ThreeRail;

        for (index in this.initData.rails) {
            rail = new ThreeRail(this, this.initData.rails[index]);
            this.rails[rail.getId()] = rail;
        }
    }

    private createDoors() {
        let index;
        let door: ThreeDoor;

        for (index in this.initData.doors) {
            door = new ThreeDoor(this, this.initData.doors[index]);
            door.initState();
            this.doors[door.getId()] = door;
        }
    }

    private createDoorViews() {
        let index;

        for (index in this.doors) {
            this.doors[index].createView();
        }
    }

    private createRailViews() {
        let railId;

        for (railId in this.rails) {
            this.rails[railId].createView();
            this.view3d.add(this.rails[railId].view3d);
        }
    }
}