import React from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./MainMenu.css"
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {NavLink} from "react-router-dom";
import '../../../i18n';
import {TOGGLE_MENU} from "../../../constants";
import {useMainMenuItems} from '../../../hooks/useMainMenuItems';
import {IMenuItem} from '../../../interfaces';

export const cnMainMenu = cn('MainMenu');

export interface IMainMenuProps extends IClassNameProps {
}

export const MainMenu: React.FC<IMainMenuProps> = ({className}) => {
    const isMenuOpen = useSelector((state: AppState) => state.menus.MainMenu.visible);
    const userData = useSelector((state: AppState) => state.user);

    const menuItems: IMenuItem[] = useMainMenuItems(userData);
    const dispatch = useDispatch();
    const hideMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: "MainMenu",
                value: false
            },
        });
    };

    return (
        <div className={cnMainMenu({visible: isMenuOpen}, [className])}>
            {menuItems.map((item, index) => {
                return (<NavLink key={index} onClick={hideMenu} className={cnMainMenu('Item', item.mods)} to={item.url}>
                    {item.title}
                </NavLink>);
            })}
        </div>
    );
}