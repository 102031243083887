import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {cnConstructorForm} from '../ConstructorForm';
import {
    IConstructorFormData
} from '../../../../../common-code/domain/interfaces/IConstructorFormData/IConstructorFormData';
import {Dispatch} from 'redux';
import {CHANGE_DRANK_BOTTOM_PLINTH} from '../../../../constants';
import {TSelectOption} from '../../../../../common-code/domain/types';
import {TDrankPlinth} from '../../../../../common-code/domain/types';
import {cnSelectInput, SelectInput} from '../../../all/SelectInput';

export const cnDrankPlintFragment = cn('DrankPlintFragment');

export interface IDrankPlintFragmentProps extends IClassNameProps {
    constructorFormData: IConstructorFormData,
    dispatch: Dispatch,
    options: TSelectOption[]
}

export const DrankPlinthFragment: React.FC<IDrankPlintFragmentProps> = ({
                                                                            className,
                                                                            constructorFormData,
                                                                            dispatch,
                                                                            options
                                                                        }) => {

    const changeDrankPlinth = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: CHANGE_DRANK_BOTTOM_PLINTH,
            payload: event.target.value as TDrankPlinth,
        });
    };

    return (
        <div className={cnConstructorForm(cnDrankPlintFragment(), [className])}>
            <SelectInput
                className={cnConstructorForm(cnSelectInput())}
                showPlaceholder={false}
                options={options}
                value={constructorFormData.drankPlinth as string}
                callBack={changeDrankPlinth}
                placeholder={'dsa'}
            />
            {/*<RadioboxInput*/}
            {/*    className={cnConstructorForm(cnRadioboxInput(), [cnRadioboxInput({flex: 'row'})])}*/}
            {/*    options={options}*/}
            {/*    value={constructorFormData.drankPlinth}*/}
            {/*    callBack={changeDrankPlint}*/}
            {/*/>*/}
        </div>
    );
};