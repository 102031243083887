import {IPoint} from "../../../../../../domain/intarfaces";
import {Vector3} from "three";
import {ThreeConstructor} from "../../../ThreeConstructor";
import {ThreeObject} from "../../ThreeObject";
import {ThreePanel, ThreeShelf} from "../../panels";
import {TConnectionType} from '../../../../../../common-code/domain/types/TConnectionType';
import {TPointType} from '../../../../../../common-code/domain/types/TPointType';
import {TPointConnection} from '../../../../../../common-code/domain/types/TPointConnection';
import {TLevel} from '../../../../../../common-code/domain/types/TLevel';
import {IPointData} from '../../../../../../common-code/domain/interfaces/IPointData/IPointData';

export class ThreePoint extends ThreeObject implements IPoint {
    connectionId: number;
    connectionType: TConnectionType;
    value: Vector3;
    sort: number;
    type: TPointType;
    isInit: boolean;
    panelId?: number;
    parentPanel?: ThreePanel | ThreeShelf;
    connection: TPointConnection;
    level?: TLevel;

    constructor(mainConstructor: ThreeConstructor, pointData: IPointData) {
        super(mainConstructor, pointData);
        this.sort = pointData.sort;
        this.type = pointData.type;
        this.connectionId = pointData.connectionId;
        this.connectionType = pointData.connectionType;
        this.connection = this.mainConstructor.getPointConnectionById(this.connectionId);
        this.value = new Vector3();
        this.isInit = false;
    }

    public isInited(): boolean
    {
        return this.isInit;
    }

    public getSaveData(): IPointData {
        super.getSaveData();
        return {
            connectionId: this.connectionId,
            connectionType: this.connectionType,
            sort: this.sort,
            type: this.type,
            id: this.id
        }
    }

    public getType(): TPointType
    {
        return this.type;
    }

    public setPosition()
    {
        this.view3d.position.copy(this.calculatePosition());
    }

    public calculatePosition(): Vector3 {
        if (!this.isInit) {
            this.isInit = true;
        }
        return this.value;
    }

    public calculateShiftPosition(): Vector3 {
        this.calculatePosition();

        return this.value;
    }

    public remove() {
        super.remove();
        this.mainConstructor.removeFromScene(this);
    }

    public setParentPanel(): ThreePanel | ThreeShelf {
        let parentPanel;

        if (!this.panelId) {
            throw new Error('ThreePanelPoint required parentPanel!');
        }
        parentPanel = this.mainConstructor.getPanelById(this.panelId);

        if (parentPanel) {
            if (parentPanel.isPanel) {
                this.parentPanel = parentPanel as ThreePanel;
            } else {
                this.parentPanel = parentPanel as ThreeShelf;
            }
        } else {
            throw new Error('ThreePanelPoint required parentPanel!');
        }

        return this.parentPanel;
    }

    public getParentPanel():ThreePanel | ThreeShelf | undefined {
        return this.parentPanel;
    }

    public getPosition(): Vector3
    {
        return this.value;
    }

    public getIndention(): number
    {
        // TODO потом тут нужно будет расчитывать схождение соединения
        return 0;
    }

}