export const COORDINATE_TYPE_2D = '2d';
export const COORDINATE_TYPE_3D = '3d';

export const AXIS_X = 'x';
export const AXIS_Y = 'y';
export const AXIS_Z = 'z';
export const INTERSECTION_SHIFT = 'shift';
export const INTERSECTION_SHIFT_RIGHT = 'shiftR';
export const INTERSECTION_LEFT_INDENTION = 'lIndent';
export const INTERSECTION_RIGHT_INDENTION = 'lIndent';
export const NEIGHBOR_TYPE_LEFT = 'left';
export const NEIGHBOR_TYPE_RIGHT = 'right';
