import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './RadioboxInput.css';
import {Radiobox, Radio} from '@yandex/ui/Radiobox/desktop/bundle';
import {TRadioboxOption} from '../../../../common-code/domain/types/TRadioboxOption';

export const cnRadioboxInput = cn('RadioboxInput');

export interface IRadioboxInputProps extends IClassNameProps {
    options: Array<TRadioboxOption>,
    value: string,
    callBack: (event: ChangeEvent<HTMLInputElement>) => void
}

export const RadioboxInput: React.FC<IRadioboxInputProps> = ({ className, options, value,  callBack }) => {
    return (
        <div className={cnRadioboxInput({ cg: true }, [className])}>
            <Radiobox
                size={'m'}
                view={'default'}
                value={value}
                onChange={callBack}
            >
                {options.map((option, index) => {
                    return (
                        <Radio
                            key={index}
                            value={option.value}
                        >
                            {option.text}
                        </Radio>);
                })}
            </Radiobox>
        </div>
    );
};