import {ThreeFiller} from "../ThreeFiller";
import {IDoorFiller} from "../../../../../../domain/intarfaces/IDoorFiller/IDoorFiller";
import {ThreeDoor} from "../../ThreeDoor/ThreeDoor";
import {
    Box2,
    BufferGeometry,
    EdgesGeometry,
    ExtrudeGeometry, LineBasicMaterial,
    LineSegments,
    Mesh,
    Shape,
    Vector2
} from "three";
import {ThreeGeometry} from "../../../helpers";
import {AXIS_X, AXIS_Y} from "../../../../../../domain/constants";
import {IDoorFillerSegmentInfo} from "../../../interfaces/IDoorFillerSegmentInfo/IDoorFillerSegmentInfo";
import {LineBasicMaterialParameters} from "three/src/materials/LineBasicMaterial";
import {TPanelShift} from '../../../../../../common-code/domain/types';
import {TFillerSegmentData} from '../../../../../../common-code/domain/types';
import {IDoorFillerData} from '../../../../../../common-code/domain/interfaces/IDoorFillerData/IDoorFillerData';
import {ISquareEntityData} from '../../../../../../common-code/domain/interfaces/ISquareEntityData';
import {IStartEndPoints} from "../../../../../../common-code/domain/interfaces/IStartEndPoints";
import {TMaterialType} from '../../../../../../common-code/domain/types/TMaterialType';
import {MATERIAL_TYPE_LDSP, MATERIAL_TYPE_MIRROR} from '../../../../../../common-code/constants';


export class ThreeDoorFiller extends ThreeFiller implements IDoorFiller {
    segments: IDoorFillerSegmentInfo[];
    points: Vector2[];
    initData: IDoorFillerData;
    door: ThreeDoor;
    shape?: Shape;
    depth: number;
    shift: TPanelShift;
    sort: number;
    materialType: TMaterialType;

    constructor(door: ThreeDoor, options: IDoorFillerData) {
        super(door.doorContainer.sideWardrobe, options);
        this.door = door;
        this.initData = options;
        this.segments = [];
        this.points = [];
        this.depth = options.depth;
        this.shift = options.shift;
        this.sort = options.sort;
        this.materialId = options.materialId;
        this.materialType = options.materialType;
        this.material = this.initMaterialData();
    }

    public initState() {
        this.setSegments();
        this.calculatePoints();
    }


    public getSaveData(): IDoorFillerData {
        super.getSaveData();
        let globalPoints: IStartEndPoints | undefined;
        globalPoints = this.calculateGlobalPoints();

        return {
            id: this.getId(),
            depth: this.depth,
            shift: this.shift,
            sort: this.sort,
            materialId: this.materialId,
            productCode: this.getProductCode(),
            materialType: this.materialType,
            segments: this.getSegmentsSaveData(),
            startPoint: globalPoints?.start,
            endPoint: globalPoints?.end,
            directionType: this.calculateDirectionType(globalPoints),
        };
    }

    protected initMaterialData(): ISquareEntityData | undefined {
        switch (this.materialType) {
            case MATERIAL_TYPE_MIRROR:
                return this.mainConstructor.getMirrorById(this.materialId);
            case MATERIAL_TYPE_LDSP:
            default:
                return this.mainConstructor.getLdspById(this.materialId);
        }
    }

    protected getSegmentsSaveData(): TFillerSegmentData[] {
        let segments: TFillerSegmentData[] = [];
        let segmentInfo: IDoorFillerSegmentInfo;

        for (segmentInfo of this.segments) {
            segments.push(segmentInfo.info);
        }

        return segments;
    }

    protected createBodyMaterial() {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.mainConstructor.getConstructorService().getDoorFillerBodyMaterial(this.materialId, this.materialType);
        }

        return this.bodyMaterial;
    }

    protected setSegments() {
        let segmentData;

        for (segmentData of this.initData.segments) {
            this.segments.push({
                segment: this.door.getProfileById(segmentData.segmentId),
                info: segmentData,
                connection: this.mainConstructor.getFillerConnectionById(segmentData.connectionId)
            });
        }
        this.segments.sort((a, b) => a.info.sort - b.info.sort);
    }

    protected calculatePoints() {
        let index;
        let nextSegmentInfo: IDoorFillerSegmentInfo;
        let currentSegmentInfo: IDoorFillerSegmentInfo;
        let leftSegmentPoints;
        let rightSegmentPoints;
        let intersectPoint;

        for (index in this.segments) {
            currentSegmentInfo = this.segments[index];
            if (this.segments[+index + 1]) {
                nextSegmentInfo = this.segments[+index + 1];
            } else {
                nextSegmentInfo = this.segments[0];
            }
            currentSegmentInfo.segment.calculatePoints();
            nextSegmentInfo.segment.calculatePoints();
            if (currentSegmentInfo.segment.points && nextSegmentInfo.segment.points) {
                leftSegmentPoints = currentSegmentInfo.info.direction ?
                    {
                        pointA: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.C, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.D, AXIS_X, AXIS_Y)
                    } :
                    {
                        pointA: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.B, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(currentSegmentInfo.segment.points.A, AXIS_X, AXIS_Y)
                    };
                rightSegmentPoints = nextSegmentInfo.info.direction ?
                    {
                        pointA: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.C, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.D, AXIS_X, AXIS_Y)
                    } :
                    {
                        pointA: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.B, AXIS_X, AXIS_Y),
                        pointB: ThreeGeometry.toVector2D(nextSegmentInfo.segment.points.A, AXIS_X, AXIS_Y)
                    };

                leftSegmentPoints = ThreeGeometry.getParallelLine(
                    leftSegmentPoints,
                    -this.mainConstructor.getConstructorService().getIndentionValue(
                        currentSegmentInfo.connection.indention,
                        currentSegmentInfo.segment
                    )
                );
                rightSegmentPoints = ThreeGeometry.getParallelLine(
                    rightSegmentPoints,
                    -this.mainConstructor.getConstructorService().getIndentionValue(
                        nextSegmentInfo.connection.indention,
                        nextSegmentInfo.segment
                    )
                );
                intersectPoint = ThreeGeometry.getIntersectionPoint(leftSegmentPoints, rightSegmentPoints);
                if (!intersectPoint && ThreeGeometry.isEqualThreeLines(leftSegmentPoints, rightSegmentPoints)) {
                    intersectPoint = ThreeGeometry.getNearPoint2D(
                        leftSegmentPoints.pointA,
                        [rightSegmentPoints.pointA, rightSegmentPoints.pointB]
                    );
                }
                if (!intersectPoint) {
                    throw new Error('Not connected segments in filler');
                } else {
                    this.points.push(intersectPoint.clone());
                }
            } else {
                throw new Error('Not calculated points in segments in filler');
            }
        }
    }

    protected createShape() {
        if (this.points.length <= 0) {
            return;
        }
        this.shape = new Shape();
        this.shape.setFromPoints(this.points);
    }

    protected createBody() {
        if (!this.shape) {
            return;
        }
        let body;
        let box;
        let bodyPosition;

        body = new Mesh(this.createBodyGeometry(), this.createBodyMaterial());
        box = new Box2().setFromPoints(this.points);
        bodyPosition = new Vector2();
        bodyPosition = box.getCenter(bodyPosition);
        body.position.set(bodyPosition.x, bodyPosition.y, this.depth + this.shift.depth);
        body.add(this.createCarcassMesh(body.geometry));
        this.view3d.add(body);
    }

    protected createCarcassMesh(geometry: BufferGeometry, materialParameters?: LineBasicMaterialParameters): LineSegments {
        if (!materialParameters) {
            materialParameters = {color: '#0000FF', linewidth: 3, transparent: true, opacity: 0.3};
        }
        return new LineSegments(
            new EdgesGeometry(geometry),
            new LineBasicMaterial(materialParameters)
        );
    }

    protected createBodyGeometry() {
        let geometry;

        geometry = new ExtrudeGeometry(this.shape, {depth: this.depth, bevelEnabled: false});
        geometry.center();

        return geometry;
    }

}