import React from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import "./ProjectSpec.css";
import {useTranslation} from 'react-i18next';
import {ThreeConstructor} from '../../3d';
import {ProjectSpecContent} from './Content/ProjectSpec-Content';

export const cnProjectSpec = cn('ProjectSpec');

export interface IPropSpecProps extends IClassNameProps {
    threeConstructor: ThreeConstructor;
}

export const ProjectSpec: React.FC<IPropSpecProps> = ({threeConstructor, className}) => {
    const {t} = useTranslation();

    const renderTableHeader = () => {
        return (
            <div
                className={cnProjectSpec('TableHeader', {size: 'big'})}
            >
                <p className={cnProjectSpec('TableCell', {offset: 'small'})}>{t('№')}</p>
                <p className={cnProjectSpec('TableCell', {justify: 'start'})}>{t('Код товара')}</p>
                <p className={cnProjectSpec('TableCell', {justify: 'start', size: 'big'})}>{t('Наименование')}</p>
                <p className={cnProjectSpec('TableCell')}>{t('Количество')}</p>
                <p className={cnProjectSpec('TableCell')}>{t('Цена')}</p>
                <p className={cnProjectSpec('TableCell')}>{t('Стоимость')}</p>
                {/*<p className={cnProjectSpec('TableCell')}>{t('Склад')}</p>*/}
            </div>
        );
    }

    return (
        <div className={cnProjectSpec({}, [className])}>
            <div className={cnProjectSpec('Title')}>
                {t('Спецификация проекта')}
            </div>
            <div className={cnProjectSpec('Content')}>
                {renderTableHeader()}
                <ProjectSpecContent projectSpec={threeConstructor.getWardrobeProjectSpec()}/>
            </div>
        </div>
    );
}